import { invoiceEntities } from '@/components/ContractBilling/BaseStore/contractsBase';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, filterable: true, visible: true, width: 75, alignment: 'center',
    }, {
      prop: 'name', label: 'Contract Name', sortable: true, filterable: true, visible: true, width: 200,
    }, {
      prop: 'invoiceEntityName', label: 'InvoiceEntity', sortable: true, filterable: true, visible: true, width: 200,
    }, {
      prop: 'contractGroupName', label: 'Group', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'startDate', label: 'Start Date', sortable: true, filterable: true, visible: true, dataType: 'date', width: 100,
    }, {
      prop: 'endDate', label: 'End Date', sortable: true, filterable: true, visible: true, dataType: 'date', width: 100,
    }, {
      prop: 'contractCategory', label: 'Contract Category', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'serviceAgreement', label: 'ServiceAgreement', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'statClass', label: 'StatClass', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'energyRecievedFrom', label: 'Energy Recieved From', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'energyDeliveredTo', label: 'Energy Delivered To', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'calcGranularity', label: 'Calc Granularity', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'calcTermStartMonthDay', label: 'Term Start MonthDay', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'calcTermEndMonthDay', label: 'Term End MonthDay', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'calcType', label: 'Calc Type', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'scid', label: 'SCID', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'baid', label: 'BAID', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'eimSub', label: 'EIM SUB', sortable: true, filterable: true, visible: true, width: 100,
    }, {
      prop: 'transactionSource', label: 'TransactionSource', sortable: true, filterable: true, visible: true, width: 150,
    }, {
      prop: 'attribute1', label: 'Attribute1', sortable: true, filterable: true, visible: true, width: 150,
    }, {
      prop: 'attribute2', label: 'Attribute2', sortable: true, filterable: true, visible: true, width: 150,
    }, {
      prop: 'attribute3', label: 'Attribute3', sortable: true, filterable: true, visible: true, width: 150,
    }, {
      prop: 'attribute4', label: 'Attribute4', sortable: true, filterable: true, visible: true, width: 150,
    }, {
      prop: 'attribute5', label: 'Attribute5', sortable: true, filterable: true, visible: true, width: 150,
    }, {
      prop: 'amountRounding', label: 'AMT ROUNDING', sortable: true, filterable: true, visible: true, width: 75,
    }, {
      prop: 'volumeRounding', label: 'VOL ROUNDING', sortable: true, filterable: true, visible: true, width: 75,
    }, {
      prop: 'createdInfo', label: 'Created By', sortable: true, filterable: true, visible: true, width: 170,
    }, {
      prop: 'updatedInfo', label: 'Updated By', sortable: true, filterable: true, visible: true, width: 170,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
  searchFiltersConfig: {
    invoiceEntities,
  },
};