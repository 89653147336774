const reporting: any = {
  createAReport: {
    baseReports: {
      cmri: 'reporting:create_a_report:base:cmri',
      allRulesVAReport: 'reporting:va:base:allRulesVAReport',
      rtmMarketBehavior: 'reporting:create_a_report:base:rtmMarketBehavior',
      lmpVsBids: 'reporting:create_a_report:base:lmp_vs_bids',
    },
    customReports: {
      customAllocationSummary: 'reporting:create_a_report:custom:customAllocationSummary',
      customAllocationInvoice: 'reporting:create_a_report:custom:allocation_invoice',
      customBD: 'reporting:create_a_report:custom:customBD',
      pricingReportWeb: 'reporting:create_a_report:custom:pricingReportWeb',
      eqr: 'reporting:create_a_report:custom:eqr',
      eimSubAllocBD: 'reporting:create_a_report:custom:eimSubAllocBD',
      eimSubAllocMappingExceptions: 'reporting:create_a_report:custom:eimSubAllocMapExceptions',
      eimSufficiencyCheck: 'reporting:create_a_report:custom:eimSufficiencyCheck',
      ferc1: 'reporting:create_a_report:custom:ferc_1',
      ferc714: 'reporting:create_a_report:custom:ferc_714',
      hourlyDynamicEtsr: 'reporting:create_a_report:hourly_dynamic_etsr',
      invoiceVsStatement: 'reporting:create_a_report:custom:invoice_vs_statement',
      smudInvoiceVsStatement: 'reporting:create_a_report:custom:smud_invoice_vs_statement',
      ists: 'reporting:create_a_report:custom:ISTS',
      meterValidation: 'reporting:create_a_report:custom:meter_validation',
      nveShadow: 'reporting:create_a_report:custom:nve_shadow',
      oasisPriceNode: 'reporting:create_a_report:custom:oasis_price_node',
      damVsRtmLmp: 'reporting:create_a_report:custom:damVsRtmLmp',
      pcaam: 'reporting:create_a_report:custom:pcaam',
      statementVsShadow: 'reporting:create_a_report:custom:statement_vs_shadow',
      testReport: 'reporting:create_a_report:custom:test_report',
      lmpOmsRevenue: 'reporting:create_a_report:custom:lmp_oms_revenue',
      gasRsrcStartUpsShutDowns: 'reporting:create_a_report:gas_rsrc_start_ups_shut_downs',
      verMaster: 'reporting:create_a_report:custom:ver_master',
      ghg: 'reporting:create_a_report:ghg',
      eimFerc: 'reporting:create_a_report:custom:eimFerc',
      congestionCollections: 'reporting:create_a_report:custom:congestionCollections',
      rtieoCongestionReport: 'reporting:create_a_report:custom:rtieoCongestionReport',
      renewableRevenueAnalysis: 'reporting:create_a_report:custom:renewable_revenue_analysis',
      batteryRevenueAnalysis: 'reporting:create_a_report:custom:battery_revenue_analysis',
      ufeReport: 'reporting:create_a_report:custom:ufeReport',
      eimTransferCustomReport: 'reporting:create_a_report:custom:eimTransferCustomReport',
      eimAtfCompare: 'reporting:create_a_report:custom:eimAtfCompare',
      rtcoReport: 'reporting:create_a_report:custom:rtcoReport',
      transmissionPricing: 'reporting:create_a_report:custom:transmissionPricing',
      virtualAwardsReport: 'reporting:create_a_report:custom:virtualAwardsReport',
      eimHydroAdjustment: 'reporting:create_a_report:custom:eimHydroAdjustment',
      hourlyAwardDispatch: 'reporting:create_a_report:custom:hourlyAwardDispatch',
      eimTransferTieFlag: 'reporting:create_a_report:custom:eimTransferTieFlag',
      eimStatementPnL: 'reporting:create_a_report:custom:eimStatementPnL',
      settlementReport: 'reporting:create_a_report:custom:settlementReport',
      eimResourceAllocations: 'reporting:create_a_report:custom:eimResourceAllocationsReport',
      settlementAuditTrail: 'reporting:create_a_report:custom:settlementAuditTrail',
      hybridRevenueAnalysis: 'reporting:create_a_report:custom:hybrid_revenue_analysis',
      nprLoad: 'reporting:create_a_report:custom:nprLoad',
      lptSelfScheduleExports: 'reporting:create_a_report:custom:lptSelfScheduleExports',
      salesPurchases: 'reporting:create_a_report:salesPurchases',
      purchaseAndSaleSummary: 'reporting:create_a_report:custom:purchaseAndSaleSummary',
      hourlyAwardDispatchCustomReport: 'reporting:create_a_report:custom:hourly_award_dispatch_custom_report',
      marketBaseSchedulesReport: 'reporting:create_a_report:custom:market_base_schedules_report',
      manualDispatch: 'reporting:create_a_report:manual_dispatch',
      monthlyTcInvoice: 'reporting:create_a_report:custom:monthly_tc_invoice_report',
      visualAnalyticsReport: 'reporting:create_a_report:custom:visualAnalyticsReport',
      economicCurtailmentReport: 'reporting:create_a_report:custom:economic_curtailment_report',
      caisoRrscTagReport: 'reporting:create_a_report:custom:caisoRsrcTagReport',
      lmpVsAllBidsPrice: 'reporting:create_a_report:custom:lmp_vs_all_bids_price',
      settlementsOperationMonthlyReport: 'reporting:create_a_report:custom:settlements_operation_monthly_report',
      cmriCustomSceReports: 'reporting:create_a_report:custom:cmri_custom_sce_reports',
      price_bd: 'reporting:create_a_report:custom:price-bd-compare',
      pi_1_minute_report: 'reporting:create_a_report:custom:pi_1_minute_report',
      congestion_Offset: 'reporting:create_a_report:custom:congestion-Offset',
      dayAheadToBaseScheduleDeviationReport: 'reporting:create_a_report:custom:day_ahead_to_base_schedule_deviation_report',
      transmissionMeterScheduleReport: 'reporting:create_a_report:custom:transmission_meter_schedule_report',
      daAllocationStateRatioReport: 'reporting:create_a_report:custom:da_allocation_state_ratio_report',
      customOasisMissingPriceAudit: 'reporting:create_a_report:custom:oasis_missing_price_audit',
      customEnergyOffsetBdReport: 'reporting:create_a_report:custom:custom_energy_offset_bd_report',
      dailyMarketSnapshotReport: 'reporting:create_a_report:custom:daily-market-snapshot-report',
      transmissionAllocationCalculationDetailsReport: 'reporting:create_a_report:custom:transmission_allocation_calculation_details_report',
      customPricingGroup: 'reporting:create_a_report:custom:oasis_pricing_group',
      fercElectricityQuarterly: 'reporting:create_a_report:custom:ferc_electricity_quarterly',
      customStateAllocationGranularity: 'reporting:create_a_report:custom:state-allocation-granularity',
      customAllocationReportByState: 'reporting:create_a_report:custom:allocation-report-by-state',
      customLAPPriceReport: 'reporting:create_a_report:custom:lap-price-report',
      customADSResourceReport: 'reporting:create_a_report:custom:ads-resource-report',
      burbank_ameresco: 'reporting:create_a_report:custom:burbank_ameresco',
      kngbrd_ameresco: 'reporting:create_a_report:custom:kngbrd_ameresco',
      transmissionAllocationSubCompanyReport: 'reporting:create_a_report:custom:transmission_allocation_subcompany_report',
      eimSalesPurchases: 'reporting:create_a_report:custom:eim_sales_purchases',
      settlementDetailReport: 'reporting:create_a_report:custom:settlement_detail_report',
      congestionPriceReport: 'reporting:create_a_report:custom:congestion_price_report',
      samcInvoicePdfReport: 'reporting:create_a_report:custom:samc_invoice_pdf_report',
      ancillary_service_report: 'reporting:create_a_report:custom:ancillary_service_report',
      loadReport: 'reporting:create_a_report:custom:load_report',
      crr_report: 'reporting:create_a_report:custom:crr_report',
      marketValueReport: 'reporting:create_a_report:custom:market_value_report',
      operationStatementReport: 'reporting:create_a_report:custom:operation_statement_report',
      bidCostRecoveryReport: 'reporting:create_a_report:custom:bid_cost_recovery_report',
      adsExceptionalDispatchReport: 'reporting:create_a_report:custom:ads_exceptional_dispatch_report',
      netPositionReports: 'reporting:create_a_report:custom:net_position_reports',
      tagSettlementCompareQuantityReport: 'reporting:create_a_report:custom:tag_settlement_compare_quantity_report',
      eimAnalyticsDaily: 'reporting:create_a_report:custom:eim_analytics_daily',
      meteringVEE: 'reporting:create_a_report:custom:metering_vee',
      ccToGlReport: 'reporting:create_a_report:custom:cc_to_gl_report',
      transferAndPricesReport: 'reporting:create_a_report:custom:transfer_and_prices_report',
      isoTotalsCostReport: 'reporting:create_a_report:custom:iso_totals_cost_report',
      monthlyStatementQuantityReport: 'reporting:create_a_report:custom:monthly_statement_quantity_report',
      weeklyInvoiceReport: 'reporting:create_a_report:custom:weekly_invoice_report',
      meterSourceCompareReport: 'reporting:create_a_report:custom:meter_source_compare_report',
      iieTagSettlementsQuantityCompare: 'reporting:create_a_report:custom:iie_tag_settlements_quantity_compare',
      baaRollup6045Report: 'reporting:create_a_report:custom:baa_rollup_6045_report',
      marketInvoiceCustomReport: 'reporting:create_a_report:custom:market_invoice_custom',
    },
  },
  contractBilling: {
    baseReports: {
      chargeSummary: 'reporting:cbm:base:charge_summary',
      customerMasterfile: 'reporting:cbm:base:customer_masterfile',
      drmtData: 'reporting:cbm:base:drmt_data',
      invoiceSummary: 'reporting:cbm:base:invoice_summary',
      meterData: 'reporting:cbm:base:meter_data',
      priceData: 'reporting:cbm:base:price_data',
      reservationData: 'reporting:cbm:base:reservation_data',
      subledger: 'reporting:cbm:base:subledger',
    },
    customReports: {
      testReport: 'reporting:cbm:custom:test_report',
      apsdr: 'reporting:cbm:custom:aps_dr',
      ferc1: 'reporting:cbm:custom:ferc_1',
      fsolegacy: 'reporting:cbm:custom:fso_legacy',
      pbiinvoice: 'reporting:cbm:custom:pbi_invoice',
      numonthlybilling: 'reporting:cbm:custom:numonthlybilling',
      monthlyBillingSummary: 'reporting:cbm:custom:monthly_billing_summary',
      aging: 'reporting:cbm:custom:aging_custom_report',
      invoicesuballoc: 'reporting:cbm:custom:invoice_suballoc_report',
      invoiceCustomSummary: 'reporting:cbm:custom:invoice_custom_summary_report',
      scheduleLossDetail: 'reporting:cbm:custom:schedule_loss_detail',
    },
  },
  etrm: {
    baseReports: {
      gasStandardReport: 'reporting:etrm:base:gas_standard_report',
      accountingStandardReport: 'reporting:etrm:base:accounting_standard_report',
      recsStandardReport: 'reporting:etrm:base:recs_standard_report',
    },
    customReports: {
      interchange: 'reporting:etrm:custom:interchange',
      gaslogbalancing: 'reporting:etrm:custom:gas_log_balancing',
      wspp: 'reporting:etrm:custom:wspp',
      wsppSummary: 'reporting:etrm:custom:wspp_summary',
      scheduledEnergyWithPrices: 'reporting:etrm:custom:scheduled_energy_with_prices',
    },
  },
  ppa: {
    baseReports: {
      subledger: 'reporting:ppa:base:subledger',
      ferc: 'reporting:ppa:base:ppa_ferc',
      mv90: 'reporting:ppa:base:ppa_mv90',
      oasisLossesDue: 'reporting:ppa:base:ppa_oasis_losses_due',
      oasisPhysicalLosses: 'reporting:ppa:base:oasis_physical_losses',
      oasisPurchaseRequest: 'reporting:ppa:base:oasis_purchase_request',
      oasisMonthlyPeak: 'reporting:ppa:base:oasis_monthly_peak',
      imbalance: 'reporting:ppa:base:imbalance',
      hourlyInvoiceDetail: 'reporting:ppa:base:houlryInvoiceDetail',
      invoiceSummary: 'reporting:ppa:base:invoicesummary',
      webTrans: 'reporting:ppa:base:webTrans',
      wregis: 'reporting:ppa:base:wregis',
      measuredDemand: 'reporting:ppa:base:measuredDemand',
      monthlyTotals: 'reporting:ppa:base:monthlyTotals',
    },
    customReports: {
      oasisWheelingRev: 'reporting:ppa:custom:oasis_wheeling_rev',
      customCsvReport: 'reporting:ppa:custom:custom_cvs_report',
    },
  },
  spp: {
    baseReports: {
      market: 'reporting:spp:base:market',
      meter: 'reporting:spp:base:meter',
      statement: 'reporting:spp:base:stlmt_statement',
      invoice: 'reporting:spp:base:stlmt_invoice',
      determinant: 'reporting:spp:base:stlmt_determinant',
    },
  },
  visualAnalytics: {
    baseReports: {
    },
    customReports: {
      allRulesReport: 'reporting:va:custom:all_rules_report',
      pricesAndAmountsReport: 'reporting:va:custom:prices_and_amounts_report',
    },
  },
};

export default reporting;