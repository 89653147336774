import { fetchResource, updateResource } from '@/utils/structuresHelper';
import { STRUCTURES_API } from '@/api';
import LOOKUP_STORE from '@/store/lookupStore';
import { sortBy } from '@/utils/dataUtil';
import defaultCustomTradeConfig from './defaultCustomTradeConfig';

const state = {
  screens: [],
  selectedScreen: null,
  intervalStartDateOptions: [
    { value: 'today', label: 'Today' },
    { value: 'tomorrow', label: 'Tomorrow' },
    { value: 'tradeStart', label: 'Trade Start Date' },
    { value: 'searchStart', label: 'Search Start Date' },
  ],
  tradeTermGroupOptions: [],
  commodityOptions: [],
  marketOptions: [],
  companyOptions: [],
  counterpartyOptions: [],
  brokerOptions: [],
  regionOptions: [],
  portfolioOptions: [],
  bookOptions: [],
  strategyOptions: [],
  variantOptions: [],
  variantTypeOptions: [],
};

const getters = {};

const actions = {
  async fetchTradeScreens({ commit }) {
    var screens = [defaultCustomTradeConfig];
    
    const { data } = await fetchResource('tradeScreens', 'SCREENS');

    if (Array.isArray(data)) {

      // we need to make sure we never load "Default Custom" from the database
      // always use default from defaultCustomTradeConfig.js
      const idx = data.findIndex(({ name }) => name === defaultCustomTradeConfig.name);
      if (idx !== -1)
        data.splice(idx, 1);
      
      screens = [defaultCustomTradeConfig, ...data];
    }
    
    commit('setScreens', screens);
  },
  async updateTradeScreen({ commit, state }, screen) {
    const screensCopy = [...state.screens];
    const screenIdx = screensCopy.findIndex(({ id }) => id === screen.id);
    screensCopy.splice(screenIdx, 1, screen);

    const payload = { data: screensCopy };
    payload.data.forEach((s, idx) => {
      s.id = idx;
    });
    payload.data = payload.data.filter((data) => data.path !== 'default-custom');
    const { data } = await updateResource('tradeScreens', 'SCREENS', payload);
    if (Array.isArray(data)) commit('setScreens', [defaultCustomTradeConfig, ...data]);
  },
  async createTradeScreen({ commit, state }, screen) {
    const screens = [...state.screens, screen].sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    const payload = { data: screens };
    payload.data.forEach((s, idx) => {
      s.id = idx;
    });
    // Remove default config before updating Resource
    payload.data = payload.data.filter((data) => data.path !== 'default-custom');
    const { data } = await updateResource('tradeScreens', 'SCREENS', payload);
    if (Array.isArray(data)) commit('setScreens', [defaultCustomTradeConfig, ...data]);
  },
  async deleteScreen({ commit, state }, screen) {
    const screens = [...state.screens];
    const screenIdx = screens.findIndex(({ name }) => name === screen.name);
    screens.splice(screenIdx, 1);

    const payload = { data: screens };
    payload.data.forEach((s, idx) => {
      s.id = idx;
    });
    const { data } = await updateResource('tradeScreens', 'SCREENS', payload);
    if (Array.isArray(data)) commit('setScreens', data);
  },

  async fetchTradeTermGroupList({ commit }) {
    commit('setTradeTermGroupOptions', LOOKUP_STORE.state.tradeTermGroupList
      .map(({ name }) => ({ label: name, value: name })));
  },
  async fetchEnergyCommodityList({ commit }) {
    commit('setCommodityOptions', LOOKUP_STORE.state.energyCommodityList
      .map(({ shortName, id }) => ({ label: shortName, value: id })));
  },
  async fetchMarketList({ commit }) {
    commit('setMarketOptions', LOOKUP_STORE.state.marketList
      .map((market) => ({ label: market.shortName, value: market.id, ...market })));
  },
  async fetchCompanyList({ commit }) {
    commit('setCompanyOptions', LOOKUP_STORE.state.companyList
      .filter(({ internalFlag }) => internalFlag)
      .map((company) => ({ label: company.shortName, value: company.id })));
  },
  async fetchCounterpartyList({ commit }) {
    commit('setCounterpartyOptions', LOOKUP_STORE.state.companyList
      .map((counterparty) => ({ label: counterparty.shortName, value: counterparty.id })));
  },
  async fetchBrokerList({ commit }) {
    commit('setBrokerOptions', LOOKUP_STORE.state.brokerList
      .map((broker) => ({ label: broker.name, value: broker.id })));
  },
  async fetchRegionList({ commit }) {
    commit('setRegionOptions', LOOKUP_STORE.state.marketList
      .map((region) => ({ label: region.shortName, value: region.id, ...region })));
  },
  async fetchPortfolioList({ commit }) {
    commit('setPortfolioOptions', LOOKUP_STORE.state.catalogList
      .filter((catalog) => !!catalog.type && catalog.type.toLowerCase() === 'portfolio')
      .map((portfolio) => ({ label: portfolio.name, value: portfolio.id })));
  },
  async fetchBookList({ commit }) {
    commit('setBookOptions', LOOKUP_STORE.state.catalogList
      .filter((catalog) => !!catalog.type && catalog.type.toLowerCase() === 'book')
      .map((book) => ({ label: book.name, value: book.id })));
  },
  async fetchStrategyList({ commit }) {
    commit('setStrategyOptions', LOOKUP_STORE.state.catalogList
      .filter((catalog) => !!catalog.type && catalog.type.toLowerCase() === 'strategy')
      .map((strategy) => ({ label: strategy.name, value: strategy.id })));
  },
  async fetchVariants({ commit }) {
    try {
      const { data: { data } } = await STRUCTURES_API.get('/variant?type=TradeTerm');
      commit('setVariantOptions', data
        .sort(sortBy('name'))
        .map((variant) => ({ prop: variant.name, label: variant.name })));
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setScreens(state, value) {
    state.screens = value;
  },
  setSelectedScreen(state, value) {
    state.selectedScreen = value;
  },
  setTradeTermGroupOptions(state, value) {
    state.tradeTermGroupOptions = value;
  },
  setCommodityOptions(state, value) {
    state.commodityOptions = value;
  },
  setMarketOptions(state, value) {
    state.marketOptions = value;
  },
  setCompanyOptions(state, value) {
    state.companyOptions = value;
  },
  setCounterpartyOptions(state, value) {
    state.counterpartyOptions = value;
  },
  setBrokerOptions(state, value) {
    state.brokerOptions = value;
  },
  setRegionOptions(state, value) {
    state.regionOptions = value;
  },
  setPortfolioOptions(state, value) {
    state.portfolioOptions = value;
  },
  setBookOptions(state, value) {
    state.bookOptions = value;
  },
  setStrategyOptions(state, value) {
    state.strategyOptions = value;
  },
  setVariantOptions(state, value) {
    state.variantOptions = value;
  },
  setVariantTypeOptions(state, value) {
    state.variantTypeOptions = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};