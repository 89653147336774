import { accessRight, lookup, recs } from '@/auth/permission';

export default {
  path: '/reference-data',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'REFERENCE DATA',
    permission: { name: lookup.ui, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [
    // {
    //   path: 'collections',
    //   name: 'Collections',
    //   meta: {
    //     visible: true,
    //     requiresAuth: true,
    //     text: 'Collections',
    //     permission: { name: 'lookup:collection', rights: [accessRight.read, accessRight.write] },
    //   },
    //   component: () => import('@/components/ReferenceDataManager/Collections/Collections'),
    // },
    // {
    //   path: 'resourcegroups',
    //   name: 'ResourceGroups',
    //   meta: {
    //     visible: true,
    //     requiresAuth: true,
    //     text: 'Resource Groups',
    //     permission: { name: 'identity', rights: [accessRight.read, accessRight.write] },
    //   },
    //   component: () => import('@/components/ReferenceDataManager/ResourceGroups/ResourceGroups'),
    // },
    // {
    //   path: 'resources',
    //   name: 'Resource Summary',
    //   meta: {
    //     visible: true,
    //     requiresAuth: true,
    //     text: 'Resources',
    //     permission: { name: 'identity', rights: [accessRight.read, accessRight.write] },
    //   },
    //   component: () => import('@/components/ReferenceDataManager/Resources/Resources'),
    // },
    // {
    //   path: 'resource-heat-rates',
    //   name: 'ResourceHeatRates',
    //   meta: {
    //     visible: true,
    //     requiresAuth: true,
    //     text: 'Resource Heat Rates',
    //     permission: { name: 'identity', rights: [accessRight.read, accessRight.write] },
    //   },
    //   component: () => import('@/components/ReferenceDataManager/ResourceHeatRates/ResourceHeatRates'),
    // },
    // {
    //   path: 'settle-price-indexes',
    //   name: 'SettlePriceIndexes',
    //   meta: {
    //     visible: true,
    //     requiresAuth: true,
    //     text: 'Settle Price Indexes',
    //     permission: { name: 'lookup:settle-price-index', rights: [accessRight.read, accessRight.write] },
    //   },
    //   component: () => import('@/components/ReferenceDataManager/SettlePriceIndexes/SettlePriceIndexes'),
    // },
    {
      path: 'recs-management',
      meta: {
        visible: true,
        requiresAuth: false,
        text: 'REC Module',
        permission: { name: recs.ui, rights: [accessRight.visible] },
      },
      component: () => import('@/components/InnerView'),
      children: [{
        path: 'organizations',
        name: 'organizations',
        meta: {
          visible: true,
          requiresAuth: true,
          text: 'Organization',
          permission: { name: recs.organization, rights: [accessRight.read, accessRight.write] },
        },
        component: () => import('@/components/RecsManagement/Organization/Organizations'),
      }]
    },
    {
      path: 'brokers',
      name: 'brokers',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Brokers',
        permission: { name: lookup.broker, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/Brokers/Brokers'),
    }, {
      path: 'commodities',
      name: 'commodities',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Commodities',
        permission: { name: lookup.commodity, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/CommodityTypes/CommodityTypes'),
    }, {
      path: 'companies',
      name: 'Companies',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Companies',
        permission: { name: lookup.company, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/Companies/Companies'),
    }, {
      path: 'company-contacts',
      name: 'CompanyContacts',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Company Contacts',
        permission: { name: lookup.companyContact, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/Contacts/CompanyContacts'),
    }, {
      path: 'contract-groups',
      name: 'ContractGroups',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Contract Groups',
        permission: { name: lookup.contractCoordinator, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/ContractCoordinators/ContractCoordinators'),
    }, {
      path: 'contract-types',
      name: 'ContractTypes',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Contract Types',
        permission: { name: lookup.contractType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/ContractTypes/ContractTypes'),
    }, {
      path: 'contract-state-types',
      name: 'ContractStateTypes',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Contract State Types',
        permission: { name: lookup.contractStateType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/ContractStateTypes/ContractStateTypes'),
    }, {
      path: 'currencies',
      name: 'Currencies',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Currencies',
        permission: { name: lookup.currency, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'Currency',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'deliverable-type',
      name: 'DeliverableType',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Deliverable Types',
        permission: { name: lookup.deliverableType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/DeliverableType/DeliverableType'),
    }, {
      path: 'renewable-sub-accounts',
      name: 'RenewableSubAccounts',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Environmental Sub Accounts',
        permission: { name: lookup.renewableSubAccount, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/EnvironmentalSubAccounts/RenewableSubAccounts'),
    }, {
      path: 'event-types',
      name: 'EventType',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Event Types',
        permission: { name: lookup.eventType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/EventTypes/EventTypes'),
    }, {
      path: 'trade-types',
      name: 'TradeType',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Trade Types',
        permission: { name: lookup.tradeType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/TradeTypes/TradeTypes'),
    }, {
      path: 'firm-types',
      name: 'FirmType',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Firm Types',
        permission: { name: lookup.firmType, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'FirmType',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'fuel-types',
      name: 'FuelType',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Fuel Types',
        permission: { name: lookup.fuelType, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'fuelType',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'forecast-sources',
      name: 'ForecastSource',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Forecast Sources',
        permission: { name: lookup.firmType, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'ForecastSource',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'ghg-sub-accounts',
      name: 'GhgSubAccounts',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'GHG Sub Accounts',
        permission: { name: lookup.ghgSubAccount, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/EnvironmentalSubAccounts/GhgSubAccounts'),
    }, {
      path: 'locations',
      name: 'Locations',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Locations',
        permission: { name: lookup.location, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/Locations/Locations'),
    }, {
      path: 'location-groups',
      name: 'LocationGroups',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Location Groups',
        permission: { name: lookup.locationGroup, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/LocationGroups/LocationGroups'),
    }, {
      path: 'location-types',
      name: 'LocationTypes',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Location Types',
        permission: { name: lookup.locationType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/LocationTypes/LocationTypes'),
    }, {
      path: 'markets',
      name: 'Markets',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Markets',
        permission: { name: lookup.market, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/Markets/Markets'),
    }, {
      path: 'marketSystems',
      name: 'MarketSystems',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Market Systems',
        permission: { name: lookup.marketSystem, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/MarketSystems/MarketSystems'),
    }, {
      path: 'market-participants',
      name: 'EnergyMarketParticipants',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Market Participants',
        permission: { name: lookup.marketParticipant, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/EnergyMarketParticipants/EnergyMarketParticipants'),
    }, {
      path: 'marketAccounts',
      name: 'MarketAccounts',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Market Accounts',
        permission: { name: lookup.marketAccount, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/MarketAccounts/MarketAccounts'),
    }, {
      path: 'product-types',
      name: 'MarketProductTypes',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Market Product Types',
        permission: { name: lookup.productType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/MarketProductTypes/MarketProductTypes'),
    }, {
      path: 'self-schedule-types',
      name: 'MarketSelfScheduleTypes',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Market Self Schedule Types',
        permission: { name: lookup.marketSelfScheduleType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/MarketSelfScheduleTypes/MarketSelfScheduleTypes'),
    }, {
      path: 'trade-products',
      name: 'MarketTradeProducts',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Market Trade Products',
        permission: { name: lookup.marketTradeProduct, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/MarketTradeProducts/MarketTradeProducts'),
    }, {
      path: 'trade-types',
      name: 'MarketTradeTypes',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Market Trade Types',
        permission: { name: lookup.marketTradeType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/MarketTradeTypes/MarketTradeTypes'),
    }, {
      path: 'deliverable-occurrence-log-status-type',
      name: 'DeliverableOccurrenceLogStatusType',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Occurrence Log Status Type',
        permission: { name: lookup.deliverableOccurrenceLogStatusType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/DeliverableOccurrenceLogStatusTypes/OccurrenceLogStatusTypes'),
    }, {
      path: 'payment-terms',
      name: 'PaymentTerms',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Payment Terms',
        permission: { name: lookup.paymentTerm, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'PaymentTerm',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'portfolios',
      name: 'Portfolios',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Portfolios',
        permission: { name: lookup.portfolio, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'Portfolio',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    },
    {
      path: 'books',
      name: 'Books',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Books',
        permission: { name: lookup.book, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'Book',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'enablingAgreements',
      name: 'EnablingAgreements',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Enabling Agreements',
        permission: { name: lookup.portfolio, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'enablingAgreement',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'term-types',
      name: 'TermTypes',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Term Types',
        permission: { name: lookup.termType, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/TermTypes/TermTypes'),
    }, {
      path: 'units',
      name: 'Units',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Units',
        permission: { name: lookup.unit, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'uom',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'strategies',
      name: 'Strategy',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Strategies',
        permission: { name: lookup.unit, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'strategy',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'term-products',
      name: 'TermProduct',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Term Products',
        permission: { name: lookup.termProduct, rights: [accessRight.read, accessRight.write] },
      },
      props: {
        catalogType: 'termProduct',
      },
      component: () => import('@/components/ReferenceDataManager/Catalogs/Catalogs'),
    }, {
      path: 'variants',
      name: 'Variants',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Variants',
        permission: { name: lookup.variant, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/ReferenceDataManager/Variants/Variants'),
    }, {
      path: 'prices',
      name: 'Prices',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Prices',
        permission: { name: lookup.price, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ReferenceDataManager/Prices/Prices'),
    }, {
      path: 'caiso-reference',
      name: 'CAISOReference',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'CAISO Reference',
        permission: { name: lookup.isoReference, rights: [accessRight.read, accessRight.write] },
        props: {
          pageType: 'CAISO',
        },
      },
      component: () => import('@/components/ReferenceDataManager/ISOReference/ISOReference'),
    },
  ],
};
