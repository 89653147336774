const components = [
  'PscsAutocomplete',
  'PscsAttachmentsDialog',
  'PscsAutocompleteCell',
  'PscsButton',
  'PscsButtonIcon',
  'PscsCallout',
  'PscsChart',
  'PscsCheckbox',
  'PscsCheckboxCell',
  'PscsColorPicker',
  'PscsColumn',
  'PscsCountdown',
  'PscsDate',
  'PscsDateCell',
  'PscsDateRange',
  'PscsDateTime',
  'PscsDateTimeCell',
  'PscsDialog',
  'PscsDropdown',
  'PscsForm',
  'PscsFormula',
  'PscsInput',
  'PscsInputCell',
  'PscsInputContact',
  'PscsInputCountProgBar',
  'PscsInputNumber',
  'PscsPanel',
  'PscsPopover',
  'PscsResizeHandle',
  'PscsRow',
  'PscsSelect',
  'PscsSelectCell',
  'PscsSelectRemote',
  'PscsSelectRemoteCell',
  'PscsSwitch',
  'PscsTableAg',
  'PscsTransfer',
  'PscsWrapper',

  'PscsAccordionDx',
  'PscsBodyDx',
  'PscsButtonDx',
  'PscsCheckboxDx',
  'PscsColorBoxDx',
  'PscsCustomTemplateXlsxUploadDx',
  'PscsDateDx',
  'PscsDialogDx',
  'PscsDropDownButtonDx',
  'PscsEditJsonDialogDx',
  'PscsDefaultParametersButtonDx',
  'PscsExcelTabFileUploadDx',
  'PscsExportAllButtonDx',
  'PscsExportDatasetButtonDx',
  'PscsExportPivotSourceButtonDx',
  'PscsFieldDx',
  'PscsFieldSetDx',
  'PscsFileUploadDx',
  'PscsFormDialogDx',
  'PscsInputDx',
  'PscsListDx',
  'PscsLoadingDx',
  'PscsPivotGridDx',
  'PscsProgressBarDx',
  'PscsRadioGroupDx',
  'PscsRealtimeSelectDx',
  'PscsSchedulerDx',
  'PscsSearchFilterDialogDx',
  'PscsSelectDx',
  'PscsSliderDx',
  'PscsSwitchDx',
  'PscsTabCardsDx',
  'PscsTableDx',
  'PscsTagBoxDx',
  'PscsTextAreaDx',
  'PscsTreeListDx',
  'PscsXlsxUploadDx',
];

export default (Vue) => components.forEach((name) => Vue.component(name, () => import(`./${name}/${name}.vue`)));