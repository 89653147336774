import { clone } from '@/utils/dataUtil';
import { METER_MANAGEMENT_API } from '@/api';

const state = {
  tableKey: -999,
  meterList: [],
  tableData: [],
  childTableData: [],
  currentTableRow: {},
  currentChildTableRow: {},
  nullTableRow: {
    id: -999,
    isoName: 'SPP',
    locationName: null,
    locationType: null,
    zeroFloor: false,
    zeroCeiling: false,
    allowSubmit: false,
    defaultSubmit: true,
    defaultExport: false,
    multiplier: 1,
    locationDescription: null,
    effectiveDate: null,
    terminationDate: null,
  },
  nullChildTableRow: {
    id: -999,
    inputMeterId: null,
    mappingType: 'METER',
    multiplier: 1,
    effectiveDate: null,
    terminationDate: null,
  },
};

const getters = {
  getMeterList: (state) => state.meterList.map(({ id, meterName }) => ({ value: id, label: meterName })),
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchMeterList');
  },
  fetchMeterList({ commit }) {
    METER_MANAGEMENT_API.get('/ref-data/all-meters').then(({ data }) => {
      commit('loadMeterList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  async fetchData({ commit }) {
    commit('setTableData', []);
    try {
      const { data: { data } } = await METER_MANAGEMENT_API.get('/ref-data/iso-locations');
      commit('setTableData', data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchDataMappings({ commit }, e) {
    commit('setChildTableData', []);
    try {
      const { data: { data } } = await METER_MANAGEMENT_API.get(`/ref-data-mappings/iso-locations/${e.id}`, e);
      commit('setChildTableData', data);
    } catch (err) {
      console.error(err);
    }
  },
  async postRecord({ state }, e) {
    try {
      const { data } = await METER_MANAGEMENT_API.post('/ref-data/iso-locations', e);
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
  async postChildRecord({ state }, e) {
    try {
      const { data } = await METER_MANAGEMENT_API.post('/ref-data-mappings/iso-locations', e);
      state.childTableData.push(data);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
  async updateRecord({ commit }, e) {
    try {
      const { data } = await METER_MANAGEMENT_API.put(`/ref-data/iso-locations/${e.id}`, e);
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
  async updateChildRecord({ commit }, e) {
    try {
      const { data } = await METER_MANAGEMENT_API.put(`/ref-data-mappings/iso-locations/${e.id}`, e);
      commit('updateChildRecord', data);
      this.$notify('Data Updated', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
  async deleteRow({ dispatch, commit, state }) {
    try {
      await METER_MANAGEMENT_API.delete(`/ref-data/iso-locations/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
  async deleteChildRow({ dispatch, commit, state }) {
    try {
      await METER_MANAGEMENT_API.delete(`/ref-data-mappings/iso-locations/${state.currentChildTableRow.id}`);
      commit('deleteChildTableRow');
      dispatch('currentChildTableRowChange', clone(state.nullChildTableRow));
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
};

const mutations = {
  loadMeterList(state, data) {
    state.meterList = data;
  },
  setTableData(state, value) {
    state.tableData = value;
  },
  setChildTableData(state, value) {
    state.childTableData = value;
  },
  currentTableRowChange(state, data) {
    state.currentTableRow = data;
  },
  currentChildTableRowChange(state, data) {
    state.currentChildTableRow = data;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  updateChildRecord(state, record) {
    const rowIndex = state.childTableData.findIndex(({ id }) => id === record.id);
    state.childTableData.splice(rowIndex, 1, record);
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    state.currentTableRow = record;
  },
  createChildTableRow(state) {
    const record = clone(state.nullChildTableRow);
    record.id = state.tableKey++;
    record.locationId = state.currentTableRow.id;
    record.locationName = state.currentTableRow.locationName;
    state.currentChildTableRow = record;
  },
  copyChildTableRow(state) {
    const record = clone(state.currentChildTableRow);
    record.id = state.tableKey++;
    state.currentChildTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
  deleteChildTableRow(state) {
    state.childTableData = state.childTableData.filter((record) => record.id !== state.currentChildTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};