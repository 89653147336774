import accessRight from '@/auth/permission/accessRight';

const PermissionMixin = {
  methods: {
    hasDeleteAccess(name) {
      return this.hasAccess({ name, right: [accessRight.delete] });
    },

    hasVisibleAccess(name) {
      return this.hasAccess({ name, right: [accessRight.visible] });
    },

    hasReadAccess(name) {
      return this.hasAccess({ name, right: [accessRight.read, accessRight.write] });
    },

    hasWriteAccess(name) {
      return this.hasAccess({ name, right: [accessRight.write] });
    },

    hasEnabledAccess(name) {
      return this.hasAccess({ name, right: [accessRight.enabled] });
    },

    hasAccess({ name, right }) {
      return this.$store.getters['auth/hasPermission']({ name, right });
    },

    anyPermission(name) {
      return this.$store.getters['auth/anyPermission']({ name });
    },
  },
};

export default PermissionMixin;