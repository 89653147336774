import { contracts, contractGroupTagBox, gridConfig } from '@/components/ContractBilling/BaseStore/contractsBase';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, filterable: true, width: 75, alignment: 'center',
    }, {
      prop: 'bdName', label: 'BD Name', sortable: true, filterable: true, width: 150,
    }, {
      prop: 'contractName', label: 'Contract', sortable: true, filterable: true, width: 100,
    }, {
      prop: 'contractGroupName', label: 'Contract Group', sortable: true, filterable: true, visible: false, width: 100,
    }, {
      prop: 'startDate', label: 'Start Date', sortable: true, filterable: true, dataType: 'date', format: 'yyyy-MM-dd', width: 100,
    }, {
      prop: 'endDate', label: 'End Date', sortable: true, filterable: true, dataType: 'date', format: 'yyyy-MM-dd', width: 100,
    }, {
      prop: 'val', label: 'Value', sortable: true, filterable: true, calculateCellValue: gridConfig.valueFormat, width: 100,
    }, {
      prop: 'description', label: 'Description', sortable: true, filterable: true,
    }, {
      prop: 'createdInfo', label: 'Created By', sortable: true, filterable: true, width: 170,
    }, {
      prop: 'updatedInfo', label: 'Updated By', sortable: true, filterable: true, width: 170,
    }, { 
      prop: 'attribute1', label: 'Attribute1', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute2', label: 'Attribute2', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute3', label: 'Attribute3', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute4', label: 'Attribute4', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute5', label: 'Attribute5', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute6', label: 'Attribute6', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute7', label: 'Attribute7', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute8', label: 'Attribute8', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute9', label: 'Attribute9', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute10', label: 'Attribute10', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute11', label: 'Attribute11', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute12', label: 'Attribute12', sortable: true, filterable: true, visible: true, width: 100 
    }, { 
      prop: 'attribute13', label: 'Attribute13', sortable: true, filterable: true, visible: true, width: 100 
    }],
    options: {
      filterRow: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
  searchFiltersConfig: {
    contractType: {
      label: 'Contract Type',
      value: 'contract',
      type: 'radio',
      options: [
        { value: 'contract', label: 'Contract' },
        { value: 'contractGroup', label: 'Contract Group' },
      ],
      watchers: [{
        propertyToWatch: 'contractTypeSelected',
        handler(newValue, oldValue) {
          if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.instance.repaint());
          }
          this.$store.commit('ciso/setParams', ['contractVisibleSelected', newValue === 'contract']);
          this.$store.commit('ciso/setParams', ['contractGroupVisibleSelected', newValue === 'contractGroup']);
          return newValue;
        },
      }],
    },
    contracts: { ...contracts, visible: 'contractVisible' },
    contractGroups: { ...contractGroupTagBox, visible: 'contractGroupVisible' },
  },
};