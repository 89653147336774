import moment from 'moment';
import { HEColumns } from '../../../utils/dataUtil';

export const EMPTY_HOURLY_VALUE = '---';

export const STRATEGY_CONFIG = {
  tableName: 'StrategyTable2',
  columns: [
    {
      label: 'entity',
      prop: 'entity',
      width: '4%',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
    }, {
      label: 'Location',
      prop: 'location',
      width: '4%',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,

    }, {
      label: 'Sub Type',
      prop: 'locationSubType',
      // width: '4%',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
    }],
  options: {
    filterHeader: true,
    multipleSelection: true,
    allowSelectAll: true,
  },
};

const getGlobalTable = (tableName) => ({
  tableName,
  columns: [
    {
      label: 'Order',
      prop: 'visualOrder',
      width: '90px',
      alignment: 'left',
      filterable: true,
      sortable: true,
      visibleIndex: 0,
      editable: false,
      sortOrder: 'asc',
      calculateSortValue: (e) => e.visualOrder || 9999,
    },
    {
      label: 'Name',
      prop: 'name',
      width: '90px',
      alignment: 'left',
      filterable: true,
      visibleIndex: 1,
      editable: false,
      validationRules: [
        { type: 'required' },
        {
          type: 'custom',
          message: 'Invalid Name',
          validationCallback: ({ data }) => {
            if (data.name) {
              return /^[a-zA-Z_$][0-9a-zA-Z_$]*$/.test(data.name);
            }
            return true;
          },
        },
      ],
    }, {
      label: 'Location',
      prop: 'location',
      width: '110px',
      alignment: 'left',
      filterable: true,
      visibleIndex: 2,
      editable: false,
    }, {
      label: 'Frequency',
      prop: 'variableType',
      width: '110px',
      alignment: 'left',
      filterable: true,
      visibleIndex: 1,
      editable: false,
      validationRules: [{ type: 'required' }],
    }, {
      label: 'Market Type',
      prop: 'marketType',
      width: '110px',
      alignment: 'left',
      filterable: true,
      visibleIndex: 4,
      editable: false,
    }, {
      label: 'Type',
      prop: 'valueType',
      width: '90px',
      alignment: 'left',
      filterable: true,
      visibleIndex: 2,
      editable: false,
      validationRules: [{ type: 'required' }],
    }, {
      label: 'Value',
      prop: 'value',
      width: '110px',
      alignment: 'left',
      filterable: true,
      visibleIndex: 3,
      editable: false,
      validationRules: [
        { type: 'required' },
        {
          type: 'custom',
          message: 'Value type is incorrect',
          validationCallback: ({ data }) => {
          // If variableType is Hourly and row is not newly created (has id), value will be empty/EMPTY_HOURLY_VALUE. Don't Validate.
            if (data.variableType === 'HOURLY' && !!data.id) return true;
            // If variableType was changed from HOURLY to DAILY, the value should not remain as the EMPTY_HOURLY_VALUE.
            if (data.variableType === 'DAILY' && data.value === EMPTY_HOURLY_VALUE) return false;
            if (!data.valueType) return false;
            const parsedVal = parseFloat(data.value);
            const singleDecimal = (data.value.match(/\./g) || []).length <= 1;
            switch (data.valueType) {
            case 'decimal':
              return singleDecimal && (!Number.isNaN(parsedVal));
            case 'string':
              return typeof (data.value) === 'string';
            case 'bool':
              return ['true', 'false'].includes(data.value.toLowerCase());
            case 'int':
              return (!Number.isNaN(parsedVal)) && (parsedVal % 1 === 0);
            default:
              return true;
            }
          },
        },
      ],
    }, {
      label: 'Strategies',
      prop: 'strategies',
      alignment: 'left',
      filterable: true,
      visibleIndex: 5,
      editable: false,
      validationRules: [{ type: 'required' }],
      actAsDxComponentName: 'dxTagBox',
      dataType: 'string',
      selectedFilterOperation: 'contains',
      calculateDisplayValue(rowData) {
        return rowData.strategies?.toString();
      },
      calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        filterValue = target === 'headerFilter' && filterValue?.length < 1 ? null : filterValue;
        return function (itemData) {
          const array1 = itemData.strategies;
          const array2 = filterValue || [undefined]; // Can filter in header for strategies that are undefined - legacy data

          if (array2.length === 0) return true;

          return array1.some((value, index) => array2.includes(value));
        };
      },
    }],
  options: {
    filterRow: true,
    filterHeader: true,
  },
});

export const DAILY_GLOBAL_VARIABLES_CONFIG = getGlobalTable('dailyGlobScriptVar3');

export const HOURLY_VALUES_CONFIG = (date) => {
  let columns = HEColumns({ editable: false, clearable: false }, true, date);
  columns = columns.map((x) => ({ ...x, prop: x.prop.replace('he0', 'he') }));
  return {
    tableName: 'hourlyVarVal',
    columns,
    options: { cellSelection: true, columnsMovable: false },
  };
};

export const DAILY_AUDIT_CONFIG = {
  tableName: 'dailyAuditTable',
  columns: [{
    label: 'Created By',
    prop: 'createdBy',
    fixed: 'left',
    alignment: 'left',
    width: '25%',
    filterable: true,
    sortable: true,
  }, {
    label: 'Created Date',
    prop: 'createdDate',
    fixed: 'left',
    alignment: 'left',
    width: '25%',
    filterable: true,
    sortable: true,
  }, {
    label: 'Effective Date',
    prop: 'effectiveDate',
    fixed: 'left',
    alignment: 'left',
    width: '25%',
    filterable: true,
    sortable: true,
  }, {
    label: 'Value',
    prop: 'value',
    fixed: 'left',
    alignment: 'left',
    width: '25%',
    filterable: true,
    sortable: true,
  }],
  options: {
    filterRow: true,
    filterHeader: true,
  },
};

export const HOURLY_AUDIT_CONFIG = {
  tableName: 'hourlyAuditTable',
  columns: [{
    label: 'Created By',
    prop: 'createdBy',
    fixed: 'left',
    alignment: 'left',
    width: '90px',
    filterable: true,
    sortable: true,
  }, {
    label: 'Created Date',
    prop: 'createdDate',
    fixed: 'left',
    alignment: 'left',
    width: '90px',
    filterable: true,
    sortable: true,
  }, {
    label: 'Effective Date',
    prop: 'effectiveDate',
    fixed: 'left',
    alignment: 'left',
    width: '90px',
    filterable: true,
    sortable: true,
  },
  ].concat([...Array(24).keys()].map((x) => ({
    label: (x + 1) < 10 ? `HE0${ x + 1}` : `HE${ x + 1}`,
    prop: `he${ x + 1}`,
    fixed: 'left',
    alignment: 'left',
    filterable: true,
    sortable: true,
  }))),
  options: {
    filterRow: true,
    filterHeader: true,
  },
};
