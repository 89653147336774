import { accessRight, spp } from '@/auth/permission';

export default {
  path: '/spp',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'SPP',
    permission: { name: spp.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'market-results',
    meta: {
      visible: true,
      rank: 1,
      requiresAuth: true,
      text: 'MARKET RESULTS',
      permission: { name: spp.marketData.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'load-forecast',
      name: 'SppLoadForecast',
      meta: {
        showCert: true,
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Load Forecast',
        permission: { name: spp.marketData.resourceForecast, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Market/LoadForecast/LoadForecast'),
    }, {
      path: 'da-cleared-offers',
      name: 'SppDaClearedOffers',
      meta: {
        showCert: true,
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'DA Cleared Offers',
        permission: { name: spp.marketData.daClearedOffers, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Market/ClearedOffers/ClearedOffers'),
    }, {
      path: 'da-cleared-bids',
      name: 'SppDaClearedBids',
      meta: {
        showCert: true,
        visible: true,
        rank: 3,
        requiresAuth: true,
        text: 'DA Cleared Bids',
        permission: { name: spp.marketData.daClearedBids, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Market/ClearedBids/ClearedBids'),
    }, {
      path: 'energy-cleared-transactions',
      name: 'SppEnergyClearedTransactions',
      meta: {
        showCert: true,
        visible: true,
        rank: 4,
        requiresAuth: true,
        text: 'DA Cleared Transactions',
        permission: { name: spp.marketData.energy.clearedTransactionSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Market/ClearedTransactions/ClearedTransactions'),
    }, {
      path: 'rt-dispatch',
      name: 'SppRtDispatch',
      meta: {
        showCert: true,
        visible: true,
        rank: 5,
        requiresAuth: true,
        text: 'RT Dispatches',
        permission: { name: spp.marketData.rtDispatch, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Market/Dispatch/Dispatches'),
    }, {
      path: 'lmp',
      name: 'SppLmp',
      meta: {
        showCert: true,
        visible: true,
        rank: 6,
        requiresAuth: true,
        text: 'LMP',
        permission: { name: spp.marketData.lmp, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Market/Lmp/Lmp'),
    }, {
      path: 'mcp',
      name: 'SppMcp',
      meta: {
        showCert: true,
        visible: true,
        rank: 7,
        requiresAuth: true,
        text: 'MCP',
        permission: { name: spp.marketData.mcp, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Market/Mcp/Mcp'),
    }, {
      path: 'notifications',
      name: 'SppNotifications',
      meta: {
        showCert: true,
        visible: true,
        rank: 8,
        requiresAuth: true,
        text: 'Notifications',
        permission: { name: spp.marketData.notification, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Market/Notifications/Notifications'),
    }, {
      path: 'energy-wind-forecast',
      name: 'SppEnergyWindForecasts',
      meta: {
        showCert: true,
        visible: true,
        rank: 61,
        requiresAuth: true,
        text: 'ENERGY - Wind Forecast',
        permission: { name: spp.marketData.energy.resourceForecastSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Energy/WindForecasts/WindForecasts'),
    }, {
      path: 'energy-forecasted-commitment',
      name: 'SppEnergyForecastedCommitment',
      meta: {
        showCert: true,
        visible: true,
        rank: 62,
        requiresAuth: true,
        text: 'ENERGY - Forecast Commitments',
        permission: { name: spp.marketData.energy.forecastedCommitmentSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Energy/ForecastedCommitment/ForecastedCommitment'),
    }, {
      path: 'energy-forecasted-commitment-history',
      name: 'SppEnergyForecastedCommitmentHistory',
      meta: {
        showCert: true,
        visible: true,
        rank: 63,
        requiresAuth: true,
        text: 'ENERGY - Forecast Commitment History',
        permission: { name: spp.marketData.energy.forecastedCommitmentHistorySet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Energy/ForecastedCommitmentHistory/ForecastedCommitmentHistory'),
    }, {
      path: 'energy-commitments',
      name: 'SppEnergyCommitments',
      meta: {
        showCert: true,
        visible: true,
        rank: 64,
        requiresAuth: true,
        text: 'ENERGY - Commitments',
        permission: { name: spp.marketData.energy.commitmentSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Energy/Commitments/Commitments'),
    }, {
      path: 'energy-commitment-history',
      name: 'SppEnergyCommitmentHistory',
      meta: {
        showCert: true,
        visible: true,
        rank: 65,
        requiresAuth: true,
        text: 'ENERGY - Commitment History',
        permission: { name: spp.marketData.energy.commitmentHistorySet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Energy/CommitmentHistory/CommitmentHistory'),
    }, {
      path: 'energy-commitment-status',
      name: 'SppEnergyCommitmentStatusConflict',
      meta: {
        showCert: true,
        visible: true,
        rank: 66,
        requiresAuth: true,
        text: 'ENERGY - Commitment Status Conflict',
        permission: { name: spp.marketData.energy.commitmentStatusConflictSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Energy/CommitmentStatusConflict/CommitmentStatusConflict'),
    }, {
      path: 'energy-emergency-limits',
      name: 'SppEnergyEmergencyLimits',
      meta: {
        showCert: true,
        visible: true,
        rank: 67,
        requiresAuth: true,
        text: 'ENERGY - Emergency Limits',
        permission: { name: spp.marketData.energy.emergencyLimit, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Energy/EmergencyLimits/EmergencyLimits'),
    }, {
      path: 'energy-overrides',
      name: 'SppEnergyOverrides',
      meta: {
        showCert: true,
        visible: true,
        rank: 68,
        requiresAuth: true,
        text: 'ENERGY - Overrides',
        permission: { name: spp.marketData.energy.overrideSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Energy/Overrides/Overrides'),
    }, {
      path: 'reserve-zone-oblig',
      name: 'SppReserveZoneOblig',
      meta: {
        showCert: true,
        visible: true,
        rank: 71,
        requiresAuth: true,
        text: 'RESERVE - Zone Obligations',
        permission: { name: spp.marketData.reserve.obligationSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Reserve/ReserveZoneOblig/ReserveZoneOblig'),
    }, {
      path: 'reserve-sys-req',
      name: 'SppReserveSysReq',
      meta: {
        showCert: true,
        visible: true,
        rank: 72,
        requiresAuth: true,
        text: 'RESERVE - System Requirements',
        permission: { name: spp.marketData.reserve.systemRequirementSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Reserve/ReserveSystemReq/ReserveSystemReq'),
    }, {
      path: 'reserve-cap',
      name: 'SppReserveCap',
      meta: {
        showCert: true,
        visible: true,
        rank: 73,
        requiresAuth: true,
        text: 'RESERVE - Cap',
        permission: { name: spp.marketData.reserve.capSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Reserve/ReserveCap/ReserveCap'),
    }, {
      path: 'reserve-cr-deployment',
      name: 'SppReserveCrDeployment',
      meta: {
        showCert: true,
        visible: true,
        rank: 74,
        requiresAuth: true,
        text: 'RESERVE - CR Deployment',
        permission: { name: spp.marketData.reserve.crDeploymentSet, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Reserve/CrDeployment/CrDeployment'),
    }, {
      path: 'reserve-reg-status',
      name: 'SppReserveRegStatus',
      meta: {
        showCert: true,
        visible: true,
        rank: 75,
        requiresAuth: true,
        text: 'RESERVE - Regulation Status',
        permission: { name: spp.marketData.reserve.regulationStatus, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/MarketResults/Reserve/RtRegulationStatus/RtRegulationStatus'),
    }],
  }, {
    path: 'scheduling',
    meta: {
      visible: true,
      rank: 1,
      requiresAuth: true,
      text: 'SCHEDULING',
      permission: { name: spp.scheduling.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'submission',
      name: 'sppSubmission',
      meta: {
        showCert: true,
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Submission',
        requiredTimeZone: 'CPT',
        permission: { name: spp.scheduling.submission, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Scheduling/Submission/Submission'),
    }, {
      path: 'resource-detail/:offerSetId',
      name: 'resourceDetail',
      props: true,
      meta: {
        visible: false,
        requiresAuth: true,
        text: 'Resource Detail',
        permission: { name: spp.scheduling.submission, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Scheduling/Submission/ResourceDetail'),
    },
    {
      path: 'resourceOfferStrategies',
      name: 'SPP',
      meta: {
        visible: true,
        requiresAuth: false,
        text: 'Resource Offer Strategies',
        permission: { name: spp.scheduling.resourceOfferSet, rights: ['write'] },
        requiredTimeZone: 'CPT',
      },
      props: {
        entityType: 'SPP_AO',
        commodity: 'POWER',
        market: 'SPP',
        marketTypes: [
          { label: 'DA', value: 'DAMKT' },
          { label: 'RT', value: 'RTBM' },
          { label: 'MI', value: 'MI' },
        ],
        moduleName: 'resourceOfferSet',
      },
      component: () => import('@/components/Scheduling/Strategies/Strategies'),
    }],
  }, {
    path: 'settlements',
    meta: {
      visible: true,
      rank: 2,
      requiresAuth: true,
      text: 'SETTLEMENTS',
      permission: { name: spp.settlements.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'meter',
      name: 'SppMeter',
      meta: {
        showCert: true,
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Meter',
        permission: { name: spp.settlements.meter.menu, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Settlements/Meter/Meter'),
    }, {
      path: 'statements',
      name: 'SppStatements',
      meta: {
        showCert: true,
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'Statements',
        permission: { name: spp.settlements.statement, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Settlements/StatementDetails/StatementDetails'),
    }, {
      path: 'determinant',
      name: 'SppDeterminant',
      meta: {
        showCert: true,
        visible: false,
        rank: 3,
        requiresAuth: true,
        text: 'Determinant',
        permission: { name: spp.settlements.statement, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Settlements/Determinant/Determinant'),
    },{
      path: 'invoices',
      name: 'SppInvoices',
      meta: {
        showCert: true,
        visible: true,
        rank: 4,
        requiresAuth: true,
        text: 'Invoices',
        permission: { name: spp.settlements.invoice, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Settlements/Invoices/Invoices'),
    }, {
      path: 'calendar',
      name: 'SppCalendar',
      meta: {
        showCert: true,
        visible: true,
        rank: 5,
        requiresAuth: true,
        text: 'Settlement Calendar',
        permission: { name: spp.settlements.calendar, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Settlements/SettlementCalendar/SettlementCalendar'),
    }, {
      path: 'run-shadow-calc',
      name: 'RunShadowCalc',
      meta: {
        showCert: true,
        visible: true,
        rank: 6,
        requiresAuth: true,
        text: 'Run Shadow',
        permission: { name: spp.settlements.statement, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Settlements/RunShadowCalc/RunShadowCalc'),
    }],
  }, {
    path: 'create-a-report',
    name: 'SppCreateAReport',
    meta: {
      showCert: true,
      visible: true,
      rank: 10,
      requiresAuth: true,
      title: 'Create a Report',
      text: 'SPP Reports',
      permission: { name: spp.reporting, rights: [accessRight.visible] },
      customFormatEnabled: true,
    },
    component: () => import('@/components/SPP/Reporting/SppReports'),
  }, {
    path: 'download',
    meta: {
      visible: true,
      rank: 20,
      requiresAuth: true,
      text: 'SPP DOWNLOADS',
      permission: { name: spp.download, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'iso-downloader',
      name: 'IsoDownloader',
      meta: {
        showCert: true,
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'ISO Downloader',
        permission: { name: spp.download, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Download/Downloader/SppDownloader'),
    }, {
      path: 'task-log',
      name: 'TaskLog',
      meta: {
        showCert: true,
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'Task Log',
        permission: { name: spp.download, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Download/TaskLog/TaskLog'),
    }, {
      path: 'auto-download-config',
      name: 'AutoDownloadConfig',
      meta: {
        showCert: true,
        visible: true,
        rank: 3,
        requiresAuth: true,
        text: 'Auto-Download Config',
        permission: { name: spp.download, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/SPP/Download/AutoDownload/AutoDownload'),
    }],
  }],
};