import {
  tradeDateRange, contracts, overrideHeaders, overrideVersions,
} from '@/components/ContractBilling/BaseStore/contractsBase';

export default {
  overridesConfiguration: {
    columns: [{
      prop: 'contractName', dataType: 'string', label: 'CONTRACT NAME', filterable: true, sortable: true,
    }, {
      prop: 'bdName', dataType: 'string', label: 'BD NAME', filterable: true, sortable: true,
    }, {
      prop: 'tradingDate', dataType: 'dateTime', label: 'TRADING DATE', format: 'yyyy-MM-dd', filterable: true, sortable: true,
    }, {
      prop: 'tradingHour', dataType: 'number', label: 'TRADING HOUR', filterable: true, sortable: true,
    }, {
      prop: 'tradingInterval', dataType: 'number', label: 'TRADING INTERVAL', filterable: true, sortable: true,
    }, {
      prop: 'val', dataType: 'number', label: 'VALUE', filterable: true, sortable: true,
    }, {
      prop: 'activeFlag', label: 'ACTIVE FLAG', dataType: 'boolean',
    }, {
      prop: 'premiseId', dataType: 'string', label: 'PREMISEID', filterable: true, sortable: true,
    }, {
      prop: 'meterId', dataType: 'string', label: 'METERID', filterable: true, sortable: true,
    }, {
      prop: 'channel', dataType: 'string', label: 'CHANNEL', filterable: true, sortable: true,
    }, {
      prop: 'tagId', dataType: 'string', label: 'TAGID', filterable: true, sortable: true,
    }, {
      prop: 'priceNode', dataType: 'string', label: 'PRICENODE', filterable: true, sortable: true,
    }, {
      prop: 'attribute1', dataType: 'string', label: 'ATTRIBUTE1', filterable: true, sortable: true,
    },
    {
      prop: 'attribute2', dataType: 'string', label: 'ATTRIBUTE2', filterable: true, sortable: true,
    },
    {
      prop: 'attribute3', dataType: 'string', label: 'ATTRIBUTE3', filterable: true, sortable: true,
    },
    {
      prop: 'attribute4', dataType: 'string', label: 'ATTRIBUTE4', filterable: true, sortable: true,
    },
    {
      prop: 'attribute5', dataType: 'string', label: 'ATTRIBUTE5', filterable: true, sortable: true,
    },
    {
      prop: 'attribute6', dataType: 'string', label: 'ATTRIBUTE6', filterable: true, sortable: true,
    },
    {
      prop: 'attribute7', dataType: 'string', label: 'ATTRIBUTE7', filterable: true, sortable: true,
    },
    {
      prop: 'attribute8', dataType: 'string', label: 'ATTRIBUTE8', filterable: true, sortable: true,
    },
    {
      prop: 'attribute9', dataType: 'string', label: 'ATTRIBUTE9', filterable: true, sortable: true,
    },
    {
      prop: 'attribute10', dataType: 'string', label: 'ATTRIBUTE10', filterable: true, sortable: true,
    },
    {
      prop: 'attribute11', dataType: 'string', label: 'ATTRIBUTE11', filterable: true, sortable: true,
    },
    {
      prop: 'attribute12', dataType: 'string', label: 'ATTRIBUTE12', filterable: true, sortable: true,
    },
    {
      prop: 'attribute13', dataType: 'string', label: 'ATTRIBUTE13', filterable: true, sortable: true,
    },
    ],
    options: {
      columnsMovable: true,
      filterRow: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
  searchFiltersConfig: {
    overrideHeaders,
    tradeDateRange,
    contracts,
    overrideVersions,
  },
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
};