import moment from 'moment';
import userStore from '@/utils/userStore';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import dateUtil from '@/utils/dateStore';
import {
  BILLING_REF_API, BILLING_STLMTS_API, BILLING_SOURCE_API, BILLING_INVOICE_API,
} from '@/api';
import { isNull, sortBy } from '@/utils/dataUtil';

function fileCycleHandler() {
  const selectedContracts = this.params.contractsSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);
  // Race Condition check
  if (!startDate || !endDate || !selectedContracts) { return null; }

  const filteredHeaders = this.params.contractSettlementsHeaders.filter(
    ({ dt, cid }) => (selectedContracts.length === 0 || selectedContracts.includes(cid))
      && dt.split('T')[0] >= startDate
      && dt.split('T')[0] <= endDate);

  const selCycles = filteredHeaders
    .map((x) => x.cycle).filter((x, i, a) => a.indexOf(x) === i)
    .map((f) => ({ value: f, label: f }));

  const topCycle = selCycles.length > 0 ? selCycles[0] : null;
  this.$store.commit('ciso/setParams', ['cycles', selCycles]);
  if (!selCycles.some((x) => x.value === this.params.cyclesSelected)) {
    this.$store.commit('ciso/setParams', ['cyclesSelected', null]);
    this.$nextTick(() => this.$store.commit('ciso/setParams', ['cyclesSelected', topCycle ? topCycle.value : null]));
  }
  return true;
}

function compareCycleHandler() {
  const selectedContracts = this.params.contractsSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);
  // Race Condition check
  if (!startDate || !endDate || !selectedContracts || !this.params.hasCompareSelected) { return null; }

  const filteredHeaders = this.params.contractSettlementsHeaders.filter(
    ({ dt, cid }) => (selectedContracts.length === 0 || selectedContracts.includes(cid))
      && dt.split('T')[0] >= startDate
      && dt.split('T')[0] <= endDate);

  const selCycles = filteredHeaders
    .map((x) => x.cycle).filter((x, i, a) => a.indexOf(x) === i)
    .map((f) => ({ value: f, label: f }));

  const topCycle = selCycles.length > 0 ? selCycles[0] : null;
  this.$store.commit('ciso/setParams', ['compareCycles', selCycles]);
  this.$store.commit('ciso/setParams', ['compareCyclesSelected', null]);
  this.$nextTick(() => this.$store.commit('ciso/setParams', ['compareCyclesSelected', topCycle ? topCycle.value : null]));
  return true;
}

function fileVersionHandler() {
  const selectedContracts = this.params.contractsSelected;
  const selectedCycle = this.params.cyclesSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);
  // Race Condition check
  if (!startDate || !endDate || !selectedContracts) { return null; }
  if (selectedContracts.length !== 1 || startDate < endDate) {
    this.$store.commit('ciso/setParams', ['versions', [{ value: 'MAX', label: 'MAX' }]]);
    this.$store.commit('ciso/setParams', ['versionsSelected', 'MAX']);
    return true;
  }

  const filteredHeaders = this.params.contractSettlementsHeaders.filter(({ dt, cid, cycle }) => selectedContracts.includes(cid)
                                                                          && cycle === selectedCycle
                                                                          && dt.split('T')[0] >= startDate
                                                                          && dt.split('T')[0] <= endDate);

  const distinctVersions = filteredHeaders
    .map((x) => x.ver).filter((x, i, a) => a.indexOf(x) === i)
    .map((f) => ({ value: f, label: f }));

  const orderedDescendingVersions = distinctVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  const topVersion = orderedDescendingVersions.length > 0 ? orderedDescendingVersions[0] : null;
  this.$store.commit('ciso/setParams', ['versions', orderedDescendingVersions]);
  this.$store.commit('ciso/setParams', ['versionsSelected', topVersion ? topVersion.value : null]);
  return true;
}

function compareVersionHandler() {
  const selectedContracts = this.params.contractsSelected;
  const selectedCycle = this.params.compareCyclesSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);
  // Race Condition check
  if (!startDate || !endDate || !selectedContracts || !this.params.hasCompareSelected) { return null; }
  if (selectedContracts.length !== 1 || startDate < endDate) {
    this.$store.commit('ciso/setParams', ['compareVersions', [{ value: 'MAX', label: 'MAX' }]]);
    this.$store.commit('ciso/setParams', ['compareVersionsSelected', 'MAX']);
    return true;
  }

  const filteredHeaders = this.params.contractSettlementsHeaders.filter(({ dt, cid, cycle }) => selectedContracts.includes(cid)
                                                                          && cycle === selectedCycle
                                                                          && dt.split('T')[0] >= startDate
                                                                          && dt.split('T')[0] <= endDate);

  const distinctVersions = filteredHeaders
    .map((x) => x.ver).filter((x, i, a) => a.indexOf(x) === i)
    .map((f) => ({ value: f, label: f }));

  const orderedDescendingVersions = distinctVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  const topVersion = orderedDescendingVersions.length > 0 ? orderedDescendingVersions[0] : null;
  this.$store.commit('ciso/setParams', ['compareVersions', orderedDescendingVersions]);
  this.$store.commit('ciso/setParams', ['compareVersionsSelected', topVersion ? topVersion.value : null]);
  return true;
}

export const gridConfig = {
  valFormat: '#,##0.####',
  valFormat2: '#,##0.##',
  currencyFormat: (value) => {
    if (value === null || parseFloat(value, 10) === 0) { return null; }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  },
  zeroCurrencyFormat: (value) => {
    if (value === null || parseFloat(value, 10) === 0) { return '$0'; }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  },
  valueFormat: (value) => {
    if (value.valueFormat === 'Percentage') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
      });
      return formatter.format(value.val);
    }
    if (value.valueFormat === 'Currency') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
      return formatter.format(value.val);
    }
    if (value.valueFormat === 'Fixed Point') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
      });
      return formatter.format(value.val);
    }
    return value.val;
  },
};

export const dateRange = {
  label: 'Date Range',
  value: (() => dateUtil.getDefaultRange()),
  type: 'dateRange',
};

export const tradeDate = {
  label: 'Trade Date',
  value: dateUtil.getDefaultDate().toISOString(),
  type: 'date',
};

export const tradeDateRange = {
  label: 'Trade Dates',
  value: (() => dateUtil.getDefaultRange()),
  type: 'dateRange',
};

export const invoiceDateRange = {
  label: 'Invoice Dates',
  value: (() => dateUtil.getDefaultRange()),
  type: 'dateRange',
};

export const contractSettlementsHeaders = {
  label: 'contractSettlementsHeaders',
  value: null,
  type: 'select',
  visible: false,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_STLMTS_API.get('/statements/headers');
      return data;
    } catch (error) {
      vue.$notify('Failed to Load Contract Settlements Headers', 'error');
      console.log(error);
    }
    return {};
  },
};

export const contracts = {
  label: 'Contract',
  value: () => [...userStore.getDefaultContract()],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/contracts');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.name;
      });
      data.sort(sortBy('label'));
      return data;
    } catch (error) {
      vue.$notify('Failed to Load Contracts', 'error');
      console.log(error);
    }
    return {};
  },
};

export const meters = {
  label: 'Meters',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/meter');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.meterId;
      });
      const key = 'label';
      const unique = [...new Map(data.map((item) => [item[key], item])).values()];
      unique.sort(sortBy('label'));
      return unique;
    } catch (error) {
      vue.$notify('Failed to Load Meters', 'error');
      console.log(error);
    }
    return {};
  },
};

export const invoiceEntities = {
  label: 'InvoiceEntity',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/invoice-entities');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.shortName;
      });

      data.sort(sortBy('label'));
      return data;
    } catch (error) {
      vue.$notify('Failed to Load InvoiceEntities', 'error');
      console.log(error);
    }
    return {};
  },
};

function configureContractGroups(contractGroups) {
  const lst = isNull(contractGroups, []);
  let data = lst.map((c) => {
    c.value = c.id;
    c.label = c.shortName;
    return c;
  });
  data = data.sort((a, b) => {
    if (a.shortName < b.shortName) return -1;
    if (a.shortName > b.shortName) return 1;
    return 0;
  });
  return data;
}

export const contractGroupList = {
  label: 'Contract Groups',
  type: 'select',
  clearable: true,
  visible: true,
  options: [],
  value: null,
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/contract-groups');
      const results = configureContractGroups(data);
      return results;
    } catch (error) {
      vue.$notify('Failed to load Contract Groups', 'error');
      console.log(error);
    }
    return [];
  },
};

export const contractGroupTagBox = {
  label: 'Contract Groups',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/contract-groups');
      const results = configureContractGroups(data);
      return results;
    } catch (error) {
      vue.$notify('Failed to load Contract Groups', 'error');
      console.log(error);
    }
    return [];
  },
};

export const contractGroupInvoiceEntity = {
  label: 'Invoice Entities',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  watchers: [{
    propertyToWatch: 'contractGroupListSelected',
    watchOptions: { immediate: true },
    async handler(newValue, oldValue) {
      try {
        if (newValue !== null && newValue.length === 0) newValue = 'null';
        const { data: { data } } = await BILLING_REF_API.get(`/contract-groups/invoice-entities/${newValue}`);

        data.forEach((d) => {
          d.value = d.id;
          d.label = d.shortName;
        });

        data.sort(sortBy('label'));

        this.$store.commit('ciso/setParams', ['invoiceEntityList', data]);
        this.$store.commit('ciso/setParams', ['invoiceEntityListUnfiltered', data]);
      } catch (error) {
        vue.$notify('Failed to load Contract Group Invoice Entities', 'error');
        console.log(error);
      }
    },
  },
  {
    propertyToWatch: 'invoiceEntityListSelected',
    handler(newValue, oldValue) {
      if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
        this.$nextTick(() => this.$parent.instance.repaint());
      }
    },
  },
  ],
};

export const contractGroupContract = {
  label: 'Contract',
  value: () => [...userStore.getDefaultContract()],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  watchers: [{
    propertyToWatch: 'contractGroupListSelected',
    watchOptions: { immediate: true },
    async handler(newValue, oldValue) {
      try {
        if (newValue !== null && newValue.length === 0) newValue = 'null';
        const { data: { data } } = await BILLING_REF_API.get(`/contract-groups/contracts/${newValue}`);
        data.forEach((d) => {
          d.value = d.id;
          d.label = d.name;
        });

        data.sort(sortBy('label'));

        this.$store.commit('ciso/setParams', ['contracts', data]);
        this.$store.commit('ciso/setParams', ['contractsUnfiltered', data]);
      } catch (error) {
        vue.$notify('Failed to load Contract Group Contracts', 'error');
        console.log(error);
      }
    },
  },
  {
    propertyToWatch: 'contractsSelected',
    handler(newValue, oldValue) {
      if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
        this.$nextTick(() => this.$parent.instance.repaint());
      }
    },
  },
  ],
};

export const chargeCodes = {
  label: 'Charge Code',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/charge-codes');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.chargeCodeName;
      });

      data.sort(sortBy('label'));
      return data;
    } catch (error) {
      vue.$notify('Failed to Load Charge Codes', 'error');
      console.error(error);
    }
    return [];
  },
};

export const cycles = {
  label: 'Cycle',
  value: null,
  type: 'select',
  visible: true,
  required: true,
  options: [],
  watchers: [{
    propertyToWatch: 'contractSettlementsHeaders',
    handler: fileCycleHandler,
  }, {
    propertyToWatch: 'tradeDateRangeSelected',
    handler: fileCycleHandler,
  }, {
    propertyToWatch: 'contractsSelected',
    handler: fileCycleHandler,
  }],
};

export const versions = {
  label: 'Version',
  value: null,
  type: 'select',
  visible: true,
  options: [],
  watchers: [{
    propertyToWatch: 'cyclesSelected',
    handler: fileVersionHandler,
  }],
};

export const hasCompare = {
  label: 'Compare',
  value: false,
  type: 'checkbox',
  visible: true,
  options: [],
  watchers: [{
    propertyToWatch: 'hasCompareSelected',
    handler(newValue, oldValue) {
      if (!newValue) {
        this.$store.commit('ciso/setParams', ['compareCyclesSelected', null]);
        this.$store.commit('ciso/setParams', ['compareVersionsSelected', null]);
      }
      if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
        this.$nextTick(() => this.$parent.instance.repaint());
      }
      return true;
    },
  }],
};

export const compareCycles = {
  label: 'Cycle',
  value: null,
  type: 'select',
  visible: 'hasCompare',
  options: [],
  watchers: [{
    propertyToWatch: 'contractSettlementsHeaders',
    handler: compareCycleHandler,
  }, {
    propertyToWatch: 'tradeDateRangeSelected',
    handler: compareCycleHandler,
  }, {
    propertyToWatch: 'contractsSelected',
    handler: compareCycleHandler,
  }, {
    propertyToWatch: 'hasCompareSelected',
    handler: compareCycleHandler,
  }],
};

export const compareVersions = {
  label: 'Version',
  value: null,
  type: 'select',
  visible: 'hasCompare',
  options: [],
  watchers: [{
    propertyToWatch: 'compareCyclesSelected',
    handler: compareVersionHandler,
  }],
};

export const overrideHeaders = {
  label: 'overrideHeaders',
  value: null,
  type: 'select',
  visible: false,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_STLMTS_API.get('/overrides/headers');
      data.forEach((d, idx) => {
        d.value = idx;
      });
      return data;
    } catch (error) {
      vue.$notify('Failed to Load Contract Override Headers', 'error');
      console.log(error);
    }
    return [];
  },
};

export const dataTypes = {
  label: 'DataTypes',
  value: null,
  type: 'select',
  visible: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_SOURCE_API.get('/reservation/data-types');
      data.forEach((d, idx) => {
        d.value = d.dataType;
        d.label = d.dataType;
      });
      return data;
    } catch (error) {
      vue.$notify('Failed to load data types', 'error');
      console.log(error);
    }
    return [];
  },
};

export const statusTypes = {
  label: 'Status Types',
  value: ['Approved', 'PendingApproval'],
  type: 'tagbox',
  visible: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await BILLING_INVOICE_API.get('/invoices/status-list');
      const statuses = data.map((item) => ({
        value: item,
        label: item,
      }));
      return statuses;
    } catch (error) {
      vue.$notify('Failed to load status types', 'error');
      console.log(error);
    }
    return [];
  },
};

function overrideVersionsHandler() {
  const selectedContracts = this.params.contractsSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);

  if (!startDate || !endDate) { return null; }

  if (selectedContracts && selectedContracts.length === 0) {
    this.$store.commit('ciso/setParams', ['overrideVersions', [{ value: 'MAX', label: 'MAX' }]]);
    this.$store.commit('ciso/setParams', ['overrideVersionsSelected', 'MAX']);
    return true;
  }

  const filteredOverrideHeaders = this.params.overrideHeaders.filter(({
    tradingDate,
    contractId,
  }) => selectedContracts.includes(contractId)
        && tradingDate.split('T')[0] >= startDate
        && tradingDate.split('T')[0] <= endDate);

  const filteredVersions = [...new Set(filteredOverrideHeaders
    .map(({ version }) => (version)))]
    .map((version) => ({ label: version, value: version }));

  const orderedVersions = filteredVersions.sort((a, b) => b.value - a.value);

  const topVersion = orderedVersions.length > 0 ? orderedVersions[0] : null;

  if (endDate > startDate) { orderedVersions.push({ value: 'MAX', label: 'MAX' }); }

  this.$store.commit('ciso/setParams', ['overrideVersions', orderedVersions]);
  this.$store.commit('ciso/setParams', ['overrideVersionsSelected', topVersion ? topVersion.value : null]);
  return true;
}

export const overrideVersions = {
  label: 'Version',
  value: null,
  type: 'select',
  visible: true,
  options: [],
  watchers: [{
    propertyToWatch: 'tradeDateRangeSelected',
    handler: overrideVersionsHandler,
  }, {
    propertyToWatch: 'contractsSelected',
    handler: overrideVersionsHandler,
  }],
};

export const extractFileType = {
  label: 'Extract Type',
  value: 'EXCEL',
  type: 'select',
  options: [{
    label: 'EXCEL', value: 'EXCEL',
  }, {
    label: 'CSV', value: 'CSV',
  }],
};
