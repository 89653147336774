import moment from 'moment';
import { clone, has } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';
import utils from '@/utils';

const state = {
  intervalsTableData: [],
  intervalsTableConfig: {
    columns: [{
      prop: 'asOfDate', label: 'As Of Date', sortable: true, dataType: 'date', filterable: true, alignment: 'left',
    }, {
      prop: 'startTime', label: 'Start Time', sortable: true, dataType: 'datetime', filterable: true, alignment: 'left',
    }, {
      prop: 'endTime', label: 'End Time', sortable: true, dataType: 'datetime', filterable: true, alignment: 'left',
    }, {
      prop: 'value', label: 'Value', dataType: 'number', sortable: true, format: { type: 'currency', precision: 4 }, filterable: true, alignment: 'right',
    }, {
      prop: 'variant', label: 'Variant', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'effectiveTime', label: 'Effective Time', sortable: true, dataType: 'datetime', filterable: true, alignment: 'left',
    }, {
      prop: 'actualFlag', label: 'Actual', sortable: false, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime', filterable: true, alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
      exportExcel: true,
    },
  },

  intervalsDateRange: dateStore.getDefaultRange(),
  selectedGranularity: 0,
  asOfDate: dateStore.getMomentNow().toISOString(),
  selectedTimeZone: dateStore.getTimeZone(),
  // selectedVariants: ['CONTRACT', 'STRIKE'],
  // variants: ['CONTRACT', 'STRIKE'],
};

const getters = {
  getIntervalsTableConfig: (state) => state.intervalsTableConfig,
  getIntervalsTableData: (state) => state.intervalsTableData,
  getIntervalsDateRange: (state) => state.intervalsDateRange,

  getIntervalsDateRangeOptions(state) {
    const { currentTableRow } = state;
    return {
      disabledDate(date) {
        if (!currentTableRow) return true;

        const start = moment(currentTableRow.effectiveDate).subtract(1, 'second');
        const end = moment(currentTableRow.terminationDate).add(1, 'second');
        return !moment(date).isBetween(start, end, '()');
      },
    };
  },

  granularities: (state) => [
    { label: 'No Expand', value: 0 },
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '30', value: 30 },
    { label: '60', value: 60 },
  ],

  timezones: () => {
    const timezones = utils.date.TZ_DEFINE.map(({ value }) => ({ value, label: value }));
    timezones.push({
      value: 'GMT', label: '(Unchanged)',
    });
    return timezones;
  },

  // variants: (state) => state.variants.reduce((acc, { name }) => {
  //   const TYPES = ['VOLUME', 'PRICE', 'AMOUNT'];
  //   TYPES.forEach((type) => {
  //     acc.push({
  //       key: name,
  //       value: `${name}-${type}`,
  //       label: `${name}-${type}`,
  //     });
  //   });
  //   return acc;
  // }, []),
};

const actions = {
  // async fetchVariants({ commit, rootGetters }) {
  //   try {
  //     const { data: { data } } = await STRUCTURES_API.get('/variant?type=Price');
  //    const hasWriteAccess = rootGetters['auth/hasPermission']({ name: etrm.trades, right: [accessRight.write] });
  //     commit('setVariants', {
  //       variants: data,
  // // //      hasWriteAccess,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },

  selectedGranularityChanged({ commit, dispatch }, value) {
    commit('setSelectedGranularity', value);
    //    dispatch('fetchPriceIntervals');
  },

  async fetchPriceIntervals({ commit, state }) {
    const { currentTableRow } = state;
    if (!currentTableRow) { return; }

    const date = state.asOfDate;

    const asOfDate = moment((typeof date === 'object') ? date.format('YYYY-MM-DD') : date.substring(0, 10));
    const start = state.intervalsDateRange[0];
    const end = state.intervalsDateRange[1];
    const timeZoneName = state.selectedTimeZone;

    const params = {
      asOfDateOn: asOfDate.format('YYYY-MM-DD'),
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      timeZoneName,
      granularity: state.selectedGranularity,
      priceType: state.currentTableRow.type,
    };
    await await STRUCTURES_API.get(`/prices/${currentTableRow.id}/intervals`, { params }).then(({ data }) => {
      commit('resetIntervalsTableData');

      // adjust timestamps by the selected time zone
      const intervals = data.data;

      intervals.forEach((d, idx) => {
        d.asOfDate = dateStore.toLocalFromDate(d.asOfDate, "GMT");
        d.startTime = dateStore.toLocalFromDate(d.startTime, timeZoneName);
        d.endTime = dateStore.toLocalFromDate(d.endTime, timeZoneName);
        d.effectiveTime = dateStore.toLocalFromDate(d.effectiveTime, timeZoneName);
        d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
      });

      commit('setIntervalTableData', intervals);
      this.$notify(`${data.data.length} Price Intervals Loaded`, 'success');
    }).catch((err) => {
      this.$notify(`Failed to Load Price Intervals: ${err}`, 'error');
    });
  },
};

const mutations = {
  // setSelectedVariants(state, value) {
  //   state.selectedVariants = value;
  // },
  setIntervalsDateRange(state, dateRange) {
    // make sure to limit to 3 months from start date
    const start = moment(dateRange[0]);
    const endMax = moment(dateRange[0]).add(12, 'month');
    let end = moment(dateRange[1]);

    if (end.isAfter(endMax)) { end = endMax; }

    state.intervalsDateRange = [start, end];
  },
  setSelectedGranularity(state, data) {
    state.selectedGranularity = data;
  },
  setAsOfDate(state, data) {
    state.asOfDate = data;
  },
  setSelectedTimeZone(state, data) {
    state.selectedTimeZone = data;
  },
  setIntervalTableData(state, data) {
    state.intervalsTableData = data;
  },
  resetIntervalsTableData(state) {
    state.intervalsTableData = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
