import utils from '@/utils';
import moment from 'moment';
import { TOOLS_API } from '@/api';
import { clone } from '@/utils/dataUtil';

const state = {
  tableData: [],
  currentTableRow: null,
  nullTableRow: {
    subcompanyId: 0,
    pseId: 0,
    scId: null,
    subcompany: null,
    pse: null,
    effectiveDate: null,
    terminationDate: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const actions = {
  async fetchEIMTcPseMap({ commit }, args) {
    const params = {
      date: args.tradeDateRangeSelected[0].toISOString(),
      scs: args.coordinatorListSelected,
    };

    try {
      const response = await TOOLS_API.get('/transmission-billing-info/sub-companies-pse-map', { params });
      response.data.forEach((obj) => {
        obj.effectiveDate = obj.effectiveDate === null ? null : moment(obj.effectiveDate).utc().format('MM/DD/YYYY');
        obj.terminationDate = obj.terminationDate === null ? null : moment(obj.terminationDate).utc().format('MM/DD/YYYY');
        obj.createdDate = obj.createdDate === null ? null : moment(obj.createdDate).utc().format('MM/DD/YYYY');
        obj.updatedDate = obj.updatedDate === null ? null : moment(obj.updatedDate).utc().format('MM/DD/YYYY');
      });
      commit('setEIMTcPseMapTableData', response.data);
    } catch {
      this.$notify('Failed to Load EIM Tc Pse Map', 'error');
    }
  },
  async upsertTableRow({ commit, state }, record) {
    const params = clone(record);
    const ID = params.subcompanyId;
    try {
      const { data: { data } } = await TOOLS_API.post('/transmission-billing-info/pse-upsert', params);
      data.forEach((obj) => {
        obj.effectiveDate = obj.effectiveDate === null ? null : moment(obj.effectiveDate).utc().format('MM/DD/YYYY');
        obj.terminationDate = obj.terminationDate === null ? null : moment(obj.terminationDate).utc().format('MM/DD/YYYY');
        obj.createdDate = obj.createdDate === null ? null : moment(obj.createdDate).utc().format('MM/DD/YYYY');
        obj.updatedDate = obj.updatedDate === null ? null : moment(obj.updatedDate).utc().format('MM/DD/YYYY');
      });
      if (ID === 0) { // ADD
        state.tableData.push(data[0]);
        this.$notify('Row Added', 'success');
      } else { // UPDATE
        commit('updateRecord', data[0]);
        this.$notify('Row Updated', 'success');
      }
    } catch (error) {
      this.$notify('Requested Operation Failed', 'error');
      console.error(error);
    }
  },
};

const mutations = {
  setEIMTcPseMapTableData(state, data) {
    state.tableData = data;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = moment().utc().format();
    record.updatedBy = this.getters['auth/getProfile'].userName;
    record.updatedDate = moment().utc().format();
    state.currentTableRow = record;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ subcompanyId }) => subcompanyId === record.subcompanyId);
    state.tableData.splice(rowIndex, 1, record);
  },
  editTableRow(state) {
    const record = clone(state.currentTableRow);
    record.updatedBy = this.getters['auth/getProfile'].userName;
    record.updatedDate = moment().utc().format();
    state.currentTableRow = record;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};