import { CISO_OMS_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { createMutations } from '@/utils/vuexHelper';
import moment from 'moment';

const state = {
  outageTableData: [],
  outageAvailabilityTableData: [],
  outageASAvailabilityTableData: [],
  outageCauseCodeTableData: [],
  outagePminTableData: [],
  outageUseLimitTableData: [],
  selectedIndex: 0,
  selectedOutage: null,
  params: null,
  isFetchingData: false,

  allOutageAvailabilityTableData: [],
  allOutageASAvailabilityTableData: [],
  allOutageCauseCodeTableData: [],
  allOutagePminTableData: [],
  allOutageUseLimitTableData: [],
};

const formatTime = (arr) => {
  arr.forEach((obj) => {
    obj.tzStartTime = moment(obj.tzStartTime).utc().format('YYYY-MM-DD HH:mm');
    obj.tzEndTime = moment(obj.tzEndTime).utc().format('YYYY-MM-DD HH:mm');
  });
};

const actions = {
  async fetchOutages({ commit, dispatch, state }, parameters = {}) {
    commit('setSelectedOutage', {});
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      startTime: startDate,
      endTime: endDate,
      tz: dateStore.getTimeZone(),
    };

    state.params = params;

    try {
      const { data: { data } } = await CISO_OMS_API.get('/outages', { params });
      formatTime(data);
      commit('setOutageTableData', data);

      if (Array.isArray(data) && data.length > 0) {
        dispatch('selectedOutageChanged', data[0]);
      } else commit('reset');
    } catch (error) {
      console.error(error);
      this.$notify({ type: 'error', message: 'Error occurred during outage retrieval' });
    }
  },
  async selectedOutageChanged({ commit, state }, outage) {
    const params = {
      outageID: outage.outageId.toString(),
      rsrcID: outage.resourceId,
      tz: dateStore.getTimeZone(),
      entities: outage.sc,
    };

    try {
      commit('setIsFetchingData', true);
      const { data: { data } } = await CISO_OMS_API.get('/outages/details', { params });
      formatTime(data.outageAvailability);
      formatTime(data.outageUseLimits);
      formatTime(data.outagePmins);
      commit('setIsFetchingData', false);
      if (data) { commit('setSelectedOutage', data); }
    } catch (error) {
      console.error(error);
      this.$notify({ type: 'error', message: 'Error occurred during outage details retrieval' });
      commit('setIsFetchingData', false);
    }
  },

  async getAllOutageDetails({ commit, state }, outages) {
    const outageIDs = outages.map((obj) => obj.outageId).join(',');
    const params = {
      outageID: outageIDs,
      tz: dateStore.getTimeZone(),
      entities: outages[0].sc,
    };

    try {
      commit('setIsFetchingData', true);
      const { data: { data } } = await CISO_OMS_API.get('/outages/details', { params });
      formatTime(data.outageAvailability);
      formatTime(data.outageUseLimits);
      formatTime(data.outagePmins);
      commit('setIsFetchingData', false);
      if (data) { commit('setAllOutages', data); }
    } catch (error) {
      console.error(error);
      this.$notify({ type: 'error', message: 'Error occurred during outage details retrieval' });
      commit('setIsFetchingData', false);
    }
  },

};

const mutations = {
  ...createMutations(
    'availabilityTableData',
    'outageTableData',
    'outageAvailabilityTableData',
    'outageASAvailabilityTableData',
    'outageCauseCodeTableData',
    'outagePminTableData',
    'outageUseLimitTableData',
    'isFetchingData',

    'allOutageAvailabilityTableData',
    'allOutageASAvailabilityTableData',
    'allOutageCauseCodeTableData',
    'allOutagePminTableData',
    'allOutageUseLimitTableData',
  ),
  setSelectedOutage(state, {
    outageAvailability, outageASAvailability, outageCauseCodes, outagePmins, outageUseLimits,
  }) {
    state.outageAvailabilityTableData = outageAvailability || [];
    state.outageASAvailabilityTableData = outageASAvailability || [];
    state.outageCauseCodeTableData = outageCauseCodes || [];
    state.outagePminTableData = outagePmins || [];
    state.outageUseLimitTableData = outageUseLimits || [];
  },
  setAllOutages(state, {
    outageAvailability, outageASAvailability, outageCauseCodes, outagePmins, outageUseLimits,
  }) {
    state.allOutageAvailabilityTableData = outageAvailability || [];
    state.allOutageASAvailabilityTableData = outageASAvailability || [];
    state.allOutageCauseCodeTableData = outageCauseCodes || [];
    state.allOutagePminTableData = outagePmins || [];
    state.allOutageUseLimitTableData = outageUseLimits || [];
  },
  reset(state) {
    state.outageTableData = [];
    state.outageAvailabilityTableData = [];
    state.outageASAvailabilityTableData = [];
    state.outageCauseCodeTableData = [];
    state.outagePminTableData = [];
    state.outageUseLimitTableData = [];

    state.allOutageAvailabilityTableData = [];
    state.allOutageASAvailabilityTableData = [];
    state.allOutageCauseCodeTableData = [];
    state.allOutagePminTableData = [];
    state.allOutageUseLimitTableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};