import axios from 'axios';
import { getInstance } from '@/auth/authWrapper';

const config = require(`./api.${process.env.NODE_ENV}`);

/* addAuth api-key bypass method incase auth0 is down */
// function addAuth(API) {
//   API.interceptors.request.use(async (reqConfig) => {
//     reqConfig.headers.common.Accept = 'application/json';
//     try {
//       const { hostname } = window.location;
//       const apiKey = window.localStorage.api_key;
//       // when being routed user confirmation, the user will not be authenticated
//       // and as such, cannot request a token
//       reqConfig.headers['x-api-key'] = `${apiKey}`;
//     } catch (error) {
//       console.error(error);
//     }
//     return reqConfig;
//   }, (error) => error);
// }

function addAuth(API) {
  API.interceptors.request.use(async (reqConfig) => {
    if (!reqConfig.headers.Accept) {
      reqConfig.headers.Accept = 'application/json';
    }
    try {
      const authService = await getInstance();
      // when being routed user confirmation, the user will not be authenticated
      // and as such, cannot request a token
      if (localStorage.getItem('isAuthenticated') === 'true') {
        const accessToken = await authService.getTokenSilently();
        reqConfig.headers.Authorization = `Bearer ${accessToken}`;
      }
    } catch (error) {
      console.error(error);
    }
    return reqConfig;
  }, (error) => error);
}

const createAPI = (baseURL) => axios.create({ baseURL });

export function createAPIFromURL(url) {
  const API = createAPI(url);
  addAuth(API);
  return API;
}

export const BALANCING_AUTHORITY_API = createAPI(config.default.BALANCING_AUTHORITY_URL);
addAuth(BALANCING_AUTHORITY_API);

export const BIDDING_API = createAPI(config.default.BIDDING_URL);
addAuth(BIDDING_API);

export const BILLING_REF_API = createAPI(config.default.BILLING_REF_URL);
addAuth(BILLING_REF_API);

export const BILLING_SOURCE_API = createAPI(config.default.BILLING_SOURCE_URL);
addAuth(BILLING_SOURCE_API);

export const BILLING_STLMTS_API = createAPI(config.default.BILLING_STLMTS_URL);
addAuth(BILLING_STLMTS_API);

export const BILLING_INVOICE_API = createAPI(config.default.BILLING_INVOICE_URL);
addAuth(BILLING_INVOICE_API);

export const BILLING_CALC_API = createAPI(config.default.BILLING_CALC_URL);
addAuth(BILLING_CALC_API);

export const CISO_ADS_API = createAPI(config.default.CISO_ADS_URL);
addAuth(CISO_ADS_API);

export const CISO_BASE_SCHD_API = createAPI(config.default.CISO_BASE_SCHD_URL);
addAuth(CISO_BASE_SCHD_API);

export const CISO_BID_SCHD_API = createAPI(config.default.CISO_BID_SCHD_URL);
addAuth(CISO_BID_SCHD_API);

export const CISO_CMRI_API = createAPI(config.default.CISO_CMRI_URL);
addAuth(CISO_CMRI_API);

export const CISO_MASTERDATA_API = createAPI(config.default.CISO_MASTERDATA_URL);
addAuth(CISO_MASTERDATA_API);

export const CISO_MASTERFILE_API = createAPI(config.default.CISO_MASTERFILE_URL);
addAuth(CISO_MASTERFILE_API);

export const CISO_INTERNAL_API = createAPI(config.default.CISO_INTERNAL_URL);
addAuth(CISO_INTERNAL_API);

export const CISO_METER_API = createAPI(config.default.CISO_METER_URL);
addAuth(CISO_METER_API);

export const CISO_OASIS_API = createAPI(config.default.CISO_OASIS_URL);
addAuth(CISO_OASIS_API);

export const CISO_OMS_API = createAPI(config.default.CISO_OMS_URL);
addAuth(CISO_OMS_API);

export const CISO_SAMC_API = createAPI(config.default.CISO_SAMC_URL);
addAuth(CISO_SAMC_API);

export const CISO_SIBR_API = createAPI(config.default.CISO_SIBR_URL);
addAuth(CISO_SIBR_API);

export const CISO_TRADE_SCHD_API = createAPI(config.default.CISO_TRADE_SCHD_URL);
addAuth(CISO_TRADE_SCHD_API);

export const CISO_SCHD_API = createAPI(config.default.CISO_SCHD_URL);
addAuth(CISO_SCHD_API);

export const CISO_CIRA_API = createAPI(config.default.CISO_CIRA_URL);
addAuth(CISO_CIRA_API);

export const CMS_API = createAPI(config.default.CMS_URL);
addAuth(CMS_API);

export const CONNECTIONS_API = createAPI(config.default.CONNECTIONS_URL);
addAuth(CONNECTIONS_API);

export const CUSTOM_API = createAPI('');
addAuth(CUSTOM_API);

export const DELIVERY_MANAGEMENT_API = createAPI(config.default.DELIVERY_MANAGEMENT_URL);
addAuth(DELIVERY_MANAGEMENT_API);

export const ETAG_API = createAPI(config.default.ETAG_URL);
addAuth(ETAG_API);

export const ETRM_API = createAPI(config.default.ETRM_URL);
addAuth(ETRM_API);

export const IDENTITY_API = createAPI(config.default.IDENTITY_URL);
addAuth(IDENTITY_API);

export const ISO_REF_API = createAPI(config.default.ISO_REF_URL);
addAuth(ISO_REF_API);

export const POSITIONS_API = createAPI(config.default.POSITIONS_URL);
addAuth(POSITIONS_API);

export const PRE_SCHEDULING_API = createAPI(config.default.PRE_SCHEDULING_URL);
addAuth(PRE_SCHEDULING_API);

export const PPA_REF_API = createAPI(config.default.PPA_REF_URL);
addAuth(PPA_REF_API);

export const PPA_SOURCE_API = createAPI(config.default.PPA_SOURCE_URL);
addAuth(PPA_SOURCE_API);

export const PPA_STLMTS_API = createAPI(config.default.PPA_STLMTS_URL);
addAuth(PPA_STLMTS_API);

export const PPA_INVOICE_API = createAPI(config.default.PPA_INVOICE_URL);
addAuth(PPA_INVOICE_API);

export const REPORTING_API = createAPI(config.default.REPORTING_URL);
addAuth(REPORTING_API);

export const SCRIPTING_API = createAPI(config.default.SCRIPTING_URL);
addAuth(SCRIPTING_API);

export const STRUCTURES_API = createAPI(config.default.STRUCTURES_URL);
addAuth(STRUCTURES_API);

export const TOOLS_API = createAPI(config.default.CISO_TOOLS_URL);
addAuth(TOOLS_API);

export const VA_API = createAPI(config.default.VA_URL);
addAuth(VA_API);

export const WORKFLOW_API = createAPI(config.default.WORKFLOW_URL);
addAuth(WORKFLOW_API);

export const SPP_MARKET_API = createAPI(config.default.SPP_MARKET_URL);
addAuth(SPP_MARKET_API);

export const SPP_METER_API = createAPI(config.default.SPP_METER_URL);
addAuth(SPP_METER_API);

export const SPP_STLMT_API = createAPI(config.default.SPP_STLMT_URL);
addAuth(SPP_STLMT_API);

export const SPP_SCHD_API = createAPI(config.default.SPP_SCHD_URL);
addAuth(SPP_SCHD_API);

export const RECS_MANAGEMENT_API = createAPI(config.default.RECS_MANAGEMENT_URL);
addAuth(RECS_MANAGEMENT_API);

export const METER_MANAGEMENT_API = createAPI(config.default.METER_MANAGEMENT_URL);
addAuth(METER_MANAGEMENT_API);