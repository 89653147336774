import schedulingSubmissionStore from './Scheduling/Submission/store';

export default {
    namespaced: true,
    state: {},
    modules: {
        scheduling: {
            namespaced: true,
            state: {},
            modules: {
                submission: schedulingSubmissionStore,
            },
        },
    },
};