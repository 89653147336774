const meterManagement: any = {
  menu: /^meter_management:*/,
  reference: {
    menu: /meter_management:ref:*/,
    meter: 'meter_management:ref:meter',
    virtualMeter: 'meter_management:ref:virtual_meter',
    isoLocation: 'meter_management:ref:iso_location',
  },
  submit: {
    menu: /meter_management:submit:*/,
    submit: 'meter_management:submit:submit',
    extractEide: 'meter_management:submit:extract_eide',
    status: 'meter_management:submit:status',
  },
};

export default meterManagement;