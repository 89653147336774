import Vue from 'vue';
import moment from 'moment';
import { STRUCTURES_API, VA_API } from '@/api';
import { uniqueArrayByObjKey } from '@/utils/dataUtil';
import { fetchUserResource } from '@/utils/structuresHelper';
import { createMutations } from '@/utils/vuexHelper';

const DAILY_AGGREGATION_SUFFIX = '_DAILYAGG';

const state = {
  locations: [],
  locationsFilter: [],
  variants: [],
  layouts: [],
  selectedLayout: null,
  selectedLayoutId: null,
  chartsTree: [],
  orderedSeries: [],
  selectedLocationGroup: null,
  selectedVariant: 'T_1',
  selectedRule: null,
  rules: [],
  groups: [],
  screenConfig: {},
};

const getters = {
  locationList: (state) => uniqueArrayByObjKey(state.locations, 'shortName')
    .map(({ shortName }) => ({ label: shortName, value: shortName })),
  layoutList: (state) => state.layouts.map(({ name, id }) => ({ value: id, label: name })).sort((a, b) => a.label.localeCompare(b.label)),
  seriesNameBySeriesId: (state) => {
    if (state.selectedLayout) {
      const chartSeriesArr = state.selectedLayout.charts.map(({ chartSeries }) => chartSeries).flat();
      const result = chartSeriesArr.reduce((acc, { seriesId, seriesName }) => {
        acc[seriesId] = seriesName;
        return acc;
      }, {});
      return result;
    }
    return {};
  },
  seriesLegend: (state) => {
    if (state.chartsTree && state.chartsTree.length) {
      return state.chartsTree.reduce((acc, { items }) => {
        items.forEach((item) => {
          acc[item.text] = item.selected;
        });
        return acc;
      }, {});
    }
    return {};
  },
  chartSeries: (state) => {
    if (state.selectedLayout) {
      return state.selectedLayout.charts.map(({ chartSeries }) => chartSeries).flat();
    }
    return [];
  },
  chartsTree: (state) => state.chartsTree,
  chartPanes: (state) => {
    if (state.chartsTree && state.chartsTree.length) {
      return state.chartsTree.slice(-1)[0].items
        .filter(({ selected }) => selected)
        .map(({ pane }) => ({
          name: pane,
          border: {
            visible: true,
          },
        }));
    }
    return [];
  },
  chartSeriesById: (state) => {
    if (state.selectedLayout) {
      return state.selectedLayout.charts.reduce((acc, { chartSeries }) => {
        chartSeries.forEach((cs) => {
          acc[cs.chartSeriesId] = cs;
        });
        return acc;
      }, {});
    }
    return {};
  },
  selectedVariant: (state) => state.selectedVariant,
  variants: (state) => state.variants,
  selectedLayout: (state) => state.selectedLayout,
  layouts: (state) => state.layouts,
  rules: (state) => state.rules.map((rule) => ({ ...rule, displayName: rule.description.replace('Rule: ', '') })),
  selectedRule: (state) => state.selectedRule,
  locations: (state) => state.locations,
  selectedLocationGroup: (state) => state.selectedLocationGroup,
  selectedLocationGroupObject: (state) => state.groups.find((locationGroup) => locationGroup.id === state.selecedLocationGroup),
  groupedLocations: ({ locationsFilter, locations }) => locations.filter(({ shortName }) => locationsFilter.includes(shortName)),
  charts: (state) => state.selectedLayout?.charts ?? [],
};

const actions = {
  async initialize({ dispatch }) {
    await dispatch('fetchLayouts');
    await dispatch('fetchLocationGroups');
  },
  async fetchDefaultLayout({ commit, dispatch, state }) {
    try {
      const data = await fetchUserResource('visualAnalytics', 'isUserDefaultLayout');
      commit('setDefaultLayout', data);
    } catch (e) {
      console.error(e);
    }
  },
  async fetchScreenConfig({ commit }, screenName) {
    try {
      const data = await STRUCTURES_API.get(`/collections/va_screen_config/resources/${screenName}`);
      commit('setScreenConfig', data.data);
    } catch (e) {
      console.error(e);
    }
  },
  async fetchLayouts({ commit, dispatch, state }, allowLayoutChange = true) {
    try {
      /*
      Data Structure:
      {
        name: string,
        description: string,
        default: bool,
        defaultVariant: string,
        defaultLocation: string
      }

      isUserDefaultLayout is a pseudo property stored in the structures db
      to save the user's default layout to use on DailyAnalysis and PortfolioAnalysis
      */
      const { data: { data } } = await VA_API.get('layouts?userOnlyFlag=true');
      if (Array.isArray(data)) {
        let layoutId = data[0].id;
        commit('setLayouts', data);
        await dispatch('fetchDefaultLayout');
        data.forEach((layout) => {
          if (layout.isUserDefaultLayout && layout.id) {
            layoutId = layout.id;
          } else {
            Vue.set(layout, 'isUserDefaultLayout', false);
          }
        });
        if (allowLayoutChange) dispatch('changeSelectedLayout', layoutId);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async fetchLocationGroups({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('location-groups?groupType=VA&module=VisualAnalytics');
      commit('setGroups', data.locationGroups);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchGroupLocations({ commit, dispatch, state }, group) {
    try {
      const { data } = await STRUCTURES_API.get(`locations?ShowSubtypes=true&LocationGroup=${group}`);

      let locationsFilter;
      if (group === 'ALL') {
        locationsFilter = state.locations.map(({ shortName }) => shortName);
      } else {
        locationsFilter = data?.data?.locations?.map((el) => el.shortName) ?? [];
      }
      commit('setSelectedLocationGroup', state.groups.find(({ label }) => label === group));
      commit('setLocationsFilter', locationsFilter);
    } catch (error) {
      console.error(error);
    }
  },
  async changeSelectedLayout({ commit, dispatch, state }, id) {
    // if id is null, reset the layout
    if (id >= 0) {
      // if id does not equal the selected index, load layout
      if (id !== state.selectedLayoutId) {
        const layout = state.layouts.find((l) => l.id === id);
        if (layout) {
          try {
            const { data } = await VA_API.get(`/layouts/${layout.id}/chart`);
            data.charts = data.charts.map((chart) => ({ visible: true, ...chart }));
            data.charts.forEach((charts) => {
              charts.chartSeries.forEach((chartSeries) => {
                if (chartSeries.dailyAggregation) chartSeries.seriesName += DAILY_AGGREGATION_SUFFIX;
              });
            });
            const ruleLCS = data.grids.find(({ name }) => name === 'ruleLocationCount');
            data.ruleLocationChartSeries = ruleLCS?.columns || [];
            commit('setSelectedLayout', data);
            commit('setSelectedLayoutId', id);

            let currentVariant = state.selectedVariant || (state.variants.length ? state.variants[0].variantName : undefined);
            state.variants.sort((a, b) => a.daysBack > b.daysBack ? 1 : -1);
            if (state.variants.map(({ variantName }) => variantName).includes(layout.defaultVariant)) {
              currentVariant = layout.defaultVariant;
            } else if (layout.defaultVariant === 'MAX') {
              currentVariant = state.variants.sort((a, b) => a.daysBack > b.daysBack ? -1 : 1);
              currentVariant = currentVariant[0].variantName;
            }
            commit('setSelectedVariant', currentVariant);
          } catch (err) {
            console.error(err);
            await dispatch('fetchLayouts');
          }
        } else {
          await dispatch('fetchLayouts');
        }
      }
    } else {
      await dispatch('fetchLayouts');
    }
  },
  async fetchLocations({ commit }, inputEffectiveDate) {
    try {
      const params = {
        showSubtypes: true,
        effectiveDate: inputEffectiveDate,
      };
      const { data: { data } } = await STRUCTURES_API.get('locations', { params });
      commit('setLocations', data.locations);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchVariants({ commit, state }, startDate) {
    try {
      const { data: { data } } = startDate
        ? await VA_API.get(`/variants/${ moment(startDate).toISOString() }`)
        : await VA_API.get('/variants');
      if (data.length) {
        commit('setVariants', data);
        data.sort((a, b) => a.daysBack > b.daysBack ? 1 : -1);
        if (state.selectedLayout && state.selectedLayout.defaultVariant === 'MAX') {
          const latestVariant = data.sort((a, b) => a.offset > b.offset ? 1 : -1);
          commit('setSelectedVariant', latestVariant[0].variantName);
        } else if (!data.map(({ variantName }) => variantName).includes(state.selectedVariant)) {
          commit('setSelectedVariant', data[0].variantName);
        } else {
          const variant = data.find(({ variantName }) => variantName === state.selectedVariant);
          commit('setSelectedVariant', variant.variantName);
        }
      }
    } catch (error) {
      console.error('Error fetching variants', error);
    }
  },
};

const mutations = {
  setDefaultLayout(state, value) {
    const layoutIdx = state.layouts.findIndex(({ id }) => id === value?.id);
    const globalDefaultIdx = state.layouts.findIndex(({ defaultFlag }) => defaultFlag === true);
    let defaultLayout = (layoutIdx !== -1)
      ? state.layouts[layoutIdx]
      : state.layouts.find(({ name }) => name === 'Basic Chart');
      // If the user does not have a default. Use the global default.
    if (defaultLayout === undefined) {
      defaultLayout = state.layouts[0];
    } else if (defaultLayout.name === 'Basic Chart' && globalDefaultIdx !== -1) {
      defaultLayout = state.layouts[globalDefaultIdx];
      defaultLayout.isUserDefaultLayout = true;
    } else if (defaultLayout) {
      defaultLayout.isUserDefaultLayout = true;
    }
  },
  setGroups(state, value) {
    value.unshift({
      shortName: 'ALL', id: 'ALL', value: 'ALL', label: 'ALL',
    });
    state.groups = value.map((val) => ({ label: val.shortName, value: val.id, ...val }));
  },
  setLocations(state, value) {
    state.locations = uniqueArrayByObjKey(value, 'shortName')
      .sort((a, b) => a.shortName.localeCompare(b.shortName));
  },
  resetLayout(state) {
    state.layouts = [];
    state.selectedLayout = null;
    state.selectedLayoutId = null;
    state.chartsTree = [];
    state.selectedVariant = 'T_1';
  },
  setSelectedLayout(state, layout) {
    state.selectedLayout = layout;
    const branches = state.selectedLayout.charts
      .filter(({ name }) => !name.toLowerCase().includes('rule'))
      .map((chart) => ({
        expanded: true,
        pane: chart.name,
        selected: chart.selected,
        text: chart.name,
        leftAxisTitle: chart.leftAxisTitle,
        rightAxisTitle: chart.rightAxisTitle,
        leftAxisFormat: chart.leftAxisFormat,
        rightAxisFormat: chart.rightAxisFormat,
        items: chart.chartSeries
          .filter(({ seriesId }) => !state.rules.map(({ ruleSeriesId }) => ruleSeriesId).includes(seriesId))
          .map((seriesMeta) => {
            let selected = false;
            const disabled = false;
            selected = seriesMeta.selected;
            const axisName = seriesMeta.axis === 'RIGHT' ? chart.rightAxisTitle : chart.leftAxisTitle || chart.name;
            return {
              pane: chart.name,
              selected,
              disabled,
              axisName,
              text: seriesMeta.chartSeriesName,
              color: seriesMeta.fillColor,
              type: seriesMeta.markerType,
              seriesId: seriesMeta.seriesId,
              seriesName: seriesMeta.seriesName,
              hoverDescription: seriesMeta.hoverDescription,
              chartSeriesId: seriesMeta.chartSeriesId,
              aggregateType: seriesMeta.aggregateType,
              dailyAggregation: seriesMeta.dailyAggregation,
              hourlyGridTotalOP: seriesMeta.hourlyGridTotalOP,
              tooltipVisible: false,
              heatMapMax: seriesMeta.heatMapMax || null,
              heatMapMid: seriesMeta.heatMapMid !== undefined && seriesMeta.heatMapMid !== null ? seriesMeta.heatMapMid : null,
              heatMapMin: seriesMeta.heatMapMin || null,
            };
          }),
      }));

    const orderedSeries = [];
    for (let i = 0; i < branches.length; i++) {
      for (let j = 0; j < branches[i].items.length; j++) {
        if (branches[i].items[j] !== undefined) {
          orderedSeries.push(branches[i].items[j]);
        }
      }
    }
    state.orderedSeries = orderedSeries;
    const lastBranch = {
      expanded: true,
      text: 'Charts',
      items: layout.charts
        .filter(({ name }) => !name.toLowerCase().includes('rules'))
        .map(({ name, selected }) => ({
          pane: name,
          text: name,
          selected,
        })),
    };
    state.chartsTree = [...branches, lastBranch];
  },
  ...createMutations(
    'layouts',
    'rules',
    'selectedLayoutId',
    'selectedRule',
    'variants',
    'selectedVariant',
    'selectedLocationGroup',
    'locationsFilter',
    'screenConfig',
  ),
};

export default {
  state,
  actions,
  mutations,
  getters,
  DAILY_AGGREGATION_SUFFIX,
};