import Vue from 'vue';
import moment from 'moment';
import qs from 'qs';
import {
  ETRM_API, CUSTOM_API, STRUCTURES_API,
  CISO_BID_SCHD_API, CISO_TRADE_SCHD_API, BIDDING_API,
} from '@/api';
import {
  handleError, cloneDeep, has, clone,
  nullOrEqualTo, sortBy,
} from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { fetchResource } from '@/utils/structuresHelper';
import { createMutations } from '@/utils/vuexHelper';

import { accessRight, etrm } from '@/auth/permission';
import LOOKUP_STORE from '@/store/lookupStore';
import { confirm } from 'devextreme/ui/dialog';
import { getOpenMarkets } from '@/components/Scheduling/utils';

import { saveAs } from 'file-saver';
import utils from '@/utils';
import { attachIntervalsToTrade, createTradeParams } from '../../tradesUtil';
import defaultColumnsFactory from './defaultColumnsFactory';
import INTERVAL_STORE from './intervalStore';
import { customRegistry } from './registry';

const TEMPLATE_FIELDS_TO_UPDATE = [
  {
    propKey: 'company', list: 'companyList', labelKey: 'shortName', listFilter: ({ internalFlag }) => internalFlag,
  },
  {
    propKey: 'counterparty',
    list: 'companyList',
    labelKey: 'shortName',
    listFilter: ({ internalFlag }) => !internalFlag
  },
  {
    propKey: 'counterparty2',
    list: 'companyList',
    labelKey: 'shortName',
    listFilter: ({ internalFlag }) => !internalFlag
  },
  {
    propKey: 'counterpartyCredit',
    list: 'companyList',
    labelKey: 'shortName',
    listFilter: ({ internalFlag }) => !internalFlag
  },
  { propKey: 'broker', list: 'brokerList', labelKey: 'name' },
  { propKey: 'commodity', list: 'energyCommodityList', labelKey: 'shortName' },
  {
    propKey: 'fuelType', list: 'catalogList', labelKey: 'name', listFilter: ({ type }) => type === 'fuelType',
  },
  {
    propKey: 'portfolio', list: 'catalogList', labelKey: 'name', listFilter: ({ type }) => type === 'portfolio',
  },
  {
    propKey: 'book', list: 'catalogList', labelKey: 'name', listFilter: ({ type }) => type === 'book',
  },
  {
    propKey: 'strategy', list: 'catalogList', labelKey: 'name', listFilter: ({ type }) => type === 'strategy',
  },
  {
    propKey: 'firmType', list: 'catalogList', labelKey: 'name', listFilter: ({ type }) => type === 'firmType',
  },
  {
    propKey: 'termProduct', list: 'catalogList', labelKey: 'name', listFilter: ({ type }) => type === 'termProduct',
  },
  {
    propKey: 'enablingAgreement',
    list: 'catalogList',
    labelKey: 'name',
    listFilter: ({ type }) => type === 'enablingAgreement',
  },
  {
    propKey: 'paymentTerm', list: 'catalogList', labelKey: 'name', listFilter: ({ type }) => type === 'paymentTerm',
  },
  { propKey: 'environmentalSubAccount', list: 'environmentalSubAccountList', labelKey: 'shortName' },
  { propKey: 'termPriceIndex', list: 'priceList', labelKey: 'name' },
  { propKey: 'termPriceIndex2', list: 'priceList', labelKey: 'name' },
  { propKey: 'marketPriceIndex', list: 'priceList', labelKey: 'name' },
  { propKey: 'intraMonthMarketPriceIndex', list: 'priceList', labelKey: 'name' },

  // NG trade attributes
  {
    propKey: 'pipeline',
    list: 'locationList',
    labelKey: 'shortName',
    listFilter: ({ locationType }) => locationType === 'PIPELINE'
  },
  {
    propKey: 'meter',
    list: 'locationList',
    labelKey: 'shortName',
    listFilter: ({ locationType }) => locationType === 'METER'
  },
  {
    propKey: 'unit', list: 'catalogList', labelKey: 'name', listFilter: ({ type }) => type === 'uom',
  },
];

const FIELDS_TO_ADD = [
  'company',
  'counterparty',
  'counterparty2',
  'counterpartyCredit',
  'commodity',
  'fuelType',
  'firmType',
  'portfolio',
  'book',
  'strategy',
  'enablingAgreement',
  'paymentTerm',
  'environmentalSubAccount',
  'termProduct',
  'termPriceIndex',
  'marketPriceIndex',
  'market',
  'pipeline',
  'meter',
  'unit',
];

const VARIANT_TYPES = [
  'VOLUME',
  'PRICE',
  'AMOUNT',
];

const NULL_TEMPLATE = () => ({
  id: null,
  defaultValues: {
    parentId: null,
    book: null,
    broker: null,
    commissionAmount: null,
    commissionPercentage: null,
    // commodityParent: null,
    commodity: null,
    fuelType: null,
    company: null,
    companyContactId: null,
    counterparty: null,
    counterpartyContactId: null,
    counterpartyCredit: null,
    direction: null,
    effectiveEndDate: null,
    effectiveStartDate: null,
    enablingAgreement: null,
    marketContract: null,
    supplyContract: null,
    paymentTerm: null,
    expirationDate: null,
    externalDate: null,
    externalName: null,
    feeAmount: null,
    feePercentage: null,
    firmType: null,
    intervalRequest: {
      termTypeId: null,
      period: null,
      intervals: [],
      variant: null,
    },
    maturityDate: null,
    pointOfDeliveryId: null,
    pointOfReceiptId: null,
    portfolio: null,
    period: null,
    regionId: null,
    status: null,
    strategy: null,
    termDeliverableTypeId: null,
    termInterval: null,
    termPrice: null,
    termPrice2: null,
    termPriceIndex2Id: null,
    termPriceIndexId: null,
    marketPriceIndexId: null,
    intraMonthMarketPriceIndexId: null,
    retirementDate: null,
    market: null,
    fromAccount: null,
    toAccount: null,
    environmentalSubAccount: null,
    termProduct: null,
    termQuantity: null,
    termQuantity2: null,
    termTimeline: null,
    ticketName: null,
    timeZone: dateStore.getTimeZone(),
    tradeDate: null,
    tradeName: null,
    trader: null,
    isoReferenceName: null,

    // NG trades attributes
    counterparty2: null,
    pipeline: null,
    meter: null,
    unit: null,
  },
});

const searchParams = {
  parentIds: undefined,
  tradeIds: undefined,
  effectiveEndDateAfter: undefined,
  effectiveStartDateBefore: undefined,
  dateRange: dateStore.getDefaultRange(),
  tradeDateRange: [],
  createdDateRange: [],
  modifiedDateRange: [],
  tradeNames: undefined,
  externalNames: undefined,
  pointOfDeliveryNames: undefined,
  pointOfReceiptNames: undefined,
  tradeDate: undefined,
  companyIds: undefined,
  counterPartyIds: undefined,
  brokerIds: undefined,
  tradeTermGroups: undefined,
  commodityIds: undefined,
  commodityParentIds: undefined,
  directions: undefined,
  marketIds: undefined,
  regionIds: undefined,
  pointOfDeliveryIds: undefined,
  pointOfReceiptIds: undefined,
  portfolioIds: undefined,
  bookIds: undefined,
  strategyIds: undefined,
  createdBy: undefined,
  pageSize: undefined,
  marketType: 'DAM',
};

const state = {
  tagTemplates: [],
  templates: [],
  currentTrade: null,
  copyTemplate: null,
  isEditingTrade: false,
  newTradeConfig: null,
  customRegistry: {},
  trades: [],
  selectedTradeScreen: null,
  customEditableAttributes: null,
  tradeScreens: [],
  pickerMinDate: null,
  tradeConfig: {
    name: 'tradesTerms',
    columns: defaultColumnsFactory(),
    options: {
      exportExcel: true,
      filterHeader: true,
      columnConfig: true,
      filterOptions: true,
    },
  },
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  imbalanceConfig: {
    columns: [
      {
        prop: 'startTime',
        label: 'DATE',
        width: '25%',
        editable: false,
        sortable: true,
        dataType: 'date',
      }, {
        prop: 'purchasedQuantity',
        label: 'BUY',
        width: '25%',
        editable: false,
        sortable: true,
        dataType: 'number',
      }, {
        prop: 'soldQuantity',
        label: 'SELL',
        width: '25%',
        editable: false,
        sortable: true,
        dataType: 'number',
      }, {
        prop: 'imbalanceQuantity',
        label: 'IMBALANCE',
        width: '25%',
        editable: false,
        sortable: true,
        dataType: 'number',
      },
    ],
  },
  searchParams: clone(searchParams),
  selectedTemplate: NULL_TEMPLATE(),
  selectedVariants: ['CONTRACT-VOLUME', 'CONTRACT-PRICE'],
  variants: [],
  traderList: [],
  imbalanceList: [],
  isTradesToSchedulesDialogVisible: false,
  isImbalanceEnabled: false,
  cascadeVariants: true,
  ...INTERVAL_STORE.state,
};

const convertDatesBackwards = (trade) => {
  const start = dateStore.toDateFromLocal(trade.effectiveStartDate, trade.timeZone);
  const end = dateStore.toDateFromLocal(trade.effectiveEndDate, trade.timeZone);
  const tradeDate = dateStore.toDateFromLocal(trade.tradeDate, trade.timeZone);
  trade.effectiveStartDate = start.toISOString();
  trade.effectiveEndDate = end.toISOString();
  trade.tradeDate = tradeDate.toISOString();

  if (trade.maturityDate) {
    const maturityDate = dateStore.toDateFromLocal(trade.maturityDate, trade.timeZone);
    trade.maturityDate = maturityDate.toISOString();
  }
  if (trade.expirationDate) {
    const expirationDate = dateStore.toDateFromLocal(trade.expirationDate, trade.timeZone);
    trade.expirationDate = expirationDate.toISOString();
  }
  if (trade.externalDate) {
    const externalDate = dateStore.toDateFromLocal(trade.externalDate, trade.timeZone);
    trade.externalDate = externalDate.toISOString();
  }
  if (trade.retirementDate) {
    const retirementDate = dateStore.toDateFromLocal(trade.retirementDate, trade.timeZone);
    trade.retirementDate = retirementDate.toISOString();
  }
};

const convertDates = (trade) => {
  const start = dateStore.toLocalStartOfDayFromDate(trade.effectiveStartDate);
  const end = dateStore.toLocalStartOfDayFromDate(trade.effectiveEndDate);
  const tradeDate = dateStore.toLocalStartOfDayFromDate(trade.tradeDate);
  const createdDate = dateStore.toLocalFromDate(trade.createdDate);
  const updatedDate = dateStore.toLocalFromDate(trade.updatedDate);

  trade.effectiveStartDate = start.toISOString();
  trade.effectiveEndDate = end.toISOString();
  trade.tradeDate = tradeDate.toISOString();
  trade.createdDate = createdDate.toISOString();
  trade.updatedDate = updatedDate.toISOString();

  if (trade.maturityDate) {
    const maturityDate = dateStore.toLocalStartOfDayFromDate(trade.maturityDate);
    trade.maturityDate = maturityDate.toISOString();
  }
  if (trade.expirationDate) {
    const expirationDate = dateStore.toLocalStartOfDayFromDate(trade.expirationDate);
    trade.expirationDate = expirationDate.toISOString();
  }
  if (trade.externalDate) {
    const externalDate = dateStore.toLocalStartOfDayFromDate(trade.externalDate);
    trade.externalDate = externalDate.toISOString();
  }
  if (trade.retirementDate) {
    const retirementDate = dateStore.toLocalStartOfDayFromDate(trade.retirementDate);
    trade.retirementDate = retirementDate.toISOString();
  }
};
const getters = {
  currentTrade: (state) => state.currentTrade,
  isEditingTrade: (state) => state.isEditingTrade,
  constantColumns: (state) => state.newTradeConfig.columns.filter(({ config }) => config && config.type === 'constant'),
  variants: (state) => state.variants.reduce((acc, { name }) => {
    VARIANT_TYPES.forEach((type) => {
      acc.push({
        key: name,
        value: `${name}-${type}`,
        label: `${name}-${type}`,
      });
    });
    return acc;
  }, []),
  getDateRangeOptions: (state) => (type) => ({
    onPick: (obj) => {
      if (state.pickerMinDate) {
        state.pickerMinDate = null;
      } else {
        state.pickerMinDate = new Date(obj.minDate).getTime();
      }
    },
    disabledDate: (time) => {
      if (state.pickerMinDate) {
        let day1 = 24 * 3600 * 1000;
        day1 *= type === 'sync' ? 31 : 0;
        const maxTime = state.pickerMinDate + day1;
        const minTime = state.pickerMinDate - day1;
        return time.getTime() > maxTime
          || time.getTime() < minTime
          || time.getTime() > Date.now() - 1 * 24 * 3600 * 1000;
      }
      return time.getTime() > Date.now() - 1 * 24 * 3600 * 1000;
    },
  }),
  ...INTERVAL_STORE.getters,
};

const actions = {
  async initialize({ commit, dispatch }) {
    await dispatch('fetchTraderList');
    await dispatch('fetchTagTemplates');
    await dispatch('fetchVariants');
    await dispatch('fetchTemplatesAsync');
    commit('setSelectedVariants', state.selectedVariants);
  },
  async fetchLookups({ dispatch, commit }) {
    await dispatch('lookup/initializeAllLookup', true, { root: true });
  },
  async fetchCustomEditableAttributes({ dispatch, commit }) {
    try {
      const { data } = await ETRM_API.get('trade-limit-groups/attributes');
      
      const screenName = state.selectedTradeScreen?.name ?? null;
      const customEditableAttributes = 
        data?.find((x) => x.screenName === screenName)?.editableAttributes ?? null;
      
      commit('setCustomEditableAttributes', customEditableAttributes);
    } catch (error) {
      // handleError(error, 'Failed to load editable attributes config.');
    }
  },
  async fetchVariants({ state, commit, rootGetters }) {
    try {
      let { data: { data } } = await STRUCTURES_API.get('/variant?type=TradeTerm');
      const hasWriteAccess = rootGetters['auth/hasPermission']({ name: etrm.trades, right: [accessRight.write] });
      if (state.selectedTradeScreen) {
        if (state.selectedTradeScreen?.variants?.length > 0) {
          const variantList = state.selectedTradeScreen.variants.map((v) => v.label);
          data = data.filter((x) => variantList.includes(x.name));
        }
      }
      await commit('setVariants', {
        variants: data,
        hasWriteAccess,
      });
      await commit('setRangeConfig', clone(state.config));
      commit('setSelectedRangeVariants', []);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchTraderList({ commit }) {
    try {
      const { data } = await ETRM_API.get('traders');
      commit('setTraderList', data.data);
    } catch (error) {
      if (error.response) console.error(error.response);
      else console.error(error);
    }
  },
  async fetchImbalanceList({ commit }, inputParams = {}) {
    try {
      const params = createTradeParams(inputParams);
      params.pageSize = undefined;
      const { data } = await ETRM_API.get('/trades/imbalance', {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
      commit('setImbalanceList', data);
    } catch (error) {
      console.error(error);
      this.$notify('Error loading trade imbalance', 'warning');
      return Promise.reject();
    }
  },
  async fetchTagTemplates({ commit, rootState }) {
    const etag = rootState.lookup.endpointList.find(({ module }) => module === 'etag');
    if (etag) {
      try {
        const { data: { tagSummaries } } = await CUSTOM_API.get(`${etag.url}/tags/variants/template`);
        commit('setTagTemplates', tagSummaries);
      } catch (error) {
        console.error(error);
      }
    }
  },
  async fetchTradeScreens({ commit, rootState }, id) {
    const { data } = await fetchResource('tradeScreens', 'SCREENS');
    commit('setTradeScreens', data);
    commit('setSelectedTradeScreen', { id, rootState });
  },
  async generateReport({ dispatch }, inputParams = []) {
    try {
      const params = createTradeParams(inputParams);
      params.TZ = dateStore.getTimeZone();
      params.pageSize = undefined;

      const { data } = await ETRM_API.get('/reporting', {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
      this.$notify(data.message);
    } catch (error) {
      this.$notify('Error creating trade report', 'warning');
    }
  },
  async generateAccountingReport({ dispatch, state }, inputParams = []) {
    try {
      const params = createTradeParams(inputParams);
      params.TZ = dateStore.getTimeZone();
      params.pageSize = undefined;

      const fullName = `STANDARD_REPORTS_ACCOUNTING_RPT_${params.effectiveEndDateAfter}-${params.effectiveStartDateBefore}.xlsx`;
      const headers = { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };

      // best gues son the number of seconds to display the popup message
      const days = moment(params.effectiveStartDateBefore).diff(moment(params.effectiveEndDateAfter), 'days');
      const n = Math.min((state.trades?.length * 30 * days || 5000), 5000);
      this.$notify({ type: 'info', message: 'Generating file...', displayTime: n });

      await ETRM_API.get('/reporting/accounting', {
        params,
        headers,
        responseType: 'blob',
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        onDownloadProgress: (progressEvent) => {
          const message = `${progressEvent.loaded} bytes downloaded`;
          this.$notify({ type: 'info', message });
        },
      }).then((response) => {
        this.$notify({ type: 'info', message: 'Download completed, saving...' });
        saveAs(response.data, fullName);
      }).catch((error) => {
        this.$notify({ type: 'error', message: 'Error downloading the file' });
      });
    } catch (error) {
      this.$notify('Error creating accounting report', 'warning');
    }
  },
  async exportTrades({ dispatch, state }, inputParams = []) {
    try {
      const params = createTradeParams(inputParams);
      params.TZ = dateStore.getTimeZone();
      params.pageSize = undefined;

      // add selected variants on the intervals panel
      params.variants = [...new Set(state.selectedVariants.map((val) => val.split('-')[0]))].join(',');

      const exportType = inputParams?.exportType || '';
      const fileType = inputParams?.fileType || 'xlsx';
      const contentType = inputParams?.contentType || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      let fullName = `TRADES_${params.effectiveEndDateAfter}-${params.effectiveStartDateBefore}.${fileType}`;
      const fEffectiveEndDateAfter = moment(params.effectiveEndDateAfter).utc().format('MMDDYY');
      if (exportType === '/aces') {
        fullName = `PS${fEffectiveEndDateAfter}_${fEffectiveEndDateAfter}.${fileType}`;
      } else if (exportType === '/allegro') {
        const fTradeDate = params?.tradeDateAfter ? moment(params.tradeDateAfter).utc().format('MMDDYY') : fEffectiveEndDateAfter;
        fullName = `NG_Deal_${fTradeDate}.${fileType}`;
      }

      const headers = { Accept: contentType };

      // best gues son the number of seconds to display the popup message
      const days = moment(params.effectiveStartDateBefore).diff(moment(params.effectiveEndDateAfter), 'days');
      const n = Math.min(state.trades?.length * 30 * days || 5000, 5000);
      this.$notify({ type: 'info', message: 'Generating file...', displayTime: n });

      await ETRM_API.get(`/trades/export${exportType}`, {
        params,
        headers,
        responseType: 'blob',
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        onDownloadProgress: (progressEvent) => {
          const message = `${progressEvent.loaded} bytes downloaded`;
          this.$notify({ type: 'info', message });
        },
      }).then((response) => {
        this.$notify({ type: 'info', message: 'Download completed, saving...' });
        saveAs(response.data, fullName);
      }).catch((error) => {
        this.$notify({ type: 'error', message: 'Error downloading the file' });
      });
    } catch (error) {
      this.$notify('Error exporting trades', 'warning');
    }
  },
  async importTrades({ state, commit }, { fileName, importSource, configurationName }) {
    try {
      await ETRM_API.post('trades/import', {
        importSource,
        configurationName,
        fileName,
      });
      this.$notify({ type: 'success', message: 'Trades Import Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Trades Import Failed' });
      console.error(error);
    }
  },
  async syncTrades({ commit, dispatch }, inputParams = {}) {
    try {
      const params = createTradeParams(inputParams);
      const { data } = await ETRM_API.put('/bridge', null, {
        params,
        // this will properly serialize arrays of values into query format, a[1, 2, 3] => a=1&a=2&a=3
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

      this.$notify(data.message);
      return Promise.resolve();
    } catch (error) {
      console.error(error);
      this.$notify('Error synching trades', 'warning');
      return Promise.reject();
    }
  },
  async pushTradeToDelivery({ commit, dispatch, state }, inputParams = {}) {
    try {
      const params = createTradeParams(inputParams);
      const { data } = await ETRM_API.patch('/trades', null, {
        params,
        // this will properly serialize arrays of values into query format, a[1, 2, 3] => a=1&a=2&a=3
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

      if (data?.success) this.$notify(data?.message, 'success');
    } catch (error) {
      this.$notify('Error pushing trade to delivery', 'error');
    }
  },
  async tradesToSchedules({ commit, dispatch }, inputParams = {}) {
    const params = createTradeParams(inputParams);

    try {
      // Step 1. Trades to Bid Schedules
      const { data1 } = await CISO_BID_SCHD_API.put('/from-trades', null, {
        params,
        // this will properly serialize arrays of values into query format, a[1, 2, 3] => a=1&a=2&a=3
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

      this.$notify('Trades to Schedule workflows started');
    } catch (error) {
      console.error(error);
      this.$notify('Error creating schedules from trades', 'warning');
    }

    try {
      // Step 2. Trades to Schedules
      const { data2 } = await CISO_TRADE_SCHD_API.put('/trades/schedules/from-trades', null, {
        params,
        // this will properly serialize arrays of values into query format, a[1, 2, 3] => a=1&a=2&a=3
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

      this.$notify('Trades to IST workflows started');
    } catch (error) {
      console.error(error);
      this.$notify('Error creating IST from trades', 'warning');
      return Promise.reject();
    }

    return Promise.resolve();
  },
  async confirmTrade({ commit, dispatch }, params) {
    try {
      const { tradeId, action } = params;
      if (tradeId && action) {
        const { data } = await ETRM_API.post(`/trades/confirmation/${tradeId}/${action}`);
      }
    } catch (error) {
      this.$notify(`Error executing '${action}' action on trade ${tradeId}`, 'error');
    }
  },
  async fetchTrades({ commit, dispatch }, inputParams = {}) {
    commit('setTrades', []);
    commit('resetIntervals');
    try {
      const params = createTradeParams(inputParams);
      params.pageSize = undefined;

      // const p = qs.stringify(params, { arrayFormat: "repeat" });
      const { data } = await ETRM_API.get('/trades', {
        params,
        // this will properly serialize arrays of values into query format, a[1, 2, 3] => a=1&a=2&a=3
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

      data.forEach((trade) => {
        FIELDS_TO_ADD.forEach((field) => {
          trade[field] = trade[`${field}Id`]
            || trade[`${field}Name`]
            || trade[`${field}ShortName`]
            || trade[`${field}FullName`]
            || trade[field];
        });

        let counterpartyContactFullName = '';
        if (trade.counterpartyContactFirstName) {
          counterpartyContactFullName += `${trade.counterpartyContactFirstName} `;
        }
        if (trade.counterpartyContactLastName) {
          counterpartyContactFullName += `${trade.counterpartyContactLastName}`;
        }
        trade.counterpartyContactFullName = counterpartyContactFullName;

        trade.intervals = [];

        convertDates(trade);

        if (trade.isoReferenceName) {
          // map ISO reference to the trade
          const isoRef = LOOKUP_STORE.state.isoReferenceList.find(({ refName }) => refName === trade.isoReferenceName);
          if (isoRef) {
            trade.isoReference = isoRef;
          }
        }
      });

      this.$notify(`${data.length} trade(s) loaded`);

      commit('setTrades', data);

      // Set current trade to the first tradeId passed in from inputParams, or deselect the trades grid to ensure the intervals grid isn't activated on unselected trades
      let tradeToSelect = null;
      let index = -1;

      if (!!params.tradeIds && params.tradeIds.length > 0) {
        const tradeIdToMatch = params.tradeIds[0];
        index = data.findIndex((element) => element.tradeId === tradeIdToMatch);
        tradeToSelect = index !== -1 ? data[index] : null;
      }

      dispatch('currentTradeChange', tradeToSelect);

      return Promise.resolve(index);
    } catch (error) {
      console.error(error);
      this.$notify('Error loading trades', 'warning');
      return Promise.reject();
    }
  },
  async currentTradeChange({ commit, dispatch, state }, trade) {
    const prevSelectedTrade = state.currentTrade;
    commit('setCurrentTrade', trade);
    if (trade) {
      if (trade.commodity) {
        const commodity = LOOKUP_STORE.state.energyCommodityList.find(({ id }) => id === trade.commodity);
        trade.commodityName = commodity.shortName;
      }
      if (trade.termType) {
        const termType = LOOKUP_STORE.state.termTypeList.find(({ id }) => id === trade.termTypeId);
        trade.termTypeName = termType.shortName;
      }

      if (
        (trade && prevSelectedTrade && trade.tradeId !== prevSelectedTrade.tradeId)
        || (trade && prevSelectedTrade === null)
      ) {
        // Selects first day of effective date of the trade on interval grid
        commit('setConsolidatedDateRange', [trade.effectiveStartDate, trade.effectiveEndDate]);

        const momSt = moment(state.searchParams.dateRange[0], 'YYYY-MM-DD').utc();
        const momEd = moment(state.searchParams.dateRange[1], 'YYYY-MM-DD').utc();
        const tradeSt = moment(trade.effectiveStartDate, 'YYYY-MM-DD').utc();
        const tradeEd = moment(trade.effectiveEndDate, 'YYYY-MM-DD').utc();

        const rangeStartDate = momSt.isBetween(tradeSt, tradeEd, 'days', '[]')
          ? momSt
          : tradeSt;
        const rangeEndDate = momEd.isBetween(tradeSt, tradeEd, 'days', '[]')
          ? momEd
          : tradeEd;
        commit('setRangeDateRange', [rangeStartDate.toISOString(), rangeEndDate.toISOString()]);
      }
    }
  },
  updateCurrentTrade({ state, commit }, data) {
    if (state.currentTrade && (state.currentTrade.status === 'NEW' || state.currentTrade.status === 'ERRORED')) {
      commit('setCurrentTrade', { ...state.currentTrade, ...data });
    }
  },
  async updateTradeAPI({ state, commit, dispatch }, updateTrade) {
    try {
      updateTrade = attachIntervalsToTrade(updateTrade, state.cachedChangedIntervals);
      updateTrade.intervalRequest = {
        // need the server to rebuild intervals because it clears them anyway upon trade update
        expandTermType: updateTrade.termTypeName !== 'HOURLY',
        termTypeId: updateTrade.termTypeId,
        period: updateTrade.period,
        intervals: updateTrade.intervals,
      };

      convertDatesBackwards(updateTrade);

      delete updateTrade.intervals;
      const { data } = await ETRM_API.put(`/trades/${updateTrade.tradeId}`, updateTrade);
      const trade = data;

      trade.intervals = [];
      FIELDS_TO_ADD.forEach((field) => {
        trade[field] = trade[`${field}Id`]
          || trade[`${field}Name`]
          || trade[`${field}ShortName`]
          || trade[`${field}FullName`];
      });

      convertDates(trade);
      if (trade.isoReferenceName) {
        // map ISO reference to the trade
        const isoRef = LOOKUP_STORE.state.isoReferenceList.find(({ refName }) => refName === trade.isoReferenceName);
        if (isoRef) {
          trade.isoReference = isoRef;
        }
      }
      commit('updateTrade', trade);

      if (state.isImbalanceEnabled) {
        const params = state.searchParams;
        dispatch('fetchImbalanceList', params);
      }

      return { error: null, trade };
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        return { error: err.response.data, trade: null };
      }
      return { error: ['API error occurred'], trade: null };
    }
  },
  async deleteTrade({ commit, state, dispatch }) {
    try {
      if (state.currentTrade.createdBy !== 'xlsx_upload') {
        await ETRM_API.delete(`/trades?tradeId=${state.currentTrade.tradeId}`);
      }
      commit('deleteTrade');
      commit('setCurrentTrade', null);
      commit('resetIntervals');
      commit('resetConsolidatedIntervals');

      if (state.isImbalanceEnabled) {
        const params = state.searchParams;
        dispatch('fetchImbalanceList', params);
      }

      this.$notify('Successfully deleted trade', 'success');
    } catch (error) {
      this.$notify('Error deleting trade', 'error');
    }
  },
  async findExternalID({ state, rootState }, newTrade) {
    if (rootState.lookup.externalIDList.length === 0 || newTrade.externalName) { return true; }
    const externalID = rootState.lookup.externalIDList.find((x) => nullOrEqualTo(x.data.direction, newTrade.direction)
      && nullOrEqualTo(x.data.por, newTrade.pointOfReceiptShortName)
      && nullOrEqualTo(x.data.pod, newTrade.pointOfDeliveryShortName)
      && nullOrEqualTo(x.data.counterParty, newTrade.counterpartyShortName)
      && nullOrEqualTo(x.data.entity, newTrade.companyShortName)
      && nullOrEqualTo(x.data.commodity, newTrade.commodityName)
      && nullOrEqualTo(x.data.market, newTrade.regionShortName)
      && nullOrEqualTo(x.data.termProduct, newTrade.termProductShortName));
    if (externalID) {
      newTrade.externalName = externalID.name;
      newTrade.tradeName = externalID.data.name;
      return true;
    }
    return false;
  },
  async createTradeAPI({ commit, dispatch, state }, newTrade) {
    try {
      commit('setDateIntervalCache');
      newTrade = attachIntervalsToTrade(newTrade, state.cachedChangedIntervals);
      newTrade.intervalRequest = {
        expandTermType: newTrade.termTypeName !== 'HOURLY',
        termTypeId: newTrade.termTypeId,
        period: newTrade.period,
        intervals: newTrade.intervals,
      };
      delete newTrade.intervals;

      convertDatesBackwards(newTrade);
      const { data } = await ETRM_API.post('/trades', newTrade);
      const trade = data;
      trade.intervals = [];
      FIELDS_TO_ADD.forEach((field) => {
        trade[field] = trade[`${field}Id`]
          || trade[`${field}Name`]
          || trade[`${field}ShortName`]
          || trade[`${field}FullName`];
      });
      if (trade.isoReferenceName) {
        // map ISO reference to the trade
        const isoRef = LOOKUP_STORE.state.isoReferenceList.find(({ refName }) => refName === trade.isoReferenceName);
        if (isoRef) {
          trade.isoReference = isoRef;
        }
      }
      convertDates(trade);
      commit('updateTrade', trade);

      if (state.isImbalanceEnabled) {
        const params = state.searchParams;
        dispatch('fetchImbalanceList', params);
      }

      return { error: null, trade };
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        return { error: err.response.data, trade: null };
      }
      return { error: ['API error occurred'], trade: null };
    }
  },
  async fetchTemplatesAsync({ commit }) {
    try {
      const { data } = await ETRM_API.get('/trades/templates');
      data.forEach((d) => {
        try {
          d.defaultValues = JSON.parse(d.defaultValues);
          // convert certain fields to id from label.
          TEMPLATE_FIELDS_TO_UPDATE.forEach(({
            propKey, list, labelKey, listFilter,
          }) => {
            const propLabel = d.defaultValues[propKey];
            if (propLabel && (Number.isNaN(Number(propLabel)))) {
              const filteredList = listFilter ? LOOKUP_STORE.state[list].filter(listFilter) : LOOKUP_STORE.state[list];
              const propObj = filteredList.find((item) => item[labelKey] === propLabel);
              d.defaultValues[propKey] = propObj?.id;
            }
          });
        } catch (err) {
          console.error(err, d);
          d.defaultValues = {};
        }
      });
      commit('setTemplates', data);
    } catch (err) {
      console.error(err);
    }
  },
  async createTemplateFromTrade({ commit, state }, template) {
    const params = {
      templateName: template.templateName,
      dealType: state.currentTrade.commodityName,
      defaultValues: clone(state.currentTrade),
    };

    params.defaultValues.intervalRequest = {
      termTypeId: state.currentTrade.termTypeId,
      period: state.currentTrade.period,
    };

    params.defaultValues.broker = state.currentTrade.brokerId;

    // certain fields should not have a default value
    params.defaultValues.tradeDate = undefined;
    params.defaultValues.effectiveStartDate = undefined;
    params.defaultValues.effectiveEndDate = undefined;
    params.defaultValues.maturityDate = undefined;
    params.defaultValues.expirationDate = undefined;

    // convert certain fields to label from id.
    TEMPLATE_FIELDS_TO_UPDATE.forEach(({ propKey, list, labelKey }) => {
      const propId = params.defaultValues[propKey];
      if (propId) {
        const propObj = LOOKUP_STORE.state[list].find(({ id }) => id === propId);
        if (propObj) {
          params.defaultValues[propKey] = propObj[labelKey];
        }
      }
    });
    if (template.isCopyTemplate) {
      const keys = [
        'tradeType',
        'company',
        'counterparty',
        'counterparty2',
        'counterpartyCredit',
        'commodity',
        'portfolio',
        'book',
        'strategy',
        'enablingAgreement',
        'paymentTerm',
        'market',
        'pipeline',
        'meter',
        'broker',
        'termProduct',
        'fuelType',
        'termPriceIndex',
        'marketPriceIndex',
        'unit',
      ];

      keys.forEach((key) => { params.defaultValues[key] = params.defaultValues[`${key}Id`]; });
      delete params.defaultValues.tradeId;
      delete params.defaultValues.parentId;
      commit('setSelectedTemplate', { ...params, id: -1 });
    } else {
      try {
        const { data } = await ETRM_API.post('/trades/templates', params);
        commit('addTemplate', data);
        this.$notify('Template successfully created', 'success');
      } catch (err) {
        console.error(err);
        this.$notify('Failed to create template', 'error');
      }
    }
  },
  async createTemplate({ commit }, template) {
    try {
      const { data } = await ETRM_API.post('/trades/templates', template);
      commit('addTemplate', data);
      this.$notify('Template successfully created', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to create template', 'error');
    }
  },
  async deleteTemplate({ commit }, template) {
    try {
      await ETRM_API.delete(`/trades/templates/${template.id}`);
      commit('removeTemplate', template.templateName);
      this.$notify('Successfully deleted template', 'success');
    } catch (err) {
      this.$notify('Error occurred while deleting template', 'error');
      console.error(err);
    }
  },
  async updateTemplate({ commit, dispatch }, template) {
    try {
      await ETRM_API.put(`/trades/templates/${template.id}`, {
        defaultValues: template.defaultValues,
      });
      await dispatch('fetchTemplatesAsync');
      this.$notify('Successfully saved template', 'success');
    } catch (err) {
      this.$notify('Error occurred while saving template', 'error');
      console.error(err);
    }
  },
  async runStrategy({ commit }, strategyModel) {
    const tz = dateStore.getTimeZone();
    const istLocations = strategyModel.locations
      .filter(({ schdType }) => schdType === 'TRADE')
      .map(({ resource }) => ({ location: resource }));
    const sibrLocations = strategyModel.locations
      .filter(({ schdType }) => schdType === 'SCHD')
      .map(({ resource }) => ({ location: resource }));

    const tradeDate = strategyModel.date;

    const startTime = dateStore.toDateFromLocal(moment(tradeDate), tz).toISOString();
    const endTime = dateStore.toDateFromLocal(moment(tradeDate), tz).add(1, 'days').toISOString();

    const closedHours = await getOpenMarkets(tradeDate);
    const openMarkets = closedHours.filter((x) => x.marketType === strategyModel.marketType);

    if (openMarkets.filter((x) => x.status === 'CLOSED').length > 0) {
      const result = await confirm(
        'The market has already closed for some of the schedules you are attempting to create. Are you sure you want to continue?',
        'Confirm Action',
      );
      if (!result) {
        return;
      }
    }

    const sibrReqBody = {
      runDefaultStrategy: true,
      marketType: strategyModel.marketType,
      module: 'sibrSchedules',
      commodity: 'POWER',
      market: 'CAISO',
      entityType: 'SC',
      startTime,
      endTime,
      tz,
      locations: sibrLocations,
    };

    const istReqBody = {
      runDefaultStrategy: true,
      marketType: strategyModel.marketType,
      module: 'tradeSchedules',
      commodity: 'POWER',
      market: 'CAISO',
      entityType: 'SC',
      startTime,
      endTime,
      tz,
      locations: istLocations,
    };

    try {
      if (sibrLocations && sibrLocations.length > 0) { await BIDDING_API.patch('/strategies', sibrReqBody); }
      this.$notify('Successfully Executed SIBR Scripts');
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
    try {
      if (istLocations && istLocations.length > 0) { await BIDDING_API.patch('/strategies', istReqBody); }
      this.$notify('Successfully Executed IST Scripts');
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
    commit('setIsTradesToSchedulesDialogVisible', false);
  },
  async updateCascadedIntervals({ dispatch, commit }, value) {
    commit('setCascadeVariants', value);
    commit('resetIntervals');
    dispatch('fetchIntervals');
  },
  getDefaultDailyDate({ state }, startDateType) {
    const tradeStartDate = moment.utc(state.currentTrade.effectiveStartDate);
    const tradeEndDate = moment.utc(state.currentTrade.effectiveEndDate);
    const searchStartDate = moment.utc(state.searchParams.dateRange[0]);
    let defaultDate;

    switch (startDateType?.toLowerCase()) {
      case 'today':
        defaultDate = moment();
        break;
      case 'tomorrow':
        defaultDate = moment().add(1, 'days');
        break;
      case 'tradestart':
        defaultDate = tradeStartDate;
        break;
      case 'searchstart':
        defaultDate = searchStartDate;
        break;
      default:
        defaultDate = moment.max(tradeStartDate, searchStartDate);
        break;
    }
    return tradeEndDate >= defaultDate ? moment.max(tradeStartDate, defaultDate) : tradeStartDate;
  },
  ...INTERVAL_STORE.actions,
};

const mutations = {
  setSelectedVariants(state, value) {
    state.selectedVariants = value;
    // Iterates over columns and set visibility to the one that matches the tag box
    state.config.columns
      .filter(({ prop }) => !['startTime', 'endTime', 'he', 'momentEndTime', 'momentStartTime'].includes(prop))
      .forEach((col) => {
        if (value.includes(col.prop) || value.some((val) => val.split('-')[0] === col.label)) {
          col.visible = true;
        } else {
          col.visible = false;
        }
      });
  },
  setSelectedRangeVariants(state, value) {
    state.selectedRangeVariants = value;
    // Iterates over columns and set visibility to the one that matches the tag box
    state.rangeConfig.columns
      .filter(({ prop }) => !['startTime', 'endTime', 'he', 'momentEndTime', 'momentStartTime'].includes(prop))
      .forEach((col) => {
        if (value.includes(col.prop) || value.some((val) => val.split('-')[0] === col.label)) {
          col.visible = true;
        } else {
          col.visible = false;
        }
      });
  },
  setImbalanceList(state, data) {
    state.imbalanceList = data;
  },
  setTraderList(state, data) {
    state.traderList = data;
  },
  removeTemplate(state, value) {
    const index = state.templates.findIndex((x) => x.templateName === value);
    state.templates.splice(index, 1);
  },
  resetSelectedTemplate(state) {
    state.selectedTemplate = NULL_TEMPLATE();
  },
  resetSelectedTemplateStatus(state) {
    if (state.selectedTemplate && state.selectedTemplate.defaultValues) state.selectedTemplate.defaultValues.status = 'NEW';
  },
  setSelectedTradeScreen(state, { id, rootState }) {
    state.selectedTradeScreen = state.tradeScreens.find((screen) => screen.id === Number(id));
    state.selectedTradeScreen.columns.forEach((col) => {
      // Sets up dropdown list for new trades
      if (
        has(col, 'config')
        && col.config.type === 'select'
        && has(col.config, 'reference')
        && col.config.reference.list
      ) {
        const { reference } = col.config;
        const {
          list, label, prop, value,
        } = reference;
        let data = [];
        if (list === 'custom') {
          data = col.config.customList.split(',').map((val) => ({ value: val, label: val }));
        } else if (list === 'sysDef') {
          // Handle for dropdowns populated with data oustide Lookup Store
          if (prop === 'timeZone') {
            data = utils.date.TZ_DEFINE || [];
          } else if (prop === 'trader') {
            data = state.traderList
              .sort(sortBy('userName'))
              .map(({ userName }) => ({ value: userName, label: userName }));
          }
        } else {
          [, data] = Object.entries(rootState.lookup).find(([key]) => list.toLowerCase() === key.toLowerCase());
          data.forEach((refData) => {
            Vue.set(refData, 'label', refData[label]);
            Vue.set(refData, 'value', refData[value]);
          });
        }
        Vue.set(col, 'data', data);
      }
    });

    const config = cloneDeep(state.tradeConfig);
    config.name = state.selectedTradeScreen.name.replace(/ /g, '');

    // search parameters
    config.intervalStartDateType = state.selectedTradeScreen.intervalStartDateType || undefined;
    config.tradeTermGroups = state.selectedTradeScreen.tradeTermGroups || undefined;
    config.commodities = state.selectedTradeScreen.commodities || undefined;
    config.markets = state.selectedTradeScreen.markets || undefined;
    config.companies = state.selectedTradeScreen.companies || undefined;
    config.counterparties = state.selectedTradeScreen.counterparties || undefined;
    config.brokers = state.selectedTradeScreen.brokers || undefined;
    config.regions = state.selectedTradeScreen.regions || undefined;
    config.portfolios = state.selectedTradeScreen.portfolios || undefined;
    config.books = state.selectedTradeScreen.books || undefined;
    config.strategies = state.selectedTradeScreen.strategies || undefined;

    state.searchParams.companyIds = state.selectedTradeScreen.company;
    state.searchParams.counterPartyIds = state.selectedTradeScreen.counterparty;
    state.searchParams.brokerIds = state.selectedTradeScreen.broker;
    state.searchParams.tradeTermGroups = state.selectedTradeScreen.tradeTermGroup;
    state.searchParams.commodityIds = state.selectedTradeScreen.commodity;
    state.searchParams.marketIds = state.selectedTradeScreen.market;
    state.searchParams.regionIds = state.selectedTradeScreen.region;
    state.searchParams.portfolioIds = state.selectedTradeScreen.portfolio;
    state.searchParams.bookIds = state.selectedTradeScreen.book;
    state.searchParams.strategyIds = state.selectedTradeScreen.strategy;

    config.columns = [];
    state.selectedTradeScreen.columns.forEach((customCol) => {
      const column = state.tradeConfig.columns.find((col) => col.prop === customCol.prop);
      if (!column) return;

      // Sets column visibility
      // Have to keep column visible by default
      customCol.visible = true;

      if (has(column, 'visible')) { customCol.visible = column.visible; }

      if (has(customCol, 'config')) {
        if (has(customCol.config, 'visible')) { customCol.visible = customCol.config.visible; }

        // Sets cell type
        if (!['select', 'constant'].includes(customCol.config.type)) {
          customCol.type = customCol.config.type;
        }
        // Sets cell validation (e.g. required)
        if (has(customCol.config, 'required') && customCol.config.required) {
          let validationRules = [];
          if (has(column, 'validationRules')) {
            validationRules = column.validationRules.filter(({ type }) => type !== 'required');
          }
          customCol.validationRules = [
            ...validationRules,
            { type: 'required' },
          ];
        }
        // Sets cell constant value
        if (has(customCol.config, 'type') && customCol.config.type === 'constant') {
          customCol.editable = false;
          customCol.customizeText = () => customCol.config.constantValue;
          customCol.calculateCellValue = () => customCol.config.constantValue;
        }
        // Sets cell format (e.g. currency)
        if (has(customCol.config, 'format') && customCol.config.format.type !== 'default') {
          customCol.format = customCol.config.format;
        }
        // Finds columns that has dependency on this column
        const cascadeColumns = state.selectedTradeScreen.columns.filter((colFilters) => {
          if (
            has(colFilters, 'config')
            && has(colFilters.config, 'filters')
            && colFilters.config.filters.find((c) => c.column === customCol.prop)
          ) {
            // Adds column that are dependant to wipe the values when column value changes
            if (has(colFilters, 'dependantProps')) colFilters.dependantProps.push(customCol.prop);
            else colFilters.dependantProps = [customCol.prop];
            return true;
          }
          return false;
        });
        // If there are dependencies then set the dependency here to allow dropdown to cascade results
        if (cascadeColumns.length) {
          customCol.setCellValue = function (rowData, val, currentRow) {
            cascadeColumns.forEach((cascade) => {
              // This is necessary to trigger the update the dependant columns
              if (cascade?.dependantProps?.includes(customCol.prop)) {
                if ([undefined, ''].includes(currentRow[cascade.prop])) rowData[cascade.prop] = null;
                else rowData[cascade.prop] = '';
              }
            });
            if (has(column, 'setCellValue')) column.setCellValue(rowData, val, currentRow);
            this.defaultSetCellValue(rowData, val);
          };
        }
      }
      config.columns.push({ ...column, ...customCol });
    });

    // Set selected variants
    if (state.selectedTradeScreen?.variantTypes?.length > 0) {
      state.selectedVariants = [];
      state.selectedTradeScreen.variantTypes.forEach((variant) => {
        state.selectedVariants.push(`${variant.prop}`);
      });
    }

    state.newTradeConfig = config;
    state.customRegistry = customRegistry(config);

    // custom screens will have not cascade by default
    state.cascadeVariants = state.selectedTradeScreen?.cascadeVariants || false;
  },
  setCustomEditableAttributes(state, data) {
    state.customEditableAttributes = data;
  },
  updateTradeStatus(state, trade) {
    const tradeIdx = state.trades.findIndex(({ tradeId }) => tradeId === trade.tradeId);
    if (tradeIdx !== -1) state.trades[tradeIdx].status = trade.status;
  },
  updateTrade(state, trade) {
    const tradeIdx = state.trades.findIndex(({ tradeId }) => tradeId === trade.tradeId);
    if (tradeIdx !== -1) state.trades.splice(tradeIdx, 1, trade);
  },
  updateSavedTrade(state, trade) {
    const tradeIdx = state.trades.findIndex(({ tradeId }) => tradeId === -1);
    if (tradeIdx !== -1) state.trades.splice(tradeIdx, 1, trade);
  },
  deleteTrade(state) {
    const tradeIdx = state.trades.findIndex(({ tradeId }) => tradeId === state.currentTrade.tradeId);
    if (tradeIdx !== -1) state.trades.splice(tradeIdx, 1);
  },
  addTemplate(state, value) {
    state.templates.unshift(value);
  },
  setTemplates(state, value) {
    state.templates = value.sort(sortBy('templateName'));
  },
  setSearchParams(state, params) {
    state.searchParams = { ...state.searchParams, ...params };
  },
  setIsEditingTrade({ state }, isEditingTrade) {
    state.isEditingTrade = isEditingTrade;
  },
  reset(state) {
    state.templates = [];
    state.currentTrade = null;
    state.isEditingTrade = false;
    state.selectedTradeScreen = null;
    state.newTradeConfig = null;
    state.customRegistry = {};
    state.trades = null;
    state.tradeConfig = {
      name: 'tradesTerms',
      columns: defaultColumnsFactory(),
      options: {
        exportExcel: true,
        filterHeader: true,
        columnConfig: true,
        filterOptions: true,
      },
    };
    state.dockConfig = {
      visible: false,
      alignment: 'left',
    };
    state.searchParams = clone(searchParams);
  },
  ...createMutations(
    'copyTemplate',
    'currentTrade',
    'customRegistry',
    'isEditingTrade',
    'dockConfig',
    'selectedTemplate',
    'tagTemplates',
    'trades',
    'tradeScreens',
    'isTradesToSchedulesDialogVisible',
    'isImbalanceEnabled',
    'cascadeVariants',
    'imbalanceList',
  ),
  ...INTERVAL_STORE.mutations,
};

export { VARIANT_TYPES };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
