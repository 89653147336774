/* eslint-disable max-len */
import moment from 'moment';
import {
  eimHeaders, eimEntityCoordinatorListWithTagbox, balancingAreaAuthority,
  SamcTradeDateRangeSelectedHandler, stateHeaders,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import {
  CISO_SAMC_API, TOOLS_API, CISO_OASIS_API, STRUCTURES_API, VA_API, CISO_METER_API,
} from '@/api';
import { caiso, reporting, accessRight } from '@/auth/permission';

import {
  eimFileTypeHandler, eimPublicationHandler, eimVersionHandler, SaMCFileTypeHandler, SaMCVersionHandler,
  SettlementDetailFileTypeHandler, SettlementDetailPublicationHandler, SettlementDetailVersionHandler,
  settlementReportPublicationHandler,
  CustomAllocationInvoiceReportFileTypeHandler, CustomAllocationInvoiceReportVersionHandler,
  CustomAllocationSummaryFileTypeHandler, CustomAllocationSummaryPublicationHandler, CustomAllocationSummaryVersionHandler,
  CustomBDReportFileTypeHandler, CustomBDReportVersionHandler,
  CustomPurchaseAndSaleChargeCodeSummaryReportFileTypeHandler, CustomPurchaseAndSaleChargeCodeSummaryReportVersionHandler,
  CustomFercElectricityQuarterlyFileTypeHandler, CustomFercElectricityQuarterlyVersionHandler, CustomInvoiceVsStatementInvoiceNumHandler,
  CustomSettlementsOperationMonthlyFileTypeHandler, CustomSettlementsOperationMonthlyVersionHandler, CustomStatementVsShadowFileTypeHandler,
  SMUDCustomInvoiceVsStatementInvoiceNumberHandler,
  CongestionCollectionsFileTypeHandler, CongestionCollectionsPublicationHandler,
  CongestionPriceFileTypeHandler, CongestionPriceVersionHandler, EqrReportPublicationHandler,
  EimSufficiencyCheckPublicationHandler, EimSufficiencyCheckFileTypeHandler,
  ISTSFileTypeHandler, ISTSPublicationHandler, ISTSVersionHandler,
  LapPriceFileTypeHandler, LapPricePublicationHandler, LapPriceVersionHandler,
  NprLoadFileTypeHandler, NprLoadPublicationHandler,
  PriceCompareBdFileTypeHandler, PriceCompareBdPublicationHandler, PriceCompareBdVersionHandler,
  RtieoCongestionPublicationHandler, SalesAndPurchasesReportPublicationHandler, UfeReportPublicationHandler,
  MarketValueReportFileTypeHandler, OperationStatementReportFileTypeHandler, OperationStatementPublicationHandler,
  AncillaryServiceFileTypeHandler, AncillaryServicePublicationHandler, BidCostRecoveryFileTypeHandler, CrrReportFileTypeHandler,
  NetPositionReportsFileTypeHandler, NetPositionReportsVersionHandler,
  stateAllocFileTypeHandler, stateAllocPublicationHandler, stateAllocVersionHandler,
  TAGSETTLEMENTQUANTITYCOMPAREREPORTFileTypeHandler, TAGSETTLEMENTQUANTITYCOMPAREPublicationHandler,
  crrReportPublicationHandler, CcToGlReportFileTypeHandler, CcToGlReportPublicationHandler, CcToGlReportVersionHandler,
  gerPurchaseSaleCCSummaryBeforeSubmitCC, IIETAGSETTLEMENTSQUANTITYCOMPAREFileTypeHandler,
  IIETAGSETTLEMENTSQUANTITYCOMPAREPublicationHandler, MARKETINVOICECUSTOMFileTypeHandler, MARKETINVOICECUSTOMPublicationHandler,
} from './reportUtil';

const { tools: { createAReport: { reports, menu } } } = caiso;
const { createAReport: { baseReports, customReports }, visualAnalytics } = reporting;

export default [{
  title: 'Base',
  cards: {
    SAMCREPORT: {
      label: 'MRI-S Report',
      endpoint: '/legacy-process/samc-report',
      permission: { name: reports.mris, right: accessRight.visible },
      config: {
        SAMCREPORTreportType: {
          label: 'Report Type',
          type: 'select',
          searchable: true,
          value: 'ChargeCode',
          options: [
            'ChargeCode',
            'PreCalc',
            'Summary',
            'Summary - All Intervals',
            'Invoice',
            'Allocation - Charge Code',
            'Allocation - Summary',
          ],
        },
        SAMCREPORTcalculations: {
          label: 'Calculations',
          type: 'tagbox',
          searchable: true,
          showControls: true,
          value: null,
          options: [],
          disabled: false,
          watchers: [{
            propertyToWatch: 'SAMCREPORTreportTypeSelected',
            async handler() {
              this.config.SAMCREPORTcalculations.disabled = false;
              this.config.SAMCREPORTcalculations.visible = true;
              this.params.SAMCREPORTcalculationsSelected = [];
              this.params.SAMCREPORTcalculations = [];

              let data;
              let chargeTypes;
              switch (this.params.SAMCREPORTreportTypeSelected) {
              case 'ChargeCode':
                data = await CISO_SAMC_API.get('/statements/charge-types');
                chargeTypes = [
                  'CAISO Energy Charge Codes',
                  'EIM Energy Charge Codes',
                  ...data.data.data.map((item) => item.chargeType),
                ];
                break;
              case 'PreCalc':
                data = await CISO_SAMC_API.get('/statements/charge-types-precalc');
                chargeTypes = data.data;
                break;
              case 'Invoice':
                chargeTypes = [
                  'Summary',
                  'Bill Period',
                  'Detailed',
                  'Statement',
                  'Stmt Details',
                  'Alloc Details',
                ];
                break;
              case 'Allocation - Charge Code':
                if (this.params.SAMCREPORTfileTypeSelected) {
                  data = await CISO_SAMC_API.get('/statements/charge-types-allocation');
                  chargeTypes = [
                    'MONTHLY_INITIAL_CREDIT',
                    'MONTHLY_INITIAL_MARKET',
                    'MONTHLY_REISSUE_MARKET',
                    'MONTHLY_RECALC_MARKET',
                    'MONTHLY_RERUN_MARKET',
                    'SHADOW_MONTHLY_SETTLEMENT',
                  ].includes(this.params.SAMCREPORTfileTypeSelected) ? data.data.monthly : data.data.daily;
                } else {
                  this.config.SAMCREPORTcalculations.disabled = true;
                  chargeTypes = [];
                }
                break;
              case 'Summary':
              case 'Summary - All Intervals':
              case 'Allocation - Summary':
                this.config.SAMCREPORTcalculations.visible = true;
                chargeTypes = [];
                break;
              default:
                this.config.SAMCREPORTcalculations.disabled = true;
                chargeTypes = [];
                break;
              }
              this.params.SAMCREPORTcalculations = chargeTypes.map((value) => ({ value, label: value }));
            },
          }, {
            propertyToWatch: 'SAMCREPORTfileTypeSelected',
            async handler() {
              if (this.params.SAMCREPORTreportTypeSelected === 'Allocation - Charge Code') {
                if (this.params.SAMCREPORTfileTypeSelected) {
                  this.config.SAMCREPORTcalculations.disabled = false;
                  const data = await CISO_SAMC_API.get('/statements/charge-types-allocation');
                  const chargeTypes = [
                    'MONTHLY_INITIAL_CREDIT',
                    'MONTHLY_INITIAL_MARKET',
                    'MONTHLY_REISSUE_MARKET',
                    'MONTHLY_RECALC_MARKET',
                    'MONTHLY_RERUN_MARKET',
                    'SHADOW_MONTHLY_SETTLEMENT',
                  ].includes(this.params.SAMCREPORTfileTypeSelected) ? data.data.monthly : data.data.daily;
                  this.params.SAMCREPORTcalculations = chargeTypes.map((value) => ({ value, label: value }));
                } else {
                  this.params.SAMCREPORTcalculationsSelected = [];
                  this.params.SAMCREPORTcalculations = [];
                  this.config.SAMCREPORTcalculations.disabled = true;
                }
              }
            },
          }],
        },
        SAMCREPORTdateType: {
          label: 'Date Type',
          value: 'Trade Date',
          type: 'radio',
          options: [{ value: 'Trade Date', label: 'Trade Date' }, { value: 'Published Date', label: 'Publish Date' }],
          watchers: [{
            propertyToWatch: 'SAMCREPORTreportTypeSelected',
            async handler() {
              if (this.params.SAMCREPORTreportTypeSelected === 'Invoice') {
                this.params.SAMCREPORTdateTypeSelected = 'Published Date';
                this.config.SAMCREPORTdateType.disabled = false;
              } else {
                this.config.SAMCREPORTdateType.disabled = false;
              }
            },
          }],
        },
        SAMCREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          searchable: true,
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: SaMCFileTypeHandler,
          }, {
            propertyToWatch: 'SAMCREPORTreportTypeSelected',
            handler: SaMCFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: SaMCFileTypeHandler,
          }, {
            propertyToWatch: 'SAMCREPORTdateTypeSelected',
            handler: SaMCFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: SamcTradeDateRangeSelectedHandler,
          }, {
            propertyToWatch: 'SAMCREPORTfileTypeSelected',
            handler() {
              this.config.SAMCREPORTfileType.disabled = !this.params.SAMCREPORTfileTypeSelected;
            },
          }],
        },
        SAMCREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          searchable: true,
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'SAMCREPORTfileTypeSelected',
            handler: SaMCVersionHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: SaMCVersionHandler,
          }, {
            propertyToWatch: 'SAMCREPORTversionSelected',
            handler() {
              // !0 = false. This greys out the generate button.
              this.config.SAMCREPORTversion.disabled = !this.params.SAMCREPORTversionSelected;
              // Corrects The above issue
              if (this.params.SAMCREPORTversionSelected === 0) {
                this.config.SAMCREPORTversion.disabled = false;
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        if (['ChargeCode', 'PreCalc', 'Invoice', 'Allocation - Charge Code']
          .includes(request.reportType) && request.calculations.length === 0) {
          return { isValid: false, errorMessage: 'Please select an option from the Calculations drop down box' };
        }
        return { isValid: true };
      },
    },
    CUSTOMBDREPORT: {
      label: 'MRI-S Bill Determinant Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/mris-bill-determinant',
      permission: { name: reports.mris, right: accessRight.visible },
      config: {
        CUSTOMBDREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CustomBDReportFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomBDReportFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomBDReportFileTypeHandler,
          }, {
            propertyToWatch: 'CUSTOMBDREPORTfileTypeSelected',
            handler() {
              this.config.CUSTOMBDREPORTfileType.disabled = !this.params.CUSTOMBDREPORTfileTypeSelected;
            },
          }],
        },
        CUSTOMBDREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'CUSTOMBDREPORTfileTypeSelected',
            handler: CustomBDReportVersionHandler,
          }, {
            propertyToWatch: 'CUSTOMBDREPORTversionSelected',
            handler() {
              this.config.CUSTOMBDREPORTversion.disabled = !this.params.CUSTOMBDREPORTversionSelected;
              if (this.params.CUSTOMBDREPORTversionSelected === 0) {
                this.config.CUSTOMBDREPORTversion.disabled = false;
              }
            },
          }],
        },
        CUSTOMBDREPORTchargeCode: {
          label: 'Charge Code',
          value: null,
          type: 'select',
          permission: { name: caiso.samc.statement, right: [accessRight.read, accessRight.write] },
          searchable: true,
          disabled: false,
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await CISO_SAMC_API.get('/statements/charge-types');
              let chargeCodes = data.map((x) => x.chargeType);
              const precalcs = await CISO_SAMC_API.get('/statements/charge-types-precalc');
              chargeCodes = chargeCodes.concat(precalcs.data);
              return chargeCodes;
            } catch (err) {
              vue.$notify('Failed to load charge codes', 'error');
              console.log(err);
            }
            return [];
          },
        },
        CUSTOMBDREPORTbillDeterminants: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Bill Determinant',
          permission: { name: caiso.samc.statement, right: [accessRight.read, accessRight.write] },
          value: null,
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await CISO_SAMC_API.get('/statements/bill-determinants');
              const billDeterminant = data.map((x) => ({ label: x.chargeCode, value: x.billDeterminant }));
              return billDeterminant;
            } catch (err) {
              vue.$notify('Failed to load Bill Determinants', 'error');
              console.log(err);
            }
            return [];
          },
          watchers: [{
            propertyToWatch: 'CUSTOMBDREPORTchargeCodeSelected',
            handler(newValue) {
              try {
                const filteredBillDeterminants = this.params.CUSTOMBDREPORTbillDeterminantsUnfiltered
                  .filter(({ label: chargeCode }) => chargeCode === newValue)
                  .map((x) => x.value);

                this.config.CUSTOMBDREPORTbillDeterminants.disabled = !filteredBillDeterminants.length;
                this.params.CUSTOMBDREPORTbillDeterminants = filteredBillDeterminants;
                this.params.CUSTOMBDREPORTbillDeterminantsSelected = null;
              } catch (err) {
                vue.$notify('Failed to refresh Bill Determinants', 'error');
                console.log(err);
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {
          resources: request.resources.join(','),
          fileType: request.fileType,
          version: request.version,
          billDeterminants: request.billDeterminants.join(','),
        };
        return { isValid: true };
      },
    },
    ADSREPORT: {
      label: 'ADS Report',
      endpoint: '/legacy-process/ads-report',
      permission: { name: reports.ads, right: accessRight.visible },
      config: {
        ADSREPORTReportByDay: {
          label: 'Report Type',
          value: 1,
          type: 'radio',
          options: [{ value: 1, label: 'Report by Day' }, { value: 0, label: 'All in One' }],
          hint: 'Report by Day (default): Each day of data is in a distinct sheet \nAll in One: All data for selected date range is in one sheet',
        },
      },
      beforeSubmit(request) {
        request.reportNames = ['STANDARD-DISPATCH'];
        return { isValid: true };
      },
    },
    OASISREPORT: {
      label: 'OASIS Report',
      endpoint: '/legacy-process/oasis-report',
      permission: { name: reports.oasis, right: accessRight.visible },
      config: {
        OASISREPORTreportNames: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Report Name(s)',
          value: null,
          options: [],
          async fetchData() {
            try {
              const data = await TOOLS_API.get('/application/system-information/oasis', {
                params: {
                  isDownload: true,
                },
              });

              const reportNames = data.data
                .map((item) => (item.dataName));

              const staticReportNames = [
                'ALL ENERGY AND AS PRICES',
                'ALL ENERGY PRICES',
              ];

              const allReportNames = [...staticReportNames, ...reportNames];
              const allReportNamesOptions = allReportNames
                .map((reportName) => ({ value: reportName, label: reportName }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));

              return allReportNamesOptions;
            } catch (error) {
              vue.$notify('Failed to Load Reports', 'error');
              console.log(error);
            }
            return [];
          },
        },
        OASISREPORTBAA: { ...balancingAreaAuthority, label: 'BAA (if applicable)' },
      },
    },
    OASISLMPREPORT: {
      label: 'OASIS LMP Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/oasisLmpReport',
      permission: { name: reports.oasis, right: accessRight.visible },
      config: {
        OASISLMPREPORTreportType: {
          label: 'Report Type',
          options: [
            'LMP', 'SPTIE LMP',
          ],
          value: 'LMP',
          type: 'select',
          searchable: true,
        },
        OASISLMPREPORTmarketType: {
          label: 'Market Type',
          options: ['DAM', 'HASP', 'RTM', 'RTPD'],
          value: null,
          type: 'select',
          searchable: true,
          watchers: [{
            propertyToWatch: 'OASISLMPREPORTreportTypeSelected',
            async handler() {
              // market types for the different report types
              const lmpMarketTypes = ['DAM', 'HASP', 'RTM', 'RTPD'];
              const sptieLMPMarketTypes = ['DAM', 'RTPD', 'RTD'];

              // reset selected market type
              this.params.OASISLMPREPORTmarketTypeSelected = null;

              // correctly fill the options based on the selected report type
              switch (this.params.OASISLMPREPORTreportTypeSelected) {
              case 'LMP':
                this.params.OASISLMPREPORTmarketType = lmpMarketTypes;
                break;
              case 'SPTIE LMP':
                this.params.OASISLMPREPORTmarketType = sptieLMPMarketTypes;
                break;
              default:
              }
            },
          }],
        },
        OASISLMPREPORTpriceNodes: {
          label: 'Price Nodes',
          value: null,
          type: 'tagbox',
          permission: { name: reports.oasis, right: [accessRight.read, accessRight.write] },
          searchable: true,
          disabled: true,
          visible: true,
          options: [],
          async fetchData() {
            try {
              const { data } = await CISO_OASIS_API.get('/oasis-reports/price-nodes');
              return data;
            } catch (err) {
              vue.$notify('Failed to load price nodes', 'error');
              console.log(err);
            }
            return [];
          },
          watchers: [
            {
              propertyToWatch: 'OASISLMPREPORTreportTypeSelected',
              async handler() {
                // reset price nodes selected
                this.params.OASISLMPREPORTpriceNodesSelected = null;

                // make price node drop down visible if LMP report type was selected
                this.config.OASISLMPREPORTpriceNodes.visible = this.params.OASISLMPREPORTreportTypeSelected === 'LMP';
              },
            },
            {
              propertyToWatch: 'OASISLMPREPORTmarketTypeSelected',
              async handler() {
                this.config.OASISLMPREPORTpriceNodes.disabled = !this.params.OASISLMPREPORTmarketTypeSelected;
              },
            },
          ],
        },
        OASISLMPREPORTschedulingPointTieMappings: {
          label: 'Scheduling Tie Mappings',
          value: null,
          type: 'tagbox',
          permission: { name: reports.oasis, right: [accessRight.read, accessRight.write] },
          searchable: true,
          disabled: true,
          visible: false,
          options: [],
          async fetchSchedulingPointTieMappings(tradeDateRangeSelected) {
            try {
              const [startDate, endDate] = tradeDateRangeSelected;
              const { data } = await CISO_OASIS_API.get('oasis-reports/atlas/scheduling-point-tie-mappings',
                {
                  params: {
                    StartDate: startDate,
                    EndDate: endDate,
                  },
                });
              const t = data.map((mapping) => ({ label: `${mapping.schedulingPoint} - ${mapping.tie}`, value: `${mapping.schedulingPoint} ${mapping.tie}` }));
              return t;
            } catch (err) {
              vue.$notify('Failed to load schedule point to tie mappings', 'error');
              console.log(err);
            }
            return [];
          },
          watchers: [
            {
              propertyToWatch: 'OASISLMPREPORTreportTypeSelected',
              async handler() {
                // reset selected SPTie mappings
                this.params.OASISLMPREPORTschedulingPointTieMappingsSelected = null;

                // make component visible if SPTIE LMP was report type chosen
                this.config.OASISLMPREPORTschedulingPointTieMappings.visible = this.params.OASISLMPREPORTreportTypeSelected === 'SPTIE LMP';

                // if it is visible, fetch the SPTIE mappings
                if (this.config.OASISLMPREPORTschedulingPointTieMappings.visible) {
                  this.params.OASISLMPREPORTschedulingPointTieMappings = await this.config.OASISLMPREPORTschedulingPointTieMappings.fetchSchedulingPointTieMappings(this.params.tradeDateRangeSelected);
                }
              },
            },
            {
              propertyToWatch: 'tradeDateRangeSelected',
              async handler() {
                // reset selected SPTie mappings
                this.params.OASISLMPREPORTschedulingPointTieMappingsSelected = null;

                // if it is visible, fetch the SPTIE mappings
                if (this.config.OASISLMPREPORTschedulingPointTieMappings.visible) {
                  this.params.OASISLMPREPORTschedulingPointTieMappings = await this.config.OASISLMPREPORTschedulingPointTieMappings.fetchSchedulingPointTieMappings(this.params.tradeDateRangeSelected);
                }
              },
            },
            {
              propertyToWatch: 'OASISLMPREPORTmarketTypeSelected',
              async handler() {
                this.config.OASISLMPREPORTschedulingPointTieMappings.disabled = !this.params.OASISLMPREPORTmarketTypeSelected;
              },
            },
          ],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {};
        request.reportParams.marketType = request.marketType;
        if (request.reportType === 'LMP') request.reportParams.priceNodes = request.priceNodes.join(',');
        else if (request.reportType === 'SPTIE LMP') {
          request.reportParams.schedulingPointTieMappings = request.schedulingPointTieMappings.join(',');
        }
        return { isValid: true };
      },
    },
    CMRIREPORT: {
      label: 'CMRI Report',
      endpoint: '/legacy-process/cmri-report',
      permission: { name: reports.cmri, right: accessRight.visible },
      config: {
        CMRIREPORTreportNames: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Report Name(s)',
          value: null,
          options: [],
          watchers: [{
            propertyToWatch: 'coordinatorListSelected',
            async handler() {
              const { params } = this;
              const { coordinatorListSelected } = params;

              const baseRoute = '/application/system-data-source/cmri/distinctReportNames?isDownload=true';
              const route = coordinatorListSelected.reduce((acc, curr) => {
                acc += `&scids=${curr}`;
                return acc;
              }, baseRoute);

              const data = await TOOLS_API.get(route);

              const reportNames = data.data
                .filter((item) => item.reportName !== 'ALL')
                .map((item) => ({ value: item.reportName, label: item.reportName }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));

              params.CMRIREPORTreportNames = reportNames;
            },
          }],
          async fetchData() {
            try {
              const data = await TOOLS_API.get('/application/system-data-source/cmri/distinctReportNames', {
                params: {
                  isDownload: true,
                },
              });

              const reportNames = data.data
                .filter((item) => item.reportName !== 'ALL')
                .map((item) => ({ value: item.reportName, label: item.reportName }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));

              return reportNames;
            } catch (error) {
              vue.$notify('Failed to load CMRI report types.', 'error');
              console.log(error);
            }
            return [];
          },
        },
      },
    },
    OMSREPORT: {
      label: 'OMS Report',
      endpoint: '/legacy-process/oms-report',
      permission: { name: reports.oms, right: accessRight.visible },
      config: {
        OMSREPORTreportNames: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Report Name(s)',
          value: null,
          options: [],
          async fetchData() {
            try {
              const data = await TOOLS_API.get('/application/system-information/oms');

              const reportNames = data.data
                .filter((item) => item.download)
                .map((item) => ({ value: item.dataName, label: item.dataName }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));

              return reportNames;
            } catch (error) {
              vue.$notify('Failed to Load Reports', 'error');
              console.log(error);
            }
            return [];
          },
        },
      },
    },
    MASTERFILEREPORT: {
      label: 'Master File Report',
      endpoint: '/legacy-process/master-report',
      permission: { name: reports.masterfile, right: accessRight.visible },
      config: {
        MASTERFILEREPORTreportNames: {
          visible: true,
          type: 'select',
          searchable: true,
          label: 'Report Name(s)',
          value: null,
          options: [],
          async fetchData() {
            try {
              const data = await TOOLS_API.get('/application/system-information/masterfile');

              const reportNames = data.data
                .filter((item) => item.download)
                .map((item) => ({ value: item.dataName, label: item.dataName }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));

              return [
                { value: 'ALL', label: 'ALL' },
                ...reportNames,
              ];
            } catch (error) {
              vue.$notify('Failed to Load Reports', 'error');
              console.log(error);
            }
            return [];
          },
        },
      },
    },
    MRISREPORT: {
      label: 'Meter Report',
      endpoint: '/legacy-process/mri-s-report',
      permission: { name: reports.meter, right: accessRight.visible },
      config: {
        MRISREPORTresources: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Meter Channel Resources',
          value: null,
          options: [],
          watchers: [{
            propertyToWatch: 'coordinatorListSelected',
            async handler() {
              const { params, config } = this;
              const { coordinatorListSelected, coordinatorListUnfiltered } = params;
              try {
                const { data } = await TOOLS_API.get('/application/meter-channel');
                let meterResources = data.meterChannelResources
                  .map((item) => ({ value: item.rsrc_id, label: item.rsrc_id, ba: item.ba }));
                if (coordinatorListSelected.length > 0) {
                  const BAs = [];
                  for (let i = 0; i < coordinatorListSelected.length; i++) {
                    const coordinator = coordinatorListUnfiltered.find(
                      (coordinator) => coordinatorListSelected[i] === coordinator.scId);
                    BAs.push(coordinator.baId);
                  }
                  meterResources = meterResources.filter((resource) => BAs.includes(resource.ba));
                }

                params.MRISREPORTresources = meterResources;
              } catch (error) {
                vue.$notify('Failed to Load Meter Resources', 'error');
                console.log(error);
              }
              return [];
            },
          }],
          async fetchData() {
            try {
              const { data } = await TOOLS_API.get('/application/meter-channel');
              const meterResources = data.meterChannelResources
                .map((item) => ({ value: item.rsrc_id, label: item.rsrc_id }));

              return meterResources;
            } catch (error) {
              vue.$notify('Failed to Load Meter Resources', 'error');
              console.log(error);
            }
            return [];
          },
        },

      },
    },
    SIBRREPORT: {
      label: 'SIBR Report',
      endpoint: '/legacy-process/sibr-report',
      permission: { name: reports.sibr, right: accessRight.visible },
      config: {
        SIBRREPORTmarketType: {
          label: 'Market Type',
          options: [
            'DAM', 'RTM', 'ALL',
          ],
          value: 'ALL',
          type: 'select',
          searchable: true,
        },
        SIBRREPORTsourceType: {
          visible: true,
          type: 'select',
          searchable: true,
          label: 'Source Type',
          value: 'ALL',
          options: [],
          async fetchData() {
            try {
              const data = await TOOLS_API.get('/application/system-information/sibr', {
                params: {
                  isDownload: true,
                },
              });

              const reportNames = data.data
                .filter((item) => item.dataName !== 'BsapBalancingTests'
                  && item.dataName !== 'BaseSchedules'
                  && item.download)
                .map(({ dataName }) => dataName)
                .sort();

              return ['ALL',
                ...new Set(reportNames),
              ];
            } catch (error) {
              vue.$notify('Failed to Load SIBR Source Types', 'error');
              console.log(error);
            }
            return [];
          },
        },
      },
    },
    BSAPREPORT: {
      label: 'BSAP Report',
      endpoint: '/legacy-process/sibr-bsap-report',
      permission: { name: reports.bsap, right: accessRight.visible },
      config: {
        BSAPREPORTreportNames: {
          label: 'Report Name(s)',
          options: [
            'Balancing Tests',
            'Base Schedules',
            'Base Schedules(compare)',
          ],
          value: 'Balancing Tests',
          type: 'select',
          searchable: true,
        },
      },
    },
    RTMMARKETBEHAVIORREPORT: {
      label: 'RTM Market Behavior Report',
      endpoint: '/legacy-process/sibr-bsap-report',
      permission: { name: baseReports.rtmMarketBehavior, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportNames = ['RtmMarketBehavior'];
        return { isValid: true };
      },
    },
    CMRIEIMREPORT: {
      label: 'CMRI EIM Entity Report',
      endpoint: '/legacy-process/cmri-report',
      permission: { name: reports.cmri, right: accessRight.visible },
      config: {
        CMRIEIMREPORTcoordinators: eimEntityCoordinatorListWithTagbox,
        CMRIEIMREPORTreportNames: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Report Name(s)',
          value: null,
          options: [],
          watchers: [{
            propertyToWatch: 'CMRIEIMREPORTcoordinatorsSelected',
            async handler() {
              const { params } = this;
              const { CMRIEIMREPORTcoordinatorsSelected } = params;
              const data = await TOOLS_API
                .get(`/application/system-data-source/cmri/distinctReportNames
                ?isDownload=true&scids=${CMRIEIMREPORTcoordinatorsSelected}`);

              const reportNames = data.data
                .map((item) => ({ value: item.reportName, label: item.reportName }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));

              params.CMRIEIMREPORTreportNames = reportNames;
            },
          }],
          async fetchData() {
            try {
              const data = await TOOLS_API.get('/application/system-data-source/cmri/distinctReportNames', {
                params: {
                  isDownload: true,
                },
              });

              const reportNames = data.data
                .map((item) => ({ value: item.reportName, label: item.reportName }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));

              return reportNames;
            } catch (error) {
              vue.$notify('Failed to load CMRI EIM report types.', 'error');
              console.log(error);
            }
            return [];
          },
        },
      },
    },
    EIMBENEFITREPORT: {
      label: 'EIM Benefit Report',
      endpoint: '/legacy-process/eim-benefit-report',
      permission: { name: reports.eimBenefitCalc, right: accessRight.visible },
      config: {
        EIMBENEFITREPORTcalcType: {
          label: 'Calc Type',
          value: 'Counterfactual',
          type: 'select',
          permission: { name: menu, right: [accessRight.read, accessRight.write] },
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await TOOLS_API.get('/eim-benefit/calcs');
              const calcTypes = data.map((calc) => calc.name);
              return calcTypes;
            } catch (err) {
              vue.$notify('Failed to Eim calculation types', 'error');
              console.log(err);
            }
            return [];
          },
        },
      },
    },
    ALLRULESREPORT: {
      label: 'All Rules Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/all-rules-va-report',
      permission: { name: baseReports.allRulesVAReport, right: accessRight.visible },
      config: {
        ALLRULESREPORTvariant: {
          label: 'Variant',
          value: 'T_1',
          type: 'select',
          permission: { name: baseReports.allRulesVAReport, right: accessRight.visible },
          options: [],
          watchers: [{
            propertyToWatch: 'tradeDateRangeSelected',
            async handler() {
              const { params } = this;
              const { data: { data } } = await VA_API.get(`/variants/${ moment(this.params.tradeDateRangeSelected[0]).toISOString() }`);
              const reportNames = data.data
                .map((item) => ({ value: item.reportName, label: item.reportName }))
                .filter((item) => item.label !== 'T')
                .sort((a, b) => (a.value > b.value ? 1 : -1));

              params.ALLRULESREPORTvariant = reportNames;
            },
          }],
          async fetchData() {
            try {
              const { data: { data } } = await VA_API.get('/variants');
              const variant = data
                .map((variant) => ({ value: variant.variantName, label: variant.variantName }))
                .filter((item) => item.label !== 'T')
                .sort((a, b) => (a.value.substring(2) > b.value.substring(2) ? 1 : -1));
              return variant;
            } catch (err) {
              vue.$notify('Failed to Variants', 'error');
              console.log(err);
            }
            return [];
          },
        },
        ALLRULESREPORTlocationGroup: {
          label: 'Location Groups',
          value: 'ALL',
          type: 'select',
          permission: { name: baseReports.allRulesVAReport, right: accessRight.visible },
          options: [],
          async fetchData() {
            try {
              const { data } = await STRUCTURES_API.get('location-groups');
              const locationGroups = data.locationGroups.map((locationGroup) => ({ value: locationGroup.id, label: locationGroup.shortName }));
              return [{ value: 'ALL', label: 'ALL' },
                ...locationGroups,
              ];
            } catch (err) {
              vue.$notify('Failed to load Location Groups', 'error');
              console.log(err);
            }
            return [];
          },
        },
        ALLRULESREPORTlocation: {
          label: 'Location',
          visible: true,
          searchable: true,
          showControls: true,
          type: 'tagbox',
          permission: { name: baseReports.allRulesVAReport, right: accessRight.visible },
          value: null,
          options: [],
          watchers: [{
            propertyToWatch: 'ALLRULESREPORTlocationGroupSelected',
            async handler() {
              if (this.params.ALLRULESREPORTlocationGroupSelected !== 'ALL') {
                this.config.ALLRULESREPORTlocation.visible = false;
                this.params.ALLRULESREPORTlocationSelected = [];
                this.params.ALLRULESREPORTlocation = [];
                return [];
              }
              this.config.ALLRULESREPORTlocation.disabled = false;
              this.config.ALLRULESREPORTlocation.visible = true;
              this.params.ALLRULESREPORTlocationSelected = [];
              this.params.ALLRULESREPORTlocation = [];
              try {
                const { data: { data } } = await STRUCTURES_API.get('locations');
                const locations = data.locations.map((location) => ({ value: location.id, label: location.shortName }));
                this.params.ALLRULESREPORTlocation = locations;
              } catch (err) {
                vue.$notify('Failed to load Locations', 'error');
                console.log(err);
              }
              return [];
            },
          }],
        },
        ALLRULESREPORTbusinessRules: {
          label: 'Business Rule(s)',
          value: [],
          visible: true,
          searchable: true,
          showControls: true,
          type: 'tagbox',
          permission: { name: baseReports.allRulesVAReport, right: accessRight.visible },
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await VA_API.get('business-rules');
              const businessRules = data.map((rule) => ({ value: rule.id, label: rule.name })).filter((rule) => rule.label.includes('rule'));
              return businessRules;
            } catch (err) {
              vue.$notify('Failed to load Business Rules', 'error');
              console.log(err);
            }
            return [];
          },
        },
      },
      beforeSubmit(request) {
        let isValid = true;
        let errorMessage = null;
        const start = moment(request.startDate).utc().startOf('day');
        const end = moment(request.endDate).utc().startOf('day');
        const diffDay = end.diff(start, 'days');
        if (diffDay > 31) {
          isValid = false;
          errorMessage = 'Start and end date can\'t be more than 31 days out.';
        }
        if (request.businessRules.length > 20) {
          isValid = false;
          errorMessage = 'Selected report only allows up to 20 business rules.  Please reduce your business rule selection count';
        }
        request.reportParams = {
          variant: request.variant,
          businessRules: request.businessRules.join(','),
          locations: request.location.join(','),
          locationGroup: request.locationGroup,
        };
        return {
          isValid,
          errorMessage,
        };
      },
    },
    LMPVSBIDSREPORT: {
      label: 'LMP vs Bids Report',
      endpoint: '/legacy-process/samc-report',
      permission: { name: baseReports.lmpVsBids, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'LmpVsBids';
        request.reportParams = {};
        return { isValid: true };
      },
    },
  },
}, {
  title: 'Custom',
  cards: {
    HOURLYETSRREPORT: {
      label: 'Hourly Dynamic ETSR Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/hourly-dynamic-etsr',
      permission: { name: customReports.hourlyDynamicEtsr, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          resources: request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    TESTREPORT: {
      label: 'Test Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/test-report',
      permission: { name: customReports.testReport, right: accessRight.visible },
      config: {
        TESTREPORTcalcType: {
          label: 'Calc Type',
          value: 'Counterfactual',
          type: 'select',
          options: ['Counterfactual', 'Transfer'],
        },
      },
      beforeSubmit(request) {
        request.reportParams = { testParam: request.calcType };
        return { isValid: true };
      },
    },
    METERVALIDATIONREPORT: {
      label: 'Meter Validation Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/meter-validation',
      permission: { name: customReports.meterValidation, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    PCAAMREPORT: {
      label: 'PCAAM Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/pcaam',
      permission: { name: customReports.pcaam, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    FERC1REPORT: {
      label: 'FERC 1 Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/ferc-1',
      permission: { name: customReports.ferc1, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    FERC714REPORT: {
      label: 'FERC 714 Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/ferc-714',
      permission: { name: customReports.ferc714, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    EIMFERCREPORT: {
      label: 'EIM FERC Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimFerc',
      permission: { name: customReports.eimFerc, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    EQRREPORT: {
      label: 'EQR Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eqr',
      permission: { name: customReports.eqr, right: accessRight.visible },
      config: {
        EQRREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: EqrReportPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: EqrReportPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: EqrReportPublicationHandler,
          }, {
            propertyToWatch: 'EQRREPORTpublicationSelected',
            handler() {
              this.config.EQRREPORTpublication.disabled = !this.params.EQRREPORTpublicationSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          publication: request.publication,
          fileType: request.fileType,
        };
        return { isValid: true };
      },
    },
    SETTLEMENTREPORT: {
      label: 'Settlement Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/settlementreport',
      permission: { name: customReports.settlementReport, right: accessRight.visible },
      config: {
        SETTLEMENTREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: settlementReportPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: settlementReportPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: settlementReportPublicationHandler,
          }, {
            propertyToWatch: 'SETTLEMENTREPORTpublicationSelected',
            handler() {
              this.config.SETTLEMENTREPORTpublication.disabled = !this.params.SETTLEMENTREPORTpublicationSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          publication: request.publication,
        };
        return { isValid: true };
      },
    },
    EIMSUBALLOCATIONBDREPORT: {
      label: 'EIM Sub Allocation BD Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimSubAllocBD',
      permission: { name: customReports.eimSubAllocBD, right: accessRight.visible },
      config: {
        eimHeaders,
        EIMSUBALLOCATIONBDREPORTsubCompany: {
          label: 'Sub Company',
          value: null,
          type: 'tagbox',
          disabled: true,
          searchable: true,
          options: [],
          watchers: [{
            propertyToWatch: 'coordinatorListSelected',
            async handler() {
              try {
                const { data: { data } } = await TOOLS_API.get('/transmission-billing-info/sub-companies');
                const subCompanies = data.filter(({ sc }) => sc === this.params.coordinatorListSelected[0]).map(({ subCompany }) => subCompany);
                this.params.EIMSUBALLOCATIONBDREPORTsubCompany = subCompanies;
                this.config.EIMSUBALLOCATIONBDREPORTsubCompany.disabled = false;
              } catch (err) {
                vue.$notify('Failed to load charge codes', 'error');
                console.log(err);
              }
            },
          }],
        },
        EIMSUBALLOCATIONBDREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'eimHeadersUnfiltered',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMSUBALLOCATIONBDREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMSUBALLOCATIONBDREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMSUBALLOCATIONBDREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'EIMSUBALLOCATIONBDREPORTfileTypeSelected',
            handler() {
              this.config.EIMSUBALLOCATIONBDREPORTfileType.disabled = !this.params.EIMSUBALLOCATIONBDREPORTfileTypeSelected;
            },
          }],
        },
        EIMSUBALLOCATIONBDREPORTpublication: {
          label: 'Settlement Cycle',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'EIMSUBALLOCATIONBDREPORTfileTypeSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMSUBALLOCATIONBDREPORTpublication', 'EIMSUBALLOCATIONBDREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMSUBALLOCATIONBDREPORTpublication', 'EIMSUBALLOCATIONBDREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMSUBALLOCATIONBDREPORTpublication', 'EIMSUBALLOCATIONBDREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'EIMSUBALLOCATIONBDREPORTpublicationSelected',
            handler() {
              this.config.EIMSUBALLOCATIONBDREPORTpublication.disabled = !this.params.EIMSUBALLOCATIONBDREPORTpublicationSelected;
            },
          }],
        },
        EIMSUBALLOCATIONBDREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'EIMSUBALLOCATIONBDREPORTpublicationSelected',
            handler(newValue, oldValue) {
              eimVersionHandler(
                this,
                'EIMSUBALLOCATIONBDREPORTversion',
                'EIMSUBALLOCATIONBDREPORTfileType',
                'EIMSUBALLOCATIONBDREPORTpublication');
              return true;
            },
          }, {
            propertyToWatch: 'EIMSUBALLOCATIONBDREPORTversionSelected',
            handler() {
              // eslint-disable-next-line max-len
              this.config.EIMSUBALLOCATIONBDREPORTversion.disabled = !this.params.EIMSUBALLOCATIONBDREPORTversionSelected;
            },
          }],
        },
        EIMSUBALLOCATIONBDREPORTchargeCode: {
          label: 'Charge Code',
          value: null,
          type: 'tagbox',
          searchable: true,
          permission: { name: caiso.samc.eimSubAllocation, right: [accessRight.read, accessRight.write] },
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'EIMSUBALLOCATIONBDREPORTfileTypeSelected',
            async handler() {
              if (this.params.EIMSUBALLOCATIONBDREPORTfileTypeSelected?.length) {
                this.config.EIMSUBALLOCATIONBDREPORTchargeCode.disabled = false;
                try {
                  const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/charge-codes');
                  const chargeCodes = data.map((x) => x.name);
                  this.params.EIMSUBALLOCATIONBDREPORTchargeCode = chargeCodes;
                } catch (err) {
                  vue.$notify('Failed to load EIM charge codes', 'error');
                  console.log(err);
                }
              } else {
                this.config.EIMSUBALLOCATIONBDREPORTchargeCode.disabled = true;
                this.params.EIMSUBALLOCATIONBDREPORTchargeCode = [];
              }
            },
          }],
        },
        EIMSUBALLOCATIONBDREPORTbillDeterminant: {
          label: 'Bill Determinant',
          value: null,
          type: 'tagbox',
          searchable: true,
          permission: { name: caiso.samc.eimSubAllocation, right: [accessRight.read, accessRight.write] },
          disabled: true,
          options: [],
          watchers: [{

            propertyToWatch: 'EIMSUBALLOCATIONBDREPORTchargeCodeSelected',
            async handler() {
              if (this.params.EIMSUBALLOCATIONBDREPORTchargeCodeSelected?.length) {
                this.config.EIMSUBALLOCATIONBDREPORTbillDeterminant.disabled = false;
                try {
                  const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/bill-determinants',
                    {
                      params: {
                        chargeCode: this.params.EIMSUBALLOCATIONBDREPORTchargeCodeSelected.join(','),
                        fileType: this.params.EIMSUBALLOCATIONBDREPORTfileTypeSelected,
                      },
                    });

                  const billDeterminants = data.map((x) => x.bdName);

                  this.params.EIMSUBALLOCATIONBDREPORTbillDeterminant = billDeterminants;
                } catch (error) {
                  vue.$notify('Failed to Load Bill Determinants', 'error');
                  console.log(error);
                }
              } else {
                this.params.EIMSUBALLOCATIONBDREPORTbillDeterminant = [];
                this.config.EIMSUBALLOCATIONBDREPORTbillDeterminant.disabled = true;
              }
            },
          }, {
            propertyToWatch: 'EIMSUBALLOCATIONBDREPORTfileTypeSelected',
            async handler() {
              if (this.params.EIMSUBALLOCATIONBDREPORTchargeCodeSelected?.length) {
                this.config.EIMSUBALLOCATIONBDREPORTbillDeterminant.disabled = false;
                try {
                  const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/bill-determinants',
                    {
                      params: {
                        chargeCode: this.params.EIMSUBALLOCATIONBDREPORTchargeCodeSelected.join(','),
                        fileType: this.params.EIMSUBALLOCATIONBDREPORTfileTypeSelected,
                      },
                    });

                  const billDeterminants = data.map((x) => x.bdName);

                  this.params.EIMSUBALLOCATIONBDREPORTbillDeterminant = billDeterminants;
                } catch (error) {
                  vue.$notify('Failed to Load Bill Determinants', 'error');
                  console.log(error);
                }
              } else {
                this.params.EIMSUBALLOCATIONBDREPORTbillDeterminant = [];
                this.config.EIMSUBALLOCATIONBDREPORTbillDeterminant.disabled = true;
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.chargeCode = (request.chargeCode) ? request.chargeCode.join(',') : '';
        request.billDeterminant = (request.billDeterminant) ? request.billDeterminant.join(',') : '';
        request.subCompany = (request.subCompany) ? request.subCompany.join(',') : '';
        request.reportParams = {
          fileType: request.fileType,
          publication: request.publication,
          version: request.version,
          chargeCode: request.chargeCode,
          billDeterminant: request.billDeterminant,
          subCompany: request.subCompany,
        };
        return { isValid: true };
      },
    },
    ISTSREPORT: {
      label: 'ISTS Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/ISTS',
      permission: { name: customReports.ists, right: accessRight.visible },
      config: {
        ISTSREPORTfileType: {
          label: 'Statement',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: ISTSFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: ISTSFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: ISTSFileTypeHandler,
          }, {
            propertyToWatch: 'ISTSREPORTfileTypeSelected',
            handler() {
              this.config.ISTSREPORTfileType.disabled = !this.params.ISTSREPORTfileTypeSelected;
            },
          }],
        },
        ISTSREPORTpublication: {
          label: 'Settlement Cycle',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'ISTSREPORTfileTypeSelected',
            handler: ISTSPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: ISTSPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: ISTSPublicationHandler,
          }, {
            propertyToWatch: 'ISTSREPORTpublicationSelected',
            handler() {
              this.config.ISTSREPORTpublication.disabled = !this.params.ISTSREPORTpublicationSelected;
            },
          }],
        },
        ISTSREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'ISTSREPORTfileTypeSelected',
            handler: ISTSVersionHandler,
          }, {
            propertyToWatch: 'ISTSREPORTversionSelected',
            handler() {
              this.config.ISTSREPORTversion.disabled = !this.params.ISTSREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          version: request.version,
          publication: request.publication,
        };
        return { isValid: true };
      },
    },
    CUSTOMALLOCATIONINVOICEREPORT: {
      label: 'Allocation Invoice Report',
      endpoint: '/legacy-process/samc-custom-legacy-report',
      permission: { name: customReports.customAllocationInvoice, right: accessRight.visible },
      config: {
        CUSTOMALLOCATIONINVOICEREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CustomAllocationInvoiceReportFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomAllocationInvoiceReportFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomAllocationInvoiceReportFileTypeHandler,
          }, {
            propertyToWatch: 'CUSTOMALLOCATIONINVOICEREPORTdateTypeSelected',
            handler: CustomAllocationInvoiceReportFileTypeHandler,
          }, {
            propertyToWatch: 'CUSTOMALLOCATIONINVOICEREPORTfileTypeSelected',
            handler() {
              this.config.CUSTOMALLOCATIONINVOICEREPORTfileType.disabled = !this.params.CUSTOMALLOCATIONINVOICEREPORTfileTypeSelected;
            },
          }],
        },
        CUSTOMALLOCATIONINVOICEREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'CUSTOMALLOCATIONINVOICEREPORTfileTypeSelected',
            handler: CustomAllocationInvoiceReportVersionHandler,
          }, {
            propertyToWatch: 'CUSTOMALLOCATIONINVOICEREPORTversionSelected',
            handler() {
              this.config.CUSTOMALLOCATIONINVOICEREPORTversion.disabled = !this.params
                .CUSTOMALLOCATIONINVOICEREPORTversionSelected;
            },
          }],
        },
        CUSTOMALLOCATIONINVOICEREPORTfileType2: {
          label: 'Template',
          options: [
            'Trended', 'Weekly',
          ],
          value: 'Trended',
          type: 'select',
          searchable: true,
        },
        // paste the template field here
        CUSTOMALLOCATIONINVOICEREPORTdateType: {
          label: 'Date Type',
          value: 'Trade Date',
          type: 'radio',
          options: [{ value: 'Trade Date', label: 'Trade Date' }, { value: 'Published Date', label: 'Publish Date' }],
        },
      },
      beforeSubmit(request) {
        request.reportType = 'Custom Allocation Invoice';
        return { isValid: true };
      },
    },

    OASISPRICENODE: {
      label: 'OASIS Price Node Custom Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/oasisPriceNode',
      permission: { name: customReports.oasisPriceNode, right: accessRight.visible },
      config: {
        OASISPRICENODEmarketType: {
          label: 'Market Type',
          options: [
            'DAM', 'RTM', 'HASP', 'RTPD',
          ],
          value: 'ALL',
          type: 'select',
          searchable: true,
        },
        OASISPRICENODEpriceNodes: {
          label: 'Price Nodes',
          value: null,
          type: 'tagbox',
          permission: { name: reports.oasis, right: [accessRight.read, accessRight.write] },
          searchable: true,
          disabled: false,
          options: [],
          async fetchData() {
            try {
              const { data } = await CISO_OASIS_API.get('/oasis-reports/price-nodes');
              return data;
            } catch (err) {
              vue.$notify('Failed to load price nodes', 'error');
              console.log(err);
            }
            return [];
          },
        },
      },
      beforeSubmit(request) {
        request.entities = '';
        if (request.priceNodes == null) {
          return { isValid: false, errorMessage: 'Please select an option from the Price Nodes drop down box' };
        }
        request.reportParams = {
          marketType: request.marketType,
          priceNodes: request.priceNodes.join(','),
        };
        return { isValid: true };
      },
    },

    OASISDAMVSRTMLMP: {
      label: 'DAM vs RTM LMP Custom Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/damVsRtmLmp',
      permission: { name: customReports.damVsRtmLmp, right: accessRight.visible },
      config: {
        OASISDAMVSRTMLMPpriceNodes: {
          label: 'Price Nodes',
          value: null,
          type: 'tagbox',
          permission: { name: reports.oasis, right: [accessRight.read, accessRight.write] },
          searchable: true,
          disabled: false,
          options: [],
          async fetchData() {
            try {
              const { data } = await CISO_OASIS_API.get('/oasis-reports/price-nodes');
              return data;
            } catch (err) {
              vue.$notify('Failed to load price nodes', 'error');
              console.log(err);
            }
            return [];
          },
        },
      },
      beforeSubmit(request) {
        console.log(request);
        request.reportParams = {
          priceNodes: request.priceNodes.join(','),
        };
        return { isValid: true };
      },
    },
    CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORT: {
      label: 'Purchase and Sale Charge Code Summary',
      api: 'REPORTING',
      endpoint: '/reports/generate/purchase-and-sale-cc-summary',
      permission: { name: customReports.purchaseAndSaleSummary, right: accessRight.visible },
      config: {
        CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTchargeCodes: {
          label: 'Charge Codes',
          value: null,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          disabled: false,
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await CISO_SAMC_API.get('/statements/charge-types');
              let chargeCodes = data.map((x) => x.chargeType);
              const precalcs = await CISO_SAMC_API.get('/statements/charge-types-precalc');
              chargeCodes = chargeCodes.concat(precalcs.data);

              if (chargeCodes.length) chargeCodes.push('CAISO Energy Group');

              return chargeCodes.sort();
            } catch (err) {
              vue.$notify('Failed to load charge codes', 'error');
              console.log(err);
            }
            return [];
          },
        },
        CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTfileType: {
          label: 'Settlement Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CustomPurchaseAndSaleChargeCodeSummaryReportFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomPurchaseAndSaleChargeCodeSummaryReportFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomPurchaseAndSaleChargeCodeSummaryReportFileTypeHandler,
          }, {
            propertyToWatch: 'CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTfileTypeSelected',
            handler() {
              this.config.CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTfileType.disabled = !this.params.CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTfileTypeSelected;
            },
          }],
        },
        CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTfileTypeSelected',
            handler: CustomPurchaseAndSaleChargeCodeSummaryReportVersionHandler,
          }, {
            propertyToWatch: 'CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTversionSelected',
            handler() {
              this.config.CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTversion.disabled = !this.params.CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTversionSelected;
              if (this.params.CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTversionSelected === 0) {
                this.config.CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTversion.disabled = false;
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          chargeCodes: gerPurchaseSaleCCSummaryBeforeSubmitCC(request.chargeCodes),
          fileType: request.fileType,
          version: request.version,
        };
        return { isValid: true };
      },
    },
    CUSTOMALLOCATIONSUMMARYREPORT: {
      label: 'Custom Allocation Summary Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/customAllocationSummary',
      permission: { name: customReports.customAllocationSummary, right: accessRight.visible },
      config: {
        CUSTOMALLOCATIONSUMMARYREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CustomAllocationSummaryFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomAllocationSummaryFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomAllocationSummaryFileTypeHandler,
          }, {
            propertyToWatch: 'CUSTOMALLOCATIONSUMMARYREPORTfileTypeSelected',
            handler() {
              this.config.CUSTOMALLOCATIONSUMMARYREPORTfileType.disabled = !this.params.CUSTOMALLOCATIONSUMMARYREPORTfileTypeSelected;
            },
          }],
        },
        CUSTOMALLOCATIONSUMMARYREPORTpublication: {
          label: 'Settlement Cycle',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'CUSTOMALLOCATIONSUMMARYREPORTfileTypeSelected',
            handler: CustomAllocationSummaryPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomAllocationSummaryPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomAllocationSummaryPublicationHandler,
          }, {
            propertyToWatch: 'CUSTOMALLOCATIONSUMMARYREPORTpublicationSelected',
            handler() {
              this.config.CUSTOMALLOCATIONSUMMARYREPORTpublication.disabled = !this.params.CUSTOMALLOCATIONSUMMARYREPORTpublicationSelected;
            },
          }],
        },
        CUSTOMALLOCATIONSUMMARYREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'CUSTOMALLOCATIONSUMMARYREPORTfileTypeSelected',
            handler: CustomAllocationSummaryVersionHandler,
          }, {
            propertyToWatch: 'CUSTOMALLOCATIONSUMMARYREPORTpublicationSelected',
            handler: CustomAllocationSummaryVersionHandler,
          }, {
            propertyToWatch: 'CUSTOMALLOCATIONSUMMARYREPORTversionSelected',
            handler() {
              this.config.CUSTOMALLOCATIONSUMMARYREPORTversion.disabled = !this.params
                .CUSTOMALLOCATIONSUMMARYREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          version: request.version,
          publication: request.publication,
        };
        return { isValid: true };
      },
    },
    STATEMENTVSSHADOW: {
      label: 'Statement vs. Shadow Report',
      endpoint: '/legacy-process/samc-report',
      permission: { name: customReports.statementVsShadow, right: accessRight.visible },
      config: {
        STATEMENTVSSHADOWvariance: {
          label: 'Variance',
          value: 0,
          type: 'input',
          props: { type: 'number' },
        },
        STATEMENTVSSHADOWfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CustomStatementVsShadowFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomStatementVsShadowFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomStatementVsShadowFileTypeHandler,
          }, {
            propertyToWatch: 'STATEMENTVSSHADOWfileTypeSelected',
            handler() {
              this.config.STATEMENTVSSHADOWfileType.disabled = !this.params.STATEMENTVSSHADOWfileTypeSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        let variance = 0;
        if (!Number.isNaN(parseFloat(request.variance, 10))) { variance = parseFloat(request.variance, 10); }
        request.reportType = 'StatementVsShadow';
        request.calculations = [];
        request.reportParams = {
          fileType: request.fileType,
          variance,
        };
        return { isValid: true };
      },
    },
    INVOICEVSSTATEMENT: {
      label: 'Invoice vs. Statement Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/invoiceVsStatement',
      permission: { name: customReports.invoiceVsStatement, right: accessRight.write },
      config: {
        INVOICEVSSTATEMENTdateType: {
          label: 'Date Type',
          value: 'Trade Date',
          type: 'radio',
          options: [{ value: 'Trade Date', label: 'Trade Date' }, { value: 'Published Date', label: 'Publish Date' }],
        },
        INVOICEVSSTATEMENTinvoiceNum: {
          label: 'Invoice Num',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CustomInvoiceVsStatementInvoiceNumHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomInvoiceVsStatementInvoiceNumHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomInvoiceVsStatementInvoiceNumHandler,
          }, {
            propertyToWatch: 'INVOICEVSSTATEMENTdateTypeSelected',
            handler: CustomInvoiceVsStatementInvoiceNumHandler,
          }, {
            propertyToWatch: 'INVOICEVSSTATEMENTinvoiceNumSelected',
            handler() {
              this.config.INVOICEVSSTATEMENTinvoiceNum.disabled = !this.params.INVOICEVSSTATEMENTinvoiceNumSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          invoiceNum: request.invoiceNum,
        };
        return { isValid: true };
      },
    },
    SMUDINVOICEVSSTATEMENT: {
      label: 'Invoice vs. Statement Report',
      endpoint: '/legacy-process/samc-report',
      permission: { name: customReports.smudInvoiceVsStatement, right: accessRight.visible },
      config: {
        SMUDINVOICEVSSTATEMENTdateType: {
          label: 'Date Type',
          value: 'Trade Date',
          type: 'radio',
          options: [{ value: 'Trade Date', label: 'Trade Date' }, { value: 'Published Date', label: 'Publish Date' }],
        },
        SMUDINVOICEVSSTATEMENTinvoiceNumber: {
          label: 'Invoice Num',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: SMUDCustomInvoiceVsStatementInvoiceNumberHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: SMUDCustomInvoiceVsStatementInvoiceNumberHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: SMUDCustomInvoiceVsStatementInvoiceNumberHandler,
          }, {
            propertyToWatch: 'SMUDINVOICEVSSTATEMENTdateTypeSelected',
            handler: SMUDCustomInvoiceVsStatementInvoiceNumberHandler,
          }, {
            propertyToWatch: 'SMUDINVOICEVSSTATEMENTinvoiceNumberSelected',
            handler() {
              this.config.SMUDINVOICEVSSTATEMENTinvoiceNumber.disabled = !this.params.SMUDINVOICEVSSTATEMENTinvoiceNumberSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.calculations = [];
        request.reportType = 'InvoiceVsStatement';
        return { isValid: true };
      },
    },
    EIMSUBALLOCATIONPSEREPORT: {
      label: 'EIM Sub Allocation PSE Mapping Exceptions',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimSubAllocMappingExceptions',
      permission: { name: customReports.eimSubAllocMappingExceptions, right: accessRight.visible },
      config: {
        eimHeaders,
        EIMSUBALLOCATIONPSEREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'eimHeadersUnfiltered',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMSUBALLOCATIONPSEREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMSUBALLOCATIONPSEREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMSUBALLOCATIONPSEREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'EIMSUBALLOCATIONPSEREPORTfileTypeSelected',
            handler() {
              this.config.EIMSUBALLOCATIONPSEREPORTfileType.disabled = !this.params.EIMSUBALLOCATIONPSEREPORTfileTypeSelected;
            },
          }],
        },
        EIMSUBALLOCATIONPSEREPORTpublication: {
          label: 'Settlement Cycle',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'EIMSUBALLOCATIONPSEREPORTfileTypeSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMSUBALLOCATIONPSEREPORTpublication', 'EIMSUBALLOCATIONPSEREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMSUBALLOCATIONPSEREPORTpublication', 'EIMSUBALLOCATIONPSEREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMSUBALLOCATIONPSEREPORTpublication', 'EIMSUBALLOCATIONPSEREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'EIMSUBALLOCATIONPSEREPORTpublicationSelected',
            handler() {
              this.config.EIMSUBALLOCATIONPSEREPORTpublication.disabled = !this.params.EIMSUBALLOCATIONPSEREPORTpublicationSelected;
            },
          }],
        },
        EIMSUBALLOCATIONPSEREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'EIMSUBALLOCATIONPSEREPORTpublicationSelected',
            handler(newValue, oldValue) {
              eimVersionHandler(this, 'EIMSUBALLOCATIONPSEREPORTversion',
                'EIMSUBALLOCATIONPSEREPORTfileType', 'EIMSUBALLOCATIONPSEREPORTpublication');
              return true;
            },
          }, {
            propertyToWatch: 'EIMSUBALLOCATIONPSEREPORTversionSelected',
            handler() {
              this.config.EIMSUBALLOCATIONPSEREPORTversion.disabled = !this.params.EIMSUBALLOCATIONPSEREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          publication: request.publication,
          version: request.version,
        };
        return { isValid: true };
      },
    },
    NVESHADOWREPORT: {
      label: 'NVE Shadow Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/nve-shadow',
      permission: { name: customReports.nveShadow, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    LMP_OMS_REVENUE: {
      label: 'LMP OMS Revenue',
      api: 'REPORTING',
      endpoint: '/reports/generate/lmp-oms-revenue',
      permission: { name: customReports.lmpOmsRevenue, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {
          resources: request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    GAS_RSRC_START_UPS_SHUT_DOWNS: {
      label: 'Gas Resources ADS Startups and Shutdowns Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/gasRsrcStartUpsShutDowns',
      permission: { name: customReports.gasRsrcStartUpsShutDowns, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    VER_MASTER: {
      label: 'VER Master',
      api: 'REPORTING',
      endpoint: '/reports/generate/ver-master',
      permission: { name: customReports.verMaster, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {
          resources: request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    EIMSUFFICIENCYCHECKREPORT: {
      label: 'EIM Sufficiency Check Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/EimSufficiencyCheck',
      permission: { name: customReports.eimSufficiencyCheck, right: accessRight.visible },
      config: {
        EIMSUFFICIENCYCHECKREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: EimSufficiencyCheckFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: EimSufficiencyCheckFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: EimSufficiencyCheckFileTypeHandler,
          }, {
            propertyToWatch: 'EIMSUFFICIENCYCHECKREPORTfileTypeSelected',
            handler() {
              this.config.EIMSUFFICIENCYCHECKREPORTfileType.disabled = !this.params.EIMSUFFICIENCYCHECKREPORTfileTypeSelected;
            },
          }],
        },
        EIMSUFFICIENCYCHECKREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: EimSufficiencyCheckPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: EimSufficiencyCheckPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: EimSufficiencyCheckPublicationHandler,
          }, {
            propertyToWatch: 'EIMSUFFICIENCYCHECKREPORTpublicationSelected',
            handler() {
              this.config.EIMSUFFICIENCYCHECKREPORTpublication.disabled = !this.params.EIMSUFFICIENCYCHECKREPORTpublicationSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';

        request.reportParams = {
          publication: request.publication,
          fileType: request.fileType,
        };
        return { isValid: true };
      },
    },
    NPRANDLOADREPORT: {
      label: 'NPR and Load Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/nprLoad',
      permission: { name: customReports.nprLoad, right: accessRight.visible },
      config: {
        NPRANDLOADREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: NprLoadFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: NprLoadFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: NprLoadFileTypeHandler,
          }, {
            propertyToWatch: 'NPRANDLOADREPORTfileTypeSelected',
            handler() {
              this.config.NPRANDLOADREPORTfileType.disabled = !this.params.NPRANDLOADREPORTfileTypeSelected;
            },
          }],
        },
        NPRANDLOADREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: NprLoadPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: NprLoadPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: NprLoadPublicationHandler,
          }, {
            propertyToWatch: 'NPRANDLOADREPORTpublicationSelected',
            handler() {
              this.config.NPRANDLOADREPORTpublication.disabled = !this.params.NPRANDLOADREPORTpublicationSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          resources: request.resources.join(','),
          fileType: request.fileType,
          publication: request.publication,
        };
        return { isValid: true };
      },
    },
    RTIEOCONGESTIONREPORT: {
      label: 'RTIEO Congestion Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/rtieoCongestionReport',
      permission: { name: customReports.rtieoCongestionReport, right: accessRight.visible },
      config: {
        RTIEOCONGESTIONREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: RtieoCongestionPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: RtieoCongestionPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: RtieoCongestionPublicationHandler,
          }, {
            propertyToWatch: 'RTIEOCONGESTIONREPORTpublicationSelected',
            handler() {
              this.config.RTIEOCONGESTIONREPORTpublication.disabled = !this.params.RTIEOCONGESTIONREPORTpublicationSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {
          publication: request.publication,
        };
        return { isValid: true };
      },
    },
    RTCOREPORT: {
      label: 'RTCO Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/rtcoReport',
      permission: { name: customReports.rtcoReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    UFEREPORT: {
      label: 'UFE Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/ufeReport',
      permission: { name: customReports.ufeReport, right: accessRight.visible },
      config: {
        UFEREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: UfeReportPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: UfeReportPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: UfeReportPublicationHandler,
          }, {
            propertyToWatch: 'UFEREPORTpublicationSelected',
            handler() {
              this.config.UFEREPORTpublication.disabled = !this.params.UFEREPORTpublicationSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {
          publication: request.publication,
        };
        return { isValid: true };
      },
    },
    CONGESTIONCOLLECTIONSREPORT: {
      label: 'Congestion Collections Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/congestionCollections',
      permission: { name: customReports.congestionCollections, right: accessRight.visible },
      config: {
        CONGESTIONCOLLECTIONSREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CongestionCollectionsFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CongestionCollectionsFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CongestionCollectionsFileTypeHandler,
          }, {
            propertyToWatch: 'CONGESTIONCOLLECTIONSREPORTfileTypeSelected',
            handler() {
              this.config.CONGESTIONCOLLECTIONSREPORTfileType.disabled = !this.params.CONGESTIONCOLLECTIONSREPORTfileTypeSelected;
            },
          }],
        },
        CONGESTIONCOLLECTIONSREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CongestionCollectionsPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CongestionCollectionsPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CongestionCollectionsPublicationHandler,
          }, {
            propertyToWatch: 'CONGESTIONCOLLECTIONSREPORTpublicationSelected',
            handler() {
              this.config.CONGESTIONCOLLECTIONSREPORTpublication.disabled = !this.params.CONGESTIONCOLLECTIONSREPORTpublicationSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';

        request.reportParams = {
          publication: request.publication,
          fileType: request.fileType,
        };
        return { isValid: true };
      },
    },
    GHG_REPORT: {
      label: 'GHG REPORT',
      api: 'REPORTING',
      endpoint: '/reports/generate/ghg-report',
      permission: { name: customReports.ghg, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        return { isValid: true };
      },
    },
    PRICINGREPORT: {
      label: 'Pricing Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/pricingReportWeb',
      permission: { name: customReports.pricingReportWeb, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {
          resources: request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    TRANSMISSIONPRICINGREPORT: {
      label: 'Transmission Pricing Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/transmissionPricing',
      permission: { name: customReports.transmissionPricing, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {};
        return { isValid: true };
      },
    },
    EIMTRANSFERCUSTOMREPORT: {
      label: 'EIM Transfer Custom Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimTransferCustomReport',
      permission: { name: customReports.eimTransferCustomReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    EIMHYDROADJUSTMENTREPORT: {
      label: 'EIM Hydro Adjustment Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimHydroAdjustment',
      permission: { name: customReports.eimHydroAdjustment, right: accessRight.visible },
      config: {},
    },
    EIMTRANSFERTIEFLAGREPORT: {
      label: 'OASIS ENE_EIM_TRANSFER_TIE Flag Report (Flag Missing Hours/Intervals)',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimTransferTieFlag',
      permission: { name: customReports.eimTransferTieFlag, right: accessRight.visible },
      config: {},
    },
    EIMSTATEMENTPNLREPORT: {
      label: 'EIM Statement PnL Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimStatementPnL',
      permission: { name: customReports.eimStatementPnL, right: accessRight.visible },
      config: {},
    },
    EIMRESOURCEALLOCATIONSREPORT: {
      label: 'EIM Resource Allocations Custom Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimResourceAllocations',
      permission: { name: customReports.eimResourceAllocations, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {};
        return { isValid: true };
      },
    },
    VIRTUALAWARDSREPORT: {
      label: 'Virtual Awards Custom Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/virtualAwardsReport',
      permission: { name: customReports.virtualAwardsReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {};
        return { isValid: true };
      },
    },
    RENEWABLEREVENUEANALYSISREPORT: {
      label: 'Renewable Revenue Analysis',
      api: 'REPORTING',
      endpoint: '/reports/generate/renewable-revenue-analysis',
      permission: { name: customReports.renewableRevenueAnalysis, right: accessRight.visible },
      config: {
        RENEWABLEREVENUEANALYSISREPORTLocationGroups: {
          visible: true,
          type: 'select',
          searchable: true,
          showControls: true,
          label: 'Location Group',
          value: null,
          options: [],
          async fetchData() {
            try {
              const result = await STRUCTURES_API.get('location-groups');
              const locationGroups = result.data.locationGroups.map((lg) => ({
                value: lg.id,
                label: lg.shortName,
              })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));

              return locationGroups;
            } catch (error) {
              vue.$notify('Failed to Load Location Groups', 'error');
              console.log(error);
            }
            return [];
          },
        },
        RENEWABLEREVENUEANALYSISREPORTLocations: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Location(s)',
          value: null,
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'RENEWABLEREVENUEANALYSISREPORTLocationGroupsSelected',
            async handler(newValue) {
              this.config.RENEWABLEREVENUEANALYSISREPORTLocations.disabled = !this.params.RENEWABLEREVENUEANALYSISREPORTLocationGroupsSelected;
              try {
                const result = await STRUCTURES_API.get(`location-groups/${newValue}/locations`);
                const locations = result.data.locationGroupLocations.map((location) => ({
                  value: location.shortName,
                  label: location.shortName,
                })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
                this.params.RENEWABLEREVENUEANALYSISREPORTLocations = locations;
              } catch (error) {
                vue.$notify('Failed to Load Locations', 'error');
                console.log(error);
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          Resources: request.Locations.join(','),
        };
        return { isValid: true };
      },
    },
    GENMARGINREPORT: {
      label: 'Gen Margin Report',
      endpoint: '/legacy-process/gen-margin-report',
      permission: { name: reports.genMargin, right: accessRight.visible },
      config: {},
    },
    HOURLYAWARDDISPATCHREPORT: {
      label: 'Hourly Award And Dispatch Report',
      endpoint: '/legacy-process/hourlyAwardDispatch',
      permission: { name: customReports.hourlyAwardDispatch, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {
          Resources: request.Locations.join(','),
        };
        return { isValid: true };
      },
    },
    METERANDPRICEREPORT: {
      label: 'Meter and Price Report',
      endpoint: '/legacy-process/meter-and-price-report',
      permission: { name: reports.meterAndPrice, right: accessRight.visible },
      config: {
        METERANDPRICEREPORTIntervals: {
          label: 'Interval',
          options: [
            'ALL',
            'HOURLY',
            '5MIN',
          ],
          value: 'All',
          type: 'select',
          searchable: true,
        },
      },
    },
    EIMATFCOMPAREREPORT: {
      label: 'EIM Sub Allocation ATF Compare Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eimAtfCompare',
      permission: { name: customReports.eimAtfCompare, right: accessRight.visible },
      config: {
        eimHeaders,
        EIMATFCOMPAREREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'eimHeadersUnfiltered',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMATFCOMPAREREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMATFCOMPAREREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'EIMATFCOMPAREREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'EIMATFCOMPAREREPORTfileTypeSelected',
            handler() {
              this.config.EIMATFCOMPAREREPORTfileType.disabled = !this.params.EIMATFCOMPAREREPORTfileTypeSelected;
            },
          }],
        },
        EIMATFCOMPAREREPORTpublication: {
          label: 'Settlement Cycle',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'EIMATFCOMPAREREPORTfileTypeSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMATFCOMPAREREPORTpublication', 'EIMATFCOMPAREREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMATFCOMPAREREPORTpublication', 'EIMATFCOMPAREREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'EIMATFCOMPAREREPORTpublication', 'EIMATFCOMPAREREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'EIMATFCOMPAREREPORTpublicationSelected',
            handler() {
              this.config.EIMATFCOMPAREREPORTpublication.disabled = !this.params.EIMATFCOMPAREREPORTpublicationSelected;
            },
          }],
        },
        EIMATFCOMPAREREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'EIMATFCOMPAREREPORTpublicationSelected',
            handler(newValue, oldValue) {
              eimVersionHandler(this, 'EIMATFCOMPAREREPORTversion',
                'EIMATFCOMPAREREPORTfileType', 'EIMATFCOMPAREREPORTpublication');
              return true;
            },
          }, {
            propertyToWatch: 'EIMATFCOMPAREREPORTversionSelected',
            handler() {
              this.config.EIMATFCOMPAREREPORTversion.disabled = !this.params.EIMATFCOMPAREREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          publication: request.publication,
          version: request.version,
        };
        return { isValid: true };
      },
    },

    BATTERYREVENUEANALYSISREPORT: {
      label: 'Battery Revenue Analysis',
      api: 'REPORTING',
      endpoint: '/reports/generate/battery-revenue-analysis',
      permission: { name: customReports.batteryRevenueAnalysis, right: accessRight.visible },
      config: {
        BATTERYREVENUEANALYSISREPORTLocationGroups: {
          visible: true,
          type: 'select',
          searchable: true,
          showControls: true,
          label: 'Location Group',
          value: null,
          options: [],
          async fetchData() {
            try {
              const result = await STRUCTURES_API.get('location-groups');
              const locationGroups = result.data.locationGroups.map((lg) => ({
                value: lg.id,
                label: lg.shortName,
              })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));

              return locationGroups;
            } catch (error) {
              vue.$notify('Failed to Load Location Groups', 'error');
              console.log(error);
            }
            return [];
          },
        },
        BATTERYREVENUEANALYSISREPORTLocations: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Location(s)',
          value: null,
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'BATTERYREVENUEANALYSISREPORTLocationGroupsSelected',
            async handler(newValue) {
              this.config.BATTERYREVENUEANALYSISREPORTLocations.disabled = !this.params.BATTERYREVENUEANALYSISREPORTLocationGroupsSelected;
              try {
                const result = await STRUCTURES_API.get(`location-groups/${newValue}/locations`);
                const locations = result.data.locationGroupLocations.map((location) => ({
                  value: location.shortName,
                  label: location.shortName,
                })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
                this.params.BATTERYREVENUEANALYSISREPORTLocations = locations;
              } catch (error) {
                vue.$notify('Failed to Load Locations', 'error');
                console.log(error);
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          Resources: request.Locations.join(','),
        };
        return { isValid: true };
      },
    },
    HYBRIDREVENUEANALYSISREPORT: {
      label: 'Hybrid Revenue Analysis',
      api: 'REPORTING',
      endpoint: '/reports/generate/hybrid-revenue-analysis',
      permission: { name: customReports.hybridRevenueAnalysis, right: accessRight.visible },
      config: {
        HYBRIDREVENUEANALYSISREPORTLocationGroups: {
          visible: true,
          type: 'select',
          searchable: true,
          showControls: true,
          label: 'Location Group',
          value: null,
          options: [],
          async fetchData() {
            try {
              const result = await STRUCTURES_API.get('location-groups');
              const locationGroups = result.data.locationGroups.map((lg) => ({
                value: lg.id,
                label: lg.shortName,
              })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));

              return locationGroups;
            } catch (error) {
              vue.$notify('Failed to Load Location Groups', 'error');
              console.log(error);
            }
            return [];
          },
        },
        HYBRIDREVENUEANALYSISREPORTLocations: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Location(s)',
          value: null,
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'HYBRIDREVENUEANALYSISREPORTLocationGroupsSelected',
            async handler(newValue) {
              this.config.HYBRIDREVENUEANALYSISREPORTLocations.disabled = !this.params.HYBRIDREVENUEANALYSISREPORTLocationGroupsSelected;
              try {
                const result = await STRUCTURES_API.get(`location-groups/${newValue}/locations`);
                const locations = result.data.locationGroupLocations.map((location) => ({
                  value: location.shortName,
                  label: location.shortName,
                })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
                this.params.HYBRIDREVENUEANALYSISREPORTLocations = locations;
              } catch (error) {
                vue.$notify('Failed to Load Locations', 'error');
                console.log(error);
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        const locations = Array.isArray(request.Locations) ? request.Locations : [];
        request.reportParams = {
          Resources: locations.join(','),
        };
        return { isValid: true };
      },
    },
    SETTLEMENTAUDITTRAIL: {
      label: 'Settlement Audit Trail Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/settlement-audit-trail',
      permission: { name: customReports.settlementAuditTrail, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    LPTSELFSCHEDULEEXPORTSREPORT: {
      label: 'LPT Self Schedule Exports Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/lptSelfScheduleExports',
      permission: { name: customReports.lptSelfScheduleExports, right: accessRight.visible },
      config: {
        LPTSELFSCHEDULEEXPORTSREPORTmarketType: {
          label: 'Market Type',
          options: [
            'DAM', 'RTM', 'ALL',
          ],
          value: 'ALL',
          type: 'select',
          searchable: true,
        },
        LPTSELFSCHEDULEEXPORTSREPORTsourceType: {
          visible: true,
          type: 'select',
          searchable: true,
          label: 'Source Type',
          value: 'ALL',
          options: [],
          async fetchData() {
            try {
              const data = await TOOLS_API.get('/application/system-information/sibr', {
                params: {
                  isDownload: true,
                },
              });

              const reportNames = data.data
                .filter((item) => item.dataName !== 'BsapBalancingTests'
                  && item.dataName !== 'BaseSchedules'
                  && item.download)
                .map(({ dataName }) => dataName)
                .sort();

              return ['ALL',
                ...new Set(reportNames),
              ];
            } catch (error) {
              vue.$notify('Failed to Load SIBR Source Types', 'error');
              console.log(error);
            }
            return [];
          },
        },
      },
      beforeSubmit(request) {
        const locations = Array.isArray(request.Locations) ? request.Locations : [];
        request.reportParams = {
          Resources: locations.join(','),
          MarketType: request.marketType,
          SourceType: request.sourceType,
        };
        return { isValid: true };
      },
    },
    SALESPURCHASESREPORT: {
      label: 'Sales And Purchases Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/sales-and-purchases',
      permission: { name: customReports.salesPurchases, right: accessRight.visible },
      config: {
        SALESPURCHASESREPORTreportType: {
          label: 'Report Type',
          type: 'select',
          searchable: true,
          value: 'Merchant Sales',
          options: [
            'Merchant Sales',
            'Merchant Purchases',
            'Entity Sales',
            'Entity Purchases',
            'Validation Report',
          ],
        },
        SALESPURCHASESREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: SalesAndPurchasesReportPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: SalesAndPurchasesReportPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: SalesAndPurchasesReportPublicationHandler,
          }, {
            propertyToWatch: 'SALESPURCHASESREPORTpublicationSelected',
            handler() {
              this.config.SALESPURCHASESREPORTpublication.disabled = !this.params.SALESPURCHASESREPORTpublication;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          reportType: request.reportType,
          publication: request.publication,
        };
        return { isValid: true };
      },
    },
    HOURLYAWARDDISPATCHCUSTOMREPORT: {
      label: 'Hourly Award And Dispatch Custom Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/hourly-award-dispatch-custom-report',
      permission: { name: customReports.hourlyAwardDispatchCustomReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {
          Resources: request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    MARKETBASESCHEDULES: {
      label: 'Market Base Schedules Custom Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/market-base-schedules-report',
      permission: { name: customReports.marketBaseSchedulesReport, right: accessRight.visible },
      config: {
        MARKETBASESCHEDULESinputTimes: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Input Time',
          value: null,
          options: ['T-75', 'T-55', 'T-40'],
        },
        MARKETBASESCHEDULESproducts: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Input Product',
          value: null,
          options: ['EN', 'NR', 'RD', 'RU', 'SR'],
        },
      },
      beforeSubmit(request) {
        request.entities = (request.coordinators) ? request.coordinators.join(',') : '';
        request.reportParams = {
          inputTimes: request.inputTimes == null ? '' : request.inputTimes.join(','),
          products: request.products == null ? '' : request.products.join(','),
          resources: request.resources == null || request.resources.length === 0 ? '' : request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    MANUALDISPATCH: {
      label: 'Manual Dispatch',
      api: 'REPORTING',
      endpoint: '/reports/generate/manual-dispatch',
      permission: { name: customReports.manualDispatch, right: accessRight.visible },
      config: {
        MANUALDISPATCHLocations: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Location(s)',
          value: null,
          disabled: false,
          options: [],
          async fetchData() {
            try {
              const { locations } = (await STRUCTURES_API.get('locations')).data.data;
              // grab only the rsrc names and sort alphabetically
              return locations
                .map((location) => ({ value: location.shortName, label: location.shortName }))
                .sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
            } catch (error) {
              vue.$notify('Failed to load locations', 'error');
              console.log(error);
            }
            return [];
          },
        },
      },
      beforeSubmit(request) {
        const locations = Array.isArray(request.Locations) ? request.Locations : [];
        request.reportParams = {
          Resources: locations.join(','),
        };
        return { isValid: true };
      },
    },
    MONTHLYTCINVOICE: {
      label: 'Monthly TC Invoice Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/monthly-tc-invoice',
      permission: { name: customReports.monthlyTcInvoice, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    PRICESANDAMOUNTSREPORT: {
      label: 'Prices And Amounts Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/prices-and-amounts-report',
      permission: { name: visualAnalytics.customReports.pricesAndAmountsReport, right: accessRight.visible },
      config: {
        PRICESANDAMOUNTSREPORTvariant: {
          visible: true,
          type: 'select',
          label: 'Variant',
          value: null,
          options: ['T_1', 'T_9'],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          startDate: request.startDate,
          endDate: request.endDate,
          resources: request.resources.join(','),
          variant: request.variant,
        };
        return { isValid: true };
      },
    },
    VISUALANALYTICSREPORT: {
      label: 'Visual Analytics Report',
      api: 'REPORTING',
      endpointKey: 'VISUALANALYTICSREPORTReportType',
      permission: { name: customReports.visualAnalyticsReport, right: accessRight.visible },
      config: {
        VISUALANALYTICSREPORTReportType: {
          visible: true,
          type: 'select',
          label: 'Report Type',
          value: 'Unpivot',
          options: [{
            value: '/reports/generate/visual-analytics-report',
            label: 'Unpivot',
          }, {
            value: '/reports/generate/visual-analytics-report-pivot',
            label: 'Pivot',
          }],
        },
        VISUALANALYTICSREPORTLayouts: {
          visible: true,
          type: 'select',
          searchable: true,
          label: 'Layout',
          value: null,
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await VA_API.get('layouts?userOnlyFlag=true');
              if (Array.isArray(data)) {
                const layouts = data.map((layout) => ({
                  value: layout.id,
                  label: layout.name,
                })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
                return layouts;
              }
            } catch (error) {
              vue.$notify('Failed to Load Layouts', 'error');
              console.log(error);
            }
            return [];
          },
        },
        VISUALANALYTICSREPORTChartSeries: {
          visible: false,
          type: 'tagbox',
          label: 'Chart Series',
          value: null,
          options: [],
          watchers: [{
            propertyToWatch: 'VISUALANALYTICSREPORTLayoutsSelected',
            watchOptions: { deep: true },
            async handler(newValue) {
              try {
                const { data } = await VA_API.get(`/layouts/${newValue}/chart`);
                const seriesIds = data.charts?.map((charts) => charts.chartSeries?.map((chartSeries) => chartSeries.seriesId)).flat();
                this.params.VISUALANALYTICSREPORTChartSeries = [...new Set(seriesIds)];
                this.params.VISUALANALYTICSREPORTChartSeriesSelected = [...new Set(seriesIds)];
                if (this.params.VISUALANALYTICSREPORTVariants.includes(data.defaultVariant)) {
                  this.params.VISUALANALYTICSREPORTVariantsSelected = [data.defaultVariant];
                }
              } catch (error) {
                vue.$notify('Failed to Load Chart Series', 'error');
                console.log(error);
              }
            },
          }],
        },
        VISUALANALYTICSREPORTVariants: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          label: 'Variant',
          value: null,
          options: [],
          watchers: [{
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true, immediate: true },
            async handler(newValue) {
              try {
                const startDate = newValue[0];
                const { data: { data } } = startDate
                  ? await VA_API.get(`/variants/${ moment(startDate).toISOString() }`)
                  : await VA_API.get('/variants');
                const variants = data.map(({ variantName }) => variantName).sort();
                this.params.VISUALANALYTICSREPORTVariants = variants;
              } catch (error) {
                vue.$notify('Failed to Load Variants', 'error');
                console.log(error);
              }
            },
          }],
        },
        VISUALANALYTICSREPORTLocationGroups: {
          visible: true,
          type: 'select',
          searchable: true,
          showControls: true,
          label: 'Location Group',
          value: null,
          options: [],
          async fetchData() {
            try {
              const result = await STRUCTURES_API.get('location-groups');
              const locationGroups = result.data.locationGroups.map((lg) => ({
                value: lg.id,
                label: lg.shortName,
              })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
              return locationGroups;
            } catch (error) {
              vue.$notify('Failed to Load Location Groups', 'error');
              console.log(error);
            }
            return [];
          },
        },
        VISUALANALYTICSREPORTLocations: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Location(s)',
          value: null,
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'VISUALANALYTICSREPORTLocationGroupsSelected',
            async handler(newValue) {
              this.config.VISUALANALYTICSREPORTLocations.disabled = !this.params.VISUALANALYTICSREPORTLocationGroupsSelected;
              try {
                const result = await STRUCTURES_API.get(`location-groups/${newValue}/locations`);
                const locations = result.data.locationGroupLocations.map((location) => ({
                  value: location.shortName,
                  label: location.shortName,
                })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
                this.params.VISUALANALYTICSREPORTLocations = locations;
              } catch (error) {
                vue.$notify('Failed to Load Locations', 'error');
                console.log(error);
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.endDate = moment(request.endDate).add(1, 'days'); // VA intervals are in UTC, add day to fetch all endDate data
        const locations = Array.isArray(request.Locations) ? request.Locations : [];
        const variants = Array.isArray(request.Variants) ? request.Variants : [];
        const series = Array.isArray(request.ChartSeries) ? request.ChartSeries : [];
        request.reportParams = {
          variants: variants.join(','),
          locations: locations.join(','),
          series: series.join(','),
          layoutId: request.Layouts,
          reportType: request.ReportType,
        };
        return { isValid: true };
      },
    },
    CAISORSRCTAGREPORT: {
      label: 'Resource-Tag Allocation Compare Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/resource-tag-compare',
      permission: { name: customReports.caisoRrscTagReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    ECONOMICCURTAILMENTREPORT: {
      label: 'Economic Curtailment Report',
      endpoint: '/legacy-process/ads-report',
      permission: { name: customReports.economicCurtailmentReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportNames = ['ECONOMIC-CURTAILMENT'];
        return { isValid: true };
      },
    },
    CONGESTIONOFFSETBDREPORT: {
      label: 'Custom Congestion Offset BD Report',
      endpoint: '/legacy-process/Samc-Report',
      permission: { name: customReports.congestion_Offset, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'CongestionOffset';
        request.calculations = [];
        return { isValid: true };
      },
    },
    Pi1MinuteReport: {
      label: 'Pi 1 minute Report',
      endpoint: '/legacy-process/samc-report',
      permission: { name: customReports.pi_1_minute_report, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'OneMinMeter';
        request.calculations = [];
        return { isValid: true };
      },
    },

    BurbankAmerescoReport: {
      label: 'Burbank Ameresco Report',
      endpoint: '/legacy-process/samc-custom-legacy-report',
      permission: { name: customReports.burbank_ameresco, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'BurbankAmeresco';
        request.calculations = [];
        return { isValid: true };
      },
    },

    PASADENAKINGBRD2SOLAR1Report: {
      label: 'Pasadena KINGBRD_2_SOLAR 1 REPORT',
      endpoint: '/legacy-process/samc-custom-legacy-report',
      permission: { name: customReports.kngbrd_ameresco, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'KngbrdAmeresco';
        request.calculations = [];
        return { isValid: true };
      },
    },

    PRICEBDCOMPAREREPORT: {
      label: 'Price BD Compare Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/price-bd-compare',
      permission: { name: customReports.price_bd, right: accessRight.visible },
      config: {
        PRICEBDCOMPAREREPORTfileType: {
          label: 'Settlement Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: PriceCompareBdFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: PriceCompareBdFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: PriceCompareBdFileTypeHandler,
          }, {
            propertyToWatch: 'PRICEBDCOMPAREREPORTfileTypeSelected',
            handler() {
              this.config.PRICEBDCOMPAREREPORTfileType.disabled = !this.params.PRICEBDCOMPAREREPORTfileTypeSelected;
            },
          }],
        },
        PRICEBDCOMPAREREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: PriceCompareBdPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: PriceCompareBdPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: PriceCompareBdPublicationHandler,
          }, {
            propertyToWatch: 'PRICEBDCOMPAREREPORTfileTypeSelected',
            handler: PriceCompareBdPublicationHandler,
          }, {
            propertyToWatch: 'PRICEBDCOMPAREREPORTpublicationSelected',
            handler() {
              this.config.PRICEBDCOMPAREREPORTpublication.disabled = !this.params.PRICEBDCOMPAREREPORTpublicationSelected;
            },
          }],
        },
        PRICEBDCOMPAREREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'PRICEBDCOMPAREREPORTpublicationSelected',
            handler: PriceCompareBdVersionHandler,
          }, {
            propertyToWatch: 'PRICEBDCOMPAREREPORTversionSelected',
            handler() {
              // !0 = false. This greys out the generate button.
              this.config.PRICEBDCOMPAREREPORTversion.disabled = !this.params.PRICEBDCOMPAREREPORTversionSelected;
              // Corrects The above issue
              if (this.params.PRICEBDCOMPAREREPORTversionSelected === 0) {
                this.config.PRICEBDCOMPAREREPORTversion.disabled = false;
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          publication: request.publication,
          version: request.version,
        };
        return { isValid: true };
      },
    },

    CMRICUSTOMREPORT: {
      label: 'CMRI Custom Report',
      endpoint: '/legacy-process/cmri-report',
      permission: { name: customReports.cmriCustomSceReports, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportNames = ['SCE_UnitCommitment', 'SCE_MarketAwards_DAM', 'SCE_MarketAwards_HASP', 'SCE_ISOCommitment', 'SCE_ExpectedEnergy', 'SCE_ExpectedEnergyAlloc'];
        return { isValid: true };
      },
    },
    NETPOSITIONREPORTS: {
      label: 'Net Position Reports',
      endpoint: '/legacy-process/cmri-report',
      permission: { name: customReports.netPositionReports, right: accessRight.visible },
      config: {
        NETPOSITIONREPORTSintervalType: {
          label: 'Interval Type',
          value: 'Daily',
          type: 'select',
          searchable: true,
          options: [{ value: 'Daily', label: 'Daily' }, { value: 'Daily Actualized', label: 'Daily Actualized' }, { value: 'Hourly', label: 'Hourly' }, { value: 'Hourly Actualized', label: 'Hourly Actualized' }, { value: '15-minute', label: '15-minute' }, { value: '15-minute Actualized', label: '15-minute Actualized' }],
        },
        NETPOSITIONREPORTSfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: NetPositionReportsFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: NetPositionReportsFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: NetPositionReportsFileTypeHandler,
          }, {
            propertyToWatch: 'NETPOSITIONREPORTSfileTypeSelected',
            handler() {
              this.config.NETPOSITIONREPORTSfileType.disabled = !this.params.NETPOSITIONREPORTSfileTypeSelected;
            },
          }],
        },
        NETPOSITIONREPORTSversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'NETPOSITIONREPORTSfileTypeSelected',
            handler: NetPositionReportsVersionHandler,
          }, {
            propertyToWatch: 'NETPOSITIONREPORTSversionSelected',
            handler() {
              this.config.NETPOSITIONREPORTSversion.disabled = !this.params.NETPOSITIONREPORTSversionSelected;
              if (this.params.NETPOSITIONREPORTSversionSelected === 0) {
                this.config.NETPOSITIONREPORTSversion.disabled = false;
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportNames = [];
        request.reportType = 'CMRI-NET-POSITION-REPORTS';
        return { isValid: true };
      },
    },
    CUSTOMSAMCMONTHLYMGMT: {
      label: 'CAISO Settlements Operation Monthly Reports',
      endpoint: '/legacy-process/samc-report',
      permission: { name: customReports.settlementsOperationMonthlyReport, right: accessRight.visible },
      config: {
        CUSTOMSAMCMONTHLYMGMTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          searchable: true,
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CustomSettlementsOperationMonthlyFileTypeHandler,
          }, {
            propertyToWatch: 'CUSTOMSAMCMONTHLYMGMTreportTypeSelected',
            handler: CustomSettlementsOperationMonthlyFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomSettlementsOperationMonthlyFileTypeHandler,
          }, {
            propertyToWatch: 'CUSTOMSAMCMONTHLYMGMTdateTypeSelected',
            handler: CustomSettlementsOperationMonthlyFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            handler: CustomSettlementsOperationMonthlyFileTypeHandler,
          }, {
            propertyToWatch: 'CUSTOMSAMCMONTHLYMGMTfileTypeSelected',
            handler() {
              this.config.CUSTOMSAMCMONTHLYMGMTfileType.disabled = !this.params.CUSTOMSAMCMONTHLYMGMTfileTypeSelected;
            },
          }],
        },
        CUSTOMSAMCMONTHLYMGMTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          searchable: true,
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'CUSTOMSAMCMONTHLYMGMTfileTypeSelected',
            handler: CustomSettlementsOperationMonthlyVersionHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomSettlementsOperationMonthlyVersionHandler,
          }, {
            propertyToWatch: 'CUSTOMSAMCMONTHLYMGMTversionSelected',
            handler() {
              // !0 = false. This greys out the generate button.
              this.config.CUSTOMSAMCMONTHLYMGMTversion.disabled = !this.params.CUSTOMSAMCMONTHLYMGMTversionSelected;
              // Corrects The above issue
              if (this.params.CUSTOMSAMCMONTHLYMGMTversionSelected === 0) {
                this.config.CUSTOMSAMCMONTHLYMGMTversion.disabled = false;
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportType = 'Custom Monthly Management';
        request.calculations = [];
        return { isValid: true };
      },
    },
    LMPVSALLBIDSPRICE: {
      label: 'Bid vs LMP All Bids Report',
      endpoint: '/legacy-process/samc-custom-legacy-report',
      permission: { name: customReports.lmpVsAllBidsPrice, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'LmpVsAllBidsPrice';
        return { isValid: true };
      },
    },
    DAYAHEADTOBASESCHEDULEDEVIATIONREPORT: {
      label: 'Day-Ahead to Base Schedule Deviation',
      api: 'REPORTING',
      endpoint: '/reports/generate/day-ahead-to-base-schedule-deviation-report',
      permission: { name: customReports.dayAheadToBaseScheduleDeviationReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        return { isValid: true };
      },
    },
    DAALLCATIONSTATERATIOREPORT: {
      label: 'DA Allocation State Ratio Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/da-allocation-state-ratio-report',
      permission: { name: customReports.daAllocationStateRatioReport, right: accessRight.visible },
      config: {
        stateHeaders,
        DAALLCATIONSTATERATIOREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'stateHeadersUnfiltered',
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'DAALLCATIONSTATERATIOREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'DAALLCATIONSTATERATIOREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'DAALLCATIONSTATERATIOREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'DAALLCATIONSTATERATIOREPORTfileTypeSelected',
            handler() {
              this.config.DAALLCATIONSTATERATIOREPORTfileType.disabled = !this.params.DAALLCATIONSTATERATIOREPORTfileTypeSelected;
            },
          }],
        },
        DAALLCATIONSTATERATIOREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'DAALLCATIONSTATERATIOREPORTfileTypeSelected',
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'DAALLCATIONSTATERATIOREPORTpublication', 'DAALLCATIONSTATERATIOREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'DAALLCATIONSTATERATIOREPORTpublication', 'DAALLCATIONSTATERATIOREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'DAALLCATIONSTATERATIOREPORTpublication', 'DAALLCATIONSTATERATIOREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'DAALLCATIONSTATERATIOREPORTpublicationSelected',
            handler() {
              this.config.DAALLCATIONSTATERATIOREPORTpublication.disabled = !this.params.DAALLCATIONSTATERATIOREPORTpublicationSelected;
            },
          }],
        },
        DAALLCATIONSTATERATIOREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'DAALLCATIONSTATERATIOREPORTpublicationSelected',
            handler(newValue, oldValue) {
              stateAllocVersionHandler(
                this,
                'DAALLCATIONSTATERATIOREPORTversion',
                'DAALLCATIONSTATERATIOREPORTfileType',
                'DAALLCATIONSTATERATIOREPORTpublication');
              return true;
            },
          }, {
            propertyToWatch: 'DAALLCATIONSTATERATIOREPORTversionSelected',
            handler() {
              // eslint-disable-next-line max-len
              this.config.DAALLCATIONSTATERATIOREPORTversion.disabled = !this.params.DAALLCATIONSTATERATIOREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          publication: request.publication,
          version: request.version,
        };
        return { isValid: true };
      },
    },
    CUSTOMOASISMISSINGPRICEAUDITREPORT: {
      label: 'OASIS Missing Price Audit Report',
      endpoint: '/legacy-process/oasis-report',
      permission: { name: customReports.customOasisMissingPriceAudit, right: accessRight.visible },
      config: {
        CUSTOMOASISMISSINGPRICEAUDITREPORTreportNames: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Pricing Report(s)',
          value: null,
          options: ['RTPD_LMP',
            'RTD_LMP',
            'RTPD_SPTIE_LMP',
            'RTD_SPTIE_LMP',
            'RTPD_EIM_GHG',
            'RTD_EIM_GHG'],
        },
      },
      beforeSubmit(request) {
        request.reportType = 'OASIS-MISSING-PRICE-AUDIT';
        return { isValid: true };
      },
    },
    CUSTOMENERGYOFFSETBDREPORT: {
      label: 'Custom Energy Offset BD Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/custom-energy-offset-bd-report',
      permission: { name: customReports.customEnergyOffsetBdReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        return { isValid: true };
      },
    },
    DAILYMARKETSNAPSHOTREPORT: {
      label: 'Daily Market Snapshot Report',
      endpoint: '/legacy-process/daily-market-report',
      permission: { name: customReports.dailyMarketSnapshotReport, right: accessRight.visible },
      config: {
        DAILYMARKETSNAPSHOTREPORTmeterSource: {
          visible: true,
          type: 'radio',
          searchable: true,
          showControls: true,
          label: 'Meter Source',
          value: 'Internal Meter',
          options: ['Internal Meter', 'MRI-S Meter'],
        },
      },
      beforeSubmit(request) {
        return { isValid: true };
      },
    },
    TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORT: {
      label: 'Transmission Allocation Calculation Details Report',
      endpoint: '/legacy-process/transmission-report',
      permission: { name: customReports.transmissionAllocationCalculationDetailsReport, right: accessRight.visible },
      config: {
        eimHeaders,
        TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'eimHeadersUnfiltered',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType', false);
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType', false);
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType', false);
              return true;
            },
          }, {
            propertyToWatch: 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileTypeSelected',
            handler() {
              this.config.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType.disabled = !this.params.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileTypeSelected;
            },
          }],
        },
        TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileTypeSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublication', 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublication', 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublication', 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublicationSelected',
            handler() {
              this.config.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublication.disabled = !this.params.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublicationSelected;
            },
          }],
        },
        TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublicationSelected',
            handler(newValue, oldValue) {
              eimVersionHandler(
                this,
                'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTversion',
                'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileType',
                'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTpublication');
              return true;
            },
          }, {
            propertyToWatch: 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTversionSelected',
            handler() {
              // eslint-disable-next-line max-len
              this.config.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTversion.disabled = !this.params.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTversionSelected;
            },
          }],
        },
        TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTreportType: {
          label: 'Report Type',
          value: 'detailOrSummary',
          type: 'radio',
          options: [{ value: 'summaryType', label: 'Summary' }, { value: 'detailType', label: 'Detail' }],
        },
        TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTchargeCodes: {
          label: 'Charge Codes',
          value: null,
          type: 'tagbox',
          searchable: true,
          permission: { name: caiso.samc.eimSubAllocation, right: [accessRight.read, accessRight.write] },
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileTypeSelected',
            async handler() {
              if (this.params.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTfileTypeSelected?.length) {
                this.config.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTchargeCodes.disabled = false;
                try {
                  const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/charge-code-groups');
                  const chargeCodes = [...new Set(data.map((x) => x.chargeCode))];
                  this.params.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTchargeCodes = chargeCodes;
                } catch (err) {
                  vue.$notify('Failed to load EIM charge codes', 'error');
                  console.log(err);
                }
              } else {
                this.config.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTchargeCodes.disabled = true;
                this.params.TRANSMISSIONALLOCATIONCALCULATIONDETAILSREPORTchargeCodes = [];
              }
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          publication: request.publication,
          version: request.version,
          reportType: request.reportType,
          chargeCodes: request.chargeCodes,
        };
        return { isValid: true };
      },
    },
    OASISPRICINGGROUPCUSTOMREPORT: {
      label: 'OASIS Pricing Group Custom Report',
      endpoint: '/legacy-process/oasis-report',
      permission: { name: customReports.customPricingGroup, right: accessRight.visible },
      config: {
        OASISPRICINGGROUPCUSTOMREPORTgroupFlag: {
          label: '',
          value: 'Groups',
          type: 'radio',
          options: [{ value: 'Groups', label: 'Groups' }, { value: 'Nodes', label: 'Nodes' }],
        },
        OASISPRICINGGROUPCUSTOMREPORTreportNames: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Pricing Report(s)',
          value: null,
          options: ['RTPD_LMP',
            'RTD_LMP',
            'RTPD_SPTIE_LMP',
            'RTD_SPTIE_LMP',
            'RTPD_EIM_GHG',
            'RTD_EIM_GHG'],
        },
        OASISPRICINGGROUPCUSTOMREPORTpricingGroups: {
          visible: true,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'Pricing Group(s)',
          value: null,
          options: [],
          async fetchData() {
            try {
              const data = await CISO_OASIS_API.get('/custom-groups/groups');

              const pricingGroups = data.data.data.map((pg) => ({ label: pg, value: pg }));

              return pricingGroups;
            } catch (err) {
              vue.$notify('Failed to load OASIS Pricing Groups', 'error');
              console.log(err);
            }
            return [];
          },
          watchers: [{
            propertyToWatch: 'OASISPRICINGGROUPCUSTOMREPORTgroupFlagSelected',
            async handler() {
              this.params.OASISPRICINGGROUPCUSTOMREPORTpricingGroupsSelected = null;

              this.config.OASISPRICINGGROUPCUSTOMREPORTpricingGroups.visible = this.params.OASISPRICINGGROUPCUSTOMREPORTgroupFlagSelected === 'Groups';
            },
          },
          ],
        },
        OASISPRICINGGROUPCUSTOMREPORTpricingNodes: {
          visible: false,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          label: 'PricingNodes(s)',
          value: null,
          options: [],
          async fetchData() {
            try {
              const data = await CISO_OASIS_API.get('/custom-groups/nodes');

              const pricingNodes = data.data.data.map((pn) => ({ label: pn, value: pn }));

              return pricingNodes;
            } catch (err) {
              vue.$notify('Failed to load OASIS Pricing Nodes', 'error');
              console.log(err);
            }
            return [];
          },
          watchers: [
            {
              propertyToWatch: 'OASISPRICINGGROUPCUSTOMREPORTgroupFlagSelected',
              async handler() {
                this.params.OASISPRICINGGROUPCUSTOMREPORTpricingNodesSelected = null;

                this.config.OASISPRICINGGROUPCUSTOMREPORTpricingNodes.visible = this.params.OASISPRICINGGROUPCUSTOMREPORTgroupFlagSelected === 'Nodes';
              },
            },
          ],
        },
      },
      beforeSubmit(request) {
        request.reportType = 'OASIS-PRICING-GROUP';
        return { isValid: true };
      },
    },
    FERCELECTRICITYQUARTERLYREPORT: {
      label: 'FERC ELECTRICITY QUARTERLY REPORT',
      api: 'REPORTING',
      endpoint: '/reports/generate/ferc-electricity-quarterly',
      permission: { name: customReports.fercElectricityQuarterly, right: accessRight.visible },
      config: {
        FERCELECTRICITYQUARTERLYREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CustomFercElectricityQuarterlyFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CustomFercElectricityQuarterlyFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CustomFercElectricityQuarterlyFileTypeHandler,
          }, {
            propertyToWatch: 'FERCELECTRICITYQUARTERLYREPORTfileTypeSelected',
            handler() {
              this.config.FERCELECTRICITYQUARTERLYREPORTfileType.disabled = !this.params.FERCELECTRICITYQUARTERLYREPORTfileTypeSelected;
            },
          }],
        },
        FERCELECTRICITYQUARTERLYREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'FERCELECTRICITYQUARTERLYREPORTfileTypeSelected',
            handler: CustomFercElectricityQuarterlyVersionHandler,
          }, {
            propertyToWatch: 'FERCELECTRICITYQUARTERLYREPORTversionSelected',
            handler() {
              this.config.FERCELECTRICITYQUARTERLYREPORTversion.disabled = this.params.FERCELECTRICITYQUARTERLYREPORTversionSelected === 0
                ? false : !this.params.FERCELECTRICITYQUARTERLYREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          resources: request.resources.join(','),
          fileType: request.fileType,
          version: request.version,
        };
        return { isValid: true };
      },
    },
    STATEALLOCATIONREPORTBYGRANULARITY: {
      label: 'State Allocation Report By Granularity',
      api: 'REPORTING',
      endpoint: '/reports/generate/state-allocation-report-by-granularity',
      permission: { name: customReports.customStateAllocationGranularity, right: accessRight.visible },
      config: {
        stateHeaders,
        STATEALLOCATIONREPORTBYGRANULARITYrunType: {
          label: 'Run Type',
          options: [
            'Hourly', 'Daily', 'Monthly',
          ],
          value: null,
          type: 'select',
          searchable: true,
        },
        STATEALLOCATIONREPORTBYGRANULARITYchargeCodes: {
          label: 'Charge Codes',
          value: null,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          disabled: false,
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await CISO_SAMC_API.get('/custom-state-allocation/charge-codes');
              const chargeCodes = data.map((c) => ({ label: c, value: c }));

              return chargeCodes;
            } catch (err) {
              vue.$notify('Failed to load charge codes', 'error');
              console.log(err);
            }
            return [];
          },
        },
        STATEALLOCATIONREPORTBYGRANULARITYfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'stateHeadersUnfiltered',
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'STATEALLOCATIONREPORTBYGRANULARITYfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'STATEALLOCATIONREPORTBYGRANULARITYfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'STATEALLOCATIONREPORTBYGRANULARITYfileType');
              return true;
            },
          }, {
            propertyToWatch: 'STATEALLOCATIONREPORTBYGRANULARITYfileTypeSelected',
            handler() {
              this.config.STATEALLOCATIONREPORTBYGRANULARITYfileType.disabled = !this.params.STATEALLOCATIONREPORTBYGRANULARITYfileTypeSelected;
            },
          }],
        },
        STATEALLOCATIONREPORTBYGRANULARITYpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'STATEALLOCATIONREPORTBYGRANULARITYfileTypeSelected',
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'STATEALLOCATIONREPORTBYGRANULARITYpublication', 'STATEALLOCATIONREPORTBYGRANULARITYfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'STATEALLOCATIONREPORTBYGRANULARITYpublication', 'STATEALLOCATIONREPORTBYGRANULARITYfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'STATEALLOCATIONREPORTBYGRANULARITYpublication', 'STATEALLOCATIONREPORTBYGRANULARITYfileType');
              return true;
            },
          }, {
            propertyToWatch: 'STATEALLOCATIONREPORTBYGRANULARITYpublicationSelected',
            handler() {
              this.config.STATEALLOCATIONREPORTBYGRANULARITYpublication.disabled = !this.params.STATEALLOCATIONREPORTBYGRANULARITYpublicationSelected;
            },
          }],
        },
        STATEALLOCATIONREPORTBYGRANULARITYversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'STATEALLOCATIONREPORTBYGRANULARITYpublicationSelected',
            handler(newValue, oldValue) {
              stateAllocVersionHandler(
                this,
                'STATEALLOCATIONREPORTBYGRANULARITYversion',
                'STATEALLOCATIONREPORTBYGRANULARITYfileType',
                'STATEALLOCATIONREPORTBYGRANULARITYpublication');
              return true;
            },
          }, {
            propertyToWatch: 'STATEALLOCATIONREPORTBYGRANULARITYversionSelected',
            handler() {
              this.config.STATEALLOCATIONREPORTBYGRANULARITYversion.disabled = !this.params.STATEALLOCATIONREPORTBYGRANULARITYversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          runType: request.runType,
          chargeCodes: request.chargeCodes.join(),
          fileType: request.fileType,
          publication: request.publication,
          version: request.version,
        };
        return { isValid: true };
      },
    },
    ALLOCATIONREPORTBYSTATE: {
      label: 'Allocation Report By State',
      api: 'REPORTING',
      endpoint: '/reports/generate/allocation-by-state-report',
      permission: { name: customReports.customAllocationReportByState, right: accessRight.visible },
      config: {
        stateHeaders,
        ALLOCATIONREPORTBYSTATEchargeCodes: {
          label: 'Charge Codes',
          value: null,
          type: 'tagbox',
          searchable: true,
          showControls: true,
          disabled: false,
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await CISO_SAMC_API.get('/custom-state-allocation/charge-codes');
              const chargeCodes = data.map((c) => ({ label: c, value: c }));

              return chargeCodes;
            } catch (err) {
              vue.$notify('Failed to load charge codes', 'error');
              console.log(err);
            }
            return [];
          },
        },
        ALLOCATIONREPORTBYSTATEfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'stateHeadersUnfiltered',
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'ALLOCATIONREPORTBYSTATEfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'ALLOCATIONREPORTBYSTATEfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              stateAllocFileTypeHandler(this, 'ALLOCATIONREPORTBYSTATEfileType');
              return true;
            },
          }, {
            propertyToWatch: 'ALLOCATIONREPORTBYSTATEfileTypeSelected',
            handler() {
              this.config.ALLOCATIONREPORTBYSTATEfileType.disabled = !this.params.ALLOCATIONREPORTBYSTATEfileTypeSelected;
            },
          }],
        },
        ALLOCATIONREPORTBYSTATEpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'ALLOCATIONREPORTBYSTATEfileTypeSelected',
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'ALLOCATIONREPORTBYSTATEpublication', 'ALLOCATIONREPORTBYSTATEfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'ALLOCATIONREPORTBYSTATEpublication', 'ALLOCATIONREPORTBYSTATEfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              stateAllocPublicationHandler(this, 'ALLOCATIONREPORTBYSTATEpublication', 'ALLOCATIONREPORTBYSTATEfileType');
              return true;
            },
          }, {
            propertyToWatch: 'ALLOCATIONREPORTBYSTATEpublicationSelected',
            handler() {
              this.config.ALLOCATIONREPORTBYSTATEpublication.disabled = !this.params.ALLOCATIONREPORTBYSTATEpublicationSelected;
            },
          }],
        },
        ALLOCATIONREPORTBYSTATEversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'ALLOCATIONREPORTBYSTATEpublicationSelected',
            handler(newValue, oldValue) {
              stateAllocVersionHandler(
                this,
                'ALLOCATIONREPORTBYSTATEversion',
                'ALLOCATIONREPORTBYSTATEfileType',
                'ALLOCATIONREPORTBYSTATEpublication');
              return true;
            },
          }, {
            propertyToWatch: 'ALLOCATIONREPORTBYSTATEversionSelected',
            handler() {
              this.config.ALLOCATIONREPORTBYSTATEversion.disabled = !this.params.ALLOCATIONREPORTBYSTATEversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          chargeCodes: request.chargeCodes.join(),
          fileType: request.fileType,
          publication: request.publication,
          version: request.version,
        };
        return { isValid: true };
      },
    },
    TRANSMISSIONRAWMETERSCHEDULEREPORT: {
      label: 'Transmission Raw Meter/Schedule Report',
      endpoint: '/legacy-process/eim-report',
      permission: { name: customReports.transmissionMeterScheduleReport, right: accessRight.visible },
      config: {
        TRANSMISSIONRAWMETERSCHEDULEREPORTdataType: {
          label: 'Data Type',
          value: null,
          type: 'select',
          searchable: true,
          options: [{ value: 'Schedule', label: 'Schedule' }, { value: 'Meter', label: 'Meter' }, { value: 'CaisoMeter', label: 'CaisoMeter' }],
        },
        TRANSMISSIONRAWMETERSCHEDULEREPORTtype: {
          label: 'Schedule Type',
          value: null,
          type: 'select',
          searchable: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONRAWMETERSCHEDULEREPORTdataTypeSelected',
            async handler(newValue) {
              if (this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTdataTypeSelected === 'Schedule') {
                this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTtype = [{ value: 'ALL', label: 'ALL' }, { value: 'AWARD', label: 'AWARD' }, { value: 'BASE', label: 'BASE' }, { value: 'TAG', label: 'TAG' }];
                this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTtypeSelected = 'ALL';
                this.config.TRANSMISSIONRAWMETERSCHEDULEREPORTtype.label = 'Schedule Type';
              }

              if (this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTdataTypeSelected === 'Meter') {
                this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTtype = [{ value: 'ALL', label: 'ALL' }, { value: 'LOAD', label: 'LOAD' }, { value: 'IMBAL', label: 'IMBAL' }];
                this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTtypeSelected = 'ALL';
                this.config.TRANSMISSIONRAWMETERSCHEDULEREPORTtype.label = 'Meter Type';
              }

              if (this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTdataTypeSelected === 'CaisoMeter') {
                this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTtype = [];
              }
            },
          }],
        },
        TRANSMISSIONRAWMETERSCHEDULEREPORTsubCompany: {
          label: 'Sub Company',
          value: null,
          type: 'tagbox',
          searchable: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONRAWMETERSCHEDULEREPORTdataTypeSelected',
            async handler() {
              try {
                if (this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTdataTypeSelected === 'Schedule') {
                  const { data: { data } } = await TOOLS_API.get('/transmission-billing-info/sub-companies');
                  const subCompanies = data.filter(({ sc }) => sc === this.params.coordinatorListSelected[0]).map(({ subCompany }) => subCompany);
                  this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTsubCompany = subCompanies;
                } else {
                  this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTsubCompany = [];
                }
              } catch (err) {
                vue.$notify('Failed to load charge codes', 'error');
                console.log(err);
              }
            },
          }],
        },
        TRANSMISSIONRAWMETERSCHEDULEREPORTrsrcTypes: {
          label: 'Rsrc Type',
          value: null,
          type: 'tagbox',
          searchable: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONRAWMETERSCHEDULEREPORTdataTypeSelected',
            async handler() {
              if (this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTdataTypeSelected === 'Schedule') {
                this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTrsrcTypes = ['GEN', 'ITIE', 'ETIE'];
              } else {
                this.params.TRANSMISSIONRAWMETERSCHEDULEREPORTrsrcTypes = [];
              }
            },
          }],
        },
        TRANSMISSIONRAWMETERSCHEDULEREPORTreportType: {
          value: 'TRANSMISSION-RAW-SCHEDULE',
        },
        beforeSubmit(request) {
          request.reportParams = {
            dataType: request.dataType,
            type: request.type,
            subCompany: request.subCompany,
            rsrcTypes: request.rsrcTypes,
            reportType: request.reportType,
          };
          return { isValid: true };
        },
      },
    },
    LAPPRICEREPORT: {
      label: 'LAP Price Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/lap-price-report',
      permission: { name: customReports.customLAPPriceReport, right: accessRight.visible },
      config: {
        LAPPRICEREPORTfileType: {
          label: 'Settlement Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: LapPriceFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: LapPriceFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: LapPriceFileTypeHandler,
          }, {
            propertyToWatch: 'LAPPRICEREPORTfileTypeSelected',
            handler() {
              this.config.LAPPRICEREPORTfileType.disabled = !this.params.LAPPRICEREPORTfileTypeSelected;
            },
          }],
        },
        LAPPRICEREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: LapPricePublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: LapPricePublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: LapPricePublicationHandler,
          }, {
            propertyToWatch: 'LAPPRICEREPORTpublicationSelected',
            handler() {
              this.config.LAPPRICEREPORTpublication.disabled = !this.params.LAPPRICEREPORTpublicationSelected;
            },
          }],
        },
        LAPPRICEREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'LAPPRICEREPORTfileTypeSelected',
            handler: LapPriceVersionHandler,
          }, {
            propertyToWatch: 'LAPPRICEREPORTpublicationSelected',
            handler: LapPriceVersionHandler,
          }, {
            propertyToWatch: 'LAPPRICEREPORTversionSelected',
            handler() {
              this.config.LAPPRICEREPORTversion.disabled = !this.params
                .LAPPRICEREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          settlementType: request.fileType,
          publication: request.publication,
          version: request.version,
        };
        return { isValid: true };
      },
    },
    ADSRESOURCEREPORT: {
      label: 'ADS Resource Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/ads-resource-report',
      permission: { name: customReports.customADSResourceReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {
          resources: request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    TRANSMISSIONALLOCATIONSUBCOMPANYREPORT: {
      label: 'Transmission Allocation Invoice Report By Sub Company',
      endpoint: '/legacy-process/eim-report',
      permission: { name: customReports.transmissionAllocationSubCompanyReport, right: accessRight.visible },
      config: {
        eimHeaders,
        TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'eimHeadersUnfiltered',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType', false);
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType', false);
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimFileTypeHandler(this, 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType', false);
              return true;
            },
          }, {
            propertyToWatch: 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileTypeSelected',
            handler() {
              this.config.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType.disabled = !this.params.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileTypeSelected;
            },
          }],
        },
        TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileTypeSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublication', 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublication', 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler(newValue, oldValue) {
              eimPublicationHandler(this, 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublication', 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType');
              return true;
            },
          }, {
            propertyToWatch: 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublicationSelected',
            handler() {
              this.config.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublication.disabled = !this.params.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublicationSelected;
            },
          }],
        },
        TRANSMISSIONALLOCATIONSUBCOMPANYREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublicationSelected',
            handler(newValue, oldValue) {
              eimVersionHandler(
                this,
                'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTversion',
                'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTfileType',
                'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTpublication');
              return true;
            },
          }, {
            propertyToWatch: 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTversionSelected',
            handler() {
              this.config.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTversion.disabled = !this.params.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTversionSelected;
            },
          }],
        },
        TRANSMISSIONALLOCATIONSUBCOMPANYREPORTsubCompany: {
          label: 'Sub Company',
          value: null,
          type: 'tagbox',
          searchable: true,
          options: [],
          watchers: [{
            propertyToWatch: 'coordinatorListSelected',
            async handler() {
              try {
                const { data: { data } } = await TOOLS_API.get('/transmission-billing-info/sub-companies');
                const subCompanies = data.map(({ subCompany, sc }) => ({ value: subCompany, label: subCompany, sc }));
                const subCompaniesFiltered = subCompanies.filter((data) => this.params.coordinatorListSelected.includes(data.sc));
                this.params.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTsubCompany = subCompaniesFiltered.filter((value, index, self) => self.map((v) => v.value).indexOf(value.value) === index);
                // set this param so full list can be accessed in beforeSubmit func to map subCompany to SC
                this.params.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTsubCompanyListSelected = subCompaniesFiltered;
              } catch (err) {
                vue.$notify('Failed to load charge codes', 'error');
                console.log(err);
              }
            },
          }],
        },
        TRANSMISSIONALLOCATIONSUBCOMPANYREPORTreportGroup: {
          label: 'Report Groups',
          value: null,
          type: 'select',
          searchable: true,
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/charge-code-groups');
              const chargeGroups = [...new Set(data.map(({ chargeGroup }) => chargeGroup))].map((x) => ({ value: x, label: x }));
              return chargeGroups;
            } catch (err) {
              vue.$notify('Failed to load charge codes', 'error');
              console.log(err);
            }
            return [];
          },
        },
        TRANSMISSIONALLOCATIONSUBCOMPANYREPORTchargeCodes: {
          label: 'Charge Codes',
          value: null,
          type: 'tagbox',
          searchable: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TRANSMISSIONALLOCATIONSUBCOMPANYREPORTreportGroupSelected',
            async handler() {
              try {
                const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/charge-code-groups');
                const reportGroup = this.params.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTreportGroupSelected;
                const filteredChargeCodes = data.filter(({ chargeGroup }) => reportGroup.includes(chargeGroup));
                const chargeCodes = [...new Set(filteredChargeCodes.map(({ chargeCode }) => chargeCode))];
                this.params.TRANSMISSIONALLOCATIONSUBCOMPANYREPORTchargeCodes = chargeCodes;
              } catch (err) {
                vue.$notify('Failed to load charge codes', 'error');
                console.log(err);
              }
              return [];
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportType = 'TRANSMISSION-SUBCOMPANY-CHARGE';
        request.subScs = request.subCompanyList.filter(({ value }) => request.subCompany.includes(value)).map(({ value, sc }) => ({ ba: sc, subCompany: value }));
        return { isValid: true };
      },
    },
    SETTLEMENTDETAILREPORT: {
      label: 'Settlement Detail',
      endpoint: '/legacy-process/eim-report',
      permission: { name: customReports.settlementDetailReport, right: accessRight.visible },
      config: {
        SETTLEMENTDETAILREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: SettlementDetailFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: SettlementDetailFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: SettlementDetailFileTypeHandler,
          }, {
            propertyToWatch: 'SETTLEMENTDETAILREPORTfileTypeSelected',
            handler() {
              this.config.SETTLEMENTDETAILREPORTfileType.disabled = !this.params.SETTLEMENTDETAILREPORTfileTypeSelected;
            },
          }],
        },
        SETTLEMENTDETAILREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'SETTLEMENTDETAILREPORTfileTypeSelected',
            handler: SettlementDetailPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: SettlementDetailPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: SettlementDetailPublicationHandler,
          }, {
            propertyToWatch: 'SETTLEMENTDETAILREPORTpublicationSelected',
            handler() {
              this.config.SETTLEMENTDETAILREPORTpublication.disabled = !this.params.SETTLEMENTDETAILREPORTpublicationSelected;
            },
          }],
        },
        SETTLEMENTDETAILREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'SETTLEMENTDETAILREPORTpublicationSelected',
            handler: SettlementDetailVersionHandler,
          }, {
            propertyToWatch: 'SETTLEMENTDETAILREPORTversionSelected',
            handler() {
              // !0 = false. This greys out the generate button.
              this.config.SETTLEMENTDETAILREPORTversion.disabled = !this.params.SETTLEMENTDETAILREPORTversionSelected;
              // Corrects The above issue
              if (this.params.SETTLEMENTDETAILREPORTversionSelected === 0) {
                this.config.SETTLEMENTDETAILREPORTversion.disabled = false;
              }
            },
          }],
        },
        SETTLEMENTDETAILREPORTchargeCodes: {
          label: 'Charge Codes',
          value: null,
          type: 'tagbox',
          searchable: true,
          options: [],
          async fetchData() {
            try {
              const { data: { data } } = await CISO_SAMC_API.get('/statements/charge-types');
              let chargeCodes = data.map((x) => x.chargeType);
              const precalcs = await CISO_SAMC_API.get('/statements/charge-types-precalc');
              chargeCodes = chargeCodes.concat(precalcs.data);
              return chargeCodes;
            } catch (err) {
              vue.$notify('Failed to load charge codes', 'error');
              console.log(err);
            }
            return [];
          },
        },
        SETTLEMENTDETAILREPORTcontainspacepacw: {
          label: 'Include PACE and PACW',
          value: false,
          type: 'checkbox',
        },
      },
      beforeSubmit(request) {
        request.BothSC = request.containspacepacw;
        request.reportType = 'SETTLEMENT-DETAIL-REPORT';
        return { isValid: true };
      },
    },
    EIMSALESPURCHASESREPORT: {
      label: 'EIM Sales And Purchases Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/eim-sales-and-purchases',
      permission: { name: customReports.eimSalesPurchases, right: accessRight.visible },
      config: {
        EIMSALESPURCHASESREPORTreportType: {
          label: 'Report Type',
          type: 'select',
          searchable: true,
          value: 'Sales',
          options: [
            'Sales',
            'Purchases',
          ],
        },
        EIMSALESPURCHASESREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          options: [
            'T_9_BD',
            'T_70_BD',
            'T_11_M',
          ],
        },
        EIMSALESPURCHASESREPORTsuppressed: {
          label: 'Suppressed Invoices Only',
          value: false,
          type: 'checkbox',
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          reportType: request.reportType,
          publication: request.publication,
          suppressed: request.suppressed,
        };
        return { isValid: true };
      },
    },
    CONGESTIONPRICEREPORT: {
      label: 'Congestion Price Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/congestion-price-report',
      permission: { name: customReports.congestionPriceReport, right: accessRight.visible },
      config: {
        CONGESTIONPRICEREPORTfileType: {
          label: 'Settlement Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CongestionPriceFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CongestionPriceFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CongestionPriceFileTypeHandler,
          }, {
            propertyToWatch: 'CONGESTIONPRICEREPORTfileTypeSelected',
            handler() {
              this.config.CONGESTIONPRICEREPORTfileType.disabled = !this.params.CONGESTIONPRICEREPORTfileTypeSelected;
            },
          }],
        },
        CONGESTIONPRICEREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'CONGESTIONPRICEREPORTfileTypeSelected',
            handler: CongestionPriceVersionHandler,
          }, {
            propertyToWatch: 'CONGESTIONPRICEREPORTversionSelected',
            handler() {
              this.config.CONGESTIONPRICEREPORTversion.disabled = !this.params
                .CONGESTIONPRICEREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          settlementType: request.fileType,
          version: request.version,
        };
        return { isValid: true };
      },
    },
    SAMCINVOICEPDFREPORT: {
      label: 'MRI-S Invoice PDF Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/samc-invoice-pdf-report',
      permission: { name: customReports.samcInvoicePdfReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        request.startDate = moment(request.startDate).utc().startOf('day');
        request.endDate = moment(request.endDate).utc().startOf('day');
        return { isValid: true };
      },
    },
    CUSTOMLOADREPORT: {
      label: 'Load Report',
      endpoint: '/legacy-process/cmri-report',
      permission: { name: customReports.loadReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'CMRI-LOAD-REPORT';
        return { isValid: true };
      },
    },

    ANCILLARYSERVICE: {
      label: 'Ancillary Service Report',
      endpoint: '/legacy-process/samc-custom-legacy-report',
      toolTip: 'Generates report for company PASA (1688) with resources GLNARM_7_UNIT 1, GLNARM_7_UNIT 2, GLNARM_7_UNIT 3, GLNARM_7_UNIT 4, and GLNARM_2_UNIT 5',
      permission: { name: customReports.ancillary_service_report, right: accessRight.visible },
      config: {
        ANCILLARYSERVICEfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          searchable: true,
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: AncillaryServiceFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: AncillaryServiceFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: AncillaryServiceFileTypeHandler,
          }, {
            propertyToWatch: 'ANCILLARYSERVICEfileTypeSelected',
            handler() {
              this.config.ANCILLARYSERVICEfileType.disabled = !this.params.ANCILLARYSERVICEfileTypeSelected;
            },
          }],
        },
        ANCILLARYSERVICEsettlementCycle: {
          label: 'Settlement Cycle',
          value: null,
          type: 'select',
          options: [],
          disabled: true,
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: AncillaryServicePublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: AncillaryServicePublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: AncillaryServicePublicationHandler,
          }, {
            propertyToWatch: 'ANCILLARYSERVICEsettlementCycleSelected',
            handler() {
              this.config.ANCILLARYSERVICEsettlementCycle.disabled = !this.params.ANCILLARYSERVICEsettlementCycleSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportType = 'AncillaryServiceReport';
        request.calculations = [];
        return { isValid: true };
      },
    },

    CRRREPORT: {
      label: 'CRR Report',
      endpoint: '/legacy-process/cmri-custom-legacy-report',
      permission: { name: customReports.crr_report, right: accessRight.visible },
      config: {
        CRRREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CrrReportFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CrrReportFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CrrReportFileTypeHandler,
          }, {
            propertyToWatch: 'CRRREPORTfileTypeSelected',
            handler() {
              this.config.CRRREPORTfileType.disabled = !this.params.CRRREPORTfileTypeSelected;
            },
          }],
        },
        CRRREPORTpublication: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: crrReportPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: crrReportPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: crrReportPublicationHandler,
          }, {
            propertyToWatch: 'CRRREPORTpublicationSelected',
            handler() {
              this.config.CRRREPORTpublication.disabled = !this.params.CRRREPORTpublicationSelected;
            },
          }],
        },
        CRRREPORTdateType: {
          label: 'Granularity',
          options: [
            'Daily', 'Monthly',
          ],
          value: 'Daily',
          type: 'select',
          searchable: true,
        },
      },
      beforeSubmit(request) {
        request.reportType = 'CMRI-CRR-PEAK-REPORT';
        return { isValid: true };
      },
    },

    MARKETVALUEREPORT: {
      label: 'Market Value Report',
      endpoint: '/legacy-process/samc-custom-legacy-report',
      permission: { name: customReports.marketValueReport, right: accessRight.visible },
      config: {
        MARKETVALUEREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: MarketValueReportFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: MarketValueReportFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: MarketValueReportFileTypeHandler,
          }, {
            propertyToWatch: 'MARKETVALUEREPORTfileTypeSelected',
            handler() {
              this.config.MARKETVALUEREPORTfileType.disabled = !this.params.MARKETVALUEREPORTfileTypeSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportType = 'MarketValueReport';
        return { isValid: true };
      },
    },
    OPERATIONSTATEMENTREPORT: {
      label: 'Operation Statement Report',
      endpoint: '/legacy-process/samc-custom-legacy-report',
      permission: { name: customReports.operationStatementReport, right: accessRight.visible },
      config: {
        OPERATIONSTATEMENTREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: OperationStatementReportFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: OperationStatementReportFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: OperationStatementReportFileTypeHandler,
          }, {
            propertyToWatch: 'OPERATIONSTATEMENTREPORTfileTypeSelected',
            handler() {
              this.config.OPERATIONSTATEMENTREPORTfileType.disabled = !this.params.OPERATIONSTATEMENTREPORTfileTypeSelected;
            },
          }],
        },
        OPERATIONSTATEMENTREPORTsettlementCycle: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'OPERATIONSTATEMENTREPORTfileTypeSelected',
            handler: OperationStatementPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: OperationStatementPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: OperationStatementPublicationHandler,
          }, {
            propertyToWatch: 'OPERATIONSTATEMENTREPORTsettlementCycleSelected',
            handler() {
              this.config.OPERATIONSTATEMENTREPORTsettlementCycle.disabled = !this.params.OPERATIONSTATEMENTREPORTsettlementCycleSelected;
            },
          }],
        },
        OPERATIONSTATEMENTREPORTdateType: {
          label: 'Granularity',
          options: [
            'RealTime', 'Hourly', 'Daily', 'Monthly',
          ],
          value: 'RealTime',
          type: 'select',
          searchable: true,
        },
      },
      beforeSubmit(request) {
        request.reportType = 'ProfitAndLoss';
        return { isValid: true };
      },
    },

    BIDCOSTRECOVERYREPORT: {
      label: 'Bid Cost Recovery Report',
      endpoint: '/legacy-process/samc-report',
      permission: { name: customReports.bidCostRecoveryReport, right: accessRight.visible },
      config: {
        BIDCOSTRECOVERYREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: BidCostRecoveryFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: BidCostRecoveryFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: BidCostRecoveryFileTypeHandler,
          }, {
            propertyToWatch: 'BIDCOSTRECOVERYREPORTfileTypeSelected',
            handler() {
              this.config.BIDCOSTRECOVERYREPORTfileType.disabled = !this.params.BIDCOSTRECOVERYREPORTfileTypeSelected;
            },
          }],
        },
        BIDCOSTRECOVERYREPORTdateType: {
          label: 'Interval Type',
          options: ['Daily', 'Interval'],
          value: 'Daily',
          type: 'select',
          searchable: false,
        },
      },
      beforeSubmit(request) {
        request.reportType = 'BidCostRecovery';
        return { isValid: true };
      },
    },

    ADSEXCEPTIONALDISPATCHREPORT: {
      label: 'ADS Exceptional Dispatch Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/ads-exceptional-dispatch-report',
      permission: { name: customReports.adsExceptionalDispatchReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {
          resources: request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    EIMANALYTICSDAILYREPORT: {
      label: 'EIM Analytics Daily Report',
      toolTip: 'Returns data for the last 7 days using oasis reports: PRC_INTVL_LMP, PRC_LMP, RTPD_LMP_GRP, PRC_HASP_LMP, ENE_EIM_TRANSFER_LIMITS_TIE, ENE_EIM_TRANSFER_TIE, WEIM_RSE_CAPACITY_TEST',
      api: 'REPORTING',
      endpoint: '/reports/generate/eim-analytics-daily',
      permission: { name: customReports.eimAnalyticsDaily, right: accessRight.visible },
      config: {
        EIMANALYTICSDAILYREPORTdaysBack: {
          label: 'Days Back',
          options: Array.from({ length: 7 }, (_, i) => i + 1),
          value: 1,
          type: 'select',
        },
      },
      beforeSubmit(request) {
        request.startDate = moment().utc().subtract(request.daysBack, 'days').startOf('day');
        request.endDate = moment().utc().startOf('day');
        return { isValid: true };
      },
    },
    METERINGVEEREPORT: {
      label: 'Metering VEE Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/metering-vee',
      permission: { name: customReports.meteringVEE, right: accessRight.visible },
      beforeSubmit(request) {
        return { isValid: true };
      },
      config: {},
    },
    CCTOGLREPORT: {
      label: 'CC to GL Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/cc-to-gl-report',
      permission: { name: customReports.ccToGlReport, right: accessRight.visible },
      config: {
        CCTOGLREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: CcToGlReportFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CcToGlReportFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CcToGlReportFileTypeHandler,
          }, {
            propertyToWatch: 'CCTOGLREPORTfileTypeSelected',
            handler() {
              this.config.CCTOGLREPORTfileType.disabled = !this.params.CCTOGLREPORTfileTypeSelected;
            },
          }],
        },
        CCTOGLREPORTpublication: {
          label: 'Settlement Cycle',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'CCTOGLREPORTfileTypeSelected',
            handler: CcToGlReportPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: CcToGlReportPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: CcToGlReportPublicationHandler,
          }, {
            propertyToWatch: 'CCTOGLREPORTpublicationSelected',
            handler() {
              this.config.CCTOGLREPORTpublication.disabled = !this.params.CCTOGLREPORTpublicationSelected;
            },
          }],
        },
        CCTOGLREPORTversion: {
          label: 'Version',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'CCTOGLREPORTfileTypeSelected',
            handler: CcToGlReportVersionHandler,
          }, {
            propertyToWatch: 'CCTOGLREPORTpublicationSelected',
            handler: CcToGlReportVersionHandler,
          }, {
            propertyToWatch: 'CCTOGLREPORTversionSelected',
            handler() {
              this.config.CCTOGLREPORTversion.disabled = !this.params
                .CCTOGLREPORTversionSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          version: request.version,
          publication: request.publication,
        };
        return { isValid: true };
      },
    },
    TAGSETTLEMENTQUANTITYCOMPAREREPORT: {
      label: 'Tag Settlements Quantity Compare Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/tag-settlements-quantity-compare-report',
      permission: { name: customReports.tagSettlementCompareQuantityReport, right: accessRight.visible },
      config: {
        TAGSETTLEMENTQUANTITYCOMPAREREPORTfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: TAGSETTLEMENTQUANTITYCOMPAREREPORTFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: TAGSETTLEMENTQUANTITYCOMPAREREPORTFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: TAGSETTLEMENTQUANTITYCOMPAREREPORTFileTypeHandler,
          }, {
            propertyToWatch: 'TAGSETTLEMENTQUANTITYCOMPAREREPORTfileTypeSelected',
            handler() {
              this.config.TAGSETTLEMENTQUANTITYCOMPAREREPORTfileType.disabled = !this.params.TAGSETTLEMENTQUANTITYCOMPAREREPORTfileTypeSelected;
            },
          }],
        },
        TAGSETTLEMENTQUANTITYCOMPAREREPORTsettlementCycle: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'TAGSETTLEMENTQUANTITYCOMPAREREPORTfileTypeSelected',
            handler: TAGSETTLEMENTQUANTITYCOMPAREPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: TAGSETTLEMENTQUANTITYCOMPAREPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: TAGSETTLEMENTQUANTITYCOMPAREPublicationHandler,
          }, {
            propertyToWatch: 'TAGSETTLEMENTQUANTITYCOMPAREREPORTsettlementCycleSelected',
            handler() {
              this.config.TAGSETTLEMENTQUANTITYCOMPAREREPORTsettlementCycle.disabled = !this.params.TAGSETTLEMENTQUANTITYCOMPAREREPORTsettlementCycleSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          settlementCycle: request.settlementCycle,
        };
        return { isValid: true };
      },
    },
    IIETAGSETTLEMENTSQUANTITYCOMPARE: {
      label: 'IIE Tag Settlements Quantity Compare',
      api: 'REPORTING',
      endpoint: '/reports/generate/iie-tag-settlements-compare',
      permission: { name: customReports.iieTagSettlementsQuantityCompare, right: accessRight.visible },
      config: {
        IIETAGSETTLEMENTSQUANTITYCOMPAREfileType: {
          label: 'File Type',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'samcHeadersUnfiltered',
            handler: IIETAGSETTLEMENTSQUANTITYCOMPAREFileTypeHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: IIETAGSETTLEMENTSQUANTITYCOMPAREFileTypeHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: IIETAGSETTLEMENTSQUANTITYCOMPAREFileTypeHandler,
          }, {
            propertyToWatch: 'IIETAGSETTLEMENTSQUANTITYCOMPAREfileTypeSelected',
            handler() {
              this.config.IIETAGSETTLEMENTSQUANTITYCOMPAREfileType.disabled = !this.params.IIETAGSETTLEMENTSQUANTITYCOMPAREfileTypeSelected;
            },
          }],
        },
        IIETAGSETTLEMENTSQUANTITYCOMPAREsettlementCycle: {
          label: 'Publication',
          value: null,
          type: 'select',
          disabled: true,
          options: [],
          watchers: [{
            propertyToWatch: 'IIETAGSETTLEMENTSQUANTITYCOMPAREfileTypeSelected',
            handler: IIETAGSETTLEMENTSQUANTITYCOMPAREPublicationHandler,
          }, {
            propertyToWatch: 'tradeDateRangeSelected',
            watchOptions: { deep: true },
            handler: IIETAGSETTLEMENTSQUANTITYCOMPAREPublicationHandler,
          }, {
            propertyToWatch: 'coordinatorListSelected',
            handler: IIETAGSETTLEMENTSQUANTITYCOMPAREPublicationHandler,
          }, {
            propertyToWatch: 'IIETAGSETTLEMENTSQUANTITYCOMPAREsettlementCycleSelected',
            handler() {
              this.config.IIETAGSETTLEMENTSQUANTITYCOMPAREsettlementCycle.disabled = !this.params.IIETAGSETTLEMENTSQUANTITYCOMPAREsettlementCycleSelected;
            },
          }],
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          fileType: request.fileType,
          settlementCycle: request.settlementCycle,
        };
        return { isValid: true };
      },
    },
    MARKETINVOICECUSTOM: {
      label: 'Market Invoice Custom Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/market-invoice-custom',
      permission: { name: customReports.marketInvoiceCustomReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        return { isValid: true };
      },
    },
    TRANSFERANDPRICESREPORT: {
      label: 'Transfer And Prices Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/transfer-and-prices-report',
      permission: { name: customReports.transferAndPricesReport, right: accessRight.visible },
      beforeSubmit(request) {
        return { isValid: true };
      },
      config: {},
    },
    ISOTOTALSCOSTREPORT: {
      label: 'ISO Totals Cost Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/iso-totals-cost-report',
      permission: { name: customReports.isoTotalsCostReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    MONTHLYSTATEMENTQUANTITYREPORT: {
      label: 'Monthly Statement Quantity',
      api: 'REPORTING',
      endpoint: '/reports/generate/monthly-statement-quantity-report',
      permission: { name: customReports.monthlyStatementQuantityReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportParams = {};
        return { isValid: true };
      },
    },
    WEEKLYINVOICEREPORT: {
      label: 'Weekly Invoice Report',
      endpoint: '/legacy-process/samc-report',
      permission: { name: customReports.weeklyInvoiceReport, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'WeeklyInvoice';
        request.reportParams = {};
        return { isValid: true };
      },
    },
    METERSOURCECOMPAREREPORT: {
      label: 'Meter Source Compare Report',
      api: 'REPORTING',
      endpoint: '/reports/generate/meter-source-compare-report',
      permission: { name: customReports.meterSourceCompareReport, right: accessRight.visible },
      config: {
        METERSOURCECOMPAREREPORTreportType: {
          label: 'Source System',
          type: 'tagbox',
          clearable: true,
          required: true,
          value: null,
          options: [],
          async fetchData() {
            try {
              const { data: { meterSourceSystem } } = await CISO_METER_API.get('/meter/source-systems');
              return meterSourceSystem.map(({ sourceSystem }) => ({ value: sourceSystem, label: sourceSystem }));
            } catch (error) {
              vue.$notify('Failed to load Meter System Types', 'error');
              console.log(error);
            }
            return [];
          },
        },
      },
      beforeSubmit(request) {
        request.reportParams = {
          reportType: request.reportType.join(','),
          resources: request.resources.join(','),
        };
        return { isValid: true };
      },
    },
    BAAROLLUP6045: {
      label: 'BAA Rollup 6045',
      api: 'REPORTING',
      endpoint: '/reports/generate/baa-rollup-6045-report',
      permission: { name: customReports.baaRollup6045Report, right: accessRight.visible },
      config: {},
      beforeSubmit(request) {
        request.reportType = 'BAARollup6045';
        request.reportParams = {};
        return { isValid: true };
      },
    },
  },
}];