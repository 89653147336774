import { RECS_MANAGEMENT_API, IDENTITY_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  selectedSystemNames: null,
  selectedEntityNames: [],
  systemNameOptions: ['WREGIS'],

  eligibilityData: [],
  scs: [],

  eligibilityBondTableData: [],
  eligibilityBondConfig: {
    name: 'recEligibilityBonds',
    columns: [
      {
        prop: 'systemName', label: 'System Name', sortable: true, filterable: true,
      },
      {
        prop: 'id', label: 'Bond Id', sortable: true, filterable: true,
      },
      {
        prop: 'eligibilityId', label: 'Eligibility Id', sortable: true, filterable: true,
      },
      {
        prop: 'slug', label: 'Slug', sortable: true, filterable: true,
      },
      {
        prop: 'shortName', label: 'Short Name', sortable: true, filterable: true,
      },
      {
        prop: 'longName', label: 'Long Name', sortable: true, filterable: true,
      },
      {
        prop: 'region', label: 'Region', sortable: true, filterable: true,
      },
      {
        prop: 'startDate', label: 'Start Date', sortable: true, filterable: true,
      },
      {
        prop: 'goodThruDate', label: 'Good Thru Date', sortable: true, filterable: true,
      },
      {
        prop: 'startVintage', label: 'Start Vintage', sortable: true, filterable: true,
      },
      {
        prop: 'goodThruVintage', label: 'Good Thru Vintage', sortable: true, filterable: true,
      },
      {
        prop: 'verifiedDate', label: 'Verified Date', sortable: true, filterable: true,
      },
      {
        prop: 'certificationNumber', label: 'Certification Number', sortable: true, filterable: true,
      },
      {
        prop: 'misc', label: 'Misc', sortable: true, filterable: true,
      },
      {
        prop: 'eligible', label: 'Eligible', sortable: true, filterable: true,
      },
      {
        prop: 'eligibilityBondableId', label: 'Eligibility Bondable Id', sortable: true, filterable: true,
      },
      {
        prop: 'eligibilityBondable', label: 'Eligibility Bondable', sortable: true, filterable: true,
      },
    ],
    options: {
      columnConfig: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scList: (state) => _getList(state.scs, 'name'),
};

const actions = {
  async initialize({ commit, dispatch }) {
    commit('resetTable');
    await dispatch('fetchScs');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  async loadTableData({ dispatch, commit }) {
    commit('resetTable');
    await dispatch('loadEligibilityBondData');
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'COMPANY'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async loadEligibilityBondData({ state, commit }) {
    await Promise.all(state.selectedEntityNames.map(async (entityName) => {
      const params = {
        systemName: state.selectedSystemNames,
        entityName,
      };
      await RECS_MANAGEMENT_API.get('/recs-management/eligibility/eligibilityBonds', { params }).then(({ data }) => {
        commit('addEligibilityData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Eligibility Bonds', 'error');
      });
    }));
    this.$notify(`${state.eligibilityData.length} Eligibility Bonds Loaded`, 'info');
    commit('setEligibilityBondTableData');
  },

  makeUniqueList(list) {
    const uniqueLists = Array.from(new Set(list.map(JSON.stringify))).map(JSON.parse);
    return uniqueLists;
  },
};

const mutations = {
  resetTable(state) {
    state.eligibilityBondTableData = [];
    state.eligibilityData = [];
  },
  setSelectedSystems(state, value) {
    state.selectedSystemNames = value;
  },
  setSelectedEntities(state, value) {
    state.selectedEntityNames = value;
  },
  setEligibilityBondTableData(state) {
    state.eligibilityData = actions.makeUniqueList(state.eligibilityData);
    state.eligibilityBondTableData = state.eligibilityData.map((bond) => {
      const { id, ...eligibilityProps } = bond.eligibility;
      return {
        ...bond,
        ...eligibilityProps,
        eligibilityId: bond.eligibility.id,
      };
    });
  },
  addEligibilityData(state, newEligibilityData) {
    state.eligibilityData = state.eligibilityData.concat(newEligibilityData);
  },
  ...createMutations('scs'),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};