import { STRUCTURES_API, IDENTITY_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';
import { HERows } from '@/utils/dataUtil';
import moment from 'moment';
import info from './static';

const state = {
  config: info.config,
  scs: [],
  jobs: [],
  logs: [],
  selectedScs: [],
  selectedJob: undefined,
  selectedHours: [],
  selectedDates: dateStore.getDefaultRange().map((val) => val.toDate()),
  timeZone: dateStore.getTimeZone(),
  statuses: info.statuses,
  statusTableConfig: info.statusTableConfig,
  selectedJobId: null,
};

const getters = {
  hours(state) {
    // const dstDate = dateStore.getDST(state.selectedDates[0]);
    return HERows({}, true, state.selectedDates[0]).map(({ he }) => ({ label: he, value: parseInt(he, 10) }));
  },
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchJobs');
    dispatch('fetchScs');
    dispatch('fetchLogs');
  },

  async fetchLogs({ commit }) {
    try {
      var timeZone = state.timeZone;

      const { data } = await STRUCTURES_API.get(`downloads/log?tz=${timeZone}`);
      const logs = data.data;

      logs.forEach((log) => {
        log.tradingDate = moment(log.tradingDate).utc().format('YYYY-MM-DD');
      });

      commit('setLogs', logs);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'SC'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async fetchJobs({ commit, state }) {
    try {
      const { data: { data } } = await STRUCTURES_API.get('/downloads/distinct');
      commit('setJobs', data.map(({ id, description }) => ({ label: description, value: id })));
    } catch (error) {
      console.error(error);
    }
  },
  async requeueJob({ state, dispatch }) {
    try {
      const { jobId } = state.selectedJobId;
      await STRUCTURES_API.patch(`/downloads/requeue/${jobId}`);
      this.$notify('Successfully requeued selected job', 'success');
      dispatch('fetchLogs');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to requeue job', 'error');
    }
  },
  async cancelJob({ state, dispatch }) {
    try {
      const { jobId } = state.selectedJobId;
      await STRUCTURES_API.delete(`/downloads/cancel/${jobId}`);
      this.$notify('Successfully canceled selected job', 'success');
      dispatch('fetchLogs');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to canceled job', 'error');
    }
  },
  async runJobs({ state, dispatch }) {
    try {
      const body = {
        entities: state.selectedScs.join(),
        entityType: 'SC',
        startDate: state.selectedDates[0].toLocaleDateString('en-US'),
        endDate: state.selectedDates[1].toLocaleDateString('en-US'),
        hours: state.selectedHours,
      };
      await STRUCTURES_API.put(`/downloads/${state.selectedJob}/trigger`, body);
      this.$notify('Successfully executed selected jobs', 'success');
      dispatch('fetchLogs');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to execute jobs', 'error');
    }
  },
};

const mutations = {
  ...createMutations(
    'jobs',
    'scs',
    'logs',
    'selectedScs',
    'selectedJob',
    'selectedDates',
    'selectedHours',
    'selectedJobId',
  ),
};

export default {
  namespaced: true,
  //   modules: { REFERENCE_DATA_STORE, LOOKUP_STORE },
  state,
  getters,
  actions,
  mutations,
};