import Vue from 'vue';
import moment from 'moment';
import { VA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { HEProperty } from '@/utils/dataUtil';
import { createMutations } from '@/utils/vuexHelper';
import caisoStore from '@/utils/caiso/caisoUtils';
import VABaseStore from '../VABaseStore';

const roundValue = (value) => Math.round(value * 100) / 100;

const FIRED_BUSINESS_RULES_CHART_ID = -1;

const state = {
  dailySummaryValues: {},
  seriesConstantLines: {},
  seriesConstantLinesCache: {},
  rulesConfig: {
    columns: [{
      label: 'RULE',
      prop: 'displayName',
      sortable: true,
      width: '70%',
      alignment: 'left',
      filterable: true,
      searchable: true,
      sortOrder: 'asc',
    }, {
      label: 'COUNT',
      prop: 'count',
      sortable: true,
      width: '15%',
      maxWidth: 100,
      filterable: true,
      searchable: true,
    }, {
      label: 'NOW COUNT',
      prop: 'nowCount',
      sortable: true,
      width: '15%',
      maxWidth: 100,
      filterable: true,
      searchable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  selectedLocation: null,
  locationsConfig: {
    columns: [{
      label: 'Company',
      prop: 'entityName',
      sortable: true,
      filterable: true,
      width: '25%',
      alignment: 'left',

    }, {
      label: 'Location',
      prop: 'shortName',
      sortOrder: 'asc',
      sortable: true,
      filterable: true,
      width: '45%',
      alignment: 'left',

    }, {
      cellTemplate: 'PscsOpenMasterDetailCellTemplate',
      editorOptions: {
        masterDetailTemplate: 'PscsMasterDetailListTableTemplate',
        multipleSelect: true,
        key: 'locationSubtypes',
        config: {
          columns: [{
            prop: 'id', label: 'ID', width: '25%',
          }, {
            prop: 'shortName', label: 'Name',
          }],
        },
      },
      label: 'Subtypes',
      prop: 'locationSubtypes',
      sortable: true,
      width: '10%',
      calculateCellValue: (val) => val?.locationSubtypes?.length,
    }, {
      label: 'COUNT',
      prop: 'count',
      sortable: true,
      width: '10%',
    }, {
      label: 'NOW COUNT',
      prop: 'nowCount',
      sortable: true,
      width: '10%',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  selectedGroup: 'ALL',
  chartData: [],
  gridChartData: [],
  dynamicPoints: [],
  isCurrentlyFetchingData: false,
  selectedDate: dateStore.getDefaultDate().toISOString(),
  selectedDynamicDataPoint: null,
  selectedGridChart: null,
  selectedRule: null,
  selectedSubtype: null,
  showRuleInChart: false,
};

const getters = {
  layoutFlashingRules: (state, getters) => getters.chartSeries.filter(
    ({ seriesName, seriesDescription, ruleFlashFlag }) => `${seriesName} ${seriesDescription}`.toLowerCase().includes('rule') && ruleFlashFlag,
  ),
  constantLines: (state) => Object.values(state.seriesConstantLines).flat().filter(Boolean),
  heArray: (state) => state.chartData.length ? state.chartData.map(({ hourTime }) => hourTime) : [],
  isRealtime: (state) => moment(state.selectedDate).isSame(moment(), 'day'),
  isFutureDate: (state) => moment(state.selectedDate).isAfter(moment(), 'day'),
  selectedLocationObject: (state) => state.VABaseStore.locations.find((l) => l.shortName === state.selectedLocation),
  exportData: (state, getters) => {
    const location = getters.selectedLocationObject;
    const { chartData } = state;
    const chartSeries = getters.chartSeriesById;
    const columns = [{
      header: 'COMPANY', key: 'company', width: 12,
    }, {
      header: 'LOCATION', key: 'location', width: 12,
    }, {
      header: 'DATE', key: 'tradingDate', width: 12,
    }, {
      header: 'HOUR', key: 'tradingHour', width: 12,
    }, {
      header: 'INTERVAL', key: 'tradingInterval', width: 12,
    }];
    const chartSeriesKeys = Object.keys(chartSeries).filter((key) => {
      const isRule = chartSeries[key].name.toLowerCase().includes('rule');
      const isRuleCondition = chartSeries[key].name.toLowerCase().includes('condition');
      return isRuleCondition || !isRule;
    });

    // -------------------------------- sort chartSeriesKey to match Excel with chartsTree displayed on interface
    const chartsTreeOrder = [];
    for (let i = 0; i < VABaseStore.state.chartsTree.length; i++) {
      for (let j = 0; j < VABaseStore.state.chartsTree[i].items.length; j++) {
        chartsTreeOrder.push(VABaseStore.state.chartsTree[i].items[j].text);
      }
    }
    chartSeriesKeys.sort((a, b) => (chartsTreeOrder.indexOf(chartSeries[a].name) - chartsTreeOrder.indexOf(chartSeries[b].name)));
    //-----------------------------------------------------------------------------------------------------------

    columns.push(...chartSeriesKeys.map((key) => ({ header: chartSeries[key].name, key, width: 12 })));
    if (state.selectedRule) {
      const { description, ruleSeriesId } = state.selectedRule;
      columns.push({ header: description, key: ruleSeriesId, width: 12 });
    }
    const rows = [];
    chartData.forEach((data) => {
      const item = {
        company: null,
        location: location.shortName,
        tradingDate: data.date,
        tradingHour: data.he,
        tradingInterval: data.ie,
      };
      chartSeriesKeys.forEach((key) => { item[key] = data[chartSeries[key].seriesName]; });
      if (state.selectedRule) {
        const { ruleSeriesId } = state.selectedRule;
        // seriesConstantLinesCache is an object whose property will be an array of strings
        // each string is a datetime in UTC
        const ruleIntervalData = state.seriesConstantLinesCache[ruleSeriesId];
        if (Array.isArray(ruleIntervalData)) {
          // use the data.utcStartTime property to determine if rule has been triggered
          item[ruleSeriesId] = (ruleIntervalData.includes(data.utcStartTime)) ? 1 : 0;
        }
      }
      rows.push(item);
    });
    return { location, columns, rows };
  },
  gridChartList: (state, getters) => {
    const girdChartListsWithBusinessRuleAdded = [...getters.charts];
    girdChartListsWithBusinessRuleAdded.push({ id: FIRED_BUSINESS_RULES_CHART_ID, name: 'Fired Business Rules' });
    const list = girdChartListsWithBusinessRuleAdded.reduce((acc, { id, name }) => {
      if (state.selectedGridChart && state.selectedGridChart.id === id) {
        acc.push({ id, name: `Hide ${name}` });
      } else {
        acc.push({ id, name: `Show ${name}` });
      }
      return acc;
    }, []);
    return list;
  },
  hoursInSelectedDay: (state) => dateStore.getHours(state.selectedDate),
  gridChartTableConfig: (state, getters) => {
    const hoursInADay = getters.hoursInSelectedDay.sort((a, b) => a - b);
    const config = {
      columns: [{ label: 'NAME', prop: 'name', width: '140px' }],
    };
    hoursInADay.forEach((h) => {
      config.columns.push({
        label: HEProperty(Number(h)).toUpperCase(),
        prop: `he${h}`,
        format: {
          type: 'fixedPoint',
          precision: state.selectedGridChart?.id === FIRED_BUSINESS_RULES_CHART_ID ? 0 : 2,
        },
      });
    });
    config.columns.push({
      label: 'TOTAL',
      prop: 'total',
      precision: state.selectedGridChart?.id === FIRED_BUSINESS_RULES_CHART_ID ? 0 : 2,
      width: '100px',
    });

    return config;
  },
  selectedGridChart: (state) => state.selectedGridChart,
};

const actions = {
  async initialize({ commit, dispatch, state }, hasRedirectQuery = false) {
    if (!hasRedirectQuery) commit('setSelectedDate', dateStore.getDefaultDate().toISOString());
    commit('resetLayout');
    commit('setSelectedGroup', caisoStore.resourceGroupName);
    await dispatch('fetchScreenConfig', 'daily_analysis');
    commit('applyScreenConfig');
    await dispatch('fetchBusinessRules');
    await dispatch('fetchLocations', state.selectedDate);
    await dispatch('fetchLayouts');
  },
  async fetchGridChartData({ state, getters }) {
    const layout = getters.selectedLayout;
    const { chartData } = state;
    let rows = [];
    if (state.selectedGridChart && layout) {
      const obj = getters.hoursInSelectedDay.sort((a, b) => a - b)
        .reduce((acc, i) => { acc[`he${i}`] = null; return acc; }, {});
      if (state.selectedGridChart.id === FIRED_BUSINESS_RULES_CHART_ID) {
        try {
          if (state.selectedLocation) {
            const params = {
              startTime: moment(state.selectedDate).toISOString(),
              endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
              timeZone: dateStore.getTimeZone(),
              variants: getters.selectedVariant,
              objectReferences: state.VABaseStore.rules.map((e) => e.ruleSeriesId).toString(),
              objectTypes: 'series',
              types: state.selectedLocation,
              realTimeFlag: getters.isRealtime,
            };
            if (state.selectedSubtype?.locationId === getters.selectedLocationObject.id) {
              params.subTypes = state.selectedSubtype?.shortName;
            }

            const { data: { data } } = await VA_API.post('intervals/data', params);
            const ruleNames = state.VABaseStore.rules.map((r) => r.name);
            if (Array.isArray(data)) {
              rows = state.VABaseStore.rules.map((e) => ({
                name: e.description, ruleName: e.name, hourlyGridTotalOP: 'SUM', fixedValue: 0, ...obj,
              }));
              ruleNames.forEach((ruleName) => {
                const associatedRuleRow = rows.find((r) => r.ruleName === ruleName);
                data.forEach((d) => {
                  const element = d[ruleName];
                  if (element?.value === 1) {
                    associatedRuleRow[`he${moment(d.startTime).hours() + 1}`] += 1;
                  }
                });
              });
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        const { chartSeries } = layout.charts.find((c) => c.id === state.selectedGridChart.id);
        if (chartSeries && chartSeries.length > 0) {
          rows = chartSeries
            .filter(({ name }) => !name.toLowerCase().includes('rule'))
            .map(({ name, seriesName, hourlyGridTotalOP }) => ({
              name, seriesName, hourlyGridTotalOP, ...obj,
            }));
          chartData.forEach((d) => {
            const he = `he${d.he}`;
            rows.forEach((r) => {
              if (d[r.seriesName]) r[he] += d[r.seriesName] / 12;
            });
          });
        }
      }
    }
    // Calculate Totals
    rows.forEach((row) => {
      let finalValue = 0;
      let nonNullNumbers = 0;
      const totalOP = row.hourlyGridTotalOP;
      const array = Object.keys(row).map((k) => row[k]).filter((he) => !!he && Number.isFinite(he));
      switch (totalOP) {
      case 'AVERAGE':
        array
          .forEach((val) => { nonNullNumbers++; finalValue += val; });
        if (nonNullNumbers > 0) finalValue /= nonNullNumbers;
        break;
      case 'MAX':
        [finalValue] = array.sort((a, b) => (b - a));
        break;
      case 'MIN':
        [finalValue] = array.sort((a, b) => (a - b));
        break;
      case 'SUM':
        array
          .forEach((val) => { finalValue += val ?? 0; });
        break;
      case null:
      case 'NONE':
      default:
        finalValue = null;
        break;
      }
      row.total = Number.isFinite(finalValue) ? finalValue.toFixed(row.fixedValue ?? 2) : null;
    });

    state.gridChartData = rows;
  },
  checkDynamicDataPointsConfig({ state, getters, commit }) {
    const chartSeries = getters?.selectedLayout?.ruleLocationChartSeries;
    commit('resetDynamicPoints');
    commit('setDynamicDataPointsConfig', chartSeries);
  },
  async fetchBusinessRules({ commit, dispatch, getters }) {
    try {
      const params = {
        startTime: moment(state.selectedDate).toISOString(),
        endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
        variants: getters.selectedVariant,
        realTimeFlag: getters.isRealtime,
      };
      const { data: { data } } = await VA_API.get('business-rules/rule-count', { params });
      const rules = Array.isArray(data) ? data : [];
      commit('setRules', rules);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchIntervals({
    commit, dispatch, getters, state,
  }) {
    try {
      if (getters.selectedLayout?.charts && !state.isCurrentlyFetchingData) {
        commit('setIsCurrentlyFetchingData', true);
        const seriesItems = getters.selectedLayout.charts.map(({ chartSeries }) => chartSeries).flat();

        if (state.selectedLocation) {
          commit('resetChart');
          const params = {
            startTime: moment(state.selectedDate).toISOString(),
            endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
            timeZone: dateStore.getTimeZone(),
            variants: getters.selectedVariant,
            objectReferences: seriesItems.map(({ seriesId }) => seriesId).join(','),
            objectTypes: 'series',
            types: state.selectedLocation,
            realTimeFlag: getters.isRealtime,
          };
          if (state.selectedSubtype?.locationId === getters.selectedLocationObject.id) {
            params.subTypes = state.selectedSubtype?.shortName;
          }

          const { data: { data } } = await VA_API.post('intervals/data', params);
          const metaData = [...seriesItems].reduce((acc, meta) => {
            acc[meta.seriesName] = meta;
            return acc;
          }, {});
          dispatch('formatChartData', { metaData, data: data ?? [] });
          commit('setIsCurrentlyFetchingData', false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit('setIsCurrentlyFetchingData', false);
    }
  },
  formatChartData({ state, commit }, value) {
    const metaKeys = Object.keys(value.metaData);
    const mappings = {};
    metaKeys.forEach((key) => {
      mappings[value.metaData[key].name] = { name: key, fillColor: value.metaData[key].fillColor };
    });

    value.data.sort((a, b) => moment(a.tzStartTime).isBefore(b.tzStartTime) ? -1 : 1);
    // chartData intervals instantiated with null series data for dxChart to correctly display missing data
    const intervalMap = dateStore.getTimeRangeByMinutes(state.selectedDate, 5)
      .reduce((acc, curr) => {
        const startTime = `${curr.time.toISOString().split('.')[0] }Z`;
        const endTime = `${curr.time.clone().add('5', 'minutes').toISOString().split('.')[0] }Z`;
        const he = curr.time.hours() + 1;
        const ie = curr.time.minutes() + 5;
        const settlementTime = `${(`0${he}`).slice(-2)}:${(`0${ie}`).slice(-2)}`;
        acc[startTime] = {
          location: state.selectedLocation,
          startTime,
          endTime,
          hourTime: curr.time.format('HH:mm'),
          settlementTime,
          he,
          ie,
          date: curr.time.format('L'),
          time: startTime,
          momentTime: curr.time.clone(),
          utcStartTime: startTime,
          utcEndTime: endTime,
        };
        metaKeys.forEach((name) => { acc[startTime][value.metaData[name].seriesName] = null; });
        return acc;
      }, {});
    const dailyAggSeries = new Set();
    let previousData = null;
    value.data.forEach((d) => {
      // Charts series with metadata
      const mdt = dateStore.toMoment(d.startTime);
      const interval = {
        location: d.type,
        startTime: d.tzStartTime,
        endTime: d.tzEndTime,
        hourTime: mdt.clone().format('HH:mm'),
        settlementTime: `${(`0${d.he}`).slice(-2)}:${(`0${d.ie}`).slice(-2)}`,
        he: d.he,
        ie: d.ie,
        date: mdt.clone().format('L'),
        time: d.startTime,
        momentTime: mdt.clone(),
        utcStartTime: d.startTime, // keep UTC times as they should be the primary means of matching data across time.
        utcEndTime: d.endTime,
      };

      metaKeys.forEach((name) => {
        const { multiplier = 1, seriesName } = value.metaData[name];
        const dailyAggregation = !!value.metaData[name].dailyAggregation;
        const seriesPoint = dailyAggregation ? d[seriesName.replace(VABaseStore.DAILY_AGGREGATION_SUFFIX, '')] : d[seriesName];
        if (seriesPoint) {
          if (dailyAggregation) {
            dailyAggSeries.add(name);
            interval[name] = previousData ? (multiplier * seriesPoint.value) + intervalMap[previousData.startTime][name] : multiplier * seriesPoint.value;
          } else if (typeof seriesPoint === 'object') {
            const multipliedValue = multiplier !== 1 ? multiplier * seriesPoint.value : seriesPoint.value;
            interval[name] = multipliedValue;

            // Checks if it has notes and add to data
            if (seriesPoint.notes) interval[`${seriesName}/notes`] = seriesPoint.notes;
          } else {
            interval[name] = seriesPoint;
          }
        } else {
          interval[name] = null;
        }
      });
      interval.mappings = mappings;
      intervalMap[d.startTime] = interval;
      previousData = d;
    });

    dailyAggSeries.forEach((name) => {
      const nameWithoutDAILYAGG = name.replace(VABaseStore.DAILY_AGGREGATION_SUFFIX, '');
      // eslint-disable-next-line no-undef
      const sortedData = _.sortBy(value.data.filter((data) => !!data[nameWithoutDAILYAGG]), 'startTime');
      const lastDataPointKeyInIntervalMap = sortedData[sortedData.length - 1].startTime;
      const seriesDailyAggValue = intervalMap[lastDataPointKeyInIntervalMap][name];
      sortedData.forEach((interval) => {
        intervalMap[interval.startTime][name] = seriesDailyAggValue;
      });
    });
    commit('setChartData', Object.values(intervalMap));
  },
  async fetchIntervalCount({ state, dispatch, getters }) {
    const locations = state.VABaseStore.locations
      .reduce((accumulator, currentLocation) => accumulator === ''
        ? currentLocation.shortName
        : `${accumulator},${currentLocation.shortName}`,
      '');

    try {
      const params = {
        startTime: moment(state.selectedDate).toISOString(),
        endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
        timeZone: dateStore.getTimeZone(),
        variants: getters.selectedVariant,
        objectReferences: Object.keys(state.VABaseStore.screenConfig.intervalCount
          ? state.VABaseStore.screenConfig.intervalCount : {},
        ).reduce((acc, seriesId) => acc === ''
          ? `${seriesId}`
          : `${acc},${seriesId}`,
        ''),
        objectTypes: 'series',
        types: locations,
        realTimeFlag: getters.isRealtime,
      };
      const { data } = await VA_API.post('/intervals/interval-count', params);
      dispatch('setIntervalCount', data.data);
    } catch (e) {
      console.error(e);
    }
  },
  async fetchRuleIntervalData({ state, commit, getters }) {
    if (!state.seriesConstantLinesCache[state.selectedRule.ruleSeriesId]) {
      try {
        if (state.selectedLocation) {
          const params = {
            startTime: moment(state.selectedDate).toISOString(),
            endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
            timeZone: dateStore.getTimeZone(),
            variants: getters.selectedVariant,
            objectReferences: state.selectedRule.ruleSeriesId,
            objectTypes: 'series',
            types: state.selectedLocation,
            realTimeFlag: getters.isRealtime,
          };

          if (state.selectedSubtype?.locationId === getters.selectedLocationObject.id) {
            params.subTypes = state.selectedSubtype?.shortName;
          }

          const { data: { data } } = await VA_API.post('intervals/data', params);
          if (Array.isArray(data)) {
            const rule = state.selectedRule.name;
            // filters out data where the rule's value is 1
            // and returns an array of time strings that are in UTC
            const cacheData = data
              .filter((d) => d[rule] && d[rule].value === 1)
              .map(({ startTime }) => startTime);
            Vue.set(state.seriesConstantLinesCache, state.selectedRule.ruleSeriesId, cacheData);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  async fetchRuleData({
    commit, state, dispatch, getters,
  }, rule) {
    try {
      commit('setSelectedRule', rule);
      const params = {
        startTime: moment(state.selectedDate).toISOString(),
        endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
        businessRuleId: rule.id,
        variants: getters.selectedVariant,
        realTimeFlag: getters.isRealtime,
      };
      const { data: { data } } = await VA_API.get('/business-rules/rule-location-count', { params });
      dispatch('setLocationsCount', data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchDynamicData({
    commit, dispatch, getters, state,
  }, locations = undefined) {
    if (!state.selectedDate || !getters.locations.length || !state.selectedDynamicDataPoint) return;
    commit('setIsCurrentlyFetchingData', true);

    const { seriesId } = state.locationsConfig.columns
      .find(({ label }) => label === state.selectedDynamicDataPoint);

    try {
      const params = {
        startTime: moment(state.selectedDate).toISOString(),
        endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
        timeZone: dateStore.getTimeZone(),
        variants: getters.selectedVariant,
        objectReferences: seriesId,
        objectTypes: 'series',
        types: getters.locations.map(({ shortName }) => shortName).join(','),
        realTimeFlag: getters.isRealTime,
      };
      const { data: { data } } = await VA_API.post('intervals/data', params);
      if (state.selectedDynamicDataPoint) commit('setDynamicData', data);
      commit('setIsCurrentlyFetchingData', false);
    } catch (error) {
      console.error(error);
    }
  },
  setLocationsCount({ state, commit }, counts) {
    const { locations } = state.VABaseStore;
    locations.forEach((location) => {
      Vue.set(location, 'count', 0);
      Vue.set(location, 'nowCount', 0);
    });
    counts.forEach(({ count, nowCount, name }) => {
      const location = locations.find(({ shortName }) => shortName === name);
      if (location) {
        location.count = count;
        location.nowCount = nowCount;
      }
    });

    commit('setLocations', locations);
  },
  setIntervalCount({ state, commit }, intervalCountData) {
    const { locations } = state.VABaseStore;
    const intervalCountConfig = state.VABaseStore?.screenConfig?.intervalCount;
    // initialize all series conts to zero
    Object.entries(intervalCountConfig).forEach(([seriesId, { prop }]) => {
      locations.forEach((location) => {
        location[prop] = 0;
      });
    });
    // fill in the data where applicable
    if (intervalCountData && intervalCountData.length > 0) {
      intervalCountData.forEach((intervalCount) => {
        const specificSeriesCountConfig = intervalCountConfig[intervalCount.seriesId];
        if (specificSeriesCountConfig) {
          const location = locations.find(({ shortName }) => shortName === intervalCount.type);
          if (location) {
            location[specificSeriesCountConfig.prop] = intervalCount[specificSeriesCountConfig.dataProp];
          }
        }
      });
    }
    commit('setLocations', locations);
  },
  reset({ commit }) {
    commit('resetLayout');
    commit('resetDynamicPoints');
    commit('reset');
  },
};

const mutations = {
  resetChart(state) {
    state.seriesConstantLines = {};
    state.seriesConstantLinesCache = {};
  },
  resetDynamicPoints(state) {
    state.locationsConfig.columns.forEach((col) => {
      if (col.dynamic) col.visible = false;
    });
    state.dynamicPoints = [];
    state.selectedDynamicDataPoint = null;
  },
  reset(state) {
    state.selectedRule = null;
    state.selectedLocation = null;
    state.showRuleInChart = false;
  },
  setDynamicData(state, data) {
    const { locations } = state.VABaseStore;
    state.locationsConfig.columns.forEach((col) => { if (col.dynamic) col.visible = false; });
    const selectedPointCol = state.locationsConfig.columns.find(({ label }) => label === state.selectedDynamicDataPoint);
    if (selectedPointCol) {
      const dataPoint = selectedPointCol.prop;
      if (selectedPointCol) selectedPointCol.visible = true;

      // Zeros out the dynamic data
      locations.forEach((location) => {
        Vue.set(location, dataPoint, 0);
      });

      // Sets the data points
      const sums = {};
      const avgs = {};

      let aggType;
      data.forEach((cs, index) => {
        if (cs[dataPoint]) {
          if (!aggType) aggType = state.VABaseStore.selectedLayout?.grids[0]?.columns.find((dp) => dp.chartSeriesName === state.selectedDynamicDataPoint)?.aggregateType;

          if (aggType === 'SUM') {
            if (!sums[cs.type]) sums[cs.type] = 0;
            sums[cs.type] += cs[dataPoint].value;
          } else if (aggType === 'AVERAGE') {
            if (!avgs[cs.type]) avgs[cs.type] = [];
            avgs[cs.type].push(cs.type.value);
          }
        }
      });
      if (Object.keys(sums).length) {
        Object.keys(sums).forEach((locationName) => {
          const location = locations.find(({ shortName }) => shortName === locationName);
          if (sums[locationName] > 0) {
            Vue.set(location, dataPoint, roundValue(sums[locationName]));
            sums[locationName] = 0;
          }
        });
      }
      if (Object.keys(avgs).length) {
        Object.keys(avgs).forEach((locationName) => {
          const location = locations.find(({ shortName }) => shortName === locationName);
          const avg = avgs[locationName].reduce((a, b) => a + b, 0) / avgs[locationName].length;
          Vue.set(location, dataPoint, roundValue(avg));
        });
      }
    }
  },
  setDynamicDataPointsConfig(state, chartSeries) {
    if (chartSeries?.length) {
      chartSeries.forEach((cs) => {
        state.dynamicPoints.push(cs.seriesName);
        const col = state.locationsConfig.columns.find(({ prop }) => prop === cs.seriesName);

        // DX DataGrid recognizes currency and not numeric
        let format;
        if (cs.format === 'currency') format = 'currency';
        const csCol = {
          seriesId: cs.seriesId,
          aggType: cs.aggregateType,
          prop: cs.seriesName,
          label: cs.chartSeriesName,
          format,
          width: 100,
          visible: false,
          sortable: true,
          filterable: true,
          dynamic: true,
        };
        if (col) {
          Object.assign(col, csCol);
        } else {
          state.locationsConfig.columns.push(csCol);
        }
      });
    }
  },
  applyScreenConfig(state) {
    const { screenConfig } = state.VABaseStore;
    // show fuelType config
    if (screenConfig.showFuelType
      && !state.locationsConfig.columns.find((column) => column.label === 'Fuel Type')) {
      // if we have the fueltType config
      // and our locations table doesn't already have a fueltype column
      // add it
      state.locationsConfig.columns.push({
        label: 'Fuel Type',
        width: '10%',
        sortable: true,
        calculateCellValue: (val) => val?.parameters?.find((param) => param.name === 'FUEL_SOURCE')?.value,
      });
    }
    const intervalCountArr = Object.entries(screenConfig.intervalCount ? screenConfig.intervalCount : {});
    intervalCountArr.forEach(([seriesId, { columnName, prop }]) => {
      // for each column in our interval count config
      if (!state.locationsConfig.columns.find(({ label }) => label === columnName)) {
        // if our resources table does not have the column with the specified name
        // add the column
        state.locationsConfig.columns.push({
          label: columnName,
          prop,
          width: '10%',
          sortable: true,
        });
      }
    });
  },
  toggleConstantLines(state) {
    const rule = state.selectedRule || ({});
    const { ruleSeriesId } = rule;

    Vue.set(state, 'seriesConstantLines', {});
    if (state.showRuleInChart) {
      Vue.set(state.seriesConstantLines, ruleSeriesId, state.seriesConstantLinesCache[ruleSeriesId]);
    }
  },
  setSelectedLocation(state, value) {
    // value is null --> check if currently selected location is still in location list after a new fetch
    // if no longer in location list --> selectedLocation will be set to the first location in new location list
    if (!value) {
      const locations = state.VABaseStore.locations.map(({ shortName }) => shortName);
      if (state.selectedLocation && !locations.includes(state.selectedLocation)) {
        [state.selectedLocation] = locations;
      }
      return;
    }
    // If the value being passed in is a Location object, just take the shortname
    if (typeof value === 'object') value = value.shortName;

    const locations = state.VABaseStore.locations.map(({ shortName }) => shortName);
    if (locations.includes(value)) {
      state.selectedLocation = value;
    } else {
      [state.selectedLocation] = locations;
    }
  },
  setSelectedGridChart(state, value) {
    if (state.selectedGridChart?.id === value.id) {
      state.selectedGridChart = null;
    } else {
      state.selectedGridChart = value;
    }
  },
  ...createMutations(
    'chartData',
    'isCurrentlyFetchingData',
    'selectedDate',
    'selectedDynamicDataPoint',
    'selectedRule',
    'selectedSubtype',
    'selectedGroup',
    'showRuleInChart',
  ),
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};
