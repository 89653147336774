import {
  CISO_BID_SCHD_API, CISO_MASTERDATA_API, STRUCTURES_API, BIDDING_API,
} from '@/api';
import Vue from 'vue';
import moment from 'moment';
import utils from '@/utils';
import {
  cloneDeep, HERows,
} from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { hasPermission } from '@/utils/authUtils';
import {
  mapLabelValue, computeSelfScheduleTotal, setScheduleDefaults, filterScheduleData,
  selfScheduleTotals, deconstructScheduleName,
} from '@/components/Scheduling/utils';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import bidErrorStore from './BidErrorStore';
import dailyComponentStore from './DailyComponentStore';
import {
  generateDefault, generateDefaultCurves, generateDefaultDailyBids, generateDefaultHourlyBids,
} from './bidDetailUtils';

const {
  setAllUneditable, findByStartTime, mergeByPropList,
} = utils.data;

const emptySchedule = (state) => ({
  sc: state.sc,
  resource: state.locationName,
  resourceType: state.locationType,
  marketType: state.marketType,
  startTime: state.startTime,
  endTime: state.endTime,
  daSelfSchedules: [],
  selfSchedules: [],
  bidSchedules: [],
  parameters: [],
  hourlyParameters: [],
  rampRates: [],
  startupCostCurve: [],
  startupTimeCurve: [],
  unitSchedules: [],
  transitions: [],
  validations: [],
  configuration: state.configuration,
});

const customCellClassRules = {
  compared(params) {
    if (typeof params.value === 'string') { return params.value.includes(':'); }
    return false;
  },
};

const getSchedule = async (scheduleName, executionId) => {
  let actual = null;
  let scheduleStatus = null;

  if (executionId) {
    try {
      const { data } = await BIDDING_API.get(`executions/${executionId}`);
      if (data?.output) {
        actual = data?.output;
        scheduleStatus = actual.status;
      }
    } catch (error) {
      console.error(`Error Fetching Execution ${executionId}`, error);
    }
  } else {
    try {
      const bidVariant = await CISO_BID_SCHD_API.get(`/${scheduleName}`, { params: { variant: 'ACTUAL' } });
      actual = bidVariant.data;
      scheduleStatus = actual.status;
    } catch (ex) {
      console.error(`Unable to retrieve schedule. ${ex}`);
    }
  }
  return { actual, scheduleStatus };
};

const state = {
  executionId: null,
  bidError: bidErrorStore.state,
  dailyComponent: dailyComponentStore.state,
  scheduleName: '',
  marketType: '',
  locationType: null,
  scheduleStatus: null,
  startTime: '',
  endTime: '',
  sc: '',
  date: '',
  locationName: null,
  location: null,
  scheduleType: true,
  bidsVariantSchedule: null,
  configuration: '',
  pMin: 0,
  pMax: 0,
  mode: null,
  configurations: [],
  transitions: [],
  daSelfSchedules: [],
  selfSchedules: [],
  extraSelfScheduleTypes: [],
  bidSchedules: [],
  cleanBidSchedules: [],
  revertBidSchedules: [],
  nullTransitionRow: {
    rowIndex: null,
    startTime: null,
    endTime: null,
    fromConfiguration: null,
    toConfiguration: null,
    notificationTime: null,
    transitionCost: null,
    transitionRampTime: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  transitionMatrixVisibilityFlag: false,
  compareFlag: false,
  excludeFromSelfScheduleTotalSum: [
    'he', 'tot', 'startTime', 'endTime', 'url', 'id', 'configuration', 'ru', 'rd', 'sr', 'nr',
  ],
  selfScheduleTableConfig: { columns: [] },
  bidCurveTableConfig: {
    columns: [{
      prop: 'he', editable: false, fixed: 'left', level: '1', minWidth: 60, width: 60,
    }, {
      prop: 'configuration', editable: false, level: '1', fixed: 'left', width: 146,
    }, {
      prop: 'da_ss', minWidth: 100, editable: false, fixed: 'left', level: '2', width: 60, label: 'da', visible: false,
    }, {
      prop: 'ss', minWidth: 100, editable: false, fixed: 'left', level: '2', width: 60,
    }, { label: 'energy', level: '3', isParent: true }, {
      prop: 'en_qty_01', label: 'qty_01', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_01', label: 'prc_01', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_02', label: 'qty_02', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_02', label: 'prc_02', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_03', label: 'qty_03', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_03', label: 'prc_03', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_04', label: 'qty_04', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_04', label: 'prc_04', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_05', label: 'qty_05', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_05', label: 'prc_05', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_06', label: 'qty_06', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_06', label: 'prc_06', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_07', label: 'qty_07', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_07', label: 'prc_07', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_08', label: 'qty_08', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_08', label: 'prc_08', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_09', label: 'qty_09', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_09', label: 'prc_09', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_10', label: 'qty_10', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_10', label: 'prc_10', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_11', label: 'qty_11', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_11', label: 'prc_11', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'ancillary_service', level: '4', isParent: true, visible: false,
    }, {
      prop: 'ru_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'ru_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rd_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rd_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'sr_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'sr_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'nr_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'nr_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rmu_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rmu_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rmd_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rmd_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }],
    options: { trackCompare: true, warningHighlight: true, summaryRows: true },
    customCellClassRules,
  },
  transitionMatrixTableConfig: {
    columns: [],
    columnList: (name) => (name === 'configurations') ? this.configurations : null,
  },
  bidOptions: {
    bidOptionType:
      [{ value: 'Item15MIN', label: '15MIN' },
        { value: 'DYNAMIC', label: 'DYNAMIC' },
        { value: 'HOURLY', label: 'HOURLY' },
        { value: 'ONCE', label: 'ONCE' }],
  },
  capacityTypes: {
    capacityLimitType:
      [{ value: 'GEN_CAPACITY_LIMIT', label: 'GEN' },
        { value: 'LOAD_CAPACITY_LIMIT', label: 'LOAD' }],
  },
  yesNo: {
    offGridCharge:
      [{ value: true, label: 'YES' },
        { value: false, label: 'NO' }],
    contingencyAvailabilityFlag:
      [{ value: true, label: 'YES' },
        { value: false, label: 'NO' }],
  },
};

const getters = {
  getAllRampCurveData: (state) => dailyComponentStore.getters.getAllRampCurveData(state.dailyComponent),
  getAllStartupCostData: (state) => dailyComponentStore.getters.getAllStartupCostData(state.dailyComponent),
  getAllStartupTimeData: (state) => dailyComponentStore.getters.getAllStartupTimeData(state.dailyComponent),
  getSelectedDailyBidRow: (state) => dailyComponentStore.getters.getSelectedDailyBidRow(state.dailyComponent),
  hasWritePermissionFlag(state, getters, rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
  },
  // DailyComponent Getters
  getDailyBidComponentTableConfig(state, getters, rootState, rootGetters) {
    const config = dailyComponentStore.getters.getDailyBidComponentTableConfig(state.dailyComponent);

    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.scheduleType && state.executionId === null) { return config; }
    return setAllUneditable(config);
  },
  getHourlyBidComponentTableConfig(state, getters, rootState, rootGetters) {
    const config = dailyComponentStore.getters.getHourlyBidComponentTableConfig(state.dailyComponent);

    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.scheduleType && state.executionId === null) { return config; }
    return setAllUneditable(config);
  },
  getHourlyBidComponentData(state) {
    let tableData = [];
    if (state.scheduleType && state.bidsVariantSchedule) {
      const config = state.location.locationSubtypes.find((x) => x.id === state.configuration);
      tableData = dailyComponentStore.getters.getHourlyBidComponentData(state.dailyComponent);
      if (config?.shortName) {
        tableData = tableData?.filter(({ configuration }) => configuration === config?.shortName);
      }
    }
    return tableData;
  },
  getDailyBidComponentData(state) {
    let tableData = [];
    if (state.scheduleType && state.bidsVariantSchedule) {
      const config = state.location.locationSubtypes.find((x) => x.id === state.configuration);
      tableData = dailyComponentStore.getters.getDailyBidComponentData(state.dailyComponent);
      if (config?.shortName) {
        tableData = tableData?.filter(({ configuration }) => configuration === config?.shortName);
      }
    }
    return tableData;
  },
  getRampCurveTableConfig(state, getters, rootState, rootGetters) {
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.executionId === null) { return dailyComponentStore.getters.getRampCurveTableConfig(state.dailyComponent); }
    return setAllUneditable(dailyComponentStore.getters.getRampCurveTableConfig(state.dailyComponent));
  },
  getRampCurveData(state) {
    let tableData = [];
    if (state.scheduleType && state.bidsVariantSchedule) {
      tableData = dailyComponentStore.getters.getRampCurveData(state.dailyComponent);
    }
    return tableData;
  },
  getTransitionsData(state) {
    let tableData = [];
    if (state.scheduleType && state.bidsVariantSchedule) {
      tableData = dailyComponentStore.getters.getTransitionsData(state.dailyComponent);
    }
    return tableData;
  },
  getTransitionsTableConfig(state, getters, rootState, rootGetters) {
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.executionId === null) { return dailyComponentStore.getters.getTransitionsTableConfig(state.dailyComponent); }
    return setAllUneditable(dailyComponentStore.getters.getTransitionsTableConfig(state.dailyComponent));
  },
  getStartupCostTableConfig(state, getters, rootState, rootGetters) {
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.executionId === null) { return dailyComponentStore.getters.getStartupCostTableConfig(state.dailyComponent); }
    return setAllUneditable(dailyComponentStore.getters.getStartupCostTableConfig(state.dailyComponent));
  },
  getStartupCostData() {
    let tableData = [];
    if (state.scheduleType && state.bidsVariantSchedule) {
      tableData = dailyComponentStore.getters.getStartupCostData(state.dailyComponent);
    }
    return tableData;
  },
  getStartupTimeTableConfig(state, getters, rootState, rootGetters) {
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.executionId === null) { return dailyComponentStore.getters.getStartupTimeTableConfig(state.dailyComponent); }
    return setAllUneditable(dailyComponentStore.getters.getStartupTimeTableConfig(state.dailyComponent));
  },
  getStartupTimeData(state) {
    let tableData = [];
    if (state.scheduleType && state.bidsVariantSchedule) {
      tableData = dailyComponentStore.getters.getStartupTimeData(state.dailyComponent);
    }
    return tableData;
  },
  getBidErrorTableConfiguration: (state) => bidErrorStore.getters.getTableConfig(state.bidError),
  getBidErrorTableData: (state) => bidErrorStore.getters.getTableData(state.bidError),
  getBidErrorData: (state) => bidErrorStore.getters.getData(state.bidError),
  getSelfScheduleSummaryData(state) {
    if (!state.selfSchedules) return null;
    const tableData = state.selfSchedules;
    const summaryItem = { he: 'TOTAL', summaryFlag: true };
    const keysToSum = ['pt', 'ru', 'rd', 'sr', 'nr'];

    tableData.forEach((item, idx) => {
      keysToSum.forEach((key) => {
        if (Number.isNaN(item[key])) item[key] = null;

        // Sum up data when done null out NaN
        if (idx === tableData.length - 1) {
          summaryItem[key] = tableData.reduce((acc, row) => acc + row[key], 0).toFixed(2);
        }
      });
    });
    return [summaryItem];
  },
  getBidCurveSummaryData(state) {
    if (!state.bidSchedules) return [];
    const tableData = state.cleanBidSchedules.length > 0 && state.compareFlag ? state.cleanBidSchedules : state.bidSchedules;
    const summaryItem = { he: 'TOTAL', summaryFlag: true };
    const keysToSum = [
      'da_ss',
      'ss',
    ].concat(state.bidCurveTableConfig.columns.map((column) => column.prop ?? '')
      .filter((prop) => prop.includes('qty')));

    tableData.forEach((item, idx) => {
      keysToSum.forEach((key) => {
        if (Number.isNaN(item[key]) || item[key] === undefined) item[key] = null;

        // Sum up data when done null out NaN
        if (idx === tableData.length - 1) {
          summaryItem[key] = parseFloat(tableData.reduce((acc, row) => acc + row[key], 0)).toFixed(2);
        }
      });
    });
    return [summaryItem];
  },
  getSelfScheduleTableConfiguration(state, getters, rootState, rootGetters) {
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    // would only be added for TOR and ETC types
    state.extraSelfScheduleTypes.forEach((selfSchdType) => {
      // assumes it comes in the format selfSchdType_contractRight
      const newLabel = selfSchdType.replace('_', ': ');
      const extraColumn = {
        label: newLabel,
        prop: selfSchdType,
        editable: true,
        level: '2-1',
        parent: '2',
        visible: true,
        compare: true,
        width: 145,
      };
      state.selfScheduleTableConfig.columns.push(extraColumn);
    });

    if (writePermission && state.scheduleType && state.executionId === null) { return state.selfScheduleTableConfig; }
    return setAllUneditable(state.selfScheduleTableConfig);
  },
  getBidCurveTableConfiguration(state, getters, rootState, rootGetters) {
    if (state.marketType === 'RTM') {
      state.bidCurveTableConfig.columns[2].visible = true;
    } else {
      state.bidCurveTableConfig.columns[2].visible = false;
    }
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.scheduleType && state.executionId === null) { return state.bidCurveTableConfig; }
    return setAllUneditable(state.bidCurveTableConfig);
  },
  getBidTransitionMatrixTableConfiguration(state, getters, rootState, rootGetters) {
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.executionId === null) { return state.transitionMatrixTableConfig; }
    return setAllUneditable(state.transitionMatrixTableConfig);
  },
  getTransitions: (state) => state.transitions,
};

const actions = {
  removeComponentCurve({ state }, data) {
    const { elementId, curveData } = data;

    let dataList = [];
    switch (elementId) {
    case 'startupTimeTable':
      dataList = state.dailyComponent.startupTimeCurveData;
      break;
    case 'rampCurveTable':
      dataList = state.dailyComponent.rampCurveData;
      break;
    case 'startupCostTable':
      dataList = state.dailyComponent.startupCostCurveData;
      break;
    default:
      break;
    }
    const item = dataList.find((x) => curveData.configuration === x.configuration
      && curveData.he == x.he && x.segmentId === curveData.segmentId);
    const index = dataList.indexOf(item);
    if (index > -1) dataList.splice(index, 1);
  },
  // Daily Component Actions
  ...dailyComponentStore.actions,
  // BidError Actions
  ...bidErrorStore.actions,
  reset({ commit }) {
    commit('reset');
    dailyComponentStore.mutations.reset(state.dailyComponent);
    bidErrorStore.mutations.reset(state.bidError);
  },
  async loadScheduleAction({ dispatch, commit, state }, params) {
    commit('setExecutionId', params.executionId);
    commit('setLocationType', params.locationType);
    commit('scheduleNameMutation', params.scheduleName);
    await dispatch('initializeBidConfig');

    let location = null;
    try {
      location = await dispatch('REFERENCE_DATA_STORE/fetchLocation', {
        market: 'CAISO', locationType: state.locationType, locationName: state.locationName,
      });
    } catch (ex) {
      console.error(`Unable to retrieve location: ${ex}`);
    }

    commit('setLocation', location);
    commit('setPMinPMax', location);
    dispatch('configurationChanged', params.configuration);

    const schedule = await getSchedule(params.scheduleName, params.executionId);
    const item = deconstructScheduleName(params.scheduleName, ['sc', 'marketType', 'date', 'location']);

    if (item.marketType === 'RTM') {
      try {
        const daScheduleName = params.scheduleName.replace('RTM', 'DAM');
        const daSchedule = await CISO_BID_SCHD_API.get(`/${daScheduleName}`, { params: { variant: 'ACTUAL' } });
        const { data } = daSchedule;
        if (data?.selfSchedules) {
          state.daSelfSchedules = data.selfSchedules;
        }
      } catch (err) {
        console.error('No day ahead self schedules to load.');
      }
    }

    if (state.configurations && state.configurations.length > 0 && !schedule?.actual) {
      await dispatch('createParentTransitions');
    }
    commit('loadBidsScheduleMutation', schedule.actual);
    commit('setScheduleStatus', schedule.scheduleStatus);
    dispatch('filterTableData');
  },
  changeBidScheduleAction({ commit }, item) {
    commit('setBidScheduleData', item);
  },
  async save({ dispatch, state }) {
    dispatch('filterTableData');
    const schedule = cloneDeep(state.bidsVariantSchedule);
    if (Array.isArray(state.transitions)) schedule.transitions = state.transitions;
    schedule.bidSchedules.forEach((bid) => {
      const keys = Object.keys(bid).filter((key) => key.includes('market_'));
      keys.forEach((key) => {
        bid[key] = null;
      });
    });
    if (state.mode === 'NEW') {
      await CISO_BID_SCHD_API.post('', schedule);
    } else {
      await CISO_BID_SCHD_API.put(`${schedule.scheduleName}`, schedule);
    }
  },
  async accept({ dispatch, state }, executionId) {
    await BIDDING_API.put(`/executions/status/${executionId}/accepted`);
  },
  async createParentTransitions({ commit, dispatch, state }) {
    try {
      const { data: { data } } = CISO_MASTERDATA_API.get(`/resources/${state.locationName}/transitions`);
      if (Array.isArray(data?.resourceTransitions)) {
        const schedule = {
          ...emptySchedule(state),
          configuration: null,
          transitions: data.resourceTransitions.map(({ transition }) => ({
            startTime: state.startTime,
            endTime: state.endTime,
            fromConfiguration: transition.configurationIdFrom,
            toConfiguration: transition.configurationIdTo,
            cost: transition.transitionCost,
            rampTime: transition.transitionRampTime,
            notificationTime: transition.notificationTime,
          })),
        };
        dispatch('createParentSchedule', schedule);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async createParentSchedule({ commit }, schedule) {
    try {
      const { data } = CISO_BID_SCHD_API.post('?variant=ACTUAL', schedule);
      commit('setParentScheduleTransitions', data);
    } catch (error) {
      console.error(error);
    }
  },
  compareVersion({ commit, state }, item) {
    commit('setCompareFlag', item);
    if (item) {
      state.cleanBidSchedules = cloneDeep(state.bidSchedules);
      state.bidSchedules.forEach((x) => {
        const keys = Object.keys(x);
        const marketKeys = keys.filter((x) => x.includes('market_'));
        marketKeys.forEach((key) => {
          if (x[key] || x[key] === 0) {
            const systemKey = key.substring(key.indexOf('market_') + 7);
            if (x[key] !== x[systemKey]) {
              // The compared Cell Class Rule depends on the colon formatting below
              x[systemKey] = `${x[systemKey] ?? null} : ${x[key] ?? null}`;
            }
          }
        });
      });
    } else {
      state.bidSchedules = cloneDeep(state.cleanBidSchedules);
    }
  },
  transitionRowOperation({ commit }, item) {
    if (item.type === 'ADD') {
      commit('addTransition', { index: item.rowIndex });
    } else if (item.type === 'REMOVE') {
      commit('removeTransition', { index: item.rowIndex });
    }
  },
  async loadParentScheduleTransitions({ commit, dispatch }, scheduleName) {
    try {
      if (scheduleName[scheduleName.length - 1] === '*') {
        scheduleName = scheduleName.substring(0, scheduleName.length - 1);
      }
      scheduleName = scheduleName.substring(0, scheduleName.lastIndexOf('-'));
      const params = { variant: 'ACTUAL' };
      const { data } = await CISO_BID_SCHD_API.get(`/${scheduleName}`, { params });
      commit('setParentScheduleTransitions', data);
    } catch (error) {
      console.error('Creating Parent transitions');
      dispatch('createParentTransitions');
    }
  },
  configurationChanged({ dispatch, commit, state }, item) {
    let id = item;
    let configName = null;
    if (item) {
      const configuration = state.location.locationSubtypes.find((x) => x.id === item || x.shortName === item);
      id = configuration.id;
      configName = configuration.fullName;
      commit('setPMinPMax', configuration);
    } else {
      commit('setPMinPMax', state.location);
    }

    dispatch('selectTopComponentRow', configName);
    commit('setConfiguration', id);
    dispatch('filterTableData');
  },
  filterTableData({ state }) {
    state.selfSchedules = filterScheduleData(state, 'selfSchedules');
    state.bidSchedules = filterScheduleData(state, 'bidSchedules');
  },
  changeActiveTabAction({ dispatch, state }, selection) {
    if (selection.addedItems[0].component === 'daily-bid-component') {
      // eslint-disable-next-line
      dailyComponentStore.mutations.setDailyBidComponentTableConfig(state.dailyComponent, state.marketType, state.locationType);
      dailyComponentStore.mutations.setHourlyBidComponentTableConfig(
        state.dailyComponent, state.marketType, state.locationType);
    }
    dispatch('filterTableData');
    if (state.bidsVariantSchedule) {
      selfScheduleTotals(
        state.bidsVariantSchedule.selfSchedules,
        state.bidsVariantSchedule.bidSchedules,
        state.excludeFromSelfScheduleTotalSum,
      );
    }
  },
  defaultDailyBids({ state }) {
    const defaults = [];
    if (state.location.locationSubtypes.length) {
      state.location.locationSubtypes.forEach((location) => {
        const param = generateDefault(location);
        const { hasDailyBids, hasStepCurves } = param;
        if (hasDailyBids || hasStepCurves) {
          defaults.push(param);
        }
      });
    } else {
      defaults.push(generateDefault(state.location));
    }

    defaults.forEach((defaultValues) => {
      if (defaultValues.hasStepCurves) {
        generateDefaultCurves(
          defaultValues.rampCurveData, defaultValues.startupCostCurveData, defaultValues.startupTimeCurveData, state);
      }
      generateDefaultDailyBids(defaultValues.dailyBidComponentData, state);
      generateDefaultHourlyBids(defaultValues.hourlyBidComponentData, state);
    });
  },
  selectTopComponentRow({ state }, selectedConfig) {
    let dailyBidComponents = dailyComponentStore.getters.getDailyBidComponentData(state.dailyComponent);
    if (selectedConfig) {
      dailyBidComponents = dailyBidComponents.filter((component) => component.configuration === selectedConfig);
    }
    dailyComponentStore.mutations.setSelectedDailyBidRow(state.dailyComponent, dailyBidComponents[0]);
  },
  initializeDailyComponent({ commit, dispatch }) {
    dispatch('selectTopComponentRow');
  },
  async initializeBidConfig({ commit, dispatch }) {
    try {
      const { data } = await STRUCTURES_API.get(`/tableConfigurations/${'bidScheduleTable'}`);
      const config = JSON.parse(data.data);
      if (config?.columns?.length) {
        state.bidCurveTableConfig = config;
        state.bidCurveTableConfig.columns = await dispatch('addMarketColumns', config);
        state.bidCurveTableConfig.options.summaryRows = true;
        commit('setBidCurveTableConfig', state.bidCurveTableConfig);
      }
    } catch (err) {
      state.bidCurveTableConfig.columns = await dispatch('addMarketColumns', state.bidCurveTableConfig);
      commit('setBidCurveTableConfig', state.bidCurveTableConfig);
    }
  },
  addMarketColumns({ state }, config) {
    const marketColumns = [];
    config.columns.forEach((column) => {
      if (column.prop?.match(/(qty|prc)/)) {
        marketColumns.push({
          ...column, visible: false, prop: `market_${column.prop}`, label: `market_${column.prop.substring(column.prop.indexOf('en_') + 3)}`,
        });
      }
      if (column.isParent) {
        marketColumns.push({
          ...column,
          cellStyle: (params) => {
            const id = params.column.colId;
            const mainData = params.data[id];
            const separateData = params.data[`market_${id}`];
            if (separateData && mainData !== separateData) {
              return { ' box-shadow': '0 0 0 1px #ff8635 inset !important' };
            }
            return null;
          },
        });
      } else {
        marketColumns.push({ ...column, editable: !state.compareFlag });
      }
    });
    return marketColumns;
  },
  matchMarketValues({ state }) {
    const { bidSchedules } = state;
    state.revertBidSchedules = cloneDeep(bidSchedules);
    bidSchedules.forEach((bid) => {
      const marketKeys = Object.keys(bid).filter((key) => key.includes('market_'));
      if (marketKeys.length > 0) {
        marketKeys.forEach((key) => {
          bid[key.replace('market_', '')] = bid[key];
        });
      }
    });
  },
  addNewTransition({ state }) {
    dailyComponentStore.mutations.addTransition();
  },
  resetSystemValues({ state }) {
    const { revertBidSchedules } = state;
    if (revertBidSchedules.length > 0) {
      state.bidSchedules = cloneDeep(revertBidSchedules);
      state.revertBidSchedules = [];
    }
  },
};

const mutations = {
  ...Object.entries(dailyComponentStore.mutations).reduce((acc, [mutation, func]) => ({
    ...acc,
    [mutation]: (state, value) => func(state.dailyComponent, value),
  }), {}),
  ...Object.entries(bidErrorStore.mutations).reduce((acc, [mutation, func]) => ({
    ...acc,
    [mutation]: (state, value) => func(state.bidError, value),
  }), {}),
  reset(state) {
    state.bidsVariantSchedule = null;
    state.daSelfSchedules = [];
    state.selfSchedules = [];
    state.bidSchedules = [];
    state.transitions = [];
    state.transitionMatrixVisibilityFlag = false;
    state.configuration = null;
    state.pMin = 0;
    state.pMax = 0;
  },
  setLocationType(state, item) {
    state.locationType = item;
  },
  setExecutionId(state, item) {
    state.executionId = item;
  },
  setLocation(state, location) {
    state.location = location;
    if (location?.locationSubtypes) {
      state.configurations = mapLabelValue(location.locationSubtypes, 'id', 'shortName');
    }
  },
  setPMinPMax(state, item) {
    state.pMin = Number(item?.parameters?.find((x) => x.name === 'PMIN')?.value);
    state.pMax = Number(item?.parameters?.find((x) => x.name === 'PMAX')?.value);
  },
  scheduleNameMutation(state, name) {
    const propList = ['sc', 'marketType', 'date', 'location'];
    const item = deconstructScheduleName(name, propList);
    state.sc = item.sc;
    state.scheduleName = name;
    state.locationName = item.location;
    state.marketType = item.marketType;
    state.date = `${item.date.substring(0, 4)}-${item.date.substring(4, 6)}-${item.date.substring(6, 8)}`;

    const mt = dateStore.toMomentFromStringWithFormat(state.date, 'YYYY-MM-DD');
    state.startTime = mt.toISOString();
    state.endTime = mt.clone().add(1, 'days').toISOString();
  },
  loadBidsScheduleMutation(state, item) {
    if (!item) state.mode = 'NEW'; else state.mode = 'EXISTING';
    const schedule = item || emptySchedule(state);
    const tableData = setScheduleDefaults(schedule, state);

    for (let i = 0; i < schedule.selfSchedules.length; i++) {
      const ss = schedule.selfSchedules[i];
      for (const key in ss) {
        if (key.startsWith('TOR') || key.startsWith('ETC')) {
          if (!state.extraSelfScheduleTypes.includes(key)) { state.extraSelfScheduleTypes.push(key); }
          tableData.selfSchedules[i][key] = ss[key];
        }
      }
    }

    const highlightStartTimes = new Set();
    if (tableData && tableData.validations) {
      const validations = tableData.validations.filter((o) => o.priority === -1);
      for (let i = 0; i < validations.length; i++) {
        const validation = validations[i];
        highlightStartTimes.add(validation.startTime);
      }
    }
    const highlightArray = Array.from(highlightStartTimes);
    if (highlightArray && highlightArray.length > 0) {
      for (let i = 0; i < highlightArray.length; i++) {
        const bidSchedule = schedule.bidSchedules.find((o) => o.startTime === highlightArray[i]);
        if (bidSchedule) { bidSchedule.highlight = ['he']; }
      }
    }
    state.bidsVariantSchedule = tableData;

    if (schedule && schedule.transitions && schedule.transitions.length > 0) {
      for (let i = 0; i < schedule.transitions.length; i++) {
        const transition = schedule.transitions[i];
        transition.createdDate = dateStore.toMomentFromJSDate(transition.createdDate).format('YYYY-MM-DD');
        if (transition.updatedDate) {
          transition.updatedDate = dateStore.toMomentFromJSDate(transition.updatedDate).format('YYYY-MM-DD');
        }
        transition.rowIndex = i;
      }
      if (schedule.scheduleName.match(/-/g).length === 3) {
        state.transitions = schedule.transitions;
      }
    }
    bidErrorStore.mutations.setDataMutation(state.bidError, state.bidsVariantSchedule.validations);
    dailyComponentStore.mutations
      .loadDailyBidComponentData(state.dailyComponent, state.bidsVariantSchedule.parameters);
    dailyComponentStore.mutations
      .loadHourlyBidComponentData(state.dailyComponent, state.bidsVariantSchedule.hourlyParameters);
    dailyComponentStore.mutations
      .loadRampCurveData(state.dailyComponent, state.bidsVariantSchedule.rampRates);
    dailyComponentStore.mutations
      .loadStartupCostCurveData(state.dailyComponent, state.bidsVariantSchedule.startupCostCurve);
    dailyComponentStore.mutations
      .loadStartupTimeCurveData(state.dailyComponent, state.bidsVariantSchedule.startupTimeCurve);
  },
  setSelfScheduleData(state, item) {
    if (item.value) {
      if (item.value === '0' || parseFloat(item.value)) {
        item.value = parseFloat(parseFloat(item.value).toFixed(3));
      } else {
        const ss = state.selfSchedules[item.rowIndex];
        ss[item.prop] = item.value;

        item.value = null;
      }
    }
    const ss = state.selfSchedules[item.rowIndex];
    Vue.set(ss, item.prop, item.value);

    const bs = findByStartTime(state.bidsVariantSchedule.bidSchedules, ss.startTime);
    bs.ss = computeSelfScheduleTotal(ss, state.excludeFromSelfScheduleTotalSum);
  },
  setBidScheduleData(state, item) {
    if (item.value || item.value === 0) {
      item.value = parseFloat(parseFloat(item.value).toFixed(3));
    } else {
      item.value = null;
    }
    const bs = state.compareFlag ? state.cleanBidSchedules[item.rowIndex] : state.bidSchedules[item.rowIndex];
    Vue.set(bs, item.prop, item.value);
  },
  setTransitionMatrixData(state, item) {
    if (state.transitions[item.rowIndex][item.prop] !== item.value) {
      if (item.prop === 'toConfiguration' || item.prop === 'fromConfiguration') {
        const value = state.transitionMatrixTableConfig.configurations.find((o) => o.value === item.value).label;
        state.transitions[item.rowIndex][item.prop] = value;
      } else {
        state.transitions[item.rowIndex][item.prop] = item.value;
      }
    }
  },
  addTransition(state, item) {
    const data = [];
    for (let x = 0; x <= item.index; x++) {
      const transition = state.transitions[x];
      transition.rowIndex = x;
      data.push(transition);
    }
    const newTransition = cloneDeep(state.nullTransitionRow);
    newTransition.rowIndex = item.index;
    data.push(newTransition);

    for (let x = item.index + 1; x < state.transitions.length; x++) {
      data.push(state.transitions[x]);
    }

    state.transitions = data;
  },
  removeTransition(state, item) {
    const data = [];

    for (let x = 0; x < item.index; x++) {
      data.push(state.transitions[x]);
    }

    for (let x = item.index + 1; x < state.transitions.length; x++) {
      data.push(state.transitions[x]);
    }

    if (data.length === 0) {
      const newTransition = cloneDeep(state.nullTransitionRow);
      newTransition.rowIndex = 0;
      data.push(newTransition);
    }

    state.transitions = data;
  },
  setParentScheduleTransitions(state, item) {
    if (item && item.transitions && item.transitions.length > 0) {
      for (let i = 0; i < item.transitions.length; i++) {
        const transition = item.transitions[i];
        transition.createdDate = dateStore.toMomentFromJSDate(transition.createdDate).format('YYYY-MM-DD');
        if (transition.updatedDate) {
          transition.updatedDate = dateStore.toMomentFromJSDate(transition.updatedDate).format('YYYY-MM-DD');
        }

        transition.rowIndex = i;
      }
      state.transitions = item.transitions;
    }
  },
  setScheduleType(state, item) { state.scheduleType = item; },
  setConfiguration(state, item) { state.configuration = item; },
  setSelfScheduleTableConfig(state, data) { state.selfScheduleTableConfig = data; },
  setBidCurveTableConfig(state, data) {
    data.options.summaryRows = true;
    state.bidCurveTableConfig = data;
    state.bidCurveTableConfig.customCellClassRules = customCellClassRules;
  },
  setScheduleStatus(state, item) { state.scheduleStatus = item; },
  setCompareFlag(state, item) { state.compareFlag = item; },
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  actions,
  mutations,
};