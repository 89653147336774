import dateStore from '@/utils/dateStore';
import { EventEmitter } from '@/utils/eventEmitter';
import NotificationSound from './notificationSound';

export default class NotificationItem extends EventEmitter {
  /**
   *
   * @param {string} version The version of the notification
   * @param {string} type The type of the notification
   * @param {string} text The display text
   * @param {string} state The state of the notification
   * @param {string} source The source of the notification
   * @param {string} severity The severity of the notification
   * @param {string} uid The unique id of the notification
   * @param {string} ack The acknowlegment of the notification
   * @param {Object} message The notification object itself
   * @param {Object} error The notification error object itself
   * @param {Object} misc Any misc data
   */
  constructor(
    version,
    type,
    text,
    state,
    source,
    severity,
    uid,
    ack,
    message,
    error,
    misc,
  ) {
    super();
    this.version = version;
    this.type = type;
    this.text = text;
    this.state = state;
    this.source = source;
    this.severity = severity;
    this.uid = uid;
    this.ack = 'None';
    this.message = message;
    this.error = error;
    this.timestamp = dateStore.getMomentNowLocal();
    this.route = null;
    this.misc = misc ?? {};
    this.subText = null;
    this.name = null;
    this.actions = [];
    this.sound = new NotificationSound();
    this.removeIfAcknowledged = true;
    this.showIfAcknowledgedAndNotAlreadyExists = false;
    this.textToSpeech = false;
    this.setAck(ack);
    this.css = {};
    this.subTextRoute = null;
  }

  get formattedTimestamp() {
    return `(${this.timestamp.clone().format('YYYY-MM-DD HH:mm:ss')})`;
  }

  hasError() {
    return this.error !== undefined && this.error !== null && this.error.messages !== null;
  }

  errorMessageText() {
    if (this.hasError()) {
      var m = `${this.text}: `;
      
      this.error.messages.forEach(message => {
        m += `${message.message ?? message}\n`
      });
      
      return m; 
    }
  }

  /**
   * @return {boolean} True if state is not empty
   */
  get isLoading() {
    return (this.state && this.state.toLocaleLowerCase() === 'started');
  }

  /**
   * @return {boolean} True if state is ERRORED
   */
  get isStateErrored() {
    if (this.state) return this.state === 'ERRORED';
    return false;
  }

  /**
   * @return {boolean} True if state is COMPLETED
   */
  get isStateCompleted() {
    if (this.state) return this.state === 'COMPLETED';
    return false;
  }

  get isAcknowledgementRequired() {
    return this.ack && this.ack.toLocaleLowerCase() === 'required';
  }

  get isAcknowledged() {
    return this.ack && this.ack.toLocaleLowerCase() === 'acknowledged';
  }

  setAck(ack) {
    this.ack = ack;
    if (this.ack === 'Required' && this.actions.length === 0) {
      this.actions.push({
        name: 'ack',
        hint: 'Acknowledge',
        icon: 'fas fa-user-check',
        disabled: false,
      });
    } else {
      this.actions = [];
    }
  }

  matchSourceAndType(source, type) {
    return this.source.toLocaleLowerCase() === source.toLocaleLowerCase()
      && this.type.toLocaleLowerCase() === type.toLocaleLowerCase();
  }

  actionTriggered(actionName) {
    if (actionName === 'ack') {
      const self = this;
      this.emit('action', { name: 'confirmed', item: self });
      this.sound.disable();
      this.ack = 'Confirmed';
      this.actions[0].disabled = true;
    }
  }
}
