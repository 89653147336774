import { clone } from '@/utils/dataUtil';
import { METER_MANAGEMENT_API } from '@/api';

const state = {
  tableKey: -999,
  tableData: [],
  childTableData: [],
  currentTableRow: {},
  currentChildTableRow: {},
  nullTableRow: {
    id: -999,
    meterName: null,
    meterDescription: null,
    multiplier: 1,
    effectiveDate: null,
    terminationDate: null,
  },
  nullChildTableRow: {
    id: -999,
    meterId: null,
    sourceSystem: null,
    msid: null,
    multiplier: 1,
    channel: null,
    backupMsid: null,
    backupChannel: null,
    effectiveDate: null,
    terminationDate: null,
  },
};

const actions = {
  async fetchData({ commit }) {
    commit('setTableData', []);
    try {
      const { data: { data } } = await METER_MANAGEMENT_API.get('/ref-data/meters');
      commit('setTableData', data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchMappingData({ commit }, e) {
    commit('setChildTableData', []);
    try {
      const { data: { data } } = await METER_MANAGEMENT_API.get(`/ref-data-mappings/meters/${e.id}`, e);
      commit('setChildTableData', data);
    } catch (err) {
      console.error(err);
    }
  },
  async postRecord({ state }, e) {
    try {
      const { data } = await METER_MANAGEMENT_API.post('/ref-data/meters', e);
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
  async postChildRecord({ state }, e) {
    try {
      const { data } = await METER_MANAGEMENT_API.post('/ref-data-mappings/meters', e);
      state.childTableData.push(data);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
  async updateRecord({ commit }, e) {
    try {
      const { data } = await METER_MANAGEMENT_API.put(`/ref-data/meters/${e.id}`, e);
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
  async updateChildRecord({ commit }, e) {
    try {
      const { data } = await METER_MANAGEMENT_API.put(`/ref-data-mappings/meters/${e.id}`, e);
      commit('updateChildRecord', data);
      this.$notify('Data Updated', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
  async deleteRow({ dispatch, commit, state }) {
    try {
      await METER_MANAGEMENT_API.delete(`/ref-data/meters/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
  async deleteChildRow({ dispatch, commit, state }) {
    try {
      await METER_MANAGEMENT_API.delete(`/ref-data-mappings/meters/${state.currentChildTableRow.id}`);
      commit('deleteChildTableRow');
      commit('currentChildTableRowChange', clone(state.nullChildTableRow));
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
};

const mutations = {
  loadMeterList(state, data) {
    state.meterList = data;
  },
  setTableData(state, value) {
    state.tableData = value;
  },
  setChildTableData(state, value) {
    state.childTableData = value;
  },
  currentTableRowChange(state, data) {
    state.currentTableRow = data;
  },
  currentChildTableRowChange(state, data) {
    state.currentChildTableRow = data;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  updateChildRecord(state, record) {
    const rowIndex = state.childTableData.findIndex(({ id }) => id === record.id);
    state.childTableData.splice(rowIndex, 1, record);
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    state.currentTableRow = record;
  },
  createChildTableRow(state) {
    const record = clone(state.nullChildTableRow);
    record.id = state.tableKey++;
    record.meterId = state.currentTableRow.id;
    record.meterName = state.currentTableRow.meterName;
    state.currentChildTableRow = record;
  },
  copyChildTableRow(state) {
    const record = clone(state.currentChildTableRow);
    record.id = state.tableKey++;
    state.currentChildTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
  deleteChildTableRow(state) {
    state.childTableData = state.childTableData.filter((record) => record.id !== state.currentChildTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};