import moment from 'moment';
import { BILLING_STLMTS_API, BILLING_REF_API } from '@/api';
import { has, cloneDeep } from '@/utils/dataUtil';
import config from './determinantDetailsConfig';

const state = {
  tableData: [],
  tableConfig: [],
  formulaData: [],
  selectedCells: [],
  queryParams: [],
  showRowTotals: false,
  compareCycle: false,
  attributeMapping: [],
  attributeTableObject: {},
};

const actions = {
  async fetchDrmtDetails({ dispatch, commit }, params) {
    try {
      commit('setQueryParams', params);
      const response = await BILLING_STLMTS_API.put('/statements/drmt-details?', params);
      dispatch('createTableData', response.data);
    } catch (error) {
      this.$notify('Failed to load CB Drmt Details', 'error');
    }
  },

  async fetchAttributeData({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/ref-attributes/DeterminantDetails');
      commit('loadAttributeData', data);

      const tableConfigMap = {};
      state.tableConfig.forEach((item, index) => {
        tableConfigMap[item.dataField] = { caption: item.caption, index };
      });

      state.attributeMapping.forEach(({ displayName, propertyName, activeFlag }) => {
        const tableConfigEntry = tableConfigMap[propertyName];

        if (tableConfigEntry !== undefined) {
          const { index } = tableConfigEntry;
          state.tableConfig[index].caption = displayName;
          state.tableConfig[index].visible = activeFlag;
        }
      });
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },

  async createTableData({ commit }, data) {
    const hasCompareCycle = has(state.queryParams, 'compareCycle');

    if (!hasCompareCycle) {
      const defaultColumns = cloneDeep(config.columns);
      for (let x = 0; x < defaultColumns.length; x++) {
        if (defaultColumns[x].caption === 'CompareType') {
          defaultColumns[x].area = 'filter';
          break;
        }
      }
      commit('setTableConfig', defaultColumns);
      commit('setCompareCycle', false);
      commit('setShowRowTotals', false);
    } else {
      commit('setTableConfig', config.columns);
      commit('setCompareCycle', true);
      commit('setShowRowTotals', true);
    }

    if (data && data.data && data.data.length > 0) {
      commit('reset');
      const bdList = [];
      const flatArray = [];
      data.data.forEach((head) => {
        const bd = {};
        bd.name = head.bdName;
        bd.formula = head.formula;
        if (bdList.findIndex((x) => x.name === bd.name) === -1) bdList.push(bd);
        head.data.forEach((mid) => {
          mid.tradingDate = moment.utc(mid.tradingDate).format('MM/DD/YYYY');
          const newObj = {};
          Object.keys(mid).forEach((key) => {
            if (!Array.isArray(mid[key])) { newObj[key] = mid[key]; }
          });
          Object.keys(head).forEach((key) => {
            if (!Array.isArray(head[key])) { newObj[key] = head[key]; }
          });
          flatArray.push(newObj);
        });
      });
      commit('setTableData', flatArray);
      commit('setFormulaData', bdList);
    }
  },
};

const mutations = {
  setCompareCycle(state, value) {
    state.compareCycle = value;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setFormulaData(state, value) {
    state.formulaData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setQueryParams(state, value) {
    state.queryParams = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
  reset(state) {
    state.tableData = [];
  },
  loadAttributeData(state, records) {
    state.attributeMapping = records;
    state.attributeTableObject = records.reduce((obj, item) => Object.assign(obj, { [item.propertyName]: item }), {});
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};