import Vue from 'vue';
import moment from 'moment';

import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import LOOKUP_STORE from '@/store/lookupStore';
import { confirm } from 'devextreme/ui/dialog';

import {
  HEColumns, clone, deepClone, sortBy,
} from '@/utils/dataUtil';
import { createMutations } from '@/utils/vuexHelper';

import {
  CISO_SCHD_API,
  CISO_BID_SCHD_API,
} from '@/api';

const ag_details_he_columns = (date) => HEColumns({}, true, date).map((column) => (
  {
    label: column.label.replace('HE', ''),
    prop: column.prop !== 'he2*' ? column.prop : 'he25',
    editable: true,
    width: 50,
    filter: true,
    cellDataType: 'text',
    dataType: 'string',
  }
));

const generateConfig = (type, hasSubLocations, selectedDate) => {
  const config = {
    columns: [{
      prop: 'resource',
      editable: false,
      width: 100,
      minWidth: 100,
      filter: true,
      sortingOrder: ['desc'],
      dataType: 'string',
    }, {
      prop: 'product', label: 'Prod', editable: false, width: 50, filter: true, dataType: 'string',
    }, {
      prop: 'curve', label: '#', editable: false, width: 40, filter: true, dataType: 'string',
    }, {
      prop: 'value', label: 'Q / P', editable: false, width: 50, filter: true, dataType: 'string',
    },
    ...ag_details_he_columns(selectedDate),
    ],
    customCellClassRules: {
      resourceOffset1(params) {
        return params.data.rsrcIdx % 2 === 0;
      },
      resourceOffset2(params) {
        return params.data.rsrcIdx % 2 !== 0;
      },
      priceCell(params) {
        const fields = ['resource', 'configuration', 'type', 'product', 'curve', 'scheduleType', 'flowGate'];
        return params.data.value === 'PRC' && !fields.includes(params.colDef.field);
      },
      edited(params) {
        return params.data.edits.includes(params.colDef.field);
      },
      hideText(params) {
        const fields = ['resource', 'configuration', 'type', 'product', 'scheduleType', 'flowGate'];
        if (!fields.includes(params.colDef.field)) return false;

        const previousRow = params.api.getDisplayedRowAtIndex(params.rowIndex - 1);
        return previousRow && previousRow.data[params.colDef.field] === params.value;
      },
      proposed(params) {
        return params.colDef.field === 'resource' && params.data.status === 'PROPOSED';
      },
      not_ready(params) {
        return params.colDef.field === 'resource' && params.data.status === 'NOT_READY';
      },
      not_exists(params) {
        return params.colDef.field === 'resource' && params.data.status === 'NOT_EXISTS';
      },
      pending_validation(params) {
        return params.colDef.field === 'resource' && params.data.status === 'PENDING_VALIDATION';
      },
      validating(params) {
        return params.colDef.field === 'resource' && params.data.status === 'VALIDATING';
      },
      failed_validation(params) {
        return params.colDef.field === 'resource' && params.data.status === 'FAILED_VALIDATION';
      },
      bids(params) {
        return params.colDef.field === 'resource' && params.data.status === 'BIDS';
      },
      ready(params) {
        return params.colDef.field === 'resource' && params.data.status === 'READY';
      },
      in_progress(params) {
        return params.colDef.field === 'resource' && params.data.status === 'IN_PROGRESS';
      },
      errored(params) {
        return params.colDef.field === 'resource' && params.data.status === 'ERRORED';
      },
      rejected(params) {
        return params.colDef.field === 'resource' && params.data.status === 'REJECTED';
      },
      canceled(params) {
        return params.colDef.field === 'resource' && params.data.status === 'CANCELED';
      },
      cancelling(params) {
        return params.colDef.field === 'resource' && params.data.status === 'CANCELLING';
      },
      submitted(params) {
        return params.colDef.field === 'resource' && params.data.status === 'SUBMITTED';
      },
      submitting(params) {
        return params.colDef.field === 'resource' && params.data.status === 'SUBMITTING';
      },
      retrieving(params) {
        return params.colDef.field === 'resource' && params.data.status === 'RETRIEVING';
      },
      accepted(params) {
        return params.colDef.field === 'resource' && params.data.status === 'ACCEPTED';
      },
    },
  };

  if (type === 'SIBR') {
    if (hasSubLocations) {
      config.columns.splice(1, 0, {
        prop: 'configuration', editable: false, width: 75, filter: true,
      });
    }
    // config.columns.splice(1, 0, {
    //   prop: 'type', label: 'SS', editable: false, width: 70, filter: true,
    // });
  } else {
    config.columns.splice(1, 0, {
      prop: 'scheduleType', label: 'Schedule Type', editable: false, width: 75, filter: true,
    });
    config.columns.splice(1, 0, {
      prop: 'flowGate', label: 'Flow Gate', editable: false, width: 70, filter: true,
    });
  }
  return config;
};

const state = {
  initialized: false,
  agConfig: {
    columns: [{
      prop: 'resource', editable: false, width: 75, filter: true,
    }, {
      prop: 'configuration', editable: false, width: 75, filter: true,
    },
    // {
    // prop: 'detailType', label: 'Curve', editable: false, width: 75, filter: true,
    // },
    {
      prop: 'type', label: 'SS', editable: false, width: 70, filter: true,
    }, {
      prop: 'product', label: 'Prod', editable: false, width: 50, filter: true,
    }, {
      prop: 'curve', label: '#', editable: false, width: 40, filter: true,
    }, {
      prop: 'value', label: 'Q / P', editable: false, width: 50, filter: true,
    },
    ...ag_details_he_columns(dateStore.getDefaultDateLocal().toISOString()),
    ],
    customCellClassRules: {
      resourceOffset1(params) {
        return params.data.rsrcIdx % 2 === 0;
      },
      resourceOffset2(params) {
        return params.data.rsrcIdx % 2 !== 0;
      },
      priceCell(params) {
        const fields = ['resource', 'configuration', 'type', 'product', 'curve'];
        return params.data.value === 'PRC' && !fields.includes(params.colDef.field);
      },
      edited(params) {
        return params.data.edits.includes(params.colDef.field);
      },
      // priceCell(params) {
      //   const fields = ['resource', 'configuration', 'type', 'product', 'curve'];
      //   return params.data.value === 'PRC' && !fields.includes(params.colDef.field);
      // },
    },
  },
  data: [],
  rawData: [],
  dataCache: [],
  dataEdited: false,
  hasSubLocations: false,
  selectedDate: dateStore.getDefaultDateLocal().toISOString(),
  selectedScheduleType: 'SIBR',
  selectedLocationGroupId: null,
  selectedSSTypes: ['PT'],
  selectedASTypes: [],
  selectedBidPairs: 1,
  selectedMarketType: null,
  timerCount: 0,
};

const getters = {};

const actions = {
  async initialize({ dispatch }) {
    await dispatch('fetchReferenceData');
    await dispatch('defaultUserSettings');
  },
  async fetchReferenceData({ state, dispatch, commit }) {
    const payload = {
      referenceItemList: ['fetchLocationGroupList', 'fetchLocationList'],
      market: 'CAISO',
      commodity: 'POWER',
      showSubTypes: true,
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
  },
  defaultUserSettings({ state, commit, dispatch }) {
    const userLocationGroup = state.REFERENCE_DATA_STORE.locationGroupList
      .find(({ shortName }) => shortName === caisoStore.resourceGroupName);
    if (userLocationGroup) commit('setSelectedLocationGroupId', userLocationGroup.id);

    const defaultMarketType = caisoStore.getMarketType();
    commit('setSelectedMarketType', defaultMarketType);
  },

  async fetchData({ dispatch, commit, state }) {
    // const { startDate, endDate } = getMomentDateStringFromRangeArray(inparams.tradeDateRangeSelected);
    // const locationGroup = getters.locationList
    const locationGroup = state.REFERENCE_DATA_STORE.locationGroupList
      .find((x) => x.id === state.selectedLocationGroupId)
      .shortName;
    if (!locationGroup) { console.error('invalid locationGroup'); }

    const tz = dateStore.getTimeZone();
    const momentStartTime = dateStore.toDateFromLocal(state.selectedDate, tz);

    const params = {
      start: momentStartTime.toISOString(),
      end: momentStartTime.clone().add(1, 'days').toISOString(),
      marketType: state.selectedMarketType,
      locationGroup,
      fullSchedules: true,
    };

    if (state.selectedScheduleType === 'SIBR') {
      try {
        const { data } = await CISO_SCHD_API.get('/bid/curve', { params });
        await dispatch('mapSchedules', data.schedules);
        commit('setRawData', data.schedules);
      } catch (error) {
        this.$notify('Failed to Load Data', 'error');
        console.error(error);
      }
    } else {
      try {
        const { data } = await CISO_SCHD_API.get('virtual/curve', { params });
        dispatch('mapVirtualSchedules', data.schedules);
        commit('setRawData', data.schedules);
      } catch (error) {
        this.$notify('Failed to Load Data', 'error');
        console.error(error);
      }
    }
    commit('setAgConfig', generateConfig(state.selectedScheduleType, state.hasSubLocations,
      momentStartTime));
    commit('addTimerCount');
  },
  async mapVirtualSchedules({ state, commit }, schedules) {
    const { selectedSSTypes, selectedBidPairs } = state;
    const bidPairCount = selectedBidPairs;
    let tableData = [];

    await schedules.forEach(async (schedule, idx) => {
      for (let i = 1; i <= bidPairCount; i++) {
        const existingQtyData = schedule.bidSchedules
          .reduce((acc, curr) => {
            const key = `he${(curr.he <= 9) ? '0' : ''}${curr.he}`;
            const value = curr[`en_qty_${(i <= 9) ? '0' : ''}${i}`];
            return { ...acc, [key]: value };
          }, {});

        const existingPrcData = schedule.bidSchedules
          .reduce((acc, curr) => {
            const key = `he${(curr.he <= 9) ? '0' : ''}${curr.he}`;
            const value = curr[`en_prc_${(i <= 9) ? '0' : ''}${i}`];
            return { ...acc, [key]: value };
          }, {});

        tableData.push({
          resource: schedule.resource,
          scheduleType: schedule.virtualBidType,
          flowGate: schedule.flowGate,
          type: null,
          product: 'EN',
          curve: i,
          value: 'Qty',
          rsrcIdx: idx,
          edits: [],
          status: schedule.status,
          ...existingQtyData,
        });
        tableData.push({
          resource: schedule.resource,
          scheduleType: schedule.virtualBidType,
          flowGate: schedule.flowGate,
          type: null,
          product: 'EN',
          curve: i,
          value: 'PRC',
          rsrcIdx: idx,
          edits: [],
          status: schedule.status,
          ...existingPrcData,
        });
      }
    });
    tableData = tableData.sort((a, b) => a.resource.localeCompare(b.resource));
    commit('setData', tableData);
  },
  async mapSchedules({ state, commit, dispatch }, schedules) {
    const { selectedSSTypes, selectedBidPairs } = state;
    let hasSubLocations = false;
    async function processSchedule(schedule, idx) {
      const tableData = [];

      const {
        resource, status, sc, resourceType,
      } = schedule;

      const location = state.REFERENCE_DATA_STORE.locationList.find((x) => x.shortName === resource
      && x.entityName === sc);

      if (!location) return tableData;
      let enumeratedLocations;
      if (location.locationSubtypes.length) {
        hasSubLocations = true;
        enumeratedLocations = location.locationSubtypes
          .map(({ shortName }) => ({ rsrc: resource, config: shortName }));
      } else {
        enumeratedLocations = [{ rsrc: resource, config: null }];
      }

      await Promise.all(enumeratedLocations.map(async ({ rsrc, config }) => {
        // self schedules
        selectedSSTypes.forEach((ssType) => {
          const type = ['RU', 'RD', 'SR'].includes(ssType) ? 'AS' : 'EN';
          let existingData = config
            ? schedule.selfSchedules.filter((x) => x.configuration === config)
            : schedule.selfSchedules;
          existingData = existingData.reduce((acc, curr) => {
            const key = `he${(curr.he <= 9) ? '0' : ''}${curr.he}`;
            const value = curr[`${ssType.toLowerCase()}`];
            return { ...acc, [key]: value };
          }, {});

          tableData.push({
            resource: rsrc,
            configuration: config,
            detailType: 'SS',
            type,
            product: `${ssType} SS`,
            curve: null,
            value: null,
            rsrcIdx: idx,
            edits: [],
            status,
            ...existingData,
          });
        });

        // bid schedules
        const bidPairCount = selectedBidPairs;
        for (let i = 1; i <= bidPairCount; i++) {
          let existingQtyData = config
            ? schedule.bidSchedules.filter((x) => x.configuration === config)
            : schedule.bidSchedules;
          existingQtyData = existingQtyData.reduce((acc, curr) => {
            const key = `he${(curr.he <= 9) ? '0' : ''}${curr.he}`;
            const value = curr[`en_qty_${(i <= 9) ? '0' : ''}${i}`];
            return { ...acc, [key]: value };
          }, {});

          let existingPrcData = config
            ? schedule.bidSchedules.filter((x) => x.configuration === config)
            : schedule.bidSchedules;
          existingPrcData = existingPrcData.reduce((acc, curr) => {
            const key = `he${(curr.he <= 9) ? '0' : ''}${curr.he}`;
            const value = curr[`en_prc_${(i <= 9) ? '0' : ''}${i}`];
            return { ...acc, [key]: value };
          }, {});

          tableData.push({
            resource: rsrc,
            configuration: config,
            detailType: 'BIDS',
            type: null,
            product: 'EN BID',
            curve: i,
            value: 'Qty',
            rsrcIdx: idx,
            edits: [],
            status,
            ...existingQtyData,
          });
          tableData.push({
            resource: rsrc,
            configuration: config,
            detailType: 'BIDS',
            type: null,
            product: 'EN BID',
            curve: i,
            value: 'PRC',
            rsrcIdx: idx,
            edits: [],
            status,
            ...existingPrcData,
          });
        }

        // ancillary service
        const ancillary = state.selectedASTypes;
        ancillary.forEach((as) => {
          let existingQtyData = config
            ? schedule.bidSchedules.filter((x) => x.configuration === config)
            : schedule.bidSchedules;
          existingQtyData = existingQtyData.reduce((acc, curr) => {
            const key = `he${(curr.he <= 9) ? '0' : ''}${curr.he}`;
            const value = curr[`${as.toLowerCase()}_qty`];
            return { ...acc, [key]: value };
          }, {});

          let existingPrcData = config
            ? schedule.bidSchedules.filter((x) => x.configuration === config)
            : schedule.bidSchedules;
          existingPrcData = existingPrcData.reduce((acc, curr) => {
            const key = `he${(curr.he <= 9) ? '0' : ''}${curr.he}`;
            const value = curr[`${as.toLowerCase()}_prc`];
            return { ...acc, [key]: value };
          }, {});

          tableData.push({
            resource: rsrc,
            configuration: config,
            detailType: 'BIDS',
            type: null,
            product: `${as.toUpperCase()} BID`,
            curve: null,
            value: 'Qty',
            rsrcIdx: idx,
            edits: [],
            status,
            ...existingQtyData,
          });
          tableData.push({
            resource: rsrc,
            configuration: config,
            detailType: 'BIDS',
            type: null,
            product: `${as.toUpperCase()} BID`,
            curve: null,
            value: 'PRC',
            rsrcIdx: idx,
            edits: [],
            status,
            ...existingPrcData,
          });
        });
      }));

      return tableData;
    }
    let tableData = await Promise.all(schedules.map((schedule, idx) => processSchedule(schedule, idx)));

    tableData = tableData.reduce((acc, curr) => acc.concat(curr), []);
    tableData = tableData.sort((a, b) => a.resource.localeCompare(b.resource));

    commit('setHasSubLocations', hasSubLocations);
    commit('setData', tableData);
  },
  async save({ state, dispatch }) {
    if (state.selectedScheduleType === 'SIBR') dispatch('saveBidSchedules');
    else dispatch('saveVirtualSchedules');
  },
  async saveBidSchedules({ state, dispatch }) {
    const changes = state.data.filter((x) => x.edits.length);
    const updatedSchedules = [];
    changes.forEach((change) => {
      const t = updatedSchedules.find((x) => x.resource === change.resource);
      if (!t) {
        updatedSchedules.push((state.rawData.find((x) => x.resource === change.resource)));
      }
      const schedule = updatedSchedules.find((x) => x.resource === change.resource);
      if (schedule) {
        const hours = HEColumns({}, true, schedule.date);
        if (change.detailType === 'BIDS') {
          change.edits.forEach((edit) => {
            const strHour = `${ parseInt(edit.replace('he', ''), 10)}`;
            const interval = hours.find((x) => x.prop === edit);
            const startTime = `${interval.time.toISOString().split('.')[0]}Z`;
            const endTime = `${moment(interval.time.toISOString()).add(1, 'hours').toISOString().split('.')[0]}Z`;

            const bidSchedule = change.configuration
              ? schedule.bidSchedules.find((x) => x.he === strHour
                && (x.configuration === change.configuration)
                && (x.startTime === startTime)
                && (x.endTime === endTime))
              : schedule.bidSchedules.find((x) => x.he === strHour
                && (x.startTime === startTime)
                && (x.endTime === endTime));
            let key = `${change.product.split(' ')[0]}_${change.value}`.toLowerCase();
            if (change.product.split(' ')[0] === 'EN') key += `_${(change.curve <= 9) ? '0' : ''}${change.curve}`;
            if (bidSchedule) {
              bidSchedule[key] = change[edit];
            } else {
              schedule.bidSchedules = schedule.bidSchedules ? schedule.bidSchedules : [];
              schedule.bidSchedules.push({
                startTime,
                endTime,
                configuration: change.configuration,
                he: strHour,
                [key]: change[edit],
              });
            }
          });
        } else if (change.detailType === 'SS') {
          change.edits.forEach((edit) => {
            const strHour = edit.replace('he', '');
            const interval = hours.find((x) => x.prop === edit);
            const startTime = `${interval.time.toISOString().split('.')[0]}Z`;
            const endTime = `${moment(interval.time.toISOString()).add(1, 'hours').toISOString().split('.')[0]}Z`;

            const selfSchedule = change.configuration
              ? schedule.selfSchedules.find((x) => x.he === strHour
                && (x.configuration === change.configuration)
                && (x.startTime === startTime)
                && (x.endTime === endTime))
              : schedule.selfSchedules.find((x) => x.he === strHour
                && (x.startTime === startTime)
                && (x.endTime === endTime));
            const key = change.product.split(' ')[0].toLowerCase();
            if (selfSchedule) {
              selfSchedule[key] = change[edit];
            } else {
              schedule.selfSchedules = schedule.selfSchedules ? schedule.selfSchedules : [];
              schedule.selfSchedules.push({
                startTime: moment(startTime).toISOString(),
                endTime: moment(endTime).toISOString(),
                configuration: change.configuration,
                he: strHour,
                supportingResource: null,
                wheelingResource: null,
                pt: null,
                lpt: null,
                whl: null,
                ru: null,
                rd: null,
                sr: null,
                nr: null,
                lfu: null,
                lfd: null,
                [key]: change[edit],
              });
            }
          });
        }
      }
    });

    try {
      await CISO_BID_SCHD_API.put('/batch', { schedules: updatedSchedules });
    } catch (err) {
      console.error('error saving');
    }
  },

  async saveVirtualSchedules({ state, dispatch }) {
    const changes = state.data.filter((x) => x.edits.length);
    const updatedSchedules = [];
    changes.forEach((change) => {
      let schedule = null;
      if (!updatedSchedules.includes((x) => x.resource === change.resource)) {
        updatedSchedules.push((state.rawData.find((x) => x.resource === change.resource)));
      }
      schedule = updatedSchedules.find((x) => x.resource === change.resource);

      if (schedule) {
        change.edits.forEach((edit) => {
          const hour = parseInt(edit.replace('he', ''), 10).toString();
          const virtualSchedule = schedule.bidSchedules.find((x) => x.he === hour);
          const key = `en_${change.value.toLowerCase()}_${(change.curve <= 9) ? '0' : ''}${change.curve}`;
          virtualSchedule[key] = change[edit];
        });
      }
    });
    try {
      await CISO_BID_SCHD_API.put('virtual/batch', { schedules: updatedSchedules });
    } catch (err) {
      this.$notify({ type: 'error', message: 'Update Failed' });
      console.error(err);
    }
  },
  clearChanges({ state, commit }) {
    commit('setData', state.dataCache);
    commit('setDataEdited', false);
  },
  async matchMarketValues({ state, dispatch }) {
    const result = await confirm(
      'Do you want to overwrite all resources with retrieved market values?',
      'Confirm Action',
    );
    if (!result) {
      return;
    }
    if (!state.dataEdited) {
      state.dataCache = clone(state.data);
      state.dataEdited = true;
    }
    const data = deepClone(state.rawData);
    data.forEach((schedule) => {
      const { bidSchedules } = schedule;
      bidSchedules.forEach((bid) => {
        const marketKeys = Object.keys(bid).filter((key) => key.includes('market_'));
        if (marketKeys.length > 0) {
          marketKeys.forEach((key) => {
            bid[key.replace('market_', '')] = bid[key];
          });
        }
      });
    });

    await dispatch('mapSchedules', data);
  },
};

const mutations = {
  setAgData(state, item) {
    if ((item.row[item.prop] === '') && !item.value) return;
    if (!state.dataEdited) {
      state.dataCache = clone(state.data);
      state.dataEdited = true;
    }
    if (item.value || item.value === 0) {
      item.value = parseFloat(parseFloat(item.value).toFixed(3));
    } else {
      item.value = null;
    }
    const bs = state.data[item.rowIndex];
    Vue.set(bs, item.prop, item.value);
    Vue.set(bs, 'edits', [...bs.edits, item.prop]);
  },
  addTimerCount(state) {
    state.timerCount++;
  },
  ...createMutations(
    'data',
    'agConfig',
    'rawData',
    'dataCache',
    'dataEdited',
    'hasSubLocations',
    'selectedDate',
    'selectedScheduleType',
    'selectedLocationGroupId',
    'selectedSSTypes',
    'selectedASTypes',
    'selectedBidPairs',
    'selectedMarketType',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  mutations,
  actions,
};