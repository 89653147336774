import { sortBy } from '@/utils/dataUtil';
import LOOKUP_STORE from '@/store/lookupStore';
import utils from '@/utils';

export function buildFilter(opt, col, config) {
  let filter = null;
  if (opt?.data && col?.config?.filters?.length) {
    filter = col.config.filters.reduce((acc, {
      isColumnFilter, referenceField, value, op, column, columnField, byValueField,
    }) => {
      if (referenceField && value) {
        let convertedVal = value;
        if (value === 'true') convertedVal = true;
        if (value === 'false') convertedVal = false;

        // handle special case for "within" operator
        // since it's not directly supported by the DX DataSource
        if (op === 'within') {
          const values = value.split(';');
          const rules = [];

          values.forEach((val, idx) => {
            rules.push([referenceField, '=', val], 'or');
          });

          if (rules.length > 0) {
            acc.push(rules, 'and');
          }
        } else {
          acc.push([referenceField, op, convertedVal], 'and');
        }
      }

      if (isColumnFilter) {
        // Finds and filter another by another object in different column
        const filterByColumn = config.columns.find(({ prop }) => prop === column);
        if (filterByColumn.data) {
          // Gets object to filter columns by
          const filterObj = filterByColumn.data
            .find((obj) => obj[col.config.reference.value] === opt.data[column]);
          const filterValue = filterObj ? filterObj[columnField] : null;
          acc.push([referenceField, op, filterValue], 'and');
        }
      }
      // Filters by hard-coded value
      return acc;
    }, []);

    // Removes last 'and' un-nest if single
    if (filter[filter.length - 1] === 'and') filter.splice(-1, 1);
    if (filter.length === 1) [filter] = filter;
    if (filter.length === 0) filter = null;
  }
  return filter;
}

export const customRegistry = (newTradeConfig) => {
  const sysDefColumnStore = {
    timeZone: utils.date.TZ_DEFINE,
    direction: LOOKUP_STORE.state.buySellList,
    period: LOOKUP_STORE.state.periodList,
    termTypeId: LOOKUP_STORE.state.termTypeList,
    trader: LOOKUP_STORE.state.traderList
      .sort(sortBy('userName'))
      .map(({ userName }) => ({ value: userName, label: userName })),
    enablingAgreement: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'enablingAgreement')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
    paymentTerm: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'paymentTerm')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  };

  const registry = {};
  if (newTradeConfig) {
    registry.config = newTradeConfig;
    newTradeConfig.columns.forEach((col) => {
      if (col.config && col.config.type === 'select') {
        registry[col.prop] = (opt) => {
          let store = [];
          let filter = null;
          if (col.config.reference.list === 'custom') {
            store = col.config.customList.split(',').map((value) => ({ value, label: value }));
          } else if (col.config && col.config.reference && col.config.reference.list === 'sysDef') {
            store = sysDefColumnStore[col.prop];
          } else {
            store = col.data;
            if (/*col.prop === 'recMeter'*/ true) {
              // const filterKeys = filter.filter((x) => Array.isArray(x)).map((x) => x[0]);
              // const optionKeys = [...new Set([col.config.reference.label, col.config.reference.value])];
              store = store.map((x) => ({
                ...x,
                label: col.config.reference?.label ? x[col.config.reference?.label] : x.label,
                value: col.config.reference?.value ? x[col.config.reference?.value] : x.value,
              }));

              // it doesnt seem like col.config.reference is considered anywhere else in our code.
              // when using data from lookup to populate a select editor
              // [value] and [label] key values seem to always reflect the lookup definition
              // unless stated otherwise here.
            }
            // Sorts dropdown
            if (col.config.sort && col.config.sort !== 'none') {
              if (store) {
                if (col.config.sortBy) store = store.sort(sortBy(col.config.sortBy, col.config.sort));
                else store = col.data.sort(sortBy('label', col.config.sort));
              }
            }
            filter = buildFilter(opt, col, newTradeConfig);
          }
          return { filter, store };
        };
      }
    });
  }
  return registry;
};

function location({ data }, marketName, locationType, entityType) {
  let store = LOOKUP_STORE.state.locationList
    .filter(
      (l) => l.marketName === marketName
      && l.locationType === locationType
      && l.entityType === entityType,
    )
    .sort(sortBy('shortName'))
    .map((cp) => ({ value: cp.id, label: cp.shortName, ...cp }));
  if (data) {
    let company = 'N/A';
    if (data.companyShortName) company = data.companyShortName;
    if (data.company) {
      company = LOOKUP_STORE.state.companyList
        .find((c) => c.id === data.company)
        .shortName;
    }

    let commodity = data.commodityName;
    if (!commodity && data.commodity) {
      commodity = LOOKUP_STORE.state.energyCommodityList
        .find((x) => x.id === data.commodity)
        .shortName;
    }

    store = store.filter(({ entityName, commodityName }) => commodityName === commodity && company === entityName);
  }
  return { store };
}

// Returns original registry if new config doesn't exists
export const registry = {
  traderList: () => LOOKUP_STORE.state.traderList,
  company: ({ data }) => {
    let store = LOOKUP_STORE.state.companyList
      .filter(({ internalFlag }) => internalFlag)
      .sort(sortBy('shortName'))
      .map((company) => ({
        value: company.id,
        label: company.shortName,
        ...company,
      }));
    if (data?.commodityName) {
      store = store.filter(({ commodities }) => commodities.includes(data.commodityName));
    }
    return { store };
  },
  companyContactId: (opt) => {
    let store = [];
    if (opt.data) {
      store = LOOKUP_STORE.state.contactList
        .filter(({ company }) => company === opt.data.companyShortName)
        .sort(sortBy('fullName'))
        .map((cp) => ({ value: cp.id, label: cp.fullName, ...cp }));
    }
    return { store };
  },
  counterparty({ data }) {
    let store = LOOKUP_STORE.state.companyList
      .sort(sortBy('shortName'))
      .map((cp) => ({ value: cp.id, label: cp.shortName, ...cp }));

    if (data && data.commodityName) {
      store = store.filter(({ commodities }) => commodities.includes(data.commodityName));
    }
    return { store };
  },
  counterpartyContactId: (opt) => {
    let store = [];
    if (opt.data) {
      store = LOOKUP_STORE.state.contactList
        .filter(({ company }) => company === opt.data.counterpartyShortName)
        .sort(sortBy('fullName'))
        .map((cp) => ({ value: cp.id, label: cp.fullName, ...cp }));
    }
    return { store };
  },
  counterpartyCreditId(opt) {
    let store = LOOKUP_STORE.state.companyList
      .sort(sortBy('shortName'))
      .map((cp) => ({ value: cp.id, label: cp.shortName, ...cp }));
    if (opt.data) {
      store = store.filter(({ commodities }) => commodities.includes(opt.data.commodityName));
    }
    return { store };
  },
  counterpartyCreditContactId: (opt) => {
    let store = [];
    if (opt.data) {
      store = LOOKUP_STORE.state.contactList
        .filter(({ company }) => company === opt.data.counterpartyCreditShortName)
        .sort(sortBy('fullName'))
        .map((cp) => ({ value: cp.id, label: cp.fullName, ...cp }));
    }
    return { store };
  },
  termInterval: LOOKUP_STORE.state.termIntervalList,
  timeZone: utils.date.TZ_DEFINE,
  commodity: ({ data }) => ({
    // filter: data?.commodityParent ? ['parentId', '=', data.commodityParent] : null,
    store: LOOKUP_STORE.state.energyCommodityList
      .sort(sortBy('shortName'))
      .map((com) => ({ value: com.id, label: com.shortName, ...com })),
  }),

  fuelType: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'fuelType')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),

  direction: LOOKUP_STORE.state.buySellList,

  optionType: ['CALL', 'PUT'].map((value) => ({ label: value, value })),
  optionStyle: ['American', 'European'].map((value) => ({ label: value, value })),
  exerciseFrequency: ['DAILY', 'WEEKLY', 'MONTHLY'].map((value) => ({ label: value, value })),
  optionPricingModel: ['Black-Scholes', 'Black76'].map((value) => ({ label: value, value })),

  broker: ({ data }) => ({
    store: LOOKUP_STORE.state.brokerList
      .sort(sortBy('name'))
      .map((b) => ({ value: b.id, label: b.name, ...b })),
  }),
  regionId: ({ data }) => ({
    filter: data?.commodityName ? ['commodityName', '=', data.commodityName] : null,
    store: LOOKUP_STORE.state.marketList
      .sort(sortBy('shortName'))
      .map((m) => ({ value: m.id, label: m.shortName, ...m })),
  }),
  market: ({ data }) => ({
    filter: data?.commodityName ? ['commodityName', '=', data.commodityName] : null,
    store: LOOKUP_STORE.state.marketList
      .sort(sortBy('shortName'))
      .map((m) => ({ value: m.id, label: m.shortName, ...m })),
  }),

  pointOfDeliveryId: ({ data }) => location({ data }, 'TRADE', 'POD', 'COMPANY'),

  pointOfReceiptId: ({ data }) => location({ data }, 'TRADE', 'POR', 'COMPANY'),

  pipeline: ({ data }) => location({ data }, 'TRADE', 'PIPELINE', 'COMPANY'),

  meter: ({ data }) => location({ data }, 'TRADE', 'METER', 'COMPANY'),
  unit: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'uom')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  portfolio: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'portfolio')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  firmType: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'firmType')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  book: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'book')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  strategy: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'strategy')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  // TODO: use shortName when API is updated
  enablingAgreement: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'enablingAgreement')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  paymentTerm: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'paymentTerm')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  environmentalSubAccount: ({ data }) => ({
    filter: data?.commodityName ? ['commodityName', '=', data.commodityName] : null,
    store: LOOKUP_STORE.state.environmentalSubAccountList
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.shortName, ...p })),
  }),
  isoReferenceName: ({ data }) => {
    // use market name form the data row or find market by ID from the template
    const marketName = data?.marketShortName
      || LOOKUP_STORE.state.marketList.find(({ id }) => id === data?.market)?.shortName;

    const store = LOOKUP_STORE.state.isoReferenceList
      .filter(({ market }) => market === marketName
        || data == undefined /* need last condition so that existing value is displayed in the cell */)
      .sort(sortBy('refName'))
      .map((m) => ({ value: m.refName, label: m.refName }));

    return { store };
  },
  termPriceIndex: ({ data }) => ({
    filter: data?.commodityName ? ['commodity', '=', data.commodityName] : null,
    store: LOOKUP_STORE.state.priceList
      .sort(sortBy('name'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  termPriceIndex2: ({ data }) => ({
    filter: data?.commodityName ? ['commodity', '=', data.commodityName] : null,
    store: LOOKUP_STORE.state.priceList
      .sort(sortBy('name'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  marketPriceIndex: ({ data }) => ({
    filter: data?.commodityName ? ['commodity', '=', data.commodityName] : null,
    store: LOOKUP_STORE.state.priceList
      .sort(sortBy('name'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  intraMonthMarketPriceIndex: ({ data }) => ({
    filter: data?.commodityName ? ['commodity', '=', data.commodityName] : null,
    store: LOOKUP_STORE.state.priceList
      .sort(sortBy('name'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),

  termDeliverableType: ({ data }) => ({
    store: LOOKUP_STORE.state.deliverableTypeList
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.shortName, ...p })),
  }),
  termTypeId: ({ data }) => {
    const store = LOOKUP_STORE.state.termTypeList
      .filter(
        ({ shortName }) => data?.commodityName === 'NG' ? shortName === 'DAILY' : true,
      )
      .sort(sortBy('shortName'))
      .map(({ shortName, id }) => ({ value: id, label: shortName }));

    return { store };
  },

  period: LOOKUP_STORE.state.periodList,

  termProduct: ({ data }) => ({
    store: LOOKUP_STORE.state.catalogList
      .filter(({ type }) => type === 'termProduct')
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),
  trader: () => ({
    store: LOOKUP_STORE.state.traderList
      .sort(sortBy('userName'))
      .map(({ userName }) => ({ value: userName, label: userName })),
  }),
  tradeType: ({ data }) => ({
    store: LOOKUP_STORE.state.tradeTypeList
      .sort(sortBy('shortName'))
      .map((p) => ({ value: p.id, label: p.name, ...p })),
  }),

  // NG trades

  counterparty2({ data }) {
    let store = LOOKUP_STORE.state.companyList
      .sort(sortBy('shortName'))
      .map((cp) => ({ value: cp.id, label: cp.shortName, ...cp }));

    if (data && data.commodityName) {
      store = store.filter(({ commodities }) => commodities.includes(data.commodityName));
    }
    return { store };
  },

  // tagTemplate: state.tagTemplates
  //   .sort(sortBy('name'))
  //   .map(({ name, id }) => ({ value: id, label: name })),
};