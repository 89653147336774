import moment from 'moment';
import Vue from 'vue';
import {
  has, clone, HEColumns, deepClone,
} from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';
import dateStore from '@/utils/dateStore';
import * as dateUtil from '@/utils/dateUtil';
import { createMutations } from '@/utils/vuexHelper';
import { createTimeArray } from '@/utils/rruleUtil';

const HOUR_IN_MIN = 60;

// eslint-disable-next-line
const resetData = (date, commodity, termTypeName, tz = dateStore.getTimeZone(), granularity = HOUR_IN_MIN, periodParam = null, effectiveStartDate = null, effectiveEndDate = null) => {

  let columns = HEColumns({}, true, date, granularity, tz);
  let period = periodParam;
  
  if (termTypeName === 'DAILY' /* && commodity === 'NG' */) {
    period = 'DAILY';
  }

  if (termTypeName === 'NONE' /* && commodity === 'NG' */) {
    period = 'NONE';
  }

  // handle periods
  if (period != null) {
    // DAILY => Daily, etc.
    period = dateStore.getNormalizedPeriod(period);

    let rangeEnd = null;
    if (effectiveEndDate) {
      const columns1 = HEColumns({}, true, effectiveEndDate?.toISOString() , granularity, tz);
      rangeEnd = columns1[0].timeTZ;
    }

    // this should expand the range to the period
    const range = [columns[0].timeTZ, rangeEnd];
    columns = dateStore.getTimeRangeByPeriod(range, period, tz, effectiveEndDate);
    return getPeriodData(range, period, tz, granularity, effectiveStartDate ?? date, effectiveEndDate);
  }

  const hours = mapIntervalColumns(columns, granularity);
  return hours;
};

const setAllData = (currentTrade, granularity, tz) => {
  const zone = dateUtil.getZoneName(tz)?.tz;
  const period = dateStore.getNormalizedPeriod(currentTrade.period);
  const start = dateUtil.toMoment(currentTrade.effectiveStartDate, 'YYYY-MM-DD', zone);
  const end = dateUtil.toMoment(currentTrade.effectiveEndDate, 'YYYY-MM-DD', zone);
  const range = [start, end];

  return getPeriodData(range, period, tz, granularity, start, end);
};

const getPeriodData = (range, period, tz, granularity, tradeStart, rangeEndMax = null) => {
  const columns = dateStore.getTimeRangeByPeriod(range, period, tz, rangeEndMax);
  const hours = mapIntervalColumns(columns, granularity);
  
  const zone = dateUtil.getZoneName(tz)?.tz;

  if (period != null && period !== 'DAILY' ) {
    if (isFirstPeriod(range[0], tradeStart, period, zone)) {
      const earliestDateIndex = hours.reduce((earliestIndex, current, currentIndex, array) => {
        return current.momentStartTime.isBefore(array[earliestIndex].momentStartTime) ? currentIndex : earliestIndex;
      }, 0);
      let ob = hours.splice(earliestDateIndex, 1)[0];
      ob.momentStartTime = tradeStart;
      ob.momentEndTime = tradeStart.clone().add(state.selectedGranularity, 'minutes'),
      hours.unshift(ob);
    }
  }
  return hours
};

const isFirstPeriod = (date, tradeStart, period, zone) => {
  // there is only one period for 'NONE'
  if (period === 'None')
    return true;
  
  if (period != null && period !== 'DAILY') {
    const momentDate = dateUtil.toMoment(date, 'YYYY-MM-DD', zone);
    const firstPeriodStart = dateStore.getStartOfPeriod(tradeStart, period, zone);
    const firstPeriodEnd = dateStore.getEndOfPeriod(tradeStart, period, zone);
    const inFirstPeriod = momentDate.isBefore(firstPeriodEnd) && momentDate.isAfter(firstPeriodStart);
    return inFirstPeriod;
  }
}

const mapIntervalColumns = (columns, granularity) => {
  return columns
    .map(({ he, time, timeTZ }) => ({
      he,
      startTime: timeTZ.format('HH:mm'),
      momentStartTime: timeTZ,
      endTime: timeTZ.clone().add(granularity, 'minutes').format('HH:mm'),
      momentEndTime: timeTZ.clone().add(granularity, 'minutes'),
      cascaded: {},
  }));
}

// Generates consolidated interval based off of new trade term type and values
const generateConsolidatedInterval = (state) => {
  if (!Object.keys(state.newTradeVariants).length || !state.newTradeTermTypeMetaData) return;
  state.consolidatedIntervals = [];
  const { termType } = state.newTradeTermTypeMetaData;

  const start = moment(state.consolidatedDateRange[0]);
  const end = moment(state.consolidatedDateRange[1]);
  while (start.isSameOrBefore(end.endOf('day'))) {
    const definition = termType.termTypeDefinitions
      .find(({ dayOfWeek }) => dayOfWeek.toLowerCase() === start.format('dddd').toLowerCase());
    const hasHeVal = Object.entries(definition).reduce((acc, [key, val]) => {
      if (key.includes('he')) {
        const he = Number(key.split('he')[1]);
        acc[he] = val;
      }
      return acc;
    }, {});

    Object.entries(hasHeVal).forEach(([he, hasVal]) => {
      if (hasVal) {
        const startTime = start.clone().add(he, 'hour').toISOString();
        const endTime = moment(startTime).add(1, 'hour').toISOString();
        // Code to combine time if has same value
        const previousInt = state.consolidatedIntervals.slice(-1)[0];
        if (
          previousInt
          && previousInt.endTime === startTime
          /* Filters out start and end time on last interval and stringify it to
          check if it's an exact match with the one in the store to combine it */
          // eslint-disable-next-line
          && JSON.stringify(Object.fromEntries(Object.entries(previousInt).filter(([key]) => !['startTime', 'endTime'].includes(key)))) === JSON.stringify({ ...state.newTradeVariants })
        ) {
          previousInt.endTime = endTime;
        } else {
          const payload = { startTime, endTime };
          Object.assign(payload, state.newTradeVariants);
          state.consolidatedIntervals.push(payload);
        }
      }
    });
    start.add(1, 'day');
  }
};

const defaultTableConfig = (isEditingTrade, currentTrade, config) => {
  // not used anyway - need to check how
  // const hasWriteAccess = rootGetters['auth/hasPermission']({ name: etrm.trades, right: [accessRight.write] });
  const clonedConfig = deepClone(config);
  if (!isEditingTrade) {
    clonedConfig.columns.forEach((c) => { c.editable = false; });
  } else if (currentTrade && currentTrade.status === 'PENDING') {
    clonedConfig.columns.forEach((c) => { c.editable = false; });
  }
  return clonedConfig;
};

const summarizeData = (data, selectedGranularity) => {
  const sum = { startTime: 'Total', summaryFlag: true };
  const priceCount = {};
  data.forEach((row) => {
    Object.entries(row).forEach(([key, val]) => {
      if (!['startTime', 'endTime', 'he', 'momentStartTime', 'momentEndTime'].includes(key)) {
        const rowValue = ((selectedGranularity / HOUR_IN_MIN) * val);
        if (sum[key] !== undefined && !Number.isNaN(rowValue)) {
          sum[key] += rowValue;
          if (key.toLowerCase().includes('price')) priceCount[key] += 1;
        } else {
          sum[key] = rowValue;
          if (key.toLowerCase().includes('price')) priceCount[key] = 1;
        }
      }
    });
  });

  // Rounds each of the sum
  Object.entries(sum).forEach(([key, val]) => {
    if (!['startTime', 'endTime', 'he', 'momentStartTime', 'momentEndTime'].includes(key)) {
      sum[key] = Number.isNaN(val) ? 0 : val;
      
      if (key.toLowerCase().includes('price')) {
        sum[key] /= priceCount[key];
      }
      
      if (key.toLowerCase().includes('price') || key.toLowerCase().includes('amount')) {
        sum[key] = parseFloat(sum[key]).toFixed(2);
        sum[key] = sum[key] < 0 ? `-$${Math.abs(sum[key]).toFixed(2)}` : `$${sum[key]}`;
      } else {
        sum[key] = parseFloat(sum[key]).toFixed(2);
      }
    }
  });
  return [sum];
};

const getMaximumPeriodEnd = (dailyDate, period, zone) => {
  period = dateStore.getNormalizedPeriod(period);
  const periodMaxRange = dateStore.getPeriodRange(period);
  const periodInterval = dateStore.getIntervalFromPeriod(period);

  let momentStart = dateUtil.toMoment(dailyDate, 'YYYY-MM-DD', zone);
  const periodStart = dateUtil.toMoment(dateStore.getStartOfPeriod(momentStart, period, zone), 'YYYY-MM-DD', zone);
  const maxPeriodEnd = periodStart.clone().add(periodMaxRange, periodInterval).subtract(1, 'day');

  return maxPeriodEnd;
}

const state = {
  tabs: [{
    title: 'Interval',
    component: 'daily-interval',
  // }, {
  //   title: 'Consolidated',
  //   component: 'consolidated-intervals',
  }, {
    title: 'Range',
    component: 'range-intervals',
  }],
  dailyDate: dateStore.getDefaultDate().format('YYYY-MM-DD'),
  cachedChangedIntervals: {},
  selectedGranularity: HOUR_IN_MIN,
  granularities: [HOUR_IN_MIN].map((value) => ({ label: value, value })),
  consolidatedIntervals: [],
  intervalConfig: {
    columns: [{
      prop: 'startTime', label: 'START', filterable: true, dataType: 'datetime', editable: false,
    }, {
      prop: 'endTime', label: 'END', filterable: true, dataType: 'datetime', editable: false,
    }],
    style: { dynamicSizing: true },
    options: { summaryRows: true },
  },
  config: {
    columns: [{
      prop: 'startTime', label: 'Start', width: 50, editable: false,
    }, {
      prop: 'endTime', label: 'End', width: 50, editable: false,
    }, {
      prop: 'he', label: 'Interval', width: 25, editable: false,
    }],
    style: { dynamicSizing: true },
    options: { summaryRows: true },
  },
  rangeConfig: {
    columns: [{
      prop: 'startTime', label: 'Start', width: 50, editable: false,
    }, {
      prop: 'endTime', label: 'End', width: 50, editable: false,
    }, {
      prop: 'he', label: 'Interval', width: 25, editable: false,
    }],
    style: { dynamicSizing: true },
    options: { summaryRows: true },
  },
  rangeDateRange: dateStore.getDefaultRange(),
  consolidatedDateRange: dateStore.getDefaultRange(),
  data: [],
  rangeIntervalData: [],
  newTradeTermTypeMetaData: null,
  newTradeVariants: [],
  selectedRangeVariants: [],
  selectedTabIndex: 0,
  adder: null,
  multiplier: null,
};

const getters = {
  summaryData: (state) => summarizeData(state.data, state.selectedGranularity),
  rangeSummaryData: (state) => summarizeData(state.rangeIntervalData, state.selectedGranularity),
  consolidatedSummary: (state) => {
    const sum = { startTime: 'Total', summaryFlag: true };
    state.consolidatedIntervals.forEach((row) => {
      Object.entries(row).forEach(([key, val]) => {
        if (!['startTime', 'endTime', 'he', 'momentStartTime', 'momentEndTime', 'type', 'variant'].includes(key)) {
          sum[key] = (sum[key] + val) || val;
        }
      });
    });
    return [sum];
  },
  getTabs: (state) => state.tabs,

  dailyIntervalTableConfig: (state) => {
    const { isEditingTrade, currentTrade, config } = state;
    const newConfig = defaultTableConfig(isEditingTrade, currentTrade, config);
    if (currentTrade && currentTrade.termInterval >= 60) {
      const newConfigCols = newConfig.columns.map((col) => {
        if (['startTime', 'endTime'].includes(col.prop)) return { ...col, visible: false };
        return col;
      });
      newConfig.columns = newConfigCols;
    }
    return newConfig;
  },
  rangeIntervalTableConfig: (state) => {
    const { isEditingTrade, currentTrade, rangeConfig } = state;
    const newConfig = defaultTableConfig(isEditingTrade, currentTrade, rangeConfig);
    if (currentTrade && currentTrade.termInterval >= 60) {
      const newConfigCols = newConfig.columns.map((col) => {
        if (['startTime', 'endTime'].includes(col.prop)) return { ...col, visible: false };
        return col;
      });
      newConfig.columns = newConfigCols;
    }
    return newConfig;
  },
  consolidatedIntervalTableConfig: (state) => {
    const { isEditingTrade, currentTrade, intervalConfig } = state;

    const clonedConfig = defaultTableConfig(isEditingTrade, currentTrade, intervalConfig);
    const allowStatuses = ['NEW', 'ERRORED'];

    // let user edit intervals if:
    // currentTrade is null or if the status is "NEW" or "ERRORED"
    clonedConfig.columns.forEach((col) => {
      if (!['START', 'END', 'AMOUNT'].includes(col.label) || !col.isDynamic) {
        col.editable = (!currentTrade || allowStatuses.includes(currentTrade.status));
        if (!isEditingTrade) col.editable = false;
      }
    });

    return clonedConfig;
  },
};

const actions = {
  resizeDaily({ state, commit, dispatch }) {
    // this will trigger grid size adjustment
    dispatch('fetchIntervals');
  },
  setData({ commit, dispatch, state }, { rowIndex, prop, value }) {
    commit('setData', { rowIndex, prop, value });
    commit('setDateIntervalCache');
  },
  changeConsolidatedDateRange({ commit, dispatch }, dateRange) {
    commit('setConsolidatedDateRange', dateRange);
    dispatch('fetchConsolidatedIntervals');
  },
  async changeDailyDate({ state, commit, dispatch }, value) {
    // we only care about the date part
    let date = null;
    if (typeof value === 'object') date = moment(value).format('YYYY-MM-DD');
    else date = value.substring(0, 10);

    // and we do not want to trigger the change for the same date
    if (state.dailyDate !== date) {
      commit('setDateIntervalCache');
      commit('setDailyDate', date);
      await dispatch('fetchIntervals');
      return true;
    }
    return false;
  },
  async getAllIntervals({ commit, state, dispatch }) {
    const { currentTrade } = state;
    const currentTradeId = currentTrade?.tradeId;
    const tz = currentTrade?.timeZone;

    if (!currentTradeId || (currentTradeId && currentTradeId < 0)) {
      commit('setIntervalData', { intervals: [], tz });
      commit('setDataFromCache');
      return;
    }

    const zone = dateUtil.getZoneName(tz).tz;
    
    const period = dateStore.getNormalizedPeriod(currentTrade.period);
    const interval = dateStore.getIntervalFromPeriod(period);
    const start = dateUtil.toMoment(state.currentTrade.effectiveStartDate, 'YYYY-MM-DD', zone);
    const end = dateUtil.toMoment(state.currentTrade.effectiveEndDate, 'YYYY-MM-DD', zone).add(1, interval);

    const params = {
      objectTypes: 'TradeTerm',
      objectReferences: currentTradeId,
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      granularity: state.selectedGranularity,
      // Splits and unique lists of selected variants from tag box
      variants: [...new Set(state.selectedVariants.map((val) => val.split('-')[0]))].join(','),
      cascadeVariants: state.cascadeVariants,
    };

    const { data: { data } } = await dispatch('getIntervals', params);
    // need to fix this setter to handle all interval date range
    commit('setIntervalData', { intervals: data, tz, setAll: true });
    commit('setDataFromCache');
  },
  async changeRangeDateRange({ state, commit, dispatch }, dateRange) {
    const prevDateRange = state.rangeDateRange;
    const timeZoneName = dateStore.getTimeZone();
    const startDate = dateStore.toDateFromLocal(dateRange[0], timeZoneName).toISOString();
    const endDate = dateStore.toDateFromLocal(dateRange[1], timeZoneName).toISOString();
    commit('setRangeDateRange', [startDate, endDate]);

    if (prevDateRange[0] !== dateRange[0] && prevDateRange[1] !== dateRange[1]) {
      commit('setDateIntervalCache');

      let newDailyDate = dateStore.toDateFromLocal(dateRange[0]).utc().startOf('day').toISOString()
        .split('T')[0];
      newDailyDate = moment(newDailyDate).format('YYYY-MM-DD');
      commit('setDailyDate', newDailyDate);

      await dispatch('fetchIntervals');
      return true;
    }

    return false;
  },
  async fetchConsolidatedIntervals({ commit, state }) {
    const { currentTrade } = state;
    const currentTradeId = currentTrade ? currentTrade.tradeId : null;

    if (!currentTradeId || currentTradeId < 0) {
      console.log('no tradeId');
      return;
    }
    const tz = currentTrade.timeZone;
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(state.consolidatedDateRange, tz);
    const start = momentRange[0].startOf('day').utc();
    const end = momentRange[1].startOf('day').add(1, 'days').utc();

    const params = {
      objectTypes: 'TradeTerm',
      objectReferences: currentTradeId,
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      granularity: state.selectedGranularity,
      variants: [...new Set(state.selectedVariants.map((val) => val.split('-')[0]))].join(','),
    };

    try {
      const { data: { data } } = await STRUCTURES_API.get('intervals', { params });
      commit('setConsolidatedIntervals', { intervals: data, tz });
    } catch (error) {
      console.error(error);
      this.$notify(`Error loading trade ${currentTradeId}`, 'warning');
    }
  },
  async getIntervals({ }, params) {
    try {
      const data = await STRUCTURES_API.get('intervals', { params });
      return data;
    } catch (error) {
      console.error(error);
      this.$notify(`Error loading intervals for trade ${params.objectReferences}`, 'error');
    }
  },
  async fetchIntervals({ commit, state, dispatch }) {
    const { currentTrade } = state;
    const currentTradeId = currentTrade?.tradeId;
    const tz = currentTrade?.timeZone;

    if (!currentTradeId || (currentTradeId && currentTradeId < 0)) {
      commit('setIntervalData', { intervals: [], tz });
      commit('setDataFromCache');
      return;
    }

    const zone = dateUtil.getZoneName(tz).tz;
    let start = dateUtil.toMoment(state.dailyDate, 'YYYY-MM-DD', zone);
    let end = dateUtil.toMoment(state.dailyDate, 'YYYY-MM-DD', zone).add(1, 'day');

    const termType = currentTrade.termTypeName;
    let { period } = currentTrade;

    if (termType === 'DAILY' /* && commodity === 'NG' */) {
      period = 'DAILY';
    }
      
    if (termType === 'NONE' /* && commodity === 'NG' */) {
      period = 'NONE';
    }
  
    if (period != null) {
      period = dateStore.getNormalizedPeriod(period);
      const range = dateStore.getPeriodRange(period);
      const interval = dateStore.getIntervalFromPeriod(period);

      let periodStart = dateUtil.toMoment(dateStore.getStartOfPeriod(start, period, zone), 'YYYY-MM-DD', zone);
      let tradeStart = dateUtil.toMoment(currentTrade.effectiveStartDate, 'YYYY-MM-DD', zone);
      end = getMaximumPeriodEnd(state.dailyDate, period, zone).add(1, 'day'); // api needs the extra day to get max period for daily term
      start = periodStart.isBefore(tradeStart) ? tradeStart : periodStart;
    }

    const params = {
      objectTypes: 'TradeTerm',
      objectReferences: currentTradeId,
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      granularity: state.selectedGranularity,
      // Splits and unique lists of selected variants from tag box
      variants: [...new Set(state.selectedVariants.map((val) => val.split('-')[0]))].join(','),
      cascadeVariants: state.cascadeVariants,
    };
    const { data: { data } } = await dispatch('getIntervals', params);
    commit('setIntervalData', { intervals: data, tz });
    commit('setDataFromCache');

    // fetch interval adjustments to display adder and multiplier
    params.types = ['PRICE'];
    params.variants = ['CONTRACT'];

    try {
      const intervalAdjustments = await STRUCTURES_API.get('intervals/adjustments', { params });
      commit('setIntervalAdjustmentsData', { intervalAdjustments: intervalAdjustments?.data, tz });
    } catch (error) {
      console.error(error);
      this.$notify(`Error loading interval adjustments for trade ${currentTradeId}`, 'error');
    }
  },
  async saveIntervals({ commit, dispatch }, currentTrade) {
    if (!currentTrade.tradeId || currentTrade.tradeId < 0) {
      console.log('no tradeId');
      return;
    }
    commit('setDateIntervalCache');
    try {
      currentTrade.savedFromInterval = true;
      await dispatch('etrm/trades/updateTradeAPI', currentTrade, { root: true });
      delete currentTrade.savedFromInterval;
      commit('resetIntervalCache');
      dispatch('fetchIntervals');
    } catch (error) {
      console.log(error);
      this.$notify(`Error updating trade: ${currentTrade.tradeId}`, 'error');
    }
  },
  async addIntervalsWithRule({ commit, dispatch, state }, { rule, data }) {
    const { currentTrade } = state;

    const termType = currentTrade.termTypeName;

    let count = 0;
    const ruledIntervals = {};
    const tz = currentTrade.timeZone;
    const zone = dateUtil.getZoneName(tz).tz;

    const times = createTimeArray(rule, dateStore, tz);
    const granularity = termType === 'DAILY' || termType === 'PERIOD' || termType === 'CALC' ? 1440 : currentTrade.termInterval;

    let firstDayIsSHort = false;

    times.forEach((date, idx) => {
      const startTime = date.momentStartTime.format('YYYY-MM-DD');
      const momDate = moment.tz(startTime, zone);

      const isShortDay = dateStore.isShortDay(momDate);
      const isLongDay = dateStore.isLongDay(momDate);

      // when starting on short day, there may be one interval missing
      if (idx === 0 && isShortDay) firstDayIsSHort = true;

      const ranges = dateStore.getTimeRange(momDate, granularity, tz);

      const validIntervals = [];
      let offset = 0;
      let rangeOffset = 0;

      let extraInterval = null;

      ranges.forEach((range) => {
        const intervals = clone(data);
        let matchingInterval = null;
        intervals.forEach((interval) => {
          // verify that this is a valid period
          const momInterval = moment(interval.momentStartTime);
          const momRange = range.time;
          const isSameDay = momInterval.isSame(momRange, 'date');
          if (interval.he === range.he || ((termType === 'DAILY' && isSameDay) || (termType === 'PERIOD' && isSameDay) || (termType === 'CALC' && isSameDay))) {
          // use HE2 in place of HE25 to calculate the offset
            const he = interval.he === '25' ? '2' : interval.he;

            if (range.dstFlag && offset === 0) offset++;

            // when crossing hort day hour, we need to rangeOffset
            if (range.he === '4' && isShortDay && offset === 0) offset--;

            const intervalStartTime = momDate.clone().add(he - 1, 'hours').add(offset, 'hours');
            let intervalEndTime = momDate.clone().add(he, 'hours').add(offset, 'hours');
            const rangeTime = range.timeTZ.clone().add(rangeOffset, 'hours');

            // if all times are the same, add one hour to the end
            if (intervalStartTime.toISOString() === rangeTime.toISOString()
                  && intervalEndTime.toISOString() === rangeTime.toISOString()) {
              intervalEndTime = intervalEndTime.add(1, 'hours');
            }

            const isBetweenHE = rangeTime.isBetween(intervalStartTime, intervalEndTime, null, '[)'); // need to exclude momentEndTime

            if (isBetweenHE) {
              interval.momentStartTime = intervalStartTime;
              interval.momentEndTime = intervalEndTime;

              matchingInterval = clone(interval);

              if (extraInterval === null && range.he === '2' && !range.dstFlag) {
                extraInterval = clone(interval);
                extraInterval.momentStartTime = interval.momentStartTime.clone().add(1, 'hours');
                extraInterval.momentEndTime = interval.momentEndTime.clone().add(1, 'hours');
              }

              if (offset === 2) {
                interval.momentStartTime = intervalStartTime.add(-1, 'hours');
                interval.momentEndTime = intervalEndTime.add(-1, 'hours');
              }

              validIntervals.push(interval);
              count++;
            }
          }
        });

        // trigger offset flag once but to the end of the day
        if (range.dstFlag && offset === 0) offset++;

        // if DST interval was missing, copy previous over
        if (range.dstFlag && matchingInterval == null) {
          offset++;
          rangeOffset++;
          if (extraInterval) { extraInterval.he = range.he; } else { extraInterval = { he: range.he }; }
          validIntervals.push(extraInterval);
          count++;
        }

        // fill in missing HE3 for all days after short DST and only from a previous interval
        if (firstDayIsSHort && !isShortDay && range.he === '3' && matchingInterval === null && extraInterval !== null && extraInterval.he === '2') {
          const extraInterval2 = clone(extraInterval);
          extraInterval2.he = range.he;
          extraInterval2.momentStartTime = momDate.clone().add(range.he - 1, 'hours');
          extraInterval2.momentEndTime = momDate.clone().add(range.he, 'hours');
          extraInterval2.startTime = extraInterval2.momentStartTime.clone().format('hh:mm');
          extraInterval2.endTime = extraInterval2.momentEndTime.clone().format('hh:mm');

          validIntervals.push(extraInterval2);
          count++;
        }
      });

      ruledIntervals[momDate.format('YYYY-MM-DD')] = validIntervals;
    });

    // clear manually entered intervals before applying the range
    commit('resetIntervalCache');

    // now apply ranges
    commit('setDateIntervalCache', ruledIntervals);
    this.$notify(`Successfully generated ${count} intervals`, 'info');
  },
  async applyAdjustments({ commit, dispatch, state }, item) {
    const { currentTrade } = state;
    const tz = currentTrade.timeZone;

    const endDate = currentTrade?.effectiveEndDate ? dateStore.toDateFromLocal(currentTrade?.effectiveEndDate, currentTrade?.timeZone)?.toISOString() : null;
    const range = resetData(state.dailyDate, currentTrade?.commodityName, currentTrade?.termTypeName, tz, state.selectedGranularity, currentTrade?.period, null, endDate);

    let startTime = null;
    let endTime = null;

    if (range?.length > 1) {
      startTime = range[0];
      endTime = range[range.length - 1];
    } else if (range?.length == 1) {
      startTime = range[0];
      endTime = range[0];
    }

    if (startTime != null && endTime != null) {
      const adjustment = {
        objectType: 'TradeTerm',
        objectReference: currentTrade.tradeId,
        type: 'PRICE',
        variant: 'CONTRACT',
        startTime: startTime.momentStartTime.toISOString(),
        endTime: endTime.momentEndTime.toISOString(),
        adder: state.adder,
        multiplier: state.multiplier,
      };

      try {
        await STRUCTURES_API.post('intervals/adjustments', [adjustment]);
      } catch (error) {
        console.error(error);
        this.$notify(`Error updating interval adjustments for trade ${currentTrade.tradeId}`, 'error');
      }
    }
  },
};

const mutations = {
  resetIntervals(state) {
    state.data = resetData(state.dailyDate);
    state.selectedTabIndex = 0;
    state.newTradeTermTypeMetaData = null;
    state.newTradeVariants = [];
    state.consolidatedIntervals = [];
    state.cachedChangedIntervals = {};
    state.adder = null;
    state.multiplier = null;
  },
  setSelectedTabIndex(state, index) {
    state.selectedTabIndex = index;
  },
  setIntervalData(state, item) {
    const { intervals, tz, setAll } = item;
    const { currentTrade } = state;
    const zone = dateUtil.getZoneName(tz)?.tz;

    const granularity = state.selectedGranularity;
    if (setAll) {
      state.data = setAllData(currentTrade, granularity, tz);
    } else {
      if (currentTrade?.termTypeName === 'DAILY' || currentTrade?.termTypeName === 'PERIOD') {

        var currentPeriod = currentTrade?.termTypeName === 'DAILY' ? 'DAILY' : currentTrade?.period;
        const tradeStart = currentTrade?.effectiveStartDate ? dateStore.toDateFromLocal(currentTrade?.effectiveStartDate, currentTrade?.timeZone) : null;
        const tradeEnd = currentTrade?.effectiveEndDate ? dateStore.toDateFromLocal(currentTrade?.effectiveEndDate, currentTrade?.timeZone) : null;

        const maxPeriodEnd = getMaximumPeriodEnd(state.dailyDate, currentPeriod, zone);
        const endDate = currentTrade?.effectiveEndDate ? moment.min(maxPeriodEnd, tradeEnd)  : null;

        state.data = resetData(state.dailyDate, currentTrade?.commodityName, currentTrade?.termTypeName, tz, granularity, currentTrade?.period, tradeStart, endDate);
      }
      else {
        const startDate = currentTrade?.effectiveStartDate ? dateStore.toDateFromLocal(currentTrade?.effectiveStartDate, currentTrade?.timeZone) : null;
        const endDate = currentTrade?.effectiveEndDate ? dateStore.toDateFromLocal(currentTrade?.effectiveEndDate, currentTrade?.timeZone) : null;
        state.data = resetData(state.dailyDate, currentTrade?.commodityName, currentTrade?.termTypeName, tz, granularity, currentTrade?.period, startDate, endDate);
      }
    }

    intervals.forEach(({
      startTime, endTime, type, value, variant, cascadedFlag,
    }) => {
      const intervalStartTime = moment.tz(startTime, zone);

      for (let i = 0; i < state.data.length; i++) {
        const start = moment.tz(state.data[i].momentStartTime.toISOString(), zone);
        const end = moment.tz(state.data[i].momentEndTime.toISOString(), zone);

        const isBetweenHE = intervalStartTime.isBetween(start, end, null, '[)'); // need to exclude momentEndTime

        if (isBetweenHE) {
          // Sets interval times
          const intVar = `${variant.toUpperCase()}-${type.toUpperCase()}`;
          Vue.set(state.data[i], intVar, value);
          state.data[i].cascaded[intVar] = cascadedFlag;
        }
      }
    });
  },
  setIntervalAdjustmentsData(state, item) {
    const { intervalAdjustments, tz } = item;
    const { currentTrade } = state;

    const zone = dateUtil.getZoneName(tz).tz;
    const momentDailyDate = moment.tz(state.dailyDate, zone);

    state.adder = null;
    state.multiplier = null;

    intervalAdjustments?.forEach(({
      startTime, endTime, type, adder, multiplier, variant,
    }) => {
      const intervalStartTime = moment.tz(startTime, zone);
      const intervalEndTime = moment.tz(endTime, zone);

      // find matching adjustment
      if (momentDailyDate >= intervalStartTime && momentDailyDate <= intervalEndTime) {
        state.adder = adder;
        state.multiplier = multiplier;
      }
    });
  },
  setDateIntervalCache(state, ruledIntervals) {
    if (ruledIntervals) {
      state.cachedChangedIntervals = ruledIntervals;
    } else {
      const date = moment(state.dailyDate).format('YYYY-MM-DD');
      const updates = state.data.filter(({ $updated }) => $updated);
      if (state.cachedChangedIntervals[date]) {
        updates.forEach((interval) => {
          const foundIntervalIdx = state.cachedChangedIntervals[date].findIndex(
            ({ startTime, endTime, he }) => startTime === interval.startTime
              && endTime === interval.endTime
              && he === interval.he,
          );
          // If not in cache the add otherwise replace from cache
          if (foundIntervalIdx === -1) state.cachedChangedIntervals[date].push(interval);
          else state.cachedChangedIntervals[date].splice(foundIntervalIdx, 1, interval);
          interval.$updated = false;
        });
      } else {
        state.cachedChangedIntervals[date] = updates;
      }
    }
  },
  setDataFromCache(state) {
    if (state.currentTrade?.termTypeName === 'DAILY' || state.currentTrade?.termTypeName === 'PERIOD' || state.currentTrade?.termTypeName === 'CALC') {
      for (let d = moment(state.consolidatedDateRange[0]); d.isSameOrBefore(moment(state.consolidatedDateRange[1])); d.add(1, 'days')) {
        const x = d.format('YYYY-MM-DD');
        const y = state.cachedChangedIntervals[x];
        if (y) {
          state.cachedChangedIntervals[x].forEach((interval) => {
            const foundIntervalIdx = state.data.findIndex(
              ({ startTime, endTime, he }) => startTime === interval.startTime
                && endTime === interval.endTime
                && he === interval.he,
            );
            if (foundIntervalIdx > -1) {
              state.data[foundIntervalIdx] = { ...state.data[foundIntervalIdx], ...interval };
            }
          });
        }
      }
    }
    // Finds the HE and replaces from cache
    const date = moment(state.dailyDate).format('YYYY-MM-DD');
    if (state.cachedChangedIntervals[date]) {
      state.cachedChangedIntervals[date].forEach((interval) => {
        const foundIntervalIdx = state.data.findIndex(
          ({ startTime, endTime, he }) => startTime === interval.startTime
            && endTime === interval.endTime
            && he === interval.he,
        );
        if (foundIntervalIdx > -1) {
          state.data[foundIntervalIdx] = { ...state.data[foundIntervalIdx], ...interval };
        }
      });
    }
  },
  resetConsolidatedIntervals(state) {
    state.consolidatedIntervals = [];
  },
  setConsolidatedIntervals(state, item) {
    let { intervals } = item;
    const { tz } = item;

    intervals = intervals.reduce((acc, interval) => {
      if (interval.value !== null) {
        acc.push({
          cascadedFlag: interval.cascadedFlag,
          endTime: dateStore.toLocalFromDate(interval.endTime, tz).format('YYYY-MM-DD HH:mm'),
          startTime: dateStore.toLocalFromDate(interval.startTime, tz).format('YYYY-MM-DD HH:mm'),
          type: interval.type,
          value: interval.value,
          variant: interval.variant,
        });
      }
      return acc;
    }, []);

    intervals = intervals.sort((a, b) => {
      const momentaStart = dateStore.toLocalFromDate(a.startTime, tz);
      const momentbStart = dateStore.toLocalFromDate(b.startTime, tz);
      if (momentaStart.isBefore(momentbStart)) return -1;
      if (momentaStart.isAfter(momentbStart)) return 1;
      return 0;
    });

    // Merges the intervals together if the time matches
    let intIdx = 1;
    while (intIdx < intervals.length) {
      const interval = intervals[intIdx];
      interval[`${interval.variant.toUpperCase()}-${interval.type.toUpperCase()}`] = interval.value;
      if (intIdx > 0) {
        const previousInt = intervals[intIdx - 1];
        interval[`${previousInt.variant.toUpperCase()}-${previousInt.type.toUpperCase()}`] = previousInt.value;
        if (!has(previousInt, 'cascaded')) {
          previousInt.cascaded = {
            [`${previousInt.variant.toUpperCase()}-${previousInt.type.toUpperCase()}`]: previousInt.cascadedFlag,
          };
        }
        if (
          (interval.startTime === previousInt.startTime && interval.endTime === previousInt.endTime)
        ) {
          previousInt[`${previousInt.variant.toUpperCase()}-${previousInt.type.toUpperCase()}`] = previousInt.value;
          previousInt[`${interval.variant.toUpperCase()}-${interval.type.toUpperCase()}`] = interval.value;
          // eslint-disable-next-line
          previousInt.cascaded[`${interval.variant.toUpperCase()}-${interval.type.toUpperCase()}`] = interval.cascadedFlag;
          intervals.splice(intIdx, 1);
        } else {
          intIdx++;
        }
      }
    }

    // If value is zero and fields are greater than 7, consolidates intervals
    intervals = intervals.filter((obj) => obj.value !== 0 && (Object.entries(obj).length > 7));

    // Groups intervals by day
    const intervalGroupedByDays = {};
    intervals.forEach((interval) => {
      // const day = interval.startTime.format('YYYY-MM-DD');
      const day = dateStore.toLocalFromDate(interval.startTime, tz).format('YYYY-MM-DD');
      if (has(intervalGroupedByDays, day)) {
        intervalGroupedByDays[day].push(interval);
      } else {
        intervalGroupedByDays[day] = [interval];
      }
    });

    // Merges values that have the same startTime to endTime
    Object.entries(intervalGroupedByDays).forEach(([day, intervalsByDay]) => {
      if (intervalsByDay.length) {
        let idx = 1;
        while (idx < intervalsByDay.length) {
          const interval = intervalsByDay[idx];
          const prevInt = intervalsByDay[idx - 1];
          if (interval.startTime === prevInt.endTime) {
            const prevRow = clone(prevInt);
            delete prevRow.endTime;
            delete prevRow.startTime;
            delete prevRow.type;
            delete prevRow.value;
            delete prevRow.variant;
            delete prevRow.cascaded;
            delete prevRow.cascadedFlag;
            const row = clone(interval);
            delete row.endTime;
            delete row.startTime;
            delete row.type;
            delete row.value;
            delete row.variant;
            delete row.cascaded;
            delete row.cascadedFlag;
            if (Object.entries(prevRow).every(([key, val]) => row[key] === val)) {
              prevInt.endTime = interval.endTime;
              intervalsByDay.splice(idx, 1);
            } else {
              idx++;
            }
          } else {
            idx++;
          }
        }
      }
    });

    state.consolidatedIntervals = Object.values(intervalGroupedByDays).flat();
  },
  setDailyDate(state, value) {
    if (value) {
      const date = (typeof value === 'object') ? value.format('YYYY-MM-DD') : value.substring(0, 10);

      // this should avoid triggering the same change multiple times
      if (state.dailyDate !== date) { state.dailyDate = date; }
    }
  },
  setData(state, { rowIndex, prop, value }) {
    const newValue = Number(value);
    if (Number.isNaN(newValue) || value === '' || value === null) {
      Vue.set(state.data[rowIndex], prop, null);
    } else {
      Vue.set(state.data[rowIndex], prop, newValue);
    }
    Vue.set(state.data[rowIndex], '$updated', true);
    state.data[rowIndex].cascaded[prop] = false;
  },
  updateRangeIntervalData(state, { rowIndex, prop, value }) {
    const newValue = Number(value);
    if (Number.isNaN(newValue) || value === '' || value === null) {
      Vue.set(state.rangeIntervalData[rowIndex], prop, null);
    } else {
      Vue.set(state.rangeIntervalData[rowIndex], prop, newValue);
    }
    Vue.set(state.rangeIntervalData[rowIndex], '$updated', true);
    state.rangeIntervalData[rowIndex].cascaded[prop] = false;
  },
  setConsolidatedDateRange(state, dateRange) {
    state.consolidatedDateRange = dateRange;
    generateConsolidatedInterval(state);
  },
  checkCommodityAndTermType(state, { commodity, termTypeName, granularity, period, effectiveStartDate, effectiveEndDate, timeZone }) {
    
    let tz = timeZone ?? dateStore.getTimeZone();
    let startDate = dateStore.toDateFromLocal(effectiveStartDate, tz);
    let endDate = dateStore.toDateFromLocal(effectiveEndDate, tz);
    
    state.data = resetData(state.dailyDate, commodity, termTypeName, tz, granularity ?? 60, period, startDate, endDate);
  },
  setIntervalByTermType(state, termTypeMeta) {
    let termType;
    let variant;
    let value;
    if (termTypeMeta) {
      state.newTradeTermTypeMetaData = termTypeMeta;
      termType = termTypeMeta.termType;
      variant = termTypeMeta.variant;
      value = termTypeMeta.value;

      if (value === null && has(state.newTradeVariants, variant)) {
        delete state.newTradeVariants[variant];
      } else if (Number(value)) {
        state.newTradeVariants[variant] = value;
      }
    } else if (state.newTradeTermTypeMetaData) {
      termType = state.newTradeTermTypeMetaData.termType;
      variant = state.newTradeTermTypeMetaData.variant;
      value = state.newTradeTermTypeMetaData.value;
    }

    if (termType && (termTypeMeta || state.newTradeTermTypeMetaData)) {
      const formattedDate = dateStore.toDateFromLocal(state.dailyDate).startOf('day').format('dddd').toLowerCase();
      const definition = termType.termTypeDefinitions
        .find(({ dayOfWeek }) => dayOfWeek.toLowerCase() === formattedDate);
      const hasHeVal = Object.entries(definition).reduce((acc, [key, val]) => {
        if (key.includes('he')) {
          const he = Number(key.split('he')[1]);
          acc[he] = val;
        }
        return acc;
      }, {});

      // If needs to set multiple variants
      if (Object.keys(state.newTradeVariants).length) {
        Object.entries(state.newTradeVariants).forEach(([key, val]) => {
          state.data.forEach((data) => {
            if (hasHeVal[data.he]) Vue.set(data, key, val);
            else Vue.set(data, variant, null);
          });
        });

        generateConsolidatedInterval(state);
      } else {
        state.data.forEach((data) => {
          if (hasHeVal[data.he]) Vue.set(data, variant, value);
          else Vue.set(data, variant, null);
        });
      }
    }
  },
  resetIntervalCache(state) {
    state.cachedChangedIntervals = {};
    state.data.forEach((interval) => delete interval.$updated);
  },

  setVariants(state, data) {
    const { variants, hasWriteAccess } = data;
    state.variants = variants;
    // Creates config columns for variants
    const types = ['VOLUME', 'PRICE', 'AMOUNT'];
    const variantColumns = variants.reduce((acc, { name, order }) => {
      // Check if value already exists and add one otherwise there will be duplicate columns
      let orderExists = !!acc.find(({ level, isParent }) => level === order && isParent);
      while (orderExists) {
        order += 1;
        // eslint-disable-next-line
        orderExists = !!acc.find(({ level, isParent }) => level === order && isParent);
      }
      acc.push({
        label: name,
        isParent: true,
        level: order,
        isDynamic: true,
      });
      types.forEach((type) => {
        const editable = type !== 'AMOUNT' && !['RT_FINAL', 'DA_FINAL'].includes(name) && hasWriteAccess === true;
        const columnConfig = {
          label: type,
          prop: `${name}-${type}`,
          width: 50,
          editable,
          parent: order,
          visible: false,
          isDynamic: true,
        };
        if (['PRICE', 'AMOUNT'].includes(type)) {
          columnConfig.valueFormatter = function CurrencyFormat(params) {
            if (params.value == null) {
              return;
            }
            return params.value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          };
        }
        acc.push(columnConfig);
      });
      return acc;
    }, []);
    // Is dynamic is to remove created variants columns so it doesn't dupe
    state.config.columns = state.config.columns.filter(({ isDynamic }) => !isDynamic);
    state.config.columns.push(...variantColumns);

    state.intervalConfig.columns = state.intervalConfig.columns.filter(({ isDynamic }) => !isDynamic);
    state.intervalConfig.columns.push(...variantColumns);
  },
  ...createMutations(
    'rangeDateRange',
    'selectedGranularity',
    'rangeIntervalData',
    'rangeConfig',
    'adder',
    'multiplier',
  ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};