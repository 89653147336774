import { tradeDateRange, contracts } from '@/components/ContractBilling/BaseStore/contractsBase';
import moment from 'moment';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'TRADE DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'tradingHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'CONTRACT NAME',
    dataField: 'contractName',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'DETERMINANT NAME',
    dataField: 'determinantName',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'value',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: '#,##0.#######',
    expanded: true,
  }, {
    caption: 'Contract ID',
    dataField: 'contractId',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'INTERVAL',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute1',
    dataField: 'attribute1',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute2',
    dataField: 'attribute2',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute3',
    dataField: 'attribute3',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute4',
    dataField: 'attribute4',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute5',
    dataField: 'attribute5',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute6',
    dataField: 'attribute6',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute7',
    dataField: 'attribute7',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute8',
    dataField: 'attribute8',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute9',
    dataField: 'attribute9',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute10',
    dataField: 'attribute10',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute11',
    dataField: 'attribute11',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute12',
    dataField: 'attribute12',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'Attribute13',
    dataField: 'attribute13',
    area: 'filter',
    expanded: true,
  }],
  searchFiltersConfig: {
    tradeDateRange,
    contracts,
  },
};