import moment from 'moment';
import ls from 'local-storage';
import * as dateUtil from './dateUtil';
import 'moment-timezone';

export const ZULU_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

const DateStore = function () {
  this.style = '';
  this.timeZone = '';
  this.userOptionTimeZone = '';
  this.singleDateOption = '';
  this.rangeDateOption = '';
  this.absoluteDate = '';
  this.ldh = false;
  this.resourceGroup = '';
};

const dateStore = new DateStore();

DateStore.prototype.setDefaults = function (model) {
  this.style = model.style;
  this.timeZone = model.tz;
  this.singleDateOption = model.single;
  this.rangeDateOption = model.range;
  this.absoluteDate = model.absoluteDate;
  this.ldh = model.ldh;
  this.resourceGroup = model.resourceGroup;

  if (this.timeZone.includes('D')) this.timeZone = this.timeZone.replace('D', 'P');

  const def = this.getTimeZoneDefinition();
  this.userOptionTimeZone = def.value;
  moment.tz.setDefault(def.tz);
};

DateStore.prototype.getZuluFormat = function () {
  return ZULU_FORMAT;
};

DateStore.prototype.getTimeZone = function () {
  return this.timeZone;
};

DateStore.prototype.getLongDayHour = function () {
  return this.ldh;
};

DateStore.prototype.getZone = function (value) {
  return dateUtil.getZoneName(value);
};

DateStore.prototype.getShortName = function () {
  return dateUtil.getShortName(this.timeZone);
};

/**
 * Gets the style of the dateStore object. either 'absolute' or 'relative'
 */
DateStore.prototype.toMomentFromLocal = function (date, zone = null) {
  const tz = (zone === null || zone === undefined) ? this.getTimeZoneDefinition() : dateUtil.getZoneName(zone);
  const datetimeString = ((typeof date) === 'string') ? date : date.toISOString();
  const LocalDateObject = new Date(datetimeString);

  // Create a moment object in the specified timezone
  return moment.tz([LocalDateObject.getFullYear(), LocalDateObject.getMonth(), LocalDateObject.getDate(),
    LocalDateObject.getHours(), LocalDateObject.getMinutes()], tz.tz);
};

DateStore.prototype.toLocalFromMoment = function (momentDate, zone = null) {
  momentDate = ((typeof momentDate) === 'string') ? moment(momentDate) : momentDate;
  return new Date(momentDate.get('year'), momentDate.get('month'), momentDate.get('date'),
    momentDate.get('hour'), momentDate.get('minute'), 0);
};

DateStore.prototype.getStyle = function () {
  return this.style;
};

DateStore.prototype.getSingleDateOption = function () {
  return this.singleDateOption;
};

DateStore.prototype.getSingleDateOptions = function () {
  return dateUtil.singleDateSelectionOptions;
};

DateStore.prototype.getRangeDateOption = function () {
  return this.rangeDateOption;
};

DateStore.prototype.getRangeDateOptions = function () {
  return dateUtil.multipleDateSelectionOptions;
};

DateStore.prototype.getTimeZoneDefinitions = function () {
  return dateUtil.TZ_DEFINE;
};

DateStore.prototype.getTimeZoneDefinition = function () {
  return dateUtil.getZoneName(this.timeZone);
};

DateStore.prototype.getDefaultDate = function () {
  if (this.style && this.style === 'absolute') {
    return this.toMomentFromJSDate(dateStore.absoluteDate[0]).startOf('day');
  }
  return dateUtil.singleDateFromOption(this.singleDateOption, this.timeZone);
};

DateStore.prototype.getDefaultDateLocal = function () {
  return this.toLocalFromDate(this.getDefaultDate().toDate());
};

DateStore.prototype.getDefaultRange = function () {
  if (this.style && this.style === 'absolute') {
    return [moment(dateStore.absoluteDate[0]), moment(dateStore.absoluteDate[1])];
  }
  return dateUtil.multipleDateFromOption(this.rangeDateOption, this.timeZone);
};

DateStore.prototype.getDefaultOneDayRange = function () {
  return [this.getDefaultDate(), this.getDefaultDate()];
};

DateStore.prototype.formatDateTimeFields = function (data, dateProps) {
  const zone = this.getTimeZoneDefinition().tz;
  return dateUtil.formatDataTimes(data, dateProps, zone);
};

DateStore.prototype.toMomentFromJSDate = function (date) {
  return dateUtil.toMomentFromDate(date, this.getTimeZoneDefinition().tz);
};

DateStore.prototype.toMomentAndZoneFromJSDate = function (date, tz) {
  const zone = dateUtil.getZoneName(tz).tz;
  return dateUtil.toMomentFromDate(date, zone);
};

DateStore.prototype.toMomentFromJSDateArray = function (range) {
  return [
    this.toMomentFromJSDate(range[0]),
    this.toMomentFromJSDate(range[1]),
  ];
};

DateStore.prototype.toMomentAndZoneFromJSDateArray = function (range, tz) {
  return [
    this.toMomentAndZoneFromJSDate(range[0], tz),
    this.toMomentAndZoneFromJSDate(range[1], tz),
  ];
};

DateStore.prototype.toMomentFromStringWithFormat = function (stringDateTime, format) {
  const zone = this.getTimeZoneDefinition().tz;
  return dateUtil.toMoment(stringDateTime, format, zone);
};

DateStore.prototype.getMomentNow = function () {
  return dateUtil.getNow();
};

DateStore.prototype.getMomentNowLocal = function () {
  return dateUtil.toMomentLocal();
};

DateStore.prototype.toMoment = function (value, zone = null) {
  zone = zone || this.getTimeZoneDefinition().tz;
  return moment.tz(value, zone);
};

DateStore.prototype.isMoment = function (value) {
  return moment.isMoment(value);
};

DateStore.prototype.isSameDate = function (initialDate, comparisonDate) {
  if (!this.isMoment(initialDate)) { initialDate = this.toMoment(initialDate); }
  if (!this.isMoment(comparisonDate)) { comparisonDate = this.toMoment(comparisonDate); }

  // check if day, month, and year are the same disregarding time granularity
  return initialDate.isSame(comparisonDate, 'day');
};

DateStore.prototype.momentizeData = function (data, mappings, zone) {
  mappings = mappings || [];

  if (Array.isArray(data)) {
    const dataLength = data.length;
    const mapLength = mappings.length;
    for (let dx = 0; dx < dataLength; dx++) {
      const item = data[dx];

      for (let mx = 0; mx < mapLength; mx++) {
        const map = mappings[mx];
        item[map.momentProp] = this.toMoment(item[map.prop], zone);
      }
    }
  }

  return data;
};

// 'None', 'Daily', 'Weekly', '7 Days', 'Monthly', 'Quarterly', 'Yearly'
const intervals = {
  None: 'day',
  Daily: 'day',
  Weekly: 'week',
  '7 Days': 'week',
  Monthly: 'month',
  Quarterly: 'quarter',
  Yearly: 'year',
};

const formats = {
  None: 'YYYY-MM-DD',
  Daily: 'MM/DD',
  Weekly: 'YYYY [WK]ww',
  '7 Days': 'MMM DD',
  Monthly: 'YYYY-MM',
  Quarterly: 'YYYY [Q]Q',
  Yearly: 'YYYY',
};

const ranges = {
  None: 1,
  Daily: 31,
  Weekly: 52,
  '7 Days': 26,
  Monthly: 36,
  Quarterly: 32,
  Yearly: 30,
};

DateStore.prototype.getNormalizedPeriod = function (period) {
  return period ? period.toUpperCase().substring(0, 1) + period.toLowerCase().substring(1) : 'None';
};

DateStore.prototype.getPeriodRange = function (period, date) {
  // calculate range based on the number of days in this month
  if (period === 'Daily' && date != undefined) {
    const m = this.getStartOfPeriod(date, period);
    return m.daysInMonth() - 1;
  }

  return ranges[period];
};

DateStore.prototype.getIntervalFromPeriod = function (period) {
  return intervals[period];
};

DateStore.prototype.getPeriodFormat = function (period) {
  return formats[period];
};

DateStore.prototype.getTimeRangeByPeriod = function (dateRange, period, zone = null, rangeEndMax = null) {
  const { tz } = zone ? dateUtil.getZoneName(zone) : this.getTimeZoneDefinition();
  let [rangeStart, rangeEnd] = dateRange;

  const interval = this.getIntervalFromPeriod(period);

  const maxRange = this.getPeriodRange(period, rangeStart);
  rangeEndMax ??= this.getStartOfPeriod(rangeStart, period, tz).add(maxRange, interval);

  // adjust to the max range end if not provided
  if (rangeEnd == null || rangeEnd.isAfter(rangeEndMax)) {
    rangeEnd = rangeEndMax;
  }

  // we have to ignore the time portion completely and convert timestamps into dates starting at 00:00 local time
  let start = this.getStartOfPeriod(rangeStart, period, tz);
  const end = this.getEndOfPeriod(rangeEnd, period, tz);

  const times = [];
  while (start.isBefore(end)) {
    const gmt = start.clone();
    const format = this.getPeriodFormat(period);
    const he = period !== 'None' ? gmt.format(format) : `${rangeStart.format(format)} - ${rangeEnd.format(format)}`;

    const item = {
      time: gmt, timeTZ: gmt, dstFlag: false, he,
    };

    times.push(item);

    if (period === 'None') break;

    start = start.add(1, interval);
  }
  return times;
};

DateStore.prototype.getStartOfPeriod = function (rangeStart, period, tz) {
  const m = moment(rangeStart.format('YYYY-MM-DD'));
  switch (period) {
  case 'None':
  case 'Daily':
    return moment.tz({ year: m.year(), month: m.month(), day: m.date() }, tz);
  case 'Weekly':
    var d = this.toFirstDayOfWeek(m);
    return moment.tz({ year: d.year(), month: d.month(), day: d.date() }, tz);
    // case "7 days":
    //    return new DateTime(date.Year, date.Month, date.Day, 0, 0, 0);
  case 'Monthly':
    return moment.tz({ year: m.year(), month: m.month(), day: 1 }, tz);
  case 'Quarterly':
    const q = [0, 0, 0, 3, 3, 3, 6, 6, 6, 9, 9, 9];
    return moment.tz({ year: m.year(), month: q[m.month()], day: 1 }, tz);
  case 'Yearly':
    return moment.tz({ year: m.year(), month: 0, day: 1 }, tz);
  }
  return moment.tz(m.toISOString(), tz);
};

DateStore.prototype.getEndOfPeriod = function (rangeEnd, period, tz) {
  const end = this.getStartOfPeriod(rangeEnd, period, tz);
  return end.add(1, intervals[period]);
};

DateStore.prototype.toFirstDayOfWeek = function (date) {
  let weekday = date.weekday();
  if (weekday == 0) { weekday = 7; }

  const weekStart = 1; // assuming Monday as the start of the week

  return date.add(weekStart - weekday, 'days');
};

DateStore.prototype.getTimeRangeByMinutes = function (date, numberOfMinutes) {
  let start = moment.isMoment(date) ? date.clone() : moment(date);

  const end = start.clone().add(1, 'days');

  const longDay = this.getDST(start);

  const times = [];

  while (start.isBefore(end)) {
    const item = { time: start.clone(), dstFlag: false };
    if (longDay !== null) {
      if (item.time.format('YYYY-MM-DD HH Z') === longDay.format('YYYY-MM-DD HH Z')) {
        item.dstFlag = true;
      }
    }

    times.push(item);
    start = start.add(numberOfMinutes, 'minutes');
  }

  return times;
};

DateStore.prototype.findIndexInTimeRange = function (timeRange, dt) {
  return timeRange.findIndex((i) => i.value.time.isAfter(dt));
};

DateStore.prototype.getTimeRange = function (date, numberOfMinutes = 60, zone = null, longDayHEDisplayValue = null) {
  // Handle when numberOfMinutes comes in as null
  if (numberOfMinutes === null) { numberOfMinutes = 60; }

  const { tz } = zone ? dateUtil.getZoneName(zone) : this.getTimeZoneDefinition();
  let ldh = this.getLongDayHour() ? '2*' : '25';
  if (longDayHEDisplayValue) ldh = longDayHEDisplayValue;

  let start = moment.isMoment(date) ? date.clone() : moment.tz(date, tz);
  start = start.startOf('day');

  const end = start.clone().add(1, 'day');

  const longDay = this.getDST(start, zone);

  const times = [];

  while (start.isBefore(end)) {
    const gmt = start.clone().utc();
    const targetTZ = start.clone();

    const offset = targetTZ.utcOffset() / 60;
    let he = gmt.get('hour') + offset;
    if (he < 0) he += 24;

    he += 1;

    const item = {
      time: gmt, timeTZ: targetTZ, dstFlag: false, he: he.toString(),
    };
    if (longDay !== null) {
      if (item.time.isSame(longDay)) {
        item.dstFlag = true;
        item.he = ldh;
      }
    }

    times.push(item);
    start = start.add(numberOfMinutes, 'minutes');
  }
  return times;
};

DateStore.prototype.isMoment = function (date) {
  return moment.isMoment(date);
};

DateStore.prototype.toMomentStartOfDay = function (date, zone = null) {
  return this.toMoment(date, zone).startOf('day');
};

DateStore.prototype.findMin = function (momentDates) {
  return moment.min(momentDates);
};

DateStore.prototype.findMax = function (momentDates) {
  return moment.max(momentDates);
};

// converts system local time (represented in utc)
// to user setting timezone (represented in utc)
DateStore.prototype.localSystemTimeToUserSettingTime = function (dateTime) {
  // find user setting timezone
  const timeZoneCode = this.getTimeZone();
  const timeZone = this.getZone(timeZoneCode);
  const { tz } = timeZone;

  // convert to moment and format
  const momentValueLocal = moment(dateTime)
    .local()
    .format('YYYY-MM-DD HH:mm');

  // convert local time to UTC
  const format = this.getZuluFormat();
  const userSettingInUtc = moment(momentValueLocal)
    .tz(tz)
    .utc()
    .format(format);

  return userSettingInUtc;
};

DateStore.prototype.getDST = function (date, zone = null) {
  const tz = (zone === null || zone === undefined) ? this.getTimeZoneDefinition() : dateUtil.getZoneName(zone);
  const momentDate = moment.isMoment(date) ? date : moment(date);

  if (tz.dstFlag) {
    const val = `${momentDate.format('YYYY')}-11-01T${tz.utcDSTOffset.replace('-', '')}:00Z`;
    return moment.utc(val).isoWeekday(7).add(2, 'hours');
  }
  return null;
};

DateStore.prototype.toDate = function (stringDateTime, zone) {
  const tm = this.toDateFromLocal(stringDateTime, zone);
  return tm.toDate();
};

DateStore.prototype.toDateFromLocal = function (date, zone = null) {
  const tz = (zone === null || zone === undefined) ? this.getTimeZoneDefinition() : dateUtil.getZoneName(zone);
  const targetDate = moment.tz(date, tz.tz);

  const midnightTarget = targetDate.clone().startOf('Day');
  const midnightLocal = moment.tz(midnightTarget.clone().format('YYYY-MM-DD'), moment.tz.guess());

  const offsetDiff = midnightTarget.utcOffset() - midnightLocal.utcOffset();
  targetDate.add(-1 * offsetDiff, 'minutes');
  return targetDate;
};

DateStore.prototype.toLocalFromDate = function (date, zone = null) {
  const tz = (zone === null || zone === undefined) ? this.getTimeZoneDefinition() : dateUtil.getZoneName(zone);
  return dateUtil.toLocalFromDate(date, tz);
};

DateStore.prototype.toLocalStartOfDayFromDate = function (date) {
  // convert date to date string if it isn't already
  const datetimeString = typeof date === 'string' ? date : date.toISOString();
  const [dateString] = datetimeString.split('T');

  // get local offset
  const startOfDayIsoString = moment(dateString).local().startOf('day').toISOString();
  const [, localStartOfDay] = startOfDayIsoString.split('T');
  // remove time reference from string

  const localDateTimeSTring = [dateString, localStartOfDay].join('T');
  // return moment
  return moment(localDateTimeSTring);
};

DateStore.prototype.getHours = function (currentDate) {
  const startDate = moment.isMoment(currentDate)
    ? currentDate.clone()
    : this.toMomentAndZoneFromJSDate(currentDate, this.getTimeZone());
  const endDate = startDate.clone().add('1', 'days');
  const mod = this.ldh ? '2*' : '25';
  const hours = [];
  let lastValue = false;
  let twoCount = 0;

  while (startDate.isBefore(endDate)) {
    const hour = startDate.get('hour') + 1;

    if (hour === 2) {
      twoCount++;
    }
    if (twoCount === 2) {
      if (mod === '25') { lastValue = true; } else { hours.push(mod); }
      twoCount = 0;
    } else {
      hours.push(hour.toString());
    }
    startDate.add('1', 'hours');
  }
  if (lastValue) { hours.push(mod); }
  return hours;
};

DateStore.prototype.getShortAndLongDays = function (date = null) {
  const daysOfYear = [];
  const begOfYear = date ? moment(date).startOf('year') : moment().startOf('year');
  const endOfYear = date ? moment(date).endOf('year') : moment().endOf('year');

  while (begOfYear < endOfYear) {
    begOfYear.add(1, 'day');
    daysOfYear.push(begOfYear.clone().endOf('day'));
  }

  // an array of booleans that indicate whether or not a given day is in daylight savings time
  const isDSTDaysOfYear = daysOfYear.map((a) => a.isDST());

  const shortDayIndex = isDSTDaysOfYear.indexOf(true);
  const shortDay = shortDayIndex >= 0 ? daysOfYear[shortDayIndex].clone().startOf('day') : null;

  // an array of booleans that indicate whether or not a given day is in standard time
  // starting with the day it switches from DST to ST (i.e. not the beginning of the year)
  const isSTDaysOfYear = [];
  // all days before the first DST date will be false

  // we want to flip them to true until after the first DST date
  // that way we can get the index of the 'first' standard date
  let standardTimeFlag = false;
  isDSTDaysOfYear.forEach((day) => {
    if (!day && !standardTimeFlag) {
      isSTDaysOfYear.push(!day); // add opposite value to array (true instead of false)
    } else {
      standardTimeFlag = true; // flip the flag after the first DST date
      isSTDaysOfYear.push(day); // then push the actual value
    }
  });

  const longDayIndex = isSTDaysOfYear.indexOf(false);
  const longDay = longDayIndex >= 0 ? daysOfYear[longDayIndex].clone().startOf('day') : null;

  // NOTE: time offset is based on user settings timezone
  return { shortDay, longDay };
};

DateStore.prototype.isShortDay = function (date) {
  const shortDayLongDayInfo = dateStore.getShortAndLongDays(date);
  if (!shortDayLongDayInfo.shortDay) { return false; }

  const [shortDateString] = shortDayLongDayInfo.shortDay.toISOString().split('T');
  const [dateString] = date.toISOString().split('T');
  return dateString === shortDateString;
};

DateStore.prototype.isLongDay = function (date) {
  const shortDayLongDayInfo = dateStore.getShortAndLongDays(date);
  if (!shortDayLongDayInfo.longDay) { return false; }

  const [longDateString] = shortDayLongDayInfo.longDay.toISOString().split('T');
  const [dateString] = date.toISOString().split('T');
  return dateString === longDateString;
};

/**
 * Registers a listener for date refresh updates
 * @param {Object} listener - an object that implements the method 'dateListenerCallback(dateValues)'
 */
DateStore.prototype.addDateRefreshListener = function (listener) {
  ls.on('new-default-date-options', listener);
};
DateStore.prototype.removeDateRefreshListener = function (listener) {
  ls.off('new-default-date-options', listener);
};

/**
 * Call this method in order to send a message to all listeners with updated date store information.
 * @param {Object} dateOptions - an optional dateOptions parameter. If no dateOption is
 * passed in then the current dateOptions are used.
 */
DateStore.prototype.refreshDateListeners = function (dateOptions) {
  // if date options were passed in then use them, else use the dateStore dateOptions
  dateOptions = dateOptions || {
    style: this.style,
    tz: this.timeZone,
    single: this.singleDateOption,
    range: this.rangeDateOption,
    absoluteDate: this.absoluteDate,
    ldh: this.ldh,
  };

  // create the single and range dates
  let singleDate = dateOptions.absoluteDate[0];
  let rangeDate = dateOptions.absoluteDate;

  // if the date is absolute then use absolute dates
  if (dateOptions.style === 'relative') {
    const momentDateRange = dateUtil.multipleDateFromOption(dateOptions.range, dateOptions.tz);
    rangeDate = [momentDateRange[0].toDate(), momentDateRange[1].toDate()];
    singleDate = dateUtil.singleDateFromOption(dateOptions.single, dateOptions.tz).toDate();
  }
  // send the information to all listeners
  ls.set('new-default-date-options', { singleDate, rangeDate });
};

DateStore.prototype.handleRouteMetaRequiredTimeZone = (to) => {
  // Sets moment default timezone
  const timeZone = to?.meta?.requiredTimeZone ?? dateStore.timeZone;
  const zoneName = dateUtil.getZoneName(timeZone);
  moment.tz.setDefault(zoneName.tz);

  // Sets dateStore timezone for conversions
  const hasRequiredTimeZone = !!to?.meta?.requiredTimeZone;
  if (hasRequiredTimeZone) {
    dateStore.userOptionTimeZone = dateStore.timeZone;
    dateStore.timeZone = timeZone;
  } else {
    dateStore.timeZone = dateStore.userOptionTimeZone;
  }
};

export default dateStore;