import moment from 'moment';
import dateStore from '@/utils/dateStore';
import { CISO_SAMC_API, CISO_INTERNAL_API } from '@/api';
import configs from './tagSettlementsConfig';
import detailConfigs from './tagSettlementsDetailsConfig';
import errorConfig from './tagSettlementsMessagesConfig';

function flattenModel(model) {
  const flatArray = [];
  if (model && model.data && model.data.length > 0) {
    model.data.forEach((m) => {
      const data = m.data || [];
      data.forEach((d) => {
        flatArray.push({ ...m, ...d, data: null });
      });
    });
  }
  return flatArray;
}

const state = {
  tableData: [],
  tagConfig: configs,
  tagPivot: configs.fields,
  tradeDate: null,
  detailData: [],
  detailConfig: detailConfigs,
  detailPivot: detailConfigs.fields,
  errorMessageCount: 0,
  errorMessageData: [],
  errorMessageTableConfig: errorConfig,
  detailParams: {},
  detailDatePropsAndFormats: [
    { name: 'procTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'reqTime', format: 'YYYY-MM-DD HH:mm' },
  ],
  errorMessageDatePropsAndFormats: [
    { name: 'mappingStartTimeGmt', format: 'YYYY-MM-DD HH:mm' },
    { name: 'mappingEndTimeGmt', format: 'YYYY-MM-DD HH:mm' },
    { name: 'tagStartTimeGmt', format: 'YYYY-MM-DD HH:mm' },
    { name: 'tagEndTimeGmt', format: 'YYYY-MM-DD HH:mm' },
  ],
};

const actions = {
  async fetchTagSettlementsData({ commit, dispatch }, parameters = {}) {
    commit('setTableData', []);
    commit('setTradeDate', parameters.tradeDateSelected);
    commit('setDetailParams', {});
    commit('setErrorMessageCount', 0);

    const params = {
      date: parameters.tradeDateSelected,
      granularity: parameters.granularitySelected,
    };
    if (parameters.validationEnabled) {
      const valid = await dispatch('getValidation', params);
      if (!valid) return;
    }

    try {
      const { data } = await CISO_SAMC_API.get('/tag-settlements', {
        headers: { 'api-version': '2.0' },
        params,
      });
      if (data) {
        commit('setTableData', flattenModel(data));
        commit('setErrorMessageCount', data.errorMessageCount);
      } else if (data === null) {
        commit('setTableData', []);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Tag Settlements Data', 'error');
    }
  },
  async fetchDetailData({ state, commit }) {
    commit('setDetailData', []);

    const params = {
      date: state.tradeDate,
      ...state.detailParams,
    };

    try {
      const { data } = await CISO_SAMC_API.get('/tag-settlements/details', {
        headers: { 'api-version': '2.0' },
        params,
      });

      commit('setDetailData', dateStore.formatDateTimeFields(flattenModel(data), state.detailDatePropsAndFormats));
    } catch (err) {
      console.error(err);
    }
  },
  async fetchErrorMessageData({ state, commit }) {
    commit('setErrorMessageData', []);

    const params = {
      date: state.tradeDate,
    };

    try {
      const { data: { data } } = await CISO_SAMC_API.get('/tag-settlements/messages', {
        headers: { 'api-version': '2.0' },
        params,
      });
      commit('setErrorMessageData', dateStore.formatDateTimeFields(data, state.errorMessageDatePropsAndFormats));
    } catch (e) {
      console.error(e);
    }
  },
  async reprocessTagSettlements({ state, commit }) {
    const params = {
      startDate: state.tradeDate,
      endDate: state.tradeDate,
      reportName: 'ETAG-RERUN-POST-PROCESS',
    };

    try {
      const { data: { data } } = await CISO_INTERNAL_API.post('/import',
        params,
      );
    } catch (e) {
      console.error(e);
    }
  },
  async getValidation({ commit }, params) {
    try { // Validation in a separate try catch to avoid introducing a dependency
      const validations = await CISO_SAMC_API.get('/tag-settlements/validation', {
        headers: { 'api-version': '2.0' },
        params,
      });
      if (validations.data) {
        if (validations.data.validMapping === 0) {
          this.$notify('Mapping Data Not Found', 'error');
          return false;
        }
        if (validations.data.validCalc === 0) {
          this.$notify('Calculation Data Not Found', 'error');
        }
      }
    } catch (e) {
      console.error(e);
    }
    return true;
  },
  reset({ commit }) {
    commit('setDetailParams', {});
    commit('setTradeDate', null);
    commit('setTableData', []);
    commit('setDetailData', []);
    commit('setErrorMessageCount', 0);
    commit('setErrorMessageData', []);
  },
};

const mutations = {
  setDetailParams(state, value) {
    state.detailParams = value;
  },
  setTradeDate(state, value) {
    state.tradeDate = value;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setDetailData(state, value) {
    state.detailData = Object.freeze(value);
  },
  setErrorMessageCount(state, value) {
    state.errorMessageCount = value;
  },
  setErrorMessageData(state, value) {
    state.errorMessageData = value;
  },
  setTableConfig(state, value) {
    state.tagPivot = value;
  },
  setDetailTableConfig(state, value) {
    state.detailConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};