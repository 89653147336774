import { STRUCTURES_API, ETRM_API } from '@/api';
import { clone, handleError, sortBy } from '@/utils/dataUtil';
import utils from '@/utils';
// import { forEach } from 'lodash';

const state = {
  tableKey: -999,
  tableData: [],
  childTableData: [],
  currentRow: null,
  currentChildRow: null,
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, visible: false,
    }, {
      prop: 'name', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'type', label: 'Type', sortable: true,
    // }, {
    //   prop: 'rule', label: 'Rule', sortable: true,
    }, {
      prop: 'links', label: 'Links', sortable: true,
    // }, {
    //   prop: 'totalChildren', label: 'Total Children', sortable: true,
    // }, {
    //   prop: 'children', label: 'Children', sortable: true,
    }, {
      prop: 'effectiveDate', label: 'Effective Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'terminationDate', label: 'Termination Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  childTableConfig: {
    columns: [{
      prop: 'name', label: 'Property Name', sortable: false,
    }, {
      prop: 'op', label: 'Operation', sortable: false,
    }, {
      prop: 'value', label: 'Value', sortable: false,
    }, {
      prop: 'separator', label: 'Separator', sortable: false,
    }],
  },
  nullTradeTermGroupRow: {
    id: -999,
    name: null,
    description: null,
    type: null,
    rule: null,
    links: [],
    totalChildren: null,
    children: null,
    effectiveDate: null,
    terminationDate: null,
  },
  nullTradeTermGroupRuleRow: {
    name: null,
    op: null,
    value: null,
    separator: null,
  },
};

const getters = {
  isSelectingRow() {
    if (!state.currentRow) return false;

    if (state.currentRow.description !== state.nullTradeTermGroupRow.description) return true;
    if (state.currentRow.id !== state.nullTradeTermGroupRow.id) return true;
    if (state.currentRow.name !== state.nullTradeTermGroupRow.name) return true;

    return false;
  },
  getTradeTermGroups: state => state.tableData?.map((x) => x.name),
  getTableConfig: state => state.tableConfig,
  getTableData: state => state.tableData,
  getCurrentRow: state => state.currentRow,
  getChildTableConfig: state => state.childTableConfig,
  getChildTableData: state => state.childTableData,
  getCurrentChildRow: state => state.currentChildRow,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('resetTable');
    dispatch('loadTableData');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  createChildTableRow({ commit }) {
    commit('createChildTableRow');
  },
  async loadTableData({ commit, dispatch }) {
    try {
      var { data } = await ETRM_API.get('trades/groups?includeChildren=false');

      data?.data?.forEach((tradeTermGroup) => {
        tradeTermGroup.links = tradeTermGroup.links?.map((m) => m.name );
      });
      commit('loadTableData', data.data);
    } catch (error) {
      handleError(error, 'Failed to load Trade Term groups.');
    }
  },
  async postTradeTermGroup({ commit }, tradeTermGroup) {
    try {
      if (tradeTermGroup.links){
        tradeTermGroup.links = tradeTermGroup.links.map((m) => ({ name: m }));
      }
      var { data } = await ETRM_API.post('trades/groups', tradeTermGroup);
      if (data.data.links){
        data.data.links = data.data.links?.map((m) => m.name );
      }
      commit('addTableRow', data.data);
      this.$notify('Trade Term group added', 'success');
    } catch (error) {
      handleError(error, 'Failed to add Trade Term group.');
    }
  },
  async updateTradeTermGroup({ commit }, tradeTermGroup) {
    try {
      if (tradeTermGroup.links){
        tradeTermGroup.links = tradeTermGroup.links.map((m) => ({ name: m }));
      }
      var { data } = await ETRM_API.put(`trades/groups`, tradeTermGroup);
      if (data.data.links){
        data.data.links = data.data.links?.map((m) => m.name );
      }
      commit('updateTableRow', data.data);
      commit('currentTableRowChange', data.data);
      this.$notify('Trade Term group updated', 'success');
    } catch (error) {
      handleError(error, 'Failed to update Trade Term group.');
    }
  },
  async deleteTradeTermGroup({ dispatch, commit, state }) {
    try {
      await ETRM_API.delete(`trades/groups?id=${state.currentRow.id}`);
      this.$notify('Trade Term group inactivated', 'success');
      
      // commit('deleteTableRow');
      // dispatch('currentTableRowChange', clone(state.nullTableRow));
      // dispatch('currentChildTableRowChange', null);
      
      commit('resetTable');
      dispatch('loadTableData');
    } catch (error) {
      handleError(error, 'Failed to inactivate Trade Term group.');
    }
  },
  async postTradeTermGroupRule({ state }, tradeTermGroupRule) {
    // try {
    //   const { data } = await ETRM_API.post(`trades/groups/${state.currentRow.name}/limits`, tradeTermGroupRule);
    //   state.childTableData.push(data);
    //   this.$notify('Trade limit added', 'success');
    // } catch (error) {
    //   handleError(error, 'Failed to add trade limit');
    // }
  },
  async updateTradeTermGroupRule({ commit }, tradeTermGroupRule) {
    // try {
    //   const path = `trade-limit-groups/${state.currentRow.name}/limits/${state.currentChildRow.id}`;
    //   const { data } = await ETRM_API.put(path, tradeTermGroupRule);
    //   commit('updateTradingGroupSetting', data);
    //   this.$notify('Trade limit updated', 'success');
    // } catch (error) {
    //   handleError(error, 'Failed to update trade limit.');
    // }
  },
  async currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
    // // fetch children
    // try {
    //   const { data } = await ETRM_API.get(`trade-limit-groups/${currentRow.name}`);
    //   commit('loadLimitsTableData', data.limits);

    // } catch (error) {
    //   handleError(error, 'Failed to fetch trade limit group.');
    // }
  },
  currentChildTableRowChange({ commit }, currentChildRow) {
    commit('currentChildTableRowChange', currentChildRow);
  },
  async deleteChildTableRow({ dispatch, commit, state }) {
    try {
      var updatedTradeTermGroup = clone(state.currentRow);
      var props = updatedTradeTermGroup.rule?.props;
      if (updatedTradeTermGroup.id > 0 && props) {
        updatedTradeTermGroup.rule = { 
          props: props.filter((p) => p.name !== state.currentChildRow?.name) 
        };
        dispatch('updateTradeTermGroup', updatedTradeTermGroup);
      }
      commit('deleteChildTableRow');
      // dispatch('currentChildRowChange', clone(state.nullTradeTermGroupRuleRow));
      // dispatch('currentTableRowChange', updatedTradeTermGroup);
      this.$notify('TradeTerm Group rule inactivated', 'success');
    } catch (error) {
      handleError(error, 'Failed to inactivate TradeTerm Group rule.');
    }
  },
};

const mutations = {
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((tradeTermGroup) => tradeTermGroup.id !== state.currentRow.id);
  },
  deleteChildTableRow(state) {
    state.childTableData = state.childTableData.filter((x) => x.name !== state.currentChildRow.name);
  },
  updateTableRow(state, tradeTermGroup) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === tradeTermGroup.id);
    state.tableData.splice(rowIndex, 1, tradeTermGroup);
  },
  updateChildTableRow(state, tradeTermGroupRule) {
    const rowIndex = state.childTableData.findIndex(({ name }) => name === tradeTermGroupRule.name);
    state.childTableData.splice(rowIndex, 1, tradeTermGroupRule);
  },
  currentTableRowChange(state, currentRow) {
    state.currentRow = currentRow;
    state.childTableData = currentRow?.rule?.props;
    state.currentChildRow = null;
  },
  currentChildTableRowChange(state, currentChildRow) {
    state.currentChildRow = currentChildRow;
  },
  loadTableData(state, tradeTermGroups) {
    state.tableData = tradeTermGroups;
  },
  loadChildTableData(state, tradeTermGroupRules) {
    state.childTableData = tradeTermGroupRules;
  },
  resetTable(state) {
    state.tableData = [];
    state.childTableData = [];
    state.currentRow = clone(state.nullTradeTermGroupRow);
    state.currentChildRow = null;
    state.currentLockingRow = null;
    state.currentAttributesRow = null;
    state.currentUserRow = null;
  },
  addTableRow(state, tradeTermGroup) {
    state.tableData.push(tradeTermGroup);
  },
  createTableRow(state) {
    const group = clone(state.nullTradeTermGroupRow);
    group.id = state.tableKey++;
    group.createdBy = this.getters['auth/getProfile'].userName;
    group.createdDate = utils.date.getNow().utc().format();
    state.currentRow = group;
  },
  createChildTableRow(state) {
    const setting = clone(state.nullTradeTermGroupRuleRow);
    // setting.createdBy = this.getters['auth/getProfile'].userName;
    // setting.createdDate = utils.date.getNow().utc().format();
    state.currentChildRow = setting;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};