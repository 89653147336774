import { RECS_MANAGEMENT_API, IDENTITY_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  selectedSystemNames: null,
  selectedEntityNames: [],
  systemNameOptions: ['WREGIS'],

  generatorFuelData: [],
  generationData: [],
  scs: [],

  generatorTableData: [],
  generationTableData: [],

  generatorTableConfig: {
    name: 'recGenerators',
    columns: [
      {
        prop: 'systemName', label: 'System Name', sortable: true, filterable: true,
      },
      {
        prop: 'name', label: 'Name', sortable: true, filterable: true,
      },
      {
        prop: 'mretsId', label: 'Mrets Id', sortable: true, filterable: true,
      },
      {
        prop: 'facilityName', label: 'Facility Name', sortable: true, filterable: true,
      },
      {
        prop: 'balancingAuthorityIdentifier', label: 'BA Identifier', sortable: true, filterable: true,
      },
      {
        prop: 'status', label: 'Status', sortable: true, filterable: true,
      },
      {
        prop: 'reportingUnitId', label: 'Reporting Unit Id', sortable: true, filterable: true,
      },
      {
        prop: 'county', label: 'County', sortable: true, filterable: true,
      },
      {
        prop: 'stateProvince', label: 'State Province', sortable: true, filterable: true,
      },
      {
        prop: 'country', label: 'Country', sortable: true, filterable: true,
      },
      {
        prop: 'commencedOperationDate', label: 'Commenced Operation Date', sortable: true, filterable: true,
      },
      {
        prop: 'registrationRightsAssignDate', label: 'Registration Rights Assign Date', sortable: true, filterable: true,
      },
      {
        prop: 'firstEligibleIssuance', label: 'First Eligible Issuance', sortable: true, filterable: true,
      },
      {
        prop: 'genType', label: 'GenType', sortable: true, filterable: true,
      },
      {
        prop: 'region', label: 'Region', sortable: true, filterable: true,
      },
      {
        prop: 'nameplateCapacity', label: 'Nameplate Capacity', sortable: true, filterable: true,
      },
      {
        prop: 'capacityFactor', label: 'Capacity Factor', sortable: true, filterable: true,
      },
      {
        prop: 'eiaNumber', label: 'Eia Number', sortable: true, filterable: true,
      },
      {
        prop: 'reportingInterval', label: 'Reporting Interval', sortable: true, filterable: true,
      },
      {
        prop: 'label', label: 'Label', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'remainderMwh', label: 'Remainder Mwh', sortable: true, filterable: true,
      },
      {
        prop: 'fuelSource.fuelType.name', label: 'Fuel Type', sortable: true, filterable: true,
      },
      {
        prop: 'fuelSource.description', label: 'Description', sortable: true, filterable: true,
      },
      {
        prop: 'fuelSource.resourceTypes', label: 'Resource Types', sortable: true, filterable: true,
      },
      {
        prop: 'fuelSource.isDefaultForType', label: 'Is Default For Type', sortable: true, filterable: true,
      },
      {
        prop: 'ownershipType', label: 'Ownership Type', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'meterId', label: 'Meter Id', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'generationClassification', label: 'Generation Classification', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'interconnectedUtilityIdentifier', label: 'Interconnected Utility Identifier', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'isRevenueQualityMeter', label: 'Is Revenue Quality Meter', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'isSingleOwnerFacility', label: 'Is Singl eOwner Facility', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'isMultiFuel', label: 'Is Multi Fuel', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'useHoldingPeriod', label: 'Use Holding Period', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'wregisThermalEnabled', label: 'Wregis Thermal Enabled', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'unitsCount', label: 'Units Count', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'unitsNameplateCapacity', label: 'Units Nameplate Capacity', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'imageName', label: 'Image Name', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'qualifyingFacility', label: 'Qualifying Facility', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'hasPpa', label: 'Has Ppa', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'registrationRightsAssign', label: 'Registration Rights Assign', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'courtRegulatorRightsAssign', label: 'Court Regulator Rights Assign', sortable: true, filterable: true, visible: false,
      },
    ],
    options: {
      columnConfig: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
  generationTableConfig: {
    name: 'recGenerations',
    columns: [
      {
        prop: 'dateOfAction', label: 'Date', sortable: true, filterable: true,
      },
      {
        prop: 'generationPeriodStart', label: 'Generation Period Start', sortable: true, filterable: true,
      },
      {
        prop: 'generationPeriodEnd', label: 'Generation Period End', sortable: true, filterable: true,
      },
      {
        prop: 'holdingPeriodStartDate', label: 'Holding Period Start Date', sortable: true, filterable: true,
      },
      {
        prop: 'quantity', label: 'Quantity', sortable: true, filterable: true,
      },
      {
        prop: 'quantityUnit', label: 'Quantity Unit', sortable: true, filterable: true,
      },
      {
        prop: 'status', label: 'Status', sortable: true, filterable: true,
      },
      {
        prop: 'user', label: 'User', sortable: true, filterable: true,
      },
      {
        prop: 'finalActionUser', label: 'Final Action User', sortable: true, filterable: true,
      },
      {
        prop: 'entryLinesIntervalInMinutes', label: 'Entry Lines Interval In Minutes', sortable: true, filterable: true,
      },
      {
        prop: 'notes', label: 'Notes', sortable: true, filterable: true,
      },
      {
        prop: 'fractionalRemainder', label: 'Fractional Remainder', sortable: true, filterable: true,
      },
      {
        prop: 'fuelAllocationType', label: 'Fuel Allocation Type', sortable: true, filterable: true,
      },
      {
        prop: 'reportedSource', label: 'Reported Source', sortable: true, filterable: true,
      },
      {
        prop: 'adjustmentType', label: 'Adjustment Type', sortable: true, filterable: true,
      },
      {
        prop: 'fuelAllocations', label: 'Fuel Allocations', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'parentGenerationEntry', label: 'Parent Generation Entry', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'reportingEntity', label: 'Reporting Entity', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'tickets', label: 'Tickets', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'actionInfo', label: 'Action Info', sortable: true, filterable: true, visible: false,
      },
      {
        prop: 'statusControl', label: 'Status Control', sortable: true, filterable: true, visible: false,
      },
    ],
    options: {
      columnConfig: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scList: (state) => _getList(state.scs, 'name'),
};

const actions = {
  async initialize({ commit, dispatch }) {
    commit('resetTable');
    await dispatch('fetchScs');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  generatorSelected({ state, commit }, currentRow) {
    commit('generatorSelected', currentRow);
  },
  async loadTableData({ dispatch, commit }) {
    commit('resetTable');
    await dispatch('loadGeneratorData');
    await dispatch('loadGenerationData');
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'COMPANY'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async loadGeneratorData({ state, commit }) {
    await Promise.all(state.selectedEntityNames.map(async (entityName) => {
      const params = {
        systemName: state.selectedSystemNames,
        entityName,
      };
      await RECS_MANAGEMENT_API.get('/recs-management/generator/generators', { params }).then(({ data }) => {
        commit('addGeneratorData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Generators', 'error');
      });
      
      await RECS_MANAGEMENT_API.get('/recs-management/generator/generatorFuels', { params }).then(({ data }) => {
        commit('addGeneratorFuelData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Generator Fuels', 'error');
      });
    }));

    commit('mapGeneratorFuelData');
    this.$notify(`${state.generatorTableData.length} Generators Loaded`, 'info');
  },
  async loadGenerationData({ state, commit }) {
    await Promise.all(state.selectedEntityNames.map(async (entityName) => {
      const params = {
        systemName: state.selectedSystemNames,
        entityName,
      };
      await RECS_MANAGEMENT_API.get('/recs-management/generation/generationEntry', { params }).then(({ data }) => {
        commit('addGenerationData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Generations', 'error');
      });
    }));
  },
};

const mutations = {
  resetTable(state) {
    state.generatorTableData = [];
    state.generationTableData = [];
    state.generationData = [];
  },
  setSelectedSystems(state, value) {
    state.selectedSystemNames = value;
  },
  setSelectedEntities(state, value) {
    state.selectedEntityNames = value;
  },
  addGeneratorData(state, generators) {
    state.generatorTableData = state.generatorTableData.concat(generators);
  },
  addGeneratorFuelData(state, generatorFuels) {
    state.generatorFuelData = state.generatorFuelData.concat(generatorFuels.map((x) => ({
      ...x,
      fuelSource: {
        ...x.fuelSource,
        resourceTypes: x.fuelSource.resourceTypes.map((y) => y.slug).join(', '),
      },
    })));
  },
  mapGeneratorFuelData(state) {
    state.generatorTableData = state.generatorTableData.map((g) => {
      const fuelData = state.generatorFuelData.find((x) => x.generator.id === g.id);
      return {...g, ...fuelData};
    });
  },
  addGenerationData(state, generations) {
    state.generationData = state.generationData.concat(generations);
  },
  generatorSelected(state, currentRow) {
    state.generationTableData = state.generationData
      .filter((g) => g.generator.id === currentRow.id)
      .map((g) => {
        const { generator, ...generationProps } = g;
        return { ...generationProps };
      });
  },
  ...createMutations('scs'),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};