<template>
  <div class="pscs-nav-bar">
    <dx-toolbar
      id="pscs-main-toolbar"
      ref="toolbar"
      :items="toolbarContent()" />
    <dx-drawer
      v-if="visible"
      ref="drawer"
      :close-on-outside-click="!isPinned"
      :expand-all-enabled="true"
      expand-event="click"
      opened-state-mode="shrink"
      :opened.sync="$root.isNavbarOpen"
      position="left"
      reveal-mode="slide"
      :select-by-click="true"
      template="listMenu">
      <!-- :search-enabled="true" -->
      <div id="nav-tree-view" slot="listMenu">
        <div class="navbar-buttons">
          <div class="buttons">
            <pscs-button-dx
              styling-mode="text"
              hint="Collapse all"
              :active-state-enabled="false"
              :focus-state-enabled="false"
              style="margin-right: 10px;"
              color="white"
              icon="unselectall"
              @clicked="treeInstance.collapseAll()" />
          </div>
          <div class="buttons">
            <pscs-button-dx
              styling-mode="text"
              :hint="`${isPinned ? 'Unpin' : 'Pin'} navbar`"
              :active-state-enabled="false"
              :focus-state-enabled="false"
              color="white"
              :icon="isPinned ? 'pin' : 'unpin'"
              @clicked="isPinned = !isPinned" />
          </div>
        </div>
        <dx-tree-view
          ref="treeView"
          :items="items"
          selection-mode="single"
          :search-enabled="true"
          :search-timeout="200"
          :virtual-mode-enabled="true"
          :focus-state-enabled="false"
          @item-click="selectItem"
          @item-expanded="itemExpanded">
          <div slot="item" slot-scope="{ data }">
            <div v-if="!data.name">
              {{ data.text }}
            </div>
            <router-link v-else-if="data.isCustom" :to="data.path">
              {{ data.text }}
            </router-link>
            <div v-else-if="data.text === adminToolsNavText">
              {{ data.text }}
            </div>
            <router-link v-else :to="{ name: data.name }">
              {{ data.text }}
            </router-link>
          </div>
        </dx-tree-view>
        <div class="navbar-version-info">
          <p>
            {{ (environment.length > 1 && version.length > 1 ? environment + ' - ' : environment) }}
            {{ version }}
            |
            {{ lastLogin }}
          </p>
        </div>
      </div>
      <slot />
    </dx-drawer>
    <pscs-dialog-dx
      :hide-on-outside-click="true"
      :ok-button-visible="false"
      :visible="isTimeoutDialogVisible"
      title="Timeout Notification"
      width="420px"
      @close="publishTimeOut()">
      <p>Your session is about to timeout. Click outside this box to stay signed in.</p>
    </pscs-dialog-dx>
  </div>
</template>

<script>
import { DxDrawer } from 'devextreme-vue/ui/drawer';
import { DxToolbar } from 'devextreme-vue/ui/toolbar';
import { DxTreeView } from 'devextreme-vue/ui/tree-view';
import { mapGetters, mapActions } from 'vuex';
import { hasPermission } from '@/utils/authUtils';
import { admin } from '@/auth/permission';
import { fetchResource } from '@/utils/structuresHelper';
import { has, cloneDeep } from '@/utils/dataUtil';
import userStore from '@/utils/userStore';
import { TOOLS_API } from '@/api';
import moment from 'moment';
import { getInstance } from '@/auth/authWrapper';
import PermissionMixin from '@/mixins/PermissionMixin';
import FeatureFlagMixin from '@/mixins/FeatureFlagMixin';
import uiFeatures from '@/features/ui';
import etrmFeatures from '@/features/etrm';
import ls from 'local-storage';
import dateStore from '../../utils/dateStore';

export default {
  name: 'PscsPageNavbar',
  components: { DxDrawer, DxToolbar, DxTreeView },
  mixins: [PermissionMixin, FeatureFlagMixin],
  data: () => ({
    items: [],
    selectedItem: {},
    visible: false,
    environment: window?.ENV_NAME ?? 'PRODUCTION',
    version: window?.VERSION_NUM ?? '16.0.62',
    toolbarVisible: false,
    adminToolsNavText: 'Admin Tools',
    isPinned: false,
    checkedForExpiredCertificate: false,
    isTimeoutDialogVisible: false,
    inactivityTimeout: null,
    inactivityTimeoutDialogNotice: null,
    inactivityTimeoutExpiration: (window?.TIMEOUT_INACTIVITY_IN_SECS ?? null) * 1000,
    dialogExpirationTime: 60000,
  }),
  computed: {
    ...mapGetters({
      getRoutes: 'auth/getRoutes',
      getProfile: 'auth/getProfile',
      getProfileValidFlag: 'auth/getProfileValidFlag',
      messageCount: 'notification/getMessageCount',
      permissions: 'auth/getPermissions',
    }),
    inactivityTimeoutFeatureEnabled() {
      return this.isFeatureEnabled(uiFeatures.INACTIVITY_TIMEOUT);
    },
    treeInstance() {
      return this.$refs.treeView.instance;
    },
    lastLogin() {
      if (!this.getProfile) return null;
      if (!this.getProfile?.lastLogin) return null;

      const userLogin = dateStore.toMoment(this.getProfile.lastLogin).format('YYYY-MM-DD hh:mm:ss A');
      return `${userLogin}`;
    },
    fullName() {
      if (this.getProfileValidFlag) {
        return `${this.getProfile.firstName} ${this.getProfile.lastName}`;
      }
      return '';
    },

  },
  watch: {
    '$root.isNavbarOpen': {
      handler() {
        if (!this.$root.isNavbarOpen && userStore.getCollapseNavigationMenusOnClose()) {
          this.treeInstance.collapseAll();
        }
      },
    },
    getProfile: {
      handler() {
        if (this.getProfile) {
          this.renderNavTree();
          this.fetchExpiringCerts();
          this.toolbarVisible = localStorage.getItem('isAuthenticated') === 'true';
        }
      },
      immediate: true,
      deep: true,
    },
    getRoutes: {
      handler() {
        this.renderNavTree();
      },
      deep: true,
    },
    '$route.meta.text': {
      handler() {
        let title = 'SettleCore';
        if (this.$route?.matched.length > 1) {
          if (this.$route?.matched[0]?.meta?.title) title += ` - ${this.$route?.matched[0]?.meta.title}`;
          else if (this.$route?.matched[0]?.meta?.text) title += ` - ${this.$route?.matched[0]?.meta.text}`;
        }
        if (this.$route?.meta?.title && this.$route?.meta?.title) title += ` - ${this.$route.meta.title}`;
        else if (this.$route?.meta?.text) title += ` - ${this.$route.meta.text}`;

        this.$nextTick(() => {
          document.querySelector('.app-name').innerHTML = title;
        });
      },
      immediate: true,
    },
    '$route.meta.showCert': {
      handler() {
        this.$nextTick(() => {
          this.$refs.toolbar.instance.option('items', this.toolbarContent());
        });
      },
      immediate: true,
    },
    '$route.name': {
      handler() {
        if (this.$route.name === 'Login') {
          this.visible = true;
        }
      },
    },
  },
  async mounted() {
    ls.on('check-if-time-out-is-soon', this.extendTimeOut);
    ls.on('set-popup-visible', this.setPopupVisible);
    ls.on('tab-active', this.tabActive);
    ls.on('tab-logout', () => this.logout(true));
    ls.on('tab-inactive', this.tabInactive);

    this.oidc = getInstance();
    await this.checkIfTokenExpiresSoon();
    setTimeout(this.listenForActivity, 5000);
  },
  beforeDestroy() {
    ls.remove('check-if-time-out-is-soon');
    ls.remove('set-popup-visible');
    ls.remove('tab-active');
    ls.remove('tab-logout');
    ls.remove('tab-inactive');
    ls.off('check-if-time-out-is-soon', this.extendTimeOut);
    ls.off('set-popup-visible', this.setPopupVisible);
    ls.off('tab-active', this.tabActive);
    ls.off('tab-logout', this.logout);
    ls.off('tab-inactive', this.tabInactive);
  },
  methods: {
    async checkIfTokenExpiresSoon() {
      const expiration = getInstance().expiryTime;
      if (expiration && Date.now() >= expiration) {
        this.logout();
      }
      const popupTime = expiration ? expiration - 60000 : undefined;
      if (!!popupTime && (Date.now() >= popupTime)) {
        if (localStorage.getItem('isAuthenticated') !== 'true') return;
        this.setPopupVisible();
        const npopupTime = ls.get('set-popup-visible');
        if (npopupTime) ls.set('set-popup-visible', Math.min(popupTime, npopupTime));
        else ls.set('set-popup-visible', popupTime);
      }

      let timeoutTime = 30000;
      if (this.isTimeoutDialogVisible) {
        timeoutTime = 60000;
      } else if (popupTime) {
        timeoutTime = popupTime - Date.now(); // Wait until a minute before expiry time
      }

      setTimeout(this.checkIfTokenExpiresSoon, timeoutTime);
    },
    setPopupVisible(tabInactive = false) {
      this.isTimeoutDialogVisible = !this.isTimeoutDialogVisible;

      if (this.isTimeoutDialogVisible) {
        if (!tabInactive) ls.set('tab-inactive', Math.random());
        if ('Notification' in window) {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              new Notification('SettleCore Idle Notification',
                { body: `Your session will expire in one minute on ${this.$el.ownerDocument.title}.` });
            }
          });
        }
      }
    },
    startInactivityTimer() {
      this.inactivityTimeout = setTimeout(this.logout, this.inactivityTimeoutExpiration);
      this.inactivityTimeoutDialogNotice = setTimeout(this.setPopupVisible, this.inactivityTimeoutExpiration - this.dialogExpirationTime);
    },
    registerActivity() {
      ls.set('tab-active', Date.now());
      this.resetInactivityTimer();
      this.isTimeoutDialogVisible = false;
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout);
      clearTimeout(this.inactivityTimeoutDialogNotice);
      this.startInactivityTimer();
    },
    listenForActivity() {
      if (this.inactivityTimeoutFeatureEnabled
      && localStorage.getItem('isAuthenticated')
      && this.inactivityTimeoutExpiration) {
        this.startInactivityTimer();
        document.addEventListener('click', this.registerActivity);
      }
    },
    tabInactive() {
      if (!this.isTimeoutDialogVisible) {
        if (this.inactivityTimeoutFeatureEnabled) {
          clearTimeout(this.inactivityTimeout);
          clearTimeout(this.inactivityTimeoutDialogNotice);
          this.inactivityTimeout = setTimeout(this.logout, this.dialogExpirationTime + 1000);
        }
        this.isTimeoutDialogVisible = false;
        this.setPopupVisible(true);
      }
    },
    tabActive() {
      this.resetInactivityTimer();
      this.isTimeoutDialogVisible = false;
    },
    async publishTimeOut() {
      await this.extendTimeOut();
      if (this.inactivityTimeoutFeatureEnabled) {
        this.resetInactivityTimer();
      }
      ls.set('check-if-time-out-is-soon', Math.random());
    },
    async extendTimeOut() {
      await (getInstance()).getTokenSilently();
      await (getInstance()).updateExpiryTime();
      this.$data.isTimeoutDialogVisible = false;
      ls.remove('set-popup-visible');
    },
    sortArr(list) {
      return list.sort((a, b) => {
        if (a?.rank && b?.rank) return (a.rank > b.rank) ? 1 : -1;
        if (!a?.rank && b?.rank) return 1;
        if (a?.rank && !b?.rank) return -1;
        if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
        if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
        return 0;
      });
    },
    launchAdminTools() {
      // launch admin tools as new page
      // note: whether or not it's in a new tab depends on user's browser settings
      if (window.ADMIN_TOOLS_URL) {
        window.open(window.ADMIN_TOOLS_URL);
      } else {
        window.open(`${window.location.origin}/admin-tools`);
      }
    },
    async renderNavTree() {
      let res = { data: [] };

      if (this.anyPermission(/etrm:*/)) {
        res = await fetchResource('tradeScreens', 'SCREENS');
        if (res.data) {
          // remove default custom trade screen from nav menu
          const defaultCustomIdx = res.data.findIndex((x) => x.path === 'default-custom' && x.name === 'Default Custom');
          res.data.splice(defaultCustomIdx, 1);
        }
      }

      // Putting this here fixes the content from rendering outside of drawer ¯\_(ツ)_/¯
      if (res) {
        const { data: customTradeRoutes } = res;
        // Sorts top level modules alphabetically
        const clonedRoutes = cloneDeep(this.getRoutes);
        this.items = this.sortArr(clonedRoutes).map((route) => {
          // add artifical route for admin tools external site
          if (this.anyPermission(admin.tool) && route.text === 'ADMIN' && Array.isArray(route.children)) {
            route.children.push({ text: this.adminToolsNavText, children: [] });
          }

          route.children = this.sortArr(route.children);

          return {
            text: route.text,
            name: route.name,
            selected: false,
            expanded: false,
            items: route.children.reduce((navTree, {
              text, name, children, isCustom,
            }) => {
            // sorts children
              children = this.sortArr(children);
              // Removes custom columns to be reinserted
              if (isCustom) return navTree;
              if (route.text === 'ETRM' && text === 'Trades' && Array.isArray(customTradeRoutes)) {
                // hide default trade screen
                children = this.isFeatureEnabled(etrmFeatures.HIDE_DEFAULT_TRADE_SCREEN) ? [] : children;
                customTradeRoutes.forEach((screen, idx) => {
                  children.push({
                    path: `/etrm/trades/custom/${screen.name.replace(/ /g, '')}?id=${screen.id}`,
                    text: screen.name,
                    name: screen.name,
                    selected: false,
                    expanded: false,
                    isCustom: true,
                    items: [],
                  });
                });
              }
              navTree.push({
                text,
                name,
                expanded: false,
                selected: false,
                items: children ? children.map((c) => ({ expanded: false, selected: false, ...c })) : [],
              });
              return navTree;
            }, []),
          };
        });
      }
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    itemExpanded({ node, component, itemData }) {
      if (node.parent === null) {
        if (this.selectedItem.text && this.selectedItem.text !== itemData.text) {
          component.collapseItem(this.selectedItem);
          this.selectedItem = itemData;
        }
      }
    },
    selectItem({ node, component, itemData }) {
      component.selectItem(itemData);
      if (node.parent === null || has(itemData, 'items')) {
        if (!has(itemData, 'items') && this.selectedItem.text && this.selectedItem.text !== itemData.text) {
          component.collapseAll();
        }
        this.selectedItem = itemData;

        if (this.selectedItem.text === this.adminToolsNavText) {
          this.launchAdminTools();
        }

        if (this.selectedItem.expanded) {
          this.selectedItem.expanded = false;
          component.collapseItem(this.selectedItem);
        } else {
          component.expandItem(this.selectedItem);
        }
      }
      // Pushes path to router
      if (itemData.name && this.$route.name !== itemData.name) {
        if (itemData.isCustom) {
          this.$router.push(itemData.path);
        } else {
          this.$router.push({ name: itemData.name });
        }
      }
    },
    loginOrLogout() {
      if (localStorage.getItem('isAuthenticated') === 'true') {
        this.$confirmDx('Are you sure you want to logout?', 'Logout').then((result) => {
          if (result) {
            this.logout();
            this.$refs.drawer.instance.hide();
          }
        });
      } else {
        this.login();
      }
    },
    async fetchExpiringCerts() {
      // whenever the user profile state would change,
      // this method would subsequently get invoked.
      // We will track if checking for expired certificates has already
      // been processed by evaluating the flag, checkedForExpiredCertificate
      if (this.checkedForExpiredCertificate) return;

      if (this.anyPermission(/caiso:*/)) {
        this.checkedForExpiredCertificate = true;
        try {
          const { data } = await TOOLS_API.get('/expiring-certificates');
          const certs = data.map((cert) => ({
            certificateName: cert.certificationName,
            expirationDate: moment(cert.expirationDate).format('MM/DD/YYYY'),
            daysExpired: moment(cert.expirationDate).diff(moment(), 'days'),
          }));

          let expiredMessage = '';
          certs.forEach(({ expirationDate, daysExpired, certificateName }) => {
            expiredMessage += `${certificateName} expire`;
            const plurality = daysExpired === -1 ? '' : 's';
            if (daysExpired < 0) {
              expiredMessage += (`d ${expirationDate}! (${Math.abs(daysExpired)} day${plurality} ago)`);
            } else if (daysExpired === 0) {
              expiredMessage += (`s today, ${expirationDate}!`);
            } else if (daysExpired > 0) {
              expiredMessage += (`s ${expirationDate}! (${daysExpired} day${plurality})`);
            }
            expiredMessage += '\n';
          });

          let expiredMessageNotification = '';
          if (certs.length !== 0) {
            const daysToExpire = certs[0].daysExpired;
            const today = daysToExpire === 0 ? 'today' : 'in ';
            const plurality = daysToExpire <= 1 ? '' : 's';
            if (daysToExpire >= 0) {
              expiredMessageNotification = `The server certificate expires ${today}${daysToExpire === 0 ? '!' : Math.abs(daysToExpire)} ${daysToExpire === 0 ? '' : 'day'}${plurality}`;
            } else {
              expiredMessageNotification = `The server certificate expired ${Math.abs(daysToExpire)} day${daysToExpire < -1 ? 's' : ''} ago!`;
            }
          }

          this.expiringCerts = expiredMessage;
          this.expiringCertsNotification = expiredMessageNotification;
        } catch (e) {
          console.error(e);
        }
      }
    },
    toolbarContent() {
      let title = 'SettleCore';
      if (this.$route?.matched.length > 1) {
        if (this.$route?.matched[0]?.meta?.title) title += ` - ${this.$route?.matched[0]?.meta.title}`;
        else if (this.$route?.matched[0]?.meta?.text) title += ` - ${this.$route?.matched[0]?.meta.text}`;
      }
      if (this.$route?.meta?.title && this.$route?.meta?.title) title += ` - ${this.$route.meta.title}`;
      else if (this.$route?.meta?.text) title += ` - ${this.$route.meta.text}`;

      if (!this.toolbarVisible) {
        return [{
          location: 'before',
          template: () => `<div class="app-name">${title}</div>`,
        }];
      }

      const toolbar = [{
        widget: 'dxButton',
        location: 'before',
        options: {
          icon: 'menu',
          stylingMode: 'text',
          activeStateEnabled: false,
          onClick: () => {
            this.$root.isNavbarOpen = !this.$root.isNavbarOpen;
          },
          visible: localStorage.getItem('isAuthenticated') === 'true',
        },
      }, {
        location: 'before',
        template: () => `<div class="app-name">${title}</div>`,
      }];
      if (localStorage.getItem('isAuthenticated') === 'true' && this.getProfileValidFlag) {
        const { showCert } = this.$route.meta;
        if (showCert && this.expiringCerts?.length && this.expiringCertsNotification?.length) {
          toolbar.unshift({
            location: 'after',
            widget: 'dxButton',
            isCert: true,
            options: {
              hint: this.expiringCerts,
              stylingMode: 'text',
              activeStateEnabled: false,
              focusStateEnabled: false,
              hoverStateEnabled: false,
              template: `<i class="bell fas fa-bell"><span>${this.expiringCertsNotification}</span></i>`,
            },
          });
        }
        toolbar.push({
          location: 'after',
          widget: 'dxButton',
          options: {
            stylingMode: 'text',
            activeStateEnabled: false,
            focusStateEnabled: false,
            hoverStateEnabled: false,
            onClick: () => this.toggleVisibility(),
            template: `<i class="messages dx-icon dx-icon-message"><div class="badge">${this.messageCount}</div></i>`,
          },
        }, {
          locateInMenu: 'always',
          location: 'after',
          template: () => `<div class="context-menu-user">${this.fullName}</div>`,
        }, {
          locateInMenu: 'always',
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Settings',
            onClick: () => this.$router.push({ name: 'Settings' }),
          },
        }, {
          locateInMenu: 'always',
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Reports',
            onClick: () => this.$router.push({ name: 'Reports' }),
          },
        }, {
          locateInMenu: 'always',
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Help',
            onClick: () => this.$router.push({ name: 'Help' }),
          },
        }, {
          locateInMenu: 'always',
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'About',
            onClick: () => this.$router.push({ name: 'About' }),
          },
        });
      }
      toolbar.push({
        locateInMenu: 'always',
        location: 'after',
        widget: 'dxButton',
        options: {
          text: localStorage.getItem('isAuthenticated') === 'true' ? 'Logout' : 'Login',
          onClick: () => this.loginOrLogout(),
        },
      });
      return toolbar;
    },
    openGuide(mod, perms) {
      if (perms.some((perm) => hasPermission(this.permissions, mod, perm))) {
        // window.location.pathname = '/static/SettleCore-Etag-Module-User-Guide.html';
      }
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    async logout(tabLogout = false) {
      if (!tabLogout) ls.set('tab-logout', Math.random());
      this.$auth.logout();
      await setTimeout(() => {
        this.$router.push({ path: '/' });
      }, 5000);
    },
    ...mapActions({
      toggleVisibility: 'notification/toggleVisibility',
    }),
  },
};
</script>

<style lang="scss">
.dx-popup-content {
  .context-menu-user {
    padding: 7px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }
}

.pscs-nav-bar {
  #pscs-main-toolbar.dx-toolbar {
    color: $gray-darkest;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);

    .dx-icon {
      width: 28px;
      font-size: 1.9em;
    }

    .dx-toolbar-items-container {
      width: unset;
      height: 36px;
      background-color: $gray-lightest;

      .dx-toolbar-before {
        .app-name {
          font-size: 2em;
          font-weight: 500;
        }
      }

      .dx-toolbar-after {
        margin-right: 10px;

        .dx-toolbar-item {
          padding-right: 12px;

          .dx-button-content {
            padding: 0;

            .bell {
              & > span {
                margin-left: 10px;
                font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Verdana, sans-serif;
                font-size: 16px;
                font-weight: 700;
              }

              width: 350px;
              font-size: 1.5em;
              color: $red;
            }

            .messages {
              position: relative;

              .badge {
                position: absolute;
                top: -10px;
                right: -12px;
                background-color: $orange;
              }
            }
          }
        }
      }
    }
  }

  .dx-drawer {
    height: calc(100vh - 36px);

    .dx-drawer-content {
      overflow-y: auto;
    }

    .dx-drawer-panel-content {
      width: 260px !important;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);

      #nav-tree-view {
        width: 260px;
        height: 100%;
        padding-top: 10px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        color: white;
        background-color: #2f3541;

        .navbar-version-info {
          text-align:right;
          padding-right: 5%;
          padding-top: 10%;
          font-size:0.77em
        }

        .navbar-buttons {
          display: flex;
          justify-content: space-between;
          padding-bottom: 5px;
          margin: 0 10px 5px 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.8);

          .buttons {
            display: flex;
          }

          .dx-button {
            .dx-button-content {
              padding: 0;
            }

            .dx-icon {
              font-size: 1.5rem;
            }
          }
        }

        .dx-treeview {
          height: calc(100% - 80px);
        }

        .dx-treeview-search {
          width: 94%;
          padding: 3px;
          margin: 0 auto 10px auto;
        }

        .dx-scrollable-content > .dx-treeview-node-container {
          .dx-treeview-node {
            padding-left: 0;

            .dx-state-hover {
              color: inherit;
              background-color: #e88e51;
              transition: background-color 1s;
            }

            .dx-treeview-item {
              padding: 10px 0 10px 30px;
              font-size: 1em;
              transition: all 0.35s ease-out;

              .dx-treeview-item-content {
                width: 100%;
                color: white;

                a {
                  padding: 11px 0;
                  color: white;
                  text-decoration: none;
                }
              }

              @nest &.dx-state-focused > & {
                padding: 10px 0 10px 30px;
                background-color: #e88e51;
              }
            }

            .dx-treeview-toggle-item-visibility {
              margin-top: 4px;
              margin-left: 5px;
              color: white;
            }

            ul.dx-treeview-node-container-opened li:last-child {
              border-bottom: 1px solid rgba(255, 255, 255, 0.8);
              transition: border-bottom 2s;
            }

            .dx-treeview-node-is-leaf.dx-state-selected {
              background-color: $orange;
              transition: background-color 1.5s;
            }

            // Helps indent and highlight rows correctly
            & > .dx-treeview-node-container > .dx-treeview-node {
              & > .dx-treeview-item {
                padding: 10px 0 10px 40px;

                & ~ .dx-treeview-toggle-item-visibility {
                  padding-left: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
