import { HEColumns } from '@/utils/dataUtil';
import moment from 'moment';

const roundValue = (value) => Math.round(value * 100) / 100;

export const VARIANT_ORDER = {
  CONTRACT: 1,
  FORECAST: 2,
  DA_INITIAL: 3,
  DA_FINAL: 4,
  DA_FINAL_ADJ: 5,
  RT_INITIAL: 6,
  RT_FINAL: 7,
  RT_FINAL_ADJ: 8,
  T_75: 9,
  T_55: 10,
};

const details_he_columns = (date) => HEColumns({}, true, date, 60, null, true).map((column) => (
  {
    ...column,
    editable: true,
    width: '40px',
    alignment: 'right',
    fixed: 'right',
    dataType: 'number',

    validationRules: [
      // { type: 'required' },
      // {
      // type: 'custom',
      // message: 'Value cannot exceed pmin/pmax',
      // validationCallback: (event) => {
      // if (event.value > 500) return false;
      // return true;

      // const parsedVal = parseFloat(data.value);
      // const singleDecimal = (data.value.match(/\./g) || []).length <= 1;
      // switch (data.valueType) {
      // case 'decimal':
      //   return singleDecimal && (!Number.isNaN(parsedVal));
      // case 'string':
      //   return typeof (data.value) === 'string';
      // case 'bool':
      //   return ['true', 'false'].includes(data.value.toLowerCase());
      // case 'int':
      //   return (!Number.isNaN(parsedVal)) && (parsedVal % 1 === 0);
      // default:
      //   return true;
      // }
      // },
      // },
    ],
  }
));

export const generateMdConfig = (date) => ({
  columns: [
    {
      label: 'Id',
      prop: 'objectReference',
      editable: false,
      alignment: 'left',
      width: '47px',
      sortIndex: 1,
      sortOrder: 'asc',
    },
    {
      label: 'Date',
      prop: 'date',
      dataType: 'date',
      alignment: 'left',
      width: '65px',
      editable: false,
      sortable: true,
      filterable: true,
      sortIndex: 3,
      sortOrder: 'asc',
    },
    // {
    //   label: 'Ext Id',
    //   prop: 'externalId',
    //   editable: false,
    //   alignment: 'left',
    //   width: '42px',
    // },
    {
      label: 'Configuration',
      prop: 'configuration',
      editable: false,
      alignment: 'left',
      width: '90px',
      filterable: true,
    },
    {
      label: 'POD',
      prop: 'pod',
      editable: false,
      alignment: 'left',
      width: '110px',
    },
    {
      label: 'CP',
      prop: 'counterParty',
      editable: false,
      alignment: 'left',
      width: '110px',
    }, {
      label: 'Type',
      prop: 'objectType',
      editable: false,
      alignment: 'left',
      width: '54px',
    }, {
      label: 'B/S',
      prop: 'direction',
      editable: false,
      alignment: 'left',
      width: '65px',
    }, {
      label: 'Start',
      prop: 'startTime',
      alignment: 'left',
      width: '65px',
      editable: false,
      calculateDisplayValue: (e) => moment(e.startTime).format('MM/DD/YY'),
    }, {
      label: 'End',
      prop: 'endTime',
      dataType: 'date',
      alignment: 'left',
      width: '65px',
      editable: true,
      calculateDisplayValue: (e) => moment(e.endTime).format('MM/DD/YY'),
    }, {
      label: 'Variant',
      prop: 'variant',
      editable: false,
      sortOrder: 'asc',
      sortIndex: 2,
      alignment: 'left',
      width: '30px',
      calculateSortValue: (e) => VARIANT_ORDER[e.variant],
    }, {
      label: '↓',
      prop: 'cascade',
      type: 'buttons',
      visible: true,
      width: '24px',
      alignment: 'center',
      buttons: [{
        icon: 'download',
        key: 'downloadButton',
      }],
    },
    ...details_he_columns(date),
    {
      label: 'TOTAL',
      width: '56px',
      alignment: 'right',
      cssClass: 'bold',
      fixed: 'right',
      calculateCellValue: (e) => roundValue(Object.keys(e)
        .filter((key) => key.includes('he'))
        .reduce((acc, curr) => acc + (e[curr] || 0), 0)),
    },
  ],
  options: {
    ignoreEdit: ['selected'],
    //   multipleSelection: true,
    //   allowSelectAll: true,
  },
});

export const generateBlotterTableConfig = (activeVariant, date, load) => ({
  columns: [
    {
      cellTemplate: 'PscsOpenMasterDetailCellTemplate',
      editorOptions: {
        config: [],
        multipleSelect: true,
        repaintChangesOnly: true,
        repaintRowOnSelect: ['selected'],
        masterDetailTemplate: 'PscsMasterDetailListTableTemplate',
        key: 'intervals',
        editConfig: {
          mode: 'batch',
          useIcons: true,
          allowUpdating: true,
          selectTextOnEditStart: true,
          startEditAction: 'click',
          allowNull: true,
        },
      },
      width: '50px',
      label: '[+/-]',
      editable: false,
      prop: 'details',
      fixed: 'left',
      alignment: 'center',
      calculateCellValue: (e) => '+',
    }, {
      label: 'ENT',
      prop: 'sc',
      editable: false,
      alignment: 'left',
      width: '65px',
    }, {
      label: 'Location',
      prop: 'location',
      alignment: 'left',
      filterable: true,
      editable: false,
      sortIndex: 1,
      sortOrder: 'asc',
    }, {
      label: 'SCHD Type',
      prop: 'schdType',
      editable: false,
      alignment: 'left',
      width: '65px',
      filterable: true,
    }, {
      label: 'PRD',
      prop: 'p',
      editable: false,
      alignment: 'left',
      width: '65px',
      filterable: true,
    }, {
      label: 'RES Type',
      prop: 'resType',
      editable: false,
      alignment: 'left',
      width: '65px',
      filterable: true,
    }, {
      label: 'Active Variant',
      editable: false,
      prop: 'variant',
      width: '112px',
      alignment: 'left',
      calculateCellValue: () => activeVariant,
    },
    ...HEColumns({ editable: false }, true, date, 60, null, true).map((column) => (
      {
        ...column,
        width: '40px',
        alignment: 'right',
        fixed: 'right',
        calculateCellValue: (data) => {
          const activeVariantData = data.intervals.filter((interval) => interval.variant === activeVariant);
          const activeVariantValue = activeVariantData.reduce((acc, curr) => {
            const value = curr[column.prop] || 0;
            return acc + value;
          }, 0);
          return roundValue(activeVariantValue);
        },
        calculateDisplayValue: (data) => {
          const activeVariantData = data.intervals.filter((interval) => interval.variant === activeVariant);
          const activeVariantValue = activeVariantData.reduce((acc, curr) => {
            const value = curr[column.prop];
            if (!value && value !== 0) {
              return acc;
            }
            return acc + value;
          }, null);
          return activeVariantValue;
        },
      }
    )), {
      prop: 'total',
      label: 'TOTAL',
      editable: false,
      width: '58px',
      alignment: 'right',
      cssClass: 'bold',
      fixed: 'right',
      calculateCellValue: (e) => {
        const activeVariantData = e.intervals.filter((interval) => interval.variant === activeVariant);
        return roundValue(activeVariantData
          .reduce((d_acc, d_curr) => d_acc + Object.keys(d_curr)
            .filter((key) => key.includes('he'))
            .reduce((i_acc, i_curr) => i_acc + (d_curr[i_curr] || 0),
              0),
          0));
      },
    },
  ],
  summary: [
    ...HEColumns({
      alignment: 'left', summaryType: 'sum', label: '{0}', width: 20, format: { type: 'fixedPoint', precision: 2 },
    }, false, date, 60, null, true),
    {
      prop: 'total',
      alignment: 'left',
      summaryType: 'sum',
      label: '{0}',
      width: 20,
      format: {
        type: 'fixedPoint',
        precision: 2,
      },
    },
  ],
  options: {
    filterHeader: true,
    multipleSelection: false,
    allowSelectAll: false,
    summaryRows: true,
  },
});

export const generateSubTableConfig = (initialVariant, date) => ({
  // tableName: 'blotterSubTable',
  columns: [
    {
      label: 'Id',
      prop: 'objectReference',
      editable: false,
      alignment: 'left',
      width: '45px',
      sortIndex: 2,
      sortOrder: 'desc',
      filterable: true,
      calculateSortValue: (e) => e.objectReference,
    },
    {
      label: 'Date',
      prop: 'date',
      dataType: 'date',
      alignment: 'left',
      width: '65px',
      editable: false,
      sortable: true,
      filterable: true,
      sortIndex: 3,
      sortOrder: 'asc',
    },
    {
      label: 'ENT',
      prop: 'sc',
      editable: false,
      alignment: 'left',
      width: '40px',
      filterable: false,
    },
    // {
    //   label: 'Ext Id',
    //   prop: 'externalId',
    //   editable: false,
    //   alignment: 'left',
    //   width: '40px',
    //   filterable: false,
    // },
    {
      label: 'Location',
      prop: 'location',
      editable: false,
      alignment: 'left',
      width: '130px',
      filterable: true,
      sortIndex: 1,
      sortOrder: 'asc',
    }, {
      label: 'Configuration',
      prop: 'configuration',
      editable: false,
      alignment: 'left',
      width: '110px',
      filterable: true,
    }, {
      label: 'SCHD Type',
      prop: 'schdType',
      editable: false,
      alignment: 'left',
      width: '60px',
      filterable: true,
    }, {
      label: 'RES Type',
      prop: 'resType',
      editable: false,
      alignment: 'left',
      width: '55px',
      filterable: true,
    },
    {
      label: 'POD',
      prop: 'pod',
      editable: false,
      alignment: 'left',
      width: '90px',
      filterable: true,
    }, {
      label: 'CP',
      prop: 'counterParty',
      editable: false,
      alignment: 'left',
      width: '90px',
      filterable: true,
    }, {
      label: 'PRD',
      prop: 'p',
      editable: false,
      alignment: 'left',
      width: '50px',
      filterable: true,
    }, {
      label: 'Type',
      prop: 'objectType',
      editable: false,
      alignment: 'left',
      width: '60px',
      filterable: true,
    }, {
      label: 'B/S',
      prop: 'direction',
      editable: false,
      alignment: 'left',
      width: '35px',
      // visible: false,
    }, {
      label: 'Start',
      prop: 'startTime',
      alignment: 'left',
      width: '60px',
      editable: false,
      filterable: true,
      calculateDisplayValue: (e) => moment(e.startTime).format('MM/DD/YY'),
    }, {
      label: 'End',
      prop: 'endTime',
      dataType: 'date',
      alignment: 'left',
      width: '60px',
      editable: true,
      filterable: true,
      calculateDisplayValue: (e) => moment(e.endTime).format('MM/DD/YY'),
    }, {
      label: 'Variant',
      prop: 'variant',
      editable: false,
      alignment: 'left',
      width: '70px',
      filterable: true,
    }, {
      label: '↓',
      prop: 'cascade',
      type: 'buttons',
      visible: true,
      width: '24px',
      alignment: 'center',
      buttons: [{
        icon: 'download',
        key: 'downloadButton',
      }],
    },
    ...details_he_columns(date),
    {
      prop: 'total',
      label: 'TOTAL',
      width: '58px',
      alignment: 'right',
      fixed: 'right',
      cssClass: 'bold',
      calculateCellValue: (e) => roundValue(Object.keys(e)
        .filter((key) => key.includes('he'))
        .reduce((acc, curr) => acc + (e[curr] || 0), 0)),
    },
  ],
  summary: [
    ...HEColumns({
      alignment: 'left', summaryType: 'sum', label: '{0}', width: 20, format: { type: 'fixedPoint', precision: 2 },
    }, false, date, 60, null, true),
    {
      prop: 'total',
      alignment: 'left',
      summaryType: 'sum',
      label: '{0}',
      width: 20,
      format: {
        type: 'fixedPoint',
        precision: 2,
      },
    },
  ],
  editConfig: {
    mode: 'batch',
    // useIcons: true,
    allowUpdating: true,
    selectTextOnEditStart: true,
    startEditAction: 'click',
    allowNull: true,
  },
  options: {
    ignoreEdit: ['selected'],
    filterHeader: true,
    multipleSelection: false,
    allowSelectAll: false,
    summaryRows: true,
  },
});