import moment from 'moment';
import { fetchUserResource, fetchResource } from '@/utils/structuresHelper';
import dateStore from '@/utils/dateStore';
import {
  PeriodColumns, has, sortBy, HEProperty, formatValue, totals, toNumber, cloneDeep, roundNumber
} from '@/utils/dataUtil';
import { DELIVERY_MANAGEMENT_API, STRUCTURES_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';
import { isNumber } from 'lodash';

// columns used by all grid configurations: hourly, daily, monthly, yearly
const sharedColumns = [{
  prop: 'order',
  label: '#',
  sortable: true,
  editable: false,
  fixed: 'left',
  minWidth: 30,
  alignment: 'left',
  sortOrder: 'asc',
}, {
  prop: 'label',
  label: 'NAME',
  fixed: 'left',
  alignment: 'left',
  sortable: true,
  minWidth: 100,
  cellTemplate: 'PscsRouteCellTemplate',
  editable: false,
}, {
  prop: 'variant', label: 'VARIANT', sortable: true, editable: false, alignment: 'left',
}, {
  prop: 'type', label: 'TYPE', sortable: true, editable: false, alignment: 'left', groupIndex: 0,
}, {
  prop: 'total', label: 'TOTAL', sortable: true, editable: false, fixed: 'right', alignment: 'right',
}];

const format = (row, column) => {
  const format = row.format || 'decimal';
  const fixedDecimal = row.fixedDecimal || 0;

  // this handles normal row with data values
  const value = parseFloat(row[column]);
  if (isNaN(value)) {
    return null;
  }

  if (isNumber(value)) { return formatValue(value, format, fixedDecimal); }

  return null;
};

const isWithinPeriod = (periodStartDate, startDate, period) => {
  // periodStartDate is a time moment 
  if (period === 'Hourly' || period === 'Daily')
        return periodStartDate?.isSame(startDate);

  return Math.abs(periodStartDate?.diff(startDate, 'days')) <= 1;
}

// For DST
const generateConfig = (dateRange, selectedPeriod) => ({
  name: 'positions',
  columns: [
    ...sharedColumns,
    ...PeriodColumns({
      minWidth: 30,
      sortable: false,
      editable: true,
      alignment: 'right',
      dataType: 'number',
      setCellValue: (newData, value, currentRowData, prop) => {
        // store data as entered by the user but convert to numeric value for calculations
        newData[prop] = value;
        const newValue = toNumber(value);

        // data editing is only allowed at the hourly level,
        // so we can assume data is stored in "heXX" props
        const profile = Object.entries(currentRowData)
          .filter(([key]) => key.startsWith('he'))
          .map(([he, value]) => ({ he, value }));

        // now we either override existing value or add new one
        const i = profile.findIndex(((p) => p.he === prop));
        if (i !== -1) { profile[i].value = newValue; } else { profile.push({ he: prop, value: newValue }); }

        const format = currentRowData.format || 'decimal';
        const summary = currentRowData.summary || 'sum';
        const fixedDecimal = currentRowData.fixedDecimal || 0;
        const factor = getFactor(currentRowData) ?? 1;

        // now we can calculate daily total
        let total = profile.reduce(totals[summary], Number.NaN) * factor;
        if (isNumber(total) && summary === 'avg') {
          total /= profile.length;
        }

        newData.total = formatValue(total, format, fixedDecimal);
      },
      summary: [],
    },
    true,
    dateRange,
    selectedPeriod,
    ).map((item) => ({
      ...item,
      calculateDisplayValue: (row) => format(row, item.prop),
    })),
  ],
  options: { exportExcel: true, columnConfig: false },
});

const state = {
  // page params
  commodity: undefined,
  entityType: undefined,
  market: '',
  marketTypes: [],
  moduleName: '',
  tz: 'PPT',

  tableConfiguration: generateConfig(dateStore.getDefaultOneDayRange(), 'Hourly'),
  tableData: [],
  chartData: [],
  positions: [],
  groupSettings: [],
  selectedPosition: null,
  cachedPosition: null,
  breadcrumbs: [],
  editable: false,
  autoRefreshFlag: true,
  periods: ['Hourly', 'Daily', 'Weekly', /* '7 Days', */ 'Monthly', 'Quarterly', 'Yearly'].map((value) => ({ label: value, value })),
  selectedPeriod: 'Hourly', // default is hourly
  dateRange: dateStore.getDefaultOneDayRange(),
  chartSeriesConfig: [],
  toolTipPrecision: { NET: 2 },
  hideZerosChecked: false,
  positionTypeList: [],
  positionSubTypeList: [],
  termTypes: [],
  locations: [],
  dailyGlobalVariablesData: [],
  variableDialogVisibility: false,
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  isRoot: (state) => state.breadcrumbs.length === 1,
  getToolTipPrecision: (state) => state.toolTipPrecision,
  getHideZerosChecked: (state) => state.hideZerosChecked,
  positionTypeList: (state) => state.positionTypeList,
  positionSubTypeList: (state) => state.positionSubTypeList,
  termTypeList: (state) => _getList(state.termTypes, 'shortName'),
  locationList: (state) => _getList(state.locations, 'shortName').sort(sortBy('label')),
};

const getStartAndEndTimeParam = (state, pos) => {
  const [start, end] = state.dateRange;
  return {
    startTime: moment(start).toISOString(),
    endTime: moment(end).add(1, 'day').toISOString(),
    types: pos?.type || 'VOLUME',
    intervalTypes: pos?.type || 'VOLUME',
    variants: pos?.variant || 'CURRENT',
    intervalVariants: pos?.variant || 'CURRENT',
    period: state.selectedPeriod,
    outputTimeZone: dateStore.getTimeZone(),
  };
};

const getFactor = (item) => {
  if (item?.factor && !Number.isNaN(item.factor)) {
    return Number(item.factor);
  }
  return null;
};

const getPosition = (position, positionList) => {
  if (!positionList) { return null; }
  return positionList.find((p) => p.name === position);
};

const generateChartSeriesConfig = (positionItems) => positionItems.reduce((acc, item) => {
  item.chartType = item.chartType || 'line';
  if (!item.chartType.includes('stacked') && (item.stack === 'true' || item.stack === true)) {
    item.chartType = `stacked${item.chartType}`;
  }

  // Numbers position in case of duplicates
  let positionKey = item.resourceGroupName;
  if (acc.map(({ prop }) => prop).includes(item.resourceGroupName)) {
    // If chart has plot point key it by name plus count
    const count = acc.filter(({ prop }) => prop.includes(item.resourceGroupName)).length;
    positionKey = `${item.resourceGroupName}/${count}`;
  }
  acc.push({
    label: item.label || item.resourceGroupName,
    //prop: positionKey,
    prop: item.label || item.resourceGroupName,
    visible: true,
    showInLegend: true,
    $used: false,
    ...item,
  });
  return acc;
}, []);

// get the minimum fixed point in the collection
const findSummaryDecimalPlaces = (data) => data.length
  ? Math.min(...data.map(({ fixedDecimal }) => Number(fixedDecimal) || 0))
  : 0;

const showSummaryRow = (position, root) => {
  if (!root) { return true; }
  if (!position.showNET) { return false; }
  return position.showNET === true || position.showNET === 'true';
};

const isRoot = (breadcrumbs) => breadcrumbs.length === 1;

const actions = {
  async initializeTypesLists({ dispatch }) {
    dispatch('loadPositionTypes');
    dispatch('loadPositionSubTypes');
    dispatch('fetchTermTypes');
    dispatch('fetchLocations');
  },
  setPageParams({ commit }, {
    commodity,
    entityType,
    market,
    marketTypes,
    moduleName,
  }) {
    commit('setCommodity', commodity);
    commit('setEntityType', entityType);
    commit('setMarket', market);
    commit('setMarketTypes', marketTypes);
    // commit('setMarketType', marketTypes[0]);
    commit('setModuleName', moduleName);
  },
  async loadPositions({ commit }, root) {
    const userResource = await fetchUserResource('position-management', 'POSITIONS');
    const resource = await fetchResource('position-management', 'POSITIONS');

    const userResourcePositions = userResource?.POSITIONS?.map((x) => ({ ...x, shared: false })) || [];
    const resourcePositions = resource?.POSITIONS?.map((x) => ({ ...x, shared: true })) || [];

    const POSITIONS = userResourcePositions.concat(resourcePositions);
    if (POSITIONS) {
      POSITIONS.sort(sortBy('name'));

      POSITIONS.forEach((pos, idx) => {
        pos.positionItems.sort(sortBy('order'));
        pos.positionItems.forEach((posItem, childIdx) => {
          posItem.type = posItem.type || 'VOLUME'; // this will default to VOLUME for old configurations
        });
      });

      commit('setPositions', { positions: POSITIONS, root });
    }
  },
  async searchData({ commit, state }) {
    if (!state.selectedPosition) return;
    const position = getPosition(state.selectedPosition, state.positions);
    const payload = [];
    const positionPromises = position.positionItems.map(async (pos, index) => {
      const params = getStartAndEndTimeParam(state, pos);
      const { data } = await DELIVERY_MANAGEMENT_API.get(`groupings/${pos.groupId}/aggregate-intervals`, { params });
      payload.push({
        order: Number(pos.order) || index + 1,
        id: pos.groupId,
        positionType: 'Group',
        label: pos.label,
        name: pos.resourceGroupName,
        variant: pos.variant,
        type: pos.type || 'VOLUME',
        format: pos.format || 'decimal',
        summary: pos.summary || 'sum',
        groupSummary: pos.groupSummary || 'sum',
        fixedDecimal: pos.fixedDecimal,
        profiles: data,
      });
    });

    await Promise.all(positionPromises);
    commit('setCachedPosition', position.name);
    commit('setData', payload);
  },
  async fetchGroupData({ dispatch, state }, params) {
    const previousGroups = cloneDeep(state.groupSettings);
    const previousGroup = previousGroups.find((x) => x.name === params.name);
    const times = getStartAndEndTimeParam(state, params);
    params.startTime = times.startTime;
    params.endTime = times.endTime;

    const { data } = await DELIVERY_MANAGEMENT_API.get(`groupings/${params.id}`, { params });

    data.children?.forEach((child) => {
      child.positionType = child.type;
      child.type = params.type;
    });
    await dispatch('fetchPositionsData', {
      children: data.children,
      variant: params.variant,
      type: params.type,
      previousGroup,
      defaultVariant: params.defaultVariant,
    });
  },
  async fetchTermTypes({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/term-types');
      commit('setTermTypes', data.termTypes);
    } catch (error) {
      this.$notify('Failed to fetch term types', 'error');
      console.error(error);
    }
  },
  async fetchLocations({ commit, state }, params) {
    const { data } = await STRUCTURES_API.get('/locations', params);
    commit('setLocations', data.data.locations);
  },
  async fetchPositionsData({ commit, state }, item) {
    commit('reset');
    const {
      children, variant, type, defaultVariant,
    } = item;
    const payload = [];
    const params = getStartAndEndTimeParam(state, item);

    // group children by type
    const groupedChildren = children.reduce((x, child) => {
      (x[child.positionType] = x[child.positionType] || []).push(child);
      return x;
    }, {});

    const parent = {};
    if (state.breadcrumbs.length > 0) {
      const lastCrumb = state.breadcrumbs[state.breadcrumbs.length - 1];
      parent.groupId = lastCrumb?.id;
      parent.defaultVariant = lastCrumb?.defaultVariant ?? true;
    }
    if (state.breadcrumbs.length > 1) {
      state.groupSettings.splice(0, state.groupSettings.length);
      const group = state.positions.find((group) => group.name === state.cachedPosition);
      const delivery = group.positionItems
        .find((delivery) => {
          // only the delivery group level has data formats configured
          const breadcrumb = state.breadcrumbs[1];
          return delivery.groupId === Number(breadcrumb.id)
            && delivery.label === breadcrumb.name;
        });

      parent.format = delivery?.format;
      parent.summary = delivery?.summary;
      parent.groupSummary = delivery?.groupSummary;
      parent.fixedDecimal = delivery?.fixedDecimal;
    }

    // handle delivery groups
    if (groupedChildren.DeliveryGroup && groupedChildren.DeliveryGroup.length > 0) {
      state.groupSettings.splice(0, state.groupSettings.length);
      groupedChildren.DeliveryGroup.forEach((group) => state.groupSettings.push({
        id: group.id,
        name: group.name,
        fixedDecimal: group.fixedDecimal,
      }));
      const ids = groupedChildren.DeliveryGroup.map(({ id }) => (`ids=${id}`)).join('&');
      try {
        params.parentGroupId = parent.groupId;
        params.defaultVariantFlag = parent.defaultVariant;
        const { data } = await DELIVERY_MANAGEMENT_API.get(`groupings/aggregate-intervals?${ids}`, { params });
        data.forEach((group, index) => {
          const child = groupedChildren.DeliveryGroup.find((x) => x.id === group.groupId
            && x.variant === group.variant);
          payload.push({
            order: Number(child.order) || index + 1,
            id: child.id,
            positionType: 'Group',
            name: child.name,
            type: child.type,
            variant: group.variant ?? variant,
            defaultVariant: parent.defaultVariant && group.variant == null,
            startTime: params.startTime,
            endTime: params.endTime,
            format: child.format || parent.format || 'decimal',
            summary: child.summary || parent.summary || 'sum',
            groupSummary: child.groupSummary || parent.groupSummary || 'sum',
            fixedDecimal: child.fixedDecimal || parent.fixedDecimal || 0,
            factor: child.factor,
            profiles: group.profiles,
          });
        });
      } catch (error) {
        console.error(error);
        this.$notify('Failed to fetch aggregated intervals ', 'error');
      }
    }

    // handle deliveries
    if (groupedChildren.Delivery && groupedChildren.Delivery.length > 0) {
      state.editable = true;

      params.intervalVariants = variant;
      params.defaultVariantFlag = defaultVariant ?? parent.defaultVariant;
      await Promise.all(groupedChildren.Delivery.map(async (child, index) => {
        try {
          const { data } = await DELIVERY_MANAGEMENT_API.get(`deliveries/${child.id}`, { params });

          parent.format = item.previousGroup?.format || parent.format;
          parent.summary = item.previousGroup?.summary || parent.summary;
          parent.groupSummary = item.previousGroup?.groupSummary || parent.groupSummary;
          // parent.fixedDecimal = item.previousGroup?.fixedDecimal || parent.fixedDecimal; // potential problem. no need to redefine parent.fixedDecimal?

          payload.push({
            order: Number(child.order) || index + 1,
            id: child.id,
            positionType: 'Delivery',
            name: child.name,
            type: child.type,
            variant,
            startTime: params.startTime,
            endTime: params.endTime,
            format: child.format || parent.format || 'decimal',
            summary: child.summary || parent.summary || 'sum',
            groupSummary: child.groupSummary || parent.groupSummary || 'sum',
            fixedDecimal: child.fixedDecimal || parent.fixedDecimal || 0,
            profiles: data.intervals,
          });
        } catch (error) {
          console.error(error);
          this.$notify('Failed to fetch deliveries ', 'error');
        }
      }));
    } else { state.editable = false; }

    commit('setData', payload);
  },
  async updateDelivery({ commit, state }, updates) {
    const data = updates.oldData ?? updates;
    const newData = updates.newData;
    
    // Iterates over HE01-24 and creating intervals and profiles if it doesn't exists
    const payload = [];
    const updatedProfiles = [];
    const range = dateStore.getTimeRange(state.dateRange[0], 60); // delivery updates are only at the hourly level
    
    // only include modified HE values
    const heVals = Object.entries(newData)
      .filter(([key]) => key.startsWith('he')).map(([he, val]) => ({ value: val, he }));
    
    const profile = { ...data?.profiles[0] };

    range.forEach((r) => {
      const item = heVals.find((x) => x.he === HEProperty(r.he));
      let formattedValue = null;

      if (item) {
        // 0's are valid - if input is any non numeric value then just leave default null assignment
        if (item.value !== null && item.value !== undefined && !Number.isNaN(Number(item.value))) {
          formattedValue = Number(item.value);
        }

        const interval = {
          startTime: r.timeTZ.toISOString(),
          endTime: r.timeTZ.add(1, 'hour').toISOString(),
          objectReference: data.id,
          objectType: 'DeliveryManagement',
          type: profile.type ?? data.type ?? 'VOLUME',
          value: formattedValue,
          variant: profile.variant ?? data.variant,
        };

        const newProfile = {
          ...profile,
          startTime: interval.startTime,
          endTime: interval.endTime,
          value: interval.value,
        };

        updatedProfiles.push(newProfile);
        
        // include all modified intervals
        payload.push(interval);
      }
    });

    let url = `${data.id}/intervals`;
    if (data.query) url += data.query;
    const { data: resData } = await DELIVERY_MANAGEMENT_API.put(url, { intervals: payload });
    if (resData.valid) {
      // Deleting HE data to prevent duplicates. setData will fill in HE data from profiles
      state.tableData.forEach(data => {
        for (let prop in data) {
          if (prop.startsWith('he')) {
            delete data[prop];
          }
        }
      });

      commit('setData', state.tableData);
      this.$notify('Successfully updated delivery', 'success');
    } else {
      this.$notify('Invalid delivery update', 'error');
    }
    return Promise.resolve(resData);
  },
  async loadPositionTypes({ commit }) {
    try {
      const data_types = await DELIVERY_MANAGEMENT_API.get('deliveries/types');
      commit('setPositionTypeList', data_types.data.types);
      return state.positionTypeList;
    } catch (error) {
      console.error(error);
      this.$notify('Failed to fetch delivery types', 'error');
    }
  },
  async loadPositionSubTypes({ commit }) {
    try {
      const data_subtypes = await DELIVERY_MANAGEMENT_API.get('deliveries/subtypes');
      commit('setPositionSubTypeList', data_subtypes.data.types);
      return state.positionSubTypeList;
    } catch (error) {
      console.error(error);
      this.$notify('Failed to fetch delivery subtypes', 'error');
    }
  },
  async importDelivery({ state, commit }, { fileName, tradingDate, importSource, locationName, configurationName, timeZone }) {
    try {
      await DELIVERY_MANAGEMENT_API.post('deliveries/import', {
        importSource,
        fileName,
        configurationName,
        locationName,
        timeZone
      });
      this.$notify({ type: 'success', message: 'Schedule Delivery Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Schedule Delivery Failed' });
      console.error(error);
    }
  },
  async fetchStrategyVariables({ state, dispatch }, params = { strategy: undefined }) {
    const {
      commodity, entityType, market, moduleName, selectedDates,
    } = state;
    const reqBody = {
      commodity: this.commodity,
      market: this.market,
      entityType: this.entityType,
      module: this.moduleName,
      startTime: selectedDates[0],
      endTime: selectedDates[1],
    };
    if (params.strategy) {
      try {
        const hourlyData = await BIDDING_API.get(`/variables/${ params.strategy }`, { params: { ...reqBody, type: 'HOURLY' } });
        const dailyData = await BIDDING_API.get(`/variables/${ params.strategy }`, { params: { ...reqBody, type: 'DAILY' } });
        const combinedData = { hourly: hourlyData.data, daily: dailyData.data };
        dispatch('mapVariables', { combinedData, strategy: params.strategy });
      } catch (error) {
        console.error('Error Fetching Hourly Strategy Variables', error);
      }
    } else {
      try {
        const hourlyData = await BIDDING_API.get('/variables', { params: { ...reqBody, type: 'HOURLY' } });
        const dailyData = await BIDDING_API.get('/variables', { params: { ...reqBody, type: 'DAILY' } });
        const combinedData = { hourly: hourlyData.data, daily: dailyData.data };
        dispatch('mapVariables', { combinedData });
      } catch (error) {
        console.error(`Error Fetching ${params.type} Variables`, error);
      }
    }
  },
  async mapVariables({ dispatch, state, commit }, { combinedData, type, strategy }) {
    let tableData = [];
    let map = [];
    let variables = combinedData?.daily?.variables || [];
    if (variables) {
      tableData = variables.map(({
        name, valueType, value, id, marketType, location, scriptName,
      }) => ({
        name,
        valueType,
        value,
        id,
        marketType,
        location,
        variableType: 'DAILY',
        strategies: scriptName?.split(',') || [],
      }));
    }
    variables = combinedData?.hourly?.variables || [];
    if (variables) {
      map = variables.reduce((acc, curr) => {
        if (curr.id in acc) {
          acc[curr.id].values.push({
            he: moment(curr.startTime).get('hour') + 1,
            value: curr.value,
          });
        } else {
          acc[curr.id] = {
            id: curr.id,
            name: curr.name,
            valueType: curr.valueType,
            marketType: curr.marketType,
            location: curr.location,
            variableType: 'HOURLY',
            value: EMPTY_HOURLY_VALUE,
            strategies: curr.scriptName?.split(',') || undefined,
            config: {
              id: curr.id,
              name: curr.name,
              valueType: curr.valueType,
              marketType: curr.marketType,
              location: curr.location,
              strategies: curr.scriptName?.split(',') || undefined,
            },
            values: [{
              he: moment(curr.startTime).get('hour') + 1,
              value: curr.value,
            }],
          };
        }
        return acc;
      }, {});

      Object.keys(map).forEach((item) => (tableData.push(map[item])));
    }

    commit('setDailyGlobalVariablesData', tableData);
  },
};

const mutations = {
  clearCrumbs(state) {
    state.breadcrumbs = [{ root: state.cachedPosition }];
  },
  setHideZeros(state, val) {
    state.hideZerosChecked = val;
  },
  setPositions(state, request) {
    state.positions = request.positions;
    if (!request.positions.length || state.selectedPosition) return;
    state.selectedPosition = request.root || request.positions[0].name;
  },
  setPositionTypeList(state, list) {
    state.positionTypeList = list || [];
  },
  setPositionSubTypeList(state, list) {
    state.positionSubTypeList = list || [];
  },
  setData(state, data) {
    const charDecimals = {};
    data.forEach((t) => {
      charDecimals[t.label || t.name] = t.fixedDecimal;
    });
    let maxFixedDecimal = Math.max(...Object.values(charDecimals).map(Number).filter(n => !isNaN(n)));
    charDecimals['NET'] = maxFixedDecimal;

    state.toolTipPrecision = charDecimals;
    const position = getPosition(state.selectedPosition, state.positions);
    let chartSeriesConfig = generateChartSeriesConfig(position.positionItems);
    const summaryDecimalPlaces = findSummaryDecimalPlaces(data);
    const root = isRoot(state.breadcrumbs);
    const isSummaryRowVisible = showSummaryRow(position, root);
    const summaryClass = isSummaryRowVisible ? 'visible' : 'hidden';
    let chartData = [];

    // Updates config to reflect DST
    state.tableConfiguration = generateConfig(state.dateRange, state.selectedPeriod);
    state.chartData = [];

    // Multiplies the factor and fixed decimal place for chart and graph
    data.forEach((row) => {
      const series = chartSeriesConfig.find(({
        variant, label, $used,
      }) => {
        const hasUsed = $used;
        const sameVariant = variant.toLowerCase() === row.variant.toLowerCase();
        const sameLabel = label === row.label;
        return !hasUsed && sameVariant && sameLabel;
      });

      const format = row.format || 'decimal';
      const summary = row.summary || 'sum';
      const fixedDecimal = row.fixedDecimal || 0;

      const factor = getFactor(row) ?? getFactor(series) ?? 1;

      if (series) {
        series.$used = true;
        row.label = series.label;
        row.variant = series.variant;
      } else {
        chartSeriesConfig = chartSeriesConfig.filter((x) => x.prop !== row.name);

        chartSeriesConfig.push({
          label: row.name,
          prop: row.name,
          chartType: 'stackedbar',
          visible: true,
          showInLegend: true,
          factor: 1,
          $used: true,
          variant: row.variant,
          type: row.type,
          format: row.format,
          summary: row.summary,
          groupSummary: row.groupSummary,
        });
      }
      row.profiles.forEach(({ value, startTime }) => {
        const timeCheck = (state.selectedPeriod === 'Yearly') ? moment(startTime.split('T')[0]).toISOString() : startTime;
        const prop = state.tableConfiguration.columns.find(({ timeTZ }) => isWithinPeriod(timeTZ, timeCheck, state.selectedPeriod))?.prop;
        if (prop) {
          if (typeof value === 'number') {
            const newValue = Number.parseFloat((value * factor));
            const cellValue = prop in row ? newValue + row[prop] : newValue;
            row[prop] = fixedDecimal
              ? roundNumber(cellValue, fixedDecimal)
              : cellValue; // note: toFixed returns a string
          }
          else 
            row[prop] = value;
        }
      });

      // set daily total
      if (row.profiles.length > 0) {
        let total = row.profiles.reduce(totals[summary], Number.NaN) * factor;
        if (isNumber(total)) {
          if (summary === 'avg') { total /= row.profiles.length; }
          row.total = formatValue(total, format, fixedDecimal);
        }
      }
    });

    // Extract values for chart and create NET point
    if (data.length) {
      chartData = PeriodColumns({}, false, state.dateRange, state.selectedPeriod).map(({ prop: hour }) => {
        const plotPoint = {};
        data.forEach((cell) => {
          let cellName = cell.label || cell.name;
          if (cellName) {
            // cast value back to number
            const val = Number(cell[hour]);
            let cellValue = Number.isNaN(val) ? null : val;

            // Defaults to null instead of zero
            if ([undefined, null].includes(cell[hour])) cellValue = null;
            if (has(plotPoint, cellName)) {
              // If chart has plot point key it by name plus count
              const count = Object.keys(plotPoint).filter((prop) => prop.includes(cellName)).length;
              plotPoint[`${cellName}/${count}`] = cellValue;
            } else {
              plotPoint[cellName] = cellValue;
            }
          }
        });
        if (position.showNET) {
          const noData = Object.keys(plotPoint).every((key) => [null, undefined].includes(plotPoint[key]));
          if (noData) {
            plotPoint.NET = null;
          } else {
            plotPoint.NET = Object.keys(plotPoint).reduce((acc, key) => acc + Number(plotPoint[key]), 0);
          }
        }
        plotPoint.HE = hour.toUpperCase();
        return plotPoint;
      });
    }

    if (isSummaryRowVisible) {
      state.tableConfiguration.customSummary = (options) => {
      // if(options.name === "groupSummary") {
        // let value = undefined;
        let stringValue = '0';

        switch (options.summaryProcess) {
        case 'start':
          options.totalValue = 0;
          break;

        case 'calculate':
          if (options?.value[options.name] && has(options.value, options?.name)) {
            stringValue = String(options.value[options.name]);
            stringValue = stringValue.replace(',', '').replace('$', '').replace('%', '');
          }

          const value = parseFloat(stringValue);
          if (!options.totalValue && options.value) {
            options.totalValue = {
              value: Number.NaN,
              count: 0,
              column: options.value.type,
              format: options.value.format || 'decimal',
              summary: options.value.summary || 'sum',
              groupSummary: options.value.groupSummary || options.value.summary || 'sum',
              fixedDecimal: options.value.fixedDecimal || 0,
              history: [],
            };
          }

          options.totalValue.history.push(value);

          options.totalValue.value = totals[options.totalValue.groupSummary](options.totalValue.value, { value });
          options.totalValue.count++;
          break;

        case 'finalize':
          if (options.totalValue.groupSummary === 'avg'
          && options.totalValue.count > 0) {
            options.totalValue.value /= options.totalValue.count;
          }

          options.totalValue = formatValue(
            options.totalValue.value,
            options.totalValue.format,
            options.totalValue.fixedDecimal,
          );
          break;
        default:
          options.totalValue = 0; // options.totalValue.value;
          break;
        }
      // }
      };

      state.tableConfiguration.groupSummary = [{
        prop: 'name',
        label: 'NET',
        cssClass: summaryClass,
        showInGroupFooter: true,
      }, {
        prop: 'total',
        name: 'total',
        summaryType: 'custom',
        dataType: 'number',
        cssClass: summaryClass,
        showInGroupFooter: true,
        showInColumn: 'total',
      },
      ...PeriodColumns({
        minWidth: 30,
        sortable: false,
        editable: true,
        alignment: 'right',
      }, false, state.dateRange, state.selectedPeriod).map(({ prop }) => ({
        prop,
        name: prop,
        summaryType: 'custom',
        dataType: 'number',
        cssClass: summaryClass,
        showInGroupFooter: true,
        showInColumn: prop,
      }))];
    } else {
      state.tableConfiguration.groupSummary = [];
    }

    if (isSummaryRowVisible) {
      chartSeriesConfig.push({
        visible: true,
        label: 'NET',
        prop: 'NET',
        factor: 1,
        color: 'rgba(0, 0, 0)',
        chartType: 'spline',
        stackOrder: -1,
      });

      // Finds the series setting with the highest decimal place to set to summary
      state.tableConfiguration.summary?.forEach((sum) => {
        if (sum.format) sum.format.precision = summaryDecimalPlaces;
      });
      // Finds the series setting with the highest decimal place to set to summary
      state.tableConfiguration.groupSummary?.forEach((sum) => {
        if (sum.format) sum.format.precision = summaryDecimalPlaces;
      });
    }

    // Removes series that aren't plotted
    if (chartData.length) {
      const filteredConfig = chartSeriesConfig.filter(({ label }) => Object.keys(chartData[0]).includes(label));

      // only sort if not already sorted
      if (filteredConfig.length && filteredConfig[filteredConfig.length - 1].stackOrder !== -1) {
        filteredConfig.sort(sortBy('stackOrder', 'desc')); // apply sort on stack order
      }
      state.chartSeriesConfig = filteredConfig.sort(sortBy('stackOrder', 'desc'));
    } else {
      state.chartSeriesConfig = [];
    }
    state.chartData = chartData;

    // Set row name to label if it exists
    data.forEach((row) => {
      row.label = row.label || row.name;
    });
    state.tableData = Object.freeze(data);
  },
  reset(state) {
    state.tableData = [];
    state.chartData = [];
    state.chartSeriesConfig = [];
  },
  ...createMutations(
    'breadcrumbs',
    'cachedPosition',
    'periods',
    'selectedPeriod',
    'autoRefreshFlag',
    'dateRange',
    'selectedPosition',
    'termTypes',
    'locations',
    'dailyGlobalVariablesData',
    'variableDialogVisibility',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
