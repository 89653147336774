import meterRefStore from './Meter/meterStore';
import virtualMeterRefStore from './VirtualMeter/virtualMeterStore';
import isoLocationRefStore from './IsoLocation/isoLocationStore';

export default {
  namespaced: true,
  modules: {
    meterRef: meterRefStore,
    virtualMeterRef: virtualMeterRefStore,
    isoLocationRef: isoLocationRefStore,
  },
};