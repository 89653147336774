import { accessRight, meterManagement } from '@/auth/permission';

export default {
  path: '/meter-management',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'METER MANAGEMENT',
    permission: { name: meterManagement.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'reference',
    meta: {
      visible: true,
      rank: 3,
      requiresAuth: true,
      text: 'Reference',
      permission: { name: meterManagement.reference.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'meter-ref',
      name: 'MeterRef',
      meta: {
        showCert: true,
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Meters',
        permission: { name: meterManagement.reference.meter, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/MeterManagement/ReferenceData/Meter/Meter'),
    }, {
      path: 'virtual-meter-ref',
      name: 'VirtualMeterRef',
      meta: {
        showCert: true,
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'Virtual Meters',
        permission: { name: meterManagement.reference.virtualMeter, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/MeterManagement/ReferenceData/VirtualMeter/VirtualMeter'),
    }, {
      path: 'iso-location-ref',
      name: 'IsoLocationRef',
      meta: {
        showCert: true,
        visible: true,
        rank: 3,
        requiresAuth: true,
        text: 'Iso Locations',
        permission: { name: meterManagement.reference.isoLocation, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/MeterManagement/ReferenceData/IsoLocation/IsoLocation'),
    }],
  }, {
    path: 'submit-status',
    name: 'SubmitStatus',
    meta: {
      showCert: true,
      visible: true,
      rank: 1,
      requiresAuth: true,
      text: 'Submission Status',
      permission: { name: meterManagement.submit.status, rights: [accessRight.visible] },
      customFormatEnabled: true,
    },
    component: () => import('@/components/MeterManagement/SubmitStatus/SubmitStatus'),
  }, {
    path: 'data',
    meta: {
      visible: true,
      rank: 2,
      requiresAuth: true,
      text: 'Meter Data',
      permission: { name: meterManagement.reference.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'view',
      name: 'View',
      meta: {
        showCert: true,
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'View',
        permission: { name: meterManagement.submit.submit, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/MeterManagement/ViewData/ViewData'),
    }, {
      path: 'override',
      name: 'Override',
      meta: {
        showCert: true,
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'Override',
        permission: { name: meterManagement.submit.submit, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/MeterManagement/Override/Override'),
    }, {
      path: 'submit',
      name: 'Submit',
      meta: {
        showCert: true,
        visible: true,
        rank: 3,
        requiresAuth: true,
        text: 'Submit',
        permission: { name: meterManagement.submit.submit, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/MeterManagement/Submit/Submit'),
    }],
  }],
};
