import { has, isStringNullOrEmpty } from '@/utils/dataUtil';
import LOOKUP_STORE from '@/store/lookupStore';

import moment from 'moment';

const clearHelper = (data, columns = []) => columns.forEach(({ clear }) => clear(data));

const createLookupColumn = (prop, label, visible = true, displayName = null, canBeEditable = false) => ({
  prop,
  label,
  displayName,
  filterable: true,
  sortable: true,
  visible,
  alignment: 'left',
  canBeEditable: canBeEditable,
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
  },
  setCellValue(data, value, row) {
    const found = LOOKUP_STORE.state.catalogList.find(({ id }) => id === value);
    if (found) {
      data[`${prop}Name`] = found.name;
      data[`${prop}ShortName`] = found.name;
      data[`${prop}FullName`] = found.description;
      data[`${prop}Id`] = value;
      data[prop] = value;
    } else {
      data[`${prop}Name`] = null;
      data[`${prop}ShortName`] = null;
      data[`${prop}FullName`] = null;
      data[`${prop}Id`] = null;
      data[prop] = null;
    }
  },
  calculateDisplayValue: (trade) => {
    const found = LOOKUP_STORE.state.catalogList.find(({ id }) => id === trade[`${prop}Id`]);
    return found ? found.name : null;
  },
});

const createPriceIndexColumn = (prop, label, visible = true, canBeEditable = false) => ({
  prop,
  label,
  filterable: true,
  sortable: true,
  visible,
  alignment: 'left',
  canBeEditable: canBeEditable,
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
    const data = e.row && e.row.data ? e.row.data : null;
    if (data) {
      e.editorOptions.disabled = data.termTypeShortName === null;
    }
  },
  setCellValue(data, value, row) {
    const found = LOOKUP_STORE.state.priceList.find(({ id }) => id === value);
    if (found) {
      data[prop] = value;
      data[`${prop}Name`] = found.name;
      data[`${prop}Id`] = value;
    } else {
      // clearHelper(data, [TERM_PRICE_INDEX, TERM_PRICE_INDEX2]);
    }

    if (prop === 'termPriceIndex') {
      // clear out prices when term price index is changed
      // null out the termPrice field
      data.termPrice = null;
      const termType = LOOKUP_STORE.state.termTypeList.find(({ shortName }) => shortName === 'HOURLY');
      const payload = {
        variant: 'CONTRACT-PRICE',
        termType,
        value: null,
      };
      vue.$store.commit('etrm/trades/setIntervalByTermType', payload);
    }
  },
  clear(data) {
    data[prop] = null;
    data[`${prop}Name`] = null;
    data[`${prop}Id`] = null;
  },
});

const createIsoRefColumn = (prop, label, visible = false) => ({
  prop: `isoReference.${prop}`,
  label,
  calculateDisplayValue: (trade) => trade?.isoReference ? trade?.isoReference[prop] : null,
  filterable: true,
  sortable: true,
  visible,
  alignment: 'left',
  editable: false,
  canBeEditable: false,
});

const PARENT_ID = {
  prop: 'parentId',
  label: 'Parent',
  editable: false,
  filterable: true,
  alignment: 'right',
  visible: false,
};

const TRADE_ID = {
  prop: 'tradeId',
  label: 'ID',
  editable: false,
  sortOrder: 'desc',
  filterable: true,
  sortable: true,
  alignment: 'right',
};

const ATTACHMENTS = {
  prop: 'hasAttachments',
  label: 'Attachments',
  editable: false,
  // sortOrder: 'desc',
  filterable: true,
  sortable: false,
  alignment: 'center',
  dataType: 'boolean',
};

const STATUS = {
  prop: 'status',
  label: 'Status',
  editable: false,
  filterable: true,
  sortable: true,
  validationRules: [{ type: 'required' }],
  alignment: 'left',
};

const NAME = {
  TRADE: {
    prop: 'tradeName',
    label: 'Name',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  TICKET: {
    prop: 'ticketName',
    label: 'Ticket',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  EXTERNAL: {
    prop: 'externalName',
    label: 'External',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
};

const TRADE_TYPE = {
  prop: 'tradeType',
  label: 'Trade Type',
  calculateDisplayValue: 'tradeTypeName',
  filterable: true,
  sortable: true,
  visible: false,
  alignment: 'left',
  canBeEditable: true,
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
  },
  setCellValue(data, value, row) {
    const found = LOOKUP_STORE.state.tradeTypeList.find(({ id }) => id === value);
    if (found) {
      data.tradeTypeName = found.name;
      data.tradeTypeId = value;
    } else {
      clearHelper(data, [TRADE_TYPE]);
    }
  },
  clear(data) {
    data.tradeTypeName = null;
    data.tradeTypeId = null;
  },
};

const TIME_ZONE = {
  prop: 'timeZone',
  label: 'TZ',
  filterable: true,
  sortable: true,
  calculateDisplayValue: 'timeZone',
  validationRules: [{ type: 'required' }],
  alignment: 'left',
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
  },
  setCellValue(data, value, row) {
    vue.$store.dispatch('etrm/trades/updateCurrentTrade', { timeZone: value });
    vue.$store.dispatch('etrm/trades/fetchIntervals');
  },
};

const COUNTERPARTY = {
  prop: 'counterparty',
  label: 'Counterparty',
  filterable: true,
  sortable: true,
  allowClearing: false,
  calculateDisplayValue: 'counterpartyShortName',
  validationRules: [{ type: 'required' }],
  alignment: 'left',
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
    e.editorOptions.disabled = true;

    const data = e.row?.data;
    if (data && (data.tradeId === -1 || data.status === 'NEW' || data.status === 'ERRORED' || data.status === 'UNCONFIRMED')) {
        e.editorOptions.disabled = false;
    }
  },
  setCellValue(data, value, row) {
    const found = LOOKUP_STORE.state.companyList.find(({ id }) => id === value);
    if (found) {
      data.counterpartyShortName = found.shortName;
      data.counterpartyFullName = found.fullName;
      data.counterpartyId = value;
      data.counterparty = value;
      const contact = LOOKUP_STORE.state.contactList
        .find(({ defaultTradeFlag, company }) => defaultTradeFlag && company === found.shortName);
      if (contact) {
        data.counterpartyContactId = contact.id;
        data.counterpartyContactFirstName = contact.firstName;
        data.counterpartyContactLastName = contact.lastName;
      } else {
        clearHelper(data, [COUNTERPARTY.CONTACT]);
      }
    } else {
      clearHelper(data, [COUNTERPARTY, COUNTERPARTY.CONTACT]);
    }
  },
  clear(data) {
    data.counterpartyShortName = null;
    data.counterpartyFullName = null;
    data.counterpartyId = null;
    data.counterparty = null;
  },
  COUNTERPARTY2: {
    prop: 'counterparty2',
    label: 'Counterparty2',
    filterable: true,
    sortable: true,
    visible: false,
    allowClearing: false,
    calculateDisplayValue: 'counterparty2ShortName',
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.companyList.find(({ id }) => id === value);
      if (found) {
        data.counterparty2ShortName = found.shortName;
        data.counterparty2FullName = found.fullName;
        data.counterparty2Id = value;
        data.counterparty2 = value;
      }
    },
    clear(data) {
      data.counterparty2ShortName = null;
      data.counterparty2FullName = null;
      data.counterparty2Id = null;
      data.counterparty2 = null;
    },
  },
  CONTACT: {
    prop: 'counterpartyContactId',
    label: 'C/P Contact',
    calculateDisplayValue: 'counterpartyContactFullName',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.contactList.find(({ id }) => id === value);
      if (found) {
        data.counterpartyContactId = found.id;
        data.counterpartyContactFirstName = found.firstName;
        data.counterpartyContactLastName = found.lastName;
      } else {
        clearHelper(data, [COUNTERPARTY.CONTACT]);
      }
    },
    clear(data) {
      data.counterpartyContactFirstName = null;
      data.counterpartyContactLastName = null;
      data.counterpartyContactId = null;
    },
  },
  CREDIT: {
    prop: 'counterpartyCreditId',
    label: 'C/P Credit',
    filterable: true,
    sortable: true,
    calculateDisplayValue: 'counterpartyCreditFullName',
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.companyList.find(({ id }) => id === value);
      if (found) {
        data.counterpartyCreditShortName = found.shortName;
        data.counterpartyCreditFullName = found.fullName;
        data.counterpartyCreditId = value;
        data.counterpartyCredit = value;
        const contact = LOOKUP_STORE.state.contactList
          .find(({ defaultTradeFlag, company }) => defaultTradeFlag && company === found.shortName);
        if (contact) {
          data.counterpartyCreditContactId = contact.id;
          data.counterpartyCreditContactFirstName = contact.firstName;
          data.counterpartyCreditContactLastName = contact.lastName;
        } else {
          clearHelper(data, [COUNTERPARTY.CREDIT.CONTACT]);
        }
      } else {
        clearHelper(data, [COUNTERPARTY.CREDIT, COUNTERPARTY.CREDIT.CONTACT]);
      }
    },
    clear(data) {
      data.counterpartyCreditContactId = data.id;
      data.counterpartyCreditContactFirstName = data.firstName;
      data.counterpartyCreditContactLastName = data.lastName;
    },
    CONTACT: {
      prop: 'counterpartyCreditContactId',
      label: 'C/P Credit Contact',
      calculateDisplayValue: 'counterpartyCreditContactFirstName',
      filterable: true,
      sortable: true,
      visible: false,
      alignment: 'left',
      canBeEditable: true,
      setCellValue(data, value, row) {
        const found = LOOKUP_STORE.state.contactList.find(({ id }) => id === value);
        if (found) {
          data.counterpartyCreditContactId = found.id;
          data.counterpartyCreditContactFirstName = found.firstName;
          data.counterpartyCreditContactLastName = found.lastName;
        } else {
          clearHelper(data, [COUNTERPARTY.CREDIT.CONTACT]);
        }
      },
      clear(data) {
        data.counterpartCreditContactFirstName = null;
        data.counterpartCreditContactLastName = null;
        data.counterpartCreditContactId = null;
      },
    },
  },
};

const LOCATION = {
  REGION: {
    prop: 'regionId',
    label: 'Region',
    calculateDisplayValue: 'regionShortName',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.marketList.find(({ id }) => id === value);
      if (found) {
        data.regionShortName = found.shortName;
        data.regionFullName = found.fullName;
        data.regionId = value;
      } else {
        data.regionShortName = null;
        data.regionFullName = null;
        data.regionId = null;
      }
    },
    clear(data) {
      data.regionShortName = null;
      data.regionFullName = null;
      data.regionId = null;
    },
  },
  POD: {
    prop: 'pointOfDeliveryId',
    label: 'POD',
    calculateDisplayValue: 'pointOfDeliveryShortName',
    filterable: true,
    sortable: true,
    alignment: 'left',
    canBeEditable: true,
    validationRules: [{
      type: 'custom',
      message: 'Either POR or POD is required',
      validationCallback: ({ data }) => data.pointOfDeliveryId !== null || data.pointOfReceiptId !== null,
    }],
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.locationList.find(({ id }) => id === value);
      if (found) {
        data.pointOfDeliveryShortName = found.shortName;
        data.pointOfDeliveryFullName = found.fullName;
        data.pointOfDeliveryId = value;
      } else {
        data.pointOfDeliveryShortName = null;
        data.pointOfDeliveryFullName = null;
        data.pointOfDeliveryId = null;
      }
    },
    clear(data) {
      data.pointOfDeliveryShortName = null;
      data.pointOfDeliveryFullName = null;
      data.pointOfDeliveryId = null;
    },
  },
  POR: {
    prop: 'pointOfReceiptId',
    label: 'POR',
    calculateDisplayValue: 'pointOfReceiptShortName',
    filterable: true,
    sortable: true,
    alignment: 'left',
    canBeEditable: true,
    validationRules: [{
      type: 'custom',
      message: 'Either POR or POD is required',
      validationCallback: ({ data }) => data.pointOfDeliveryId !== null || data.pointOfReceiptId !== null,
    }],
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.locationList.find(({ id }) => id === value);
      if (found) {
        data.pointOfReceiptShortName = found.shortName;
        data.pointOfReceiptFullName = found.fullName;
        data.pointOfReceiptId = value;
      } else {
        data.pointOfReceiptShortName = null;
        data.pointOfReceiptFullName = null;
        data.pointOfReceiptId = null;
      }
    },
    clear(data) {
      data.pointOfReceiptShortName = null;
      data.pointOfReceiptFullName = null;
      data.pointOfReceiptId = null;
    },
  },
  MARKET: {
    prop: 'market',
    label: 'Market',
    displayName: 'marketShortName',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.marketList.find(({ id }) => id === value);
      if (found) {
        data.marketShortName = found.shortName;
        data.marketFullName = found.fullName;
        data.marketId = value;
        data.market = value;
      } else {
        data.marketShortName = null;
        data.marketFullName = null;
        data.marketId = null;
        data.market = null;

        // ISO Ref cannot be selected without a market
        data.isoReferenceName = null;
      }
    },
    clear(data) {
      data.marketShortName = null;
      data.marketFullName = null;
      data.marketId = null;
      data.market = null;
    },
  },

  PIPELINE: {
    prop: 'pipeline',
    label: 'Pipeline',
    calculateDisplayValue: 'pipelineShortName',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.locationList.find(({ id }) => id === value);
      if (found) {
        data.pipelineShortName = found.shortName;
        data.pipelineFullName = found.fullName;
        data.pipelineId = value;
        data.pipeline = value;
      } else {
        data.pipelineShortName = null;
        data.pipelineFullName = null;
        data.pipelineId = null;
        data.pipeline = null;
      }
    },
    clear(data) {
      data.pipelineShortName = null;
      data.pipelineFullName = null;
      data.pipelineId = null;
      data.pipeline = null;
    },
  },

  METER: {
    prop: 'meter',
    label: 'Meter',
    calculateDisplayValue: 'meterShortName',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.locationList.find(({ id }) => id === value);
      if (found) {
        data.meterShortName = found.shortName;
        data.meterFullName = found.fullName;
        data.meterId = value;
        data.meter = value;
      } else {
        data.meterShortName = null;
        data.meterFullName = null;
        data.meterId = null;
        data.meter = null;
      }
    },
    clear(data) {
      data.meterShortName = null;
      data.meterFullName = null;
      data.meterId = null;
      data.meter = null;
    },
  },

  RECMETER: {
    prop: 'recMeter',
    label: 'Rec Meter',
    // calculateDisplayValue: 'shortName',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.locationList
        .find(({ locationType, shortName }) => locationType === 'METER' && shortName === value);

      if (found) {
        data.recMeter = found.shortName;
      } else {
        data.recMeter = null;
      }
    },
    clear(data) {
      data.recMeter = null;
    },
  },
};

const ISO_REFERENCE = {
  prop: 'isoReferenceName',
  label: 'ISO Ref',
  filterable: true,
  sortable: true,
  visible: false,
  alignment: 'left',
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
  },

  MARKET: createIsoRefColumn('market', 'ISO Market'),
  SELF_SCHED_OR_IST: createIsoRefColumn('selfSchedOrIst', 'ISO Schedule Type'),
  PRODUCT: createIsoRefColumn('product', 'ISO Product'),
  RESOURCE: createIsoRefColumn('resource', 'ISO Resource'),
  RESOURCE_TYPE: createIsoRefColumn('resourceType', 'ISO Resource Type'),
  FORECAST_SOURCE: createIsoRefColumn('forecastSource', 'Forecast Source'),
  SC: createIsoRefColumn('sc', 'ISO SC'),
  SELF_SCHD_TYPE: createIsoRefColumn('selfSchdType', 'ISO Self-Schedule Type'),
  CONFIGURATION: createIsoRefColumn('configuration', 'ISO Configuration'),
  WHEEL_REF_NAME: createIsoRefColumn('wheelRefName', 'ISO Wheel Ref Name'),
  SUPPORTING_RESOURCE: createIsoRefColumn('supportingResource', 'ISO Supporting Resource'),
  TRADE_TYPE: createIsoRefColumn('tradeType', 'ISO Trade Type'),
  SUBMITTING_SC: createIsoRefColumn('submittingSC', 'ISO Submitting SC'),
  TRADE_PRODUCT: createIsoRefColumn('tradeProduct', 'ISO Trade Product'),
  FROM_SC: createIsoRefColumn('fromSC', 'ISO From SC'),
  TO_SC: createIsoRefColumn('toSC', 'ISO To  SC'),
  DEPEND_ON_NAME: createIsoRefColumn('dependOnName', 'ISO Depend On Name'),
  TRADE_NAME: createIsoRefColumn('tradeName', 'ISO Trade Name'),
};

const COMPANY = {
  prop: 'company',
  label: 'Company',
  calculateDisplayValue: 'companyShortName',
  filterable: true,
  sortable: true,
  allowClearing: false,
  validationRules: [{ type: 'required' }],
  alignment: 'left',
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
    e.editorOptions.disabled = true;

    const data = e.row?.data;
    if (data && (data.tradeId === -1 || data.status === 'NEW' || data.status === 'ERRORED' || data.status === 'UNCONFIRMED')) {
        e.editorOptions.disabled = false;
    }
  },
  setCellValue(data, value, row) {
    const cp = LOOKUP_STORE.state.companyList.find(({ id }) => id === value);
    if (cp) {
      data.companyShortName = cp.shortName;
      data.companyFullName = cp.fullName;
      data.companyId = value;
      data.company = value;
      const contact = LOOKUP_STORE.state.contactList
        .find(({ defaultTradeFlag, company }) => defaultTradeFlag && company === cp.shortName);
      if (contact) {
        data.companyContactId = contact.id;
        data.companyContactFirstName = contact.firstName;
        data.companyContactLastName = contact.lastName;
      } else {
        clearHelper(data, [COMPANY.CONTACT]);
      }
    } else {
      clearHelper(data, [COMPANY.CONTACT, COMPANY]);
    }
    clearHelper(data, [LOCATION.POD, LOCATION.POR]);
  },
  clear(data) {
    data.companyShortName = null;
    data.companyFullName = null;
    data.companyId = null;
    data.company = null;
  },
  CONTACT: {
    prop: 'companyContactId',
    label: 'Contact',
    calculateDisplayValue: 'companyContactFirstName',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.contactList.find(({ id }) => id === value);
      if (found) {
        data.companyContactId = found.id;
        data.companyContactFirstName = found.firstName;
        data.companyContactLastName = found.lastName;
      } else {
        clearHelper(data, [COMPANY.CONTACT]);
      }
    },
    clear(data) {
      data.companyContactFirstName = null;
      data.companyContactLastName = null;
      data.companyContactId = null;
    },
  },
};

const COMMODITY = {
  prop: 'commodity',
  label: 'Commodity',
  filterable: true,
  sortable: true,
  validationRules: [{ type: 'required' }],
  calculateDisplayValue: 'commodityName',
  alignment: 'left',

  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
    e.editorOptions.disabled = true;

    const data = e.row?.data;
    if (data && (data.tradeId === -1 || data.status === 'NEW' || data.status === 'ERRORED' || data.status === 'UNCONFIRMED')) {
        e.editorOptions.disabled = false;
    }
  },
  setCellValue(data, value, row) {
    const commodity = LOOKUP_STORE.state.energyCommodityList.find(({ id }) => id === value);
    if (commodity) {
      data.commodityName = commodity.shortName;
      data.commodityId = value;
      data.commodity = value;

      if (row.companyId) {
        const cp = LOOKUP_STORE.state.companyList.find(({ id }) => id === row.companyId);
        if (!(cp.commodities && cp.commodities.includes(data.commodityName))) {
          clearHelper(data, [COMPANY, COMPANY.CONTACT]);
        }
      }

      if (row.counterpartyId) {
        const cp = LOOKUP_STORE.state.companyList.find(({ id }) => id === row.counterpartyId);
        if (!(cp.commodities && cp.commodities.includes(data.commodityName))) {
          clearHelper(data, [COUNTERPARTY, COUNTERPARTY.CONTACT]);
        }
      }
      const params = {
        commodity: commodity.shortName,
        termTypeName: row?.termTypeName,
        granularity: row?.termInterval ?? 60,
        period: row?.period,
        effectiveStartDate: row?.effectiveStartDate,
        effectiveEndDate: row?.effectiveEndDate,
        timeZone: row?.timeZone ?? dateStore.getTimeZone(),
    };
      vue.$store.commit('etrm/trades/checkCommodityAndTermType', params);
    } else {
      clearHelper(data, [COMMODITY, COMPANY, COMPANY.CONTACT, COUNTERPARTY, COUNTERPARTY.CONTACT]);
    }

    clearHelper(data, [LOCATION.POD, LOCATION.POR]);
  },
  clear(data) {
    data.commodityName = null;
    data.commodityId = null;
    data.commodity = null;
  },
};

const DATE = {
  TRADE: {
    prop: 'tradeDate',
    label: 'Trade Date',
    dataType: 'date',
    filterable: true,
    visible: false,
    sortable: true,
    validationRules: [{ type: 'required' }],
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
  },
  EFF_START: {
    prop: 'effectiveStartDate',
    label: 'Start Date',
    dataType: 'date',
    filterable: true,
    sortable: true,
    validationRules: [{ type: 'required' }],
    alignment: 'left',
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    setCellValue(data, value, row) {
      data.effectiveStartDate = value;
      vue.$store.dispatch('etrm/trades/changeDailyDate', value);
      vue.$store.dispatch('etrm/trades/updateCurrentTrade', { effectiveStartDate: data.effectiveStartDate });
      if (value && has(row, 'effectiveEndDate')) {
        const start = typeof value === 'object' ? value.toISOString() : value;
        const end = typeof row.effectiveEndDate === 'object'
          ? row.effectiveEndDate.toISOString()
          : row.effectiveEndDate;
        if (start > end) {
          data.effectiveEndDate = value;
          vue.$store.dispatch('etrm/trades/updateCurrentTrade', { effectiveEndDate: data.effectiveEndDate });
        }
        vue.$store.commit('etrm/trades/setConsolidatedDateRange', [start, end]);
        vue.$store.commit('etrm/trades/setRangeDateRange', [start, end]);
      }
    },
  },
  EFF_END: {
    prop: 'effectiveEndDate',
    label: 'End Date',
    dataType: 'date',
    filterable: true,
    sortable: true,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    validationRules: [{
      type: 'required',
    }, {
      type: 'custom',
      message: 'EFFECTIVE END DATE cannot be less than EFFECTIVE START DATE',
      validationCallback: ({ data }) => moment(data.effectiveEndDate).isSameOrAfter(data.effectiveStartDate),
    }],
    setCellValue(data, value, row) {
      data.effectiveEndDate = value;
      vue.$store.dispatch('etrm/trades/updateCurrentTrade', { effectiveEndDate: data.effectiveEndDate });
      if (has(row, 'effectiveStartDate') && value) {
        const start = typeof row.effectiveStartDate === 'object'
          ? row.effectiveStartDate.toISOString()
          : row.effectiveStartDate;
        const end = typeof value === 'object' ? value.toISOString() : value;
        if (start > end) {
          data.effectiveStartDate = value;
          vue.$store.dispatch('etrm/trades/updateCurrentTrade', { effectiveStartDate: data.effectiveStartDate });
        }
        vue.$store.commit('etrm/trades/setConsolidatedDateRange', [start, end]);
        vue.$store.commit('etrm/trades/setRangeDateRange', [start, end]);
      }
    },
  },
  MATURITY: {
    prop: 'maturityDate',
    label: 'Maturity Date',
    dataType: 'date',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    validationRules: [{
      type: 'custom',
      message: 'MATURITY DATE cannot be less than EFFECTIVE START DATE',
      validationCallback: ({ data }) => !data.maturityDate
        || moment(data.maturityDate).isSameOrAfter(data.effectiveStartDate),
    }],
  },
  EXPIRATION: {
    prop: 'expirationDate',
    label: 'Expiration Date',
    dataType: 'date',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    validationRules: [{
      type: 'custom',
      message: 'EXPIRATION DATE cannot be less than EFFECTIVE START DATE',
      validationCallback: ({ data }) => !data.expirationDate
        || moment(data.expirationDate).isSameOrAfter(data.effectiveStartDate),
    }],
  },
  EXTERNAL: {
    prop: 'externalDate',
    label: 'External Date',
    dataType: 'date',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
  },
  RETIREMENT: {
    prop: 'retirementDate',
    label: 'Retirement Date',
    dataType: 'date',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
  },
};

const DIRECTION = {
  prop: 'direction',
  label: 'B/S',
  filterable: true,
  sortable: true,
  validationRules: [{ type: 'required' }],
  alignment: 'left',
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
  },
};

const OPTION = {
  IS_OPTION: {
    prop: 'isOption',
    label: 'Is Option',
    filterable: true,
    sortable: true,
    visible: false,
    dataType: 'boolean',
    alignment: 'center',
    canBeEditable: true,
  },

  TYPE: {
    prop: 'optionType',
    label: 'Option Type',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    validationRules: [{
      type: 'custom',
      message: 'OPTION TYPE is required',
      validationCallback: ({ data }) => data.isOption ? data.optionType != null : true,
    }],
  },

  STRIKE_PRICE: {
    prop: 'strikePrice',
    label: 'Strike Price',
    filterable: false,
    dataType: 'number',
    sortable: true,
    format: { type: 'currency', precision: 4 },
    visible: false,
    alignment: 'right',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    validationRules: [{
      type: 'custom',
      message: 'STRIKE PRICE is required',
      validationCallback: ({ data }) => data.isOption ? data.strikePrice != null : true,
    }],
  },

  OPTION_PREMIUM: {
    prop: 'optionPremium',
    label: 'Option Premium',
    filterable: false,
    dataType: 'number',
    sortable: true,
    format: { type: 'currency', precision: 4 },
    visible: false,
    alignment: 'right',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    validationRules: [{
      type: 'custom',
      message: 'OPTION PREMIUM is required',
      validationCallback: ({ data }) => data.isOption ? data.optionPremium != null : true,
    }],
  },

  EXPIRATION_DATE: {
    prop: 'optionExpirationDate',
    label: 'Option Expiration Date',
    dataType: 'date',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    validationRules: [{
      type: 'custom',
      message: 'EXPIRATION DATE cannot be less than EFFECTIVE START DATE',
      validationCallback: ({ data }) => data.isOption
        ? !data.optionExpirationDate || moment(data.optionExpirationDate).isSameOrAfter(data.effectiveStartDate)
        : true,
    }],
  },

  PRICING_MODEL: {
    prop: 'optionPricingModel',
    label: 'Option Pricing Model',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
  },

  STYLE: {
    prop: 'optionStyle',
    label: 'Option Style',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
  },

  EXERCISE_FREQUENCY: {
    prop: 'exerciseFrequency',
    label: 'Exercise Frequency',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
  },
};

const TERM = {
  TYPE: {
    prop: 'termTypeId',
    label: 'Term',
    filterable: true,
    sortable: true,
    validationRules: [{ type: 'required' }],
    calculateDisplayValue: 'termTypeName',
    alignment: 'left',
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
      e.editorOptions.disabled = true;
  
      const data = e.row?.data;
      if (data && (data.tradeId === -1 || data.status === 'NEW' || data.status === 'ERRORED' || data.status === 'UNCONFIRMED')) {
          e.editorOptions.disabled = false;
      }
    },
      setCellValue(data, value, row) {
      const previousTermType = row.termTypeId
        ? LOOKUP_STORE.state.termTypeList.find(({ id }) => id === row.termTypeId)
        : null;

      // clear out any previous term type values
      if (previousTermType) {
        const payload = { termType: previousTermType };

        payload.variant = 'CONTRACT-VOLUME';
        payload.value = null;
        vue.$store.commit('etrm/trades/setIntervalByTermType', payload);

        payload.variant = 'CONTRACT-PRICE';
        payload.value = null;
        vue.$store.commit('etrm/trades/setIntervalByTermType', payload);
      }

      const termType = LOOKUP_STORE.state.termTypeList.find(({ id }) => id === value);
      if (termType) {
        data.termTypeName = termType.shortName;
        data.termTypeId = value;
        let currentTermQuantity = row.termQuantity;
        let currentTermPrice = row.termPrice;

        if (termType.shortName === 'HOURLY') {
        // if hourly, set quantity/price to null
          currentTermQuantity = null;
          currentTermPrice = null;
        }

        const payload = { termType };

        const params = {
          commodity: row?.commodityName,
          termTypeName: termType.shortName,
          granularity: row?.termInterval ?? 60,
          period: row?.period,
          effectiveStartDate: row?.effectiveStartDate,
          effectiveEndDate: row?.effectiveEndDate,
          timeZone: row?.timeZone ?? dateStore.getTimeZone(),
        };
        vue.$store.commit('etrm/trades/checkCommodityAndTermType', params);

        payload.variant = 'CONTRACT-VOLUME';
        payload.value = currentTermQuantity;
        vue.$store.commit('etrm/trades/setIntervalByTermType', payload);

        payload.variant = 'CONTRACT-PRICE';
        payload.value = currentTermPrice;
        vue.$store.commit('etrm/trades/setIntervalByTermType', payload);

        data.termQuantity = currentTermQuantity;
        data.termPrice = currentTermPrice;
      } else {
        // clearHelper(data, [TYPE]);
        data.termTypeName = null;
        data.termTypeId = null;
      }
    },
    clear(data) {
      data.termTypeName = null;
      data.termTypeId = null;
    },
  },

  INTERVAL: {
    prop: 'termInterval',
    label: 'Int',
    dataType: 'number',
    filterable: true,
    sortable: true,
    validationRules: [{ type: 'required' }],
    alignment: 'right',
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    setCellValue(data, value, row) {
      data.termInterval = value;
      if (value) {
        vue.$store.commit('etrm/trades/setGranularity', value);
        const termType = LOOKUP_STORE.state.termTypeList.find(({ id }) => id === row.termTypeId);
        const payload = { termType };
        vue.$nextTick(() => {
          if (has(row, 'termQuantity')) {
            payload.variant = 'CONTRACT-VOLUME';
            payload.value = row.termQuantity;
            vue.$store.commit('etrm/trades/setIntervalByTermType', payload);
          }
          if (has(row, 'termPrice')) {
            payload.variant = 'CONTRACT-PRICE';
            payload.value = row.termPrice;
            vue.$store.commit('etrm/trades/setIntervalByTermType', payload);
          }
        });
      }
    },
  },

  PERIOD: {
    prop: 'period',
    label: 'Period',
    filterable: true,
    sortable: true,
    validationRules: [{
      type: 'custom',
      message: 'Period is required when TermType is PERIOD or CALC',
      validationCallback: ({ data }) => (data.termTypeName === 'PERIOD' || data.termTypeName === 'CALC') ? data?.period != null : true,
    }],
    alignment: 'left',
    editorPreparing: (e) => {
      const data = e.row?.data;
      e.editorOptions.disabled = true;

      if (data && (data.tradeId === -1 || data.status === 'NEW' || data.status === 'ERRORED')) {
        let termType = null;
        if (!data.termTypeName) { termType = LOOKUP_STORE.state.termTypeList.find(({ id }) => id === data.termTypeId); }

        const termTypeName = data.termTypeName ?? termType?.shortName;

        if (termTypeName === 'DAILY' || termTypeName === 'CALC' || termTypeName === 'PERIOD') {
          e.editorOptions.disabled = false;
        } else {
          e.editorOptions.disabled = true;
        }
      }
    },
  },

  QUANTITY: {
    prop: 'termQuantity',
    label: 'Qty',
    filterable: true,
    dataType: 'number',
    sortable: true,
    alignment: 'right',
    canBeEditable: true,
    editorPreparing: (e) => {
      const data = e.row?.data;
      e.editorOptions.disabled = true;

      if (data) {
        let termType = null;
        if (!data.termTypeName) { termType = LOOKUP_STORE.state.termTypeList.find(({ id }) => id === data.termTypeId); }

        const termTypeName = data.termTypeName ?? termType?.shortName;

        if (termTypeName === 'HOURLY') {
          e.editorOptions.disabled = true;
        } else {
          e.editorOptions.disabled = false;
        }
      }
    },
    setCellValue(data, value, row) {
      // quantity can't be negative, set to 0 if so
      const augmentedValue = value > 0 ? value : 0;
      data.termQuantity = augmentedValue;

      const termType = LOOKUP_STORE.state.termTypeList.find(({ id }) => id === row.termTypeId);
      const payload = { variant: 'CONTRACT-VOLUME', termType, value: augmentedValue };
      vue.$store.commit('etrm/trades/setIntervalByTermType', payload);
    },
  },

  PRICE: {
    prop: 'termPrice',
    label: 'Price',
    filterable: true,
    dataType: 'number',
    sortable: true,
    format: { type: 'currency', precision: 4 },
    alignment: 'right',
    canBeEditable: true,
    editorPreparing: (e) => {
      const data = e.row?.data;
      e.editorOptions.disabled = true;

      let termType = null;
      if (!data.termTypeName) { termType = LOOKUP_STORE.state.termTypeList.find(({ id }) => id === data.termTypeId); }

      const termTypeName = data.termTypeName ?? termType?.shortName;

      if (data) {
        if (termTypeName === 'HOURLY') {
          e.editorOptions.disabled = true;
        } else if (data.termPriceIndex && data.termPriceIndex !== null) {
          e.editorOptions.disabled = true;
        } else {
          e.editorOptions.disabled = false;
        }
      }
    },
    setCellValue(data, value, row) {
      data.termPrice = value;

      const termType = LOOKUP_STORE.state.termTypeList.find(({ id }) => id === row.termTypeId);
      const payload = { variant: 'CONTRACT-PRICE', termType, value };
      vue.$store.commit('etrm/trades/setIntervalByTermType', payload);

      data.termPriceIndexName = null;
      data.termPriceIndexId = null;
    },
  },

  QUANTITY2: {
    prop: 'termQuantity2',
    label: 'Qty 2',
    filterable: false,
    dataType: 'number',
    sortable: true,
    visible: false,
    alignment: 'right',
    canBeEditable: true,
  },
  PRICE2: {
    prop: 'termPrice2',
    label: 'Price 2',
    filterable: false,
    dataType: 'number',
    sortable: true,
    format: { type: 'currency', precision: 4 },
    visible: false,
    alignment: 'right',
    canBeEditable: true,
  },

  PRODUCT: createLookupColumn('termProduct', 'Term Product', false, 'termProductName', true),
  UNIT: createLookupColumn('unit', 'Unit', false, 'unitName', true),
  DELIVERABLE_TYPE: {
    prop: 'termDeliverableType',
    label: 'Term Deliverable Type',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  TIMELINE: {
    prop: 'termTimeline',
    label: 'Term Timeline',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },

  MULTIPLIER: {
    prop: 'multiplier',
    label: 'Multiplier',
    filterable: false,
    dataType: 'number',
    sortable: true,
    format: { type: 'currency', precision: 4 },
    visible: false,
    alignment: 'right',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
  },
  ADDER: {
    prop: 'adder',
    label: 'Adder',
    filterable: false,
    dataType: 'number',
    sortable: true,
    format: { type: 'currency', precision: 4 },
    visible: false,
    alignment: 'right',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
  },
};

const INDEX = {
  TERM_PRICE: createPriceIndexColumn('termPriceIndex', 'Price Index', true, true),
  TERM_PRICE2: createPriceIndexColumn('termPriceIndex2', 'Price Index 2', false, true),
  MARKET_PRICE: createPriceIndexColumn('marketPriceIndex', 'Mkt Price Index', false, true),
  INTRA_MONTH_MARKET_PRICE: createPriceIndexColumn(
    'intraMonthMarketPriceIndex', 'I/M Market Price Index', false, true
  ),
};

const ACCOUNT = {
  ENVIRONMENTAL_SUB_ACCOUNT: {
    prop: 'environmentalSubAccount',
    label: 'Env. SubAccount',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
    editorPreparing: (e) => {
      e.editorOptions.showClearButton = false;
    },
    setCellValue(data, value, row) {
      const found = LOOKUP_STORE.state.environmentalSubAccountList.find(({ id }) => id === value);
      if (found) {
        data.environmentalSubAccountShortName = found.shortName;
        data.environmentalSubAccountId = value;
        data.environmentalSubAccount = value;
      }
    },
    clear(data) {
      data.environmentalSubAccountShortName = null;
      data.environmentalSubAccountId = null;
      data.environmentalSubAccount = null;
    },
  },
  FROM: {
    prop: 'fromAccount',
    label: 'From Account',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  TO: {
    prop: 'toAccount',
    label: 'To Account',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
};

const PORTFOLIO = createLookupColumn('portfolio', 'Portfolio', false, null, true);
const BOOK = createLookupColumn('book', 'Book', false, null, true);
const STRATEGY = createLookupColumn('strategy', 'Strategy', false, null, true);
const FIRM_TYPE = createLookupColumn('firmType', 'Firm Type', false, 'firmTypeName', true);
const ENABLING_AGREEMENT = createLookupColumn('enablingAgreement', 'Enabling Agreement', false, null, true);
const PAYMENT_TERM = createLookupColumn('paymentTerm', 'Payment Term', false, 'paymentTerm', true);
const FUEL_TYPE = createLookupColumn('fuelType', 'Fuel Type', false, 'fuelType', true);

const CONTRACT = {
  MARKET: {
    prop: 'marketContract',
    label: 'Market Contract',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  SUPPLY: {
    prop: 'supplyContract',
    label: 'Supply Contract',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
};

const BROKER = {
  prop: 'broker',
  label: 'Broker',
  calculateDisplayValue: 'brokerName',
  filterable: true,
  sortable: true,
  visible: false,
  alignment: 'left',
  canBeEditable: true,
  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
  },
  setCellValue(data, value, row) {
    const found = LOOKUP_STORE.state.brokerList.find(({ id }) => id === value);
    if (found) {
      data.brokerName = found.name;
      data.brokerId = value;
      data.broker = value;
    } else {
      clearHelper(data, [BROKER]);
    }
  },
  clear(data) {
    data.brokerName = null;
    data.brokerId = null;
    data.broker = null;
  },

  FEE: {
    AMOUNT: {
      prop: 'feeAmount',
      label: 'Fee',
      filterable: false,
      dataType: 'number',
      sortable: true,
      format: { type: 'currency', precision: 4 },
      visible: false,
      alignment: 'right',
      canBeEditable: true,
      editorPreparing: (e) => {
        e.editorOptions.showClearButton = false;
      },
    },
    PERCENTAGE: {
      prop: 'feePercentage',
      label: 'Fee %',
      filterable: false,
      dataType: 'number',
      sortable: true,
      format: { type: 'fixedPoint', precision: 2 },
      visible: false,
      alignment: 'right',
      canBeEditable: true,
      editorPreparing: (e) => {
        e.editorOptions.showClearButton = false;
      },
    },
  },

  COMMISSION: {
    AMOUNT: {
      prop: 'commissionAmount',
      label: 'Commission',
      filterable: false,
      dataType: 'number',
      sortable: true,
      format: { type: 'currency', precision: 4 },
      visible: false,
      alignment: 'right',
      canBeEditable: true,
      editorPreparing: (e) => {
        e.editorOptions.showClearButton = false;
      },
    },
    PERCENTAGE: {
      prop: 'commissionPercentage',
      label: 'Commission %',
      filterable: false,
      dataType: 'number',
      sortable: true,
      format: { type: 'fixedPoint', precision: 2 },
      visible: false,
      alignment: 'right',
      canBeEditable: true,
      editorPreparing: (e) => {
        e.editorOptions.showClearButton = false;
      },
    },
  },
};

const TRADER = {
  prop: 'trader',
  label: 'Trader',
  calculateDisplayValue: 'trader',
  editable: true,
  filterable: true,
  sortable: true,
  alignment: 'left',
  validationRules: [{ type: 'required' }],

  editorPreparing: (e) => {
    e.editorOptions.showClearButton = false;
  },
};

const TEXTS = {
  // TODO: this one is tricky and needs to be a JSON formatted text
  // EXTRAS: {
  //   prop: 'extras',
  //   label: 'Extras',
  //   filterable: true,
  //   sortable: true,
  //   visible: false,
  //   alignment: 'left',
  // },
  CUSTOM1: {
    prop: 'custom01',
    label: 'Custom01',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  CUSTOM2: {
    prop: 'custom02',
    label: 'Custom02',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  CUSTOM3: {
    prop: 'custom03',
    label: 'Custom03',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  CUSTOM4: {
    prop: 'custom04',
    label: 'Custom04',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
  NOTES: {
    prop: 'notes',
    label: 'Notes',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'left',
    canBeEditable: true,
  },
};

const CREATED_BY = {
  prop: 'createdBy',
  label: 'Created By',
  editable: false,
  filterable: true,
  sortable: true,
  alignment: 'left',
};

const CREATED_DATE = {
  prop: 'createdDate',
  label: 'Created Date',
  dataType: 'datetime',
  editable: false,
  filterable: true,
  sortable: true,
  alignment: 'left',
};

const UPDATED_BY = {
  prop: 'updatedBy',
  label: 'Updated By',
  editable: false,
  filterable: true,
  sortable: true,
  visible: false,
  alignment: 'left',
};

const UPDATED_DATE = {
  prop: 'updatedDate',
  label: 'Updated Date',
  dataType: 'datetime',
  editable: false,
  filterable: true,
  sortable: true,
  visible: false,
  alignment: 'left',
};

const RELATIONSHIPS = {
  prop: 'relationships',
  label: 'Relationships',
  cellTemplate: 'PscsRelationshipCell',
  editorPreparing: (e) => {
    const data = e.row?.data;

    if (data && data.tradeId !== -1 && data.status !== 'NEW') { e.editorOptions.disabled = true; } else { e.editorOptions.disabled = false; }
  },
};

export default () => [
  STATUS,
  PARENT_ID,
  TRADE_ID,
  ATTACHMENTS,
  NAME.TRADE,
  NAME.TICKET,
  NAME.EXTERNAL,
  TRADE_TYPE,
  COMMODITY,

  COMPANY,
  COMPANY.CONTACT,
  COUNTERPARTY,
  COUNTERPARTY.COUNTERPARTY2,
  COUNTERPARTY.CONTACT,
  COUNTERPARTY.CREDIT,
  COUNTERPARTY.CREDIT.CONTACT,

  ENABLING_AGREEMENT,
  CONTRACT.MARKET,
  CONTRACT.SUPPLY,
  PAYMENT_TERM,
  FUEL_TYPE,

  ACCOUNT.ENVIRONMENTAL_SUB_ACCOUNT,
  ACCOUNT.FROM,
  ACCOUNT.TO,

  DATE.EFF_START,
  DATE.EFF_END,
  DATE.TRADE,
  DATE.MATURITY,
  DATE.EXPIRATION,
  DATE.EXTERNAL,
  DATE.RETIREMENT,
  TIME_ZONE,
  DIRECTION,

  LOCATION.REGION,
  LOCATION.POD,
  LOCATION.POR,
  LOCATION.MARKET,
  LOCATION.PIPELINE,
  LOCATION.METER,
  LOCATION.RECMETER,

  ISO_REFERENCE,
  ISO_REFERENCE.MARKET,
  ISO_REFERENCE.SELF_SCHED_OR_IST,
  ISO_REFERENCE.PRODUCT,
  ISO_REFERENCE.RESOURCE,
  ISO_REFERENCE.RESOURCE_TYPE,
  ISO_REFERENCE.SC,
  ISO_REFERENCE.SELF_SCHD_TYPE,
  ISO_REFERENCE.CONFIGURATION,
  ISO_REFERENCE.WHEEL_REF_NAME,
  ISO_REFERENCE.SUPPORTING_RESOURCE,
  ISO_REFERENCE.TRADE_TYPE,
  ISO_REFERENCE.SUBMITTING_SC,
  ISO_REFERENCE.TRADE_PRODUCT,
  ISO_REFERENCE.FROM_SC,
  ISO_REFERENCE.TO_SC,
  ISO_REFERENCE.DEPEND_ON_NAME,
  ISO_REFERENCE.TRADE_NAME,

  TERM.TYPE,
  TERM.INTERVAL,
  TERM.PERIOD,
  TERM.QUANTITY,
  TERM.PRICE,
  TERM.QUANTITY2,
  TERM.PRICE2,
  TERM.PRODUCT,
  TERM.UNIT,
  TERM.DELIVERABLE_TYPE,
  TERM.TIMELINE,
  TERM.MULTIPLIER,
  TERM.ADDER,

  INDEX.TERM_PRICE,
  INDEX.TERM_PRICE2,
  INDEX.MARKET_PRICE,
  INDEX.INTRA_MONTH_MARKET_PRICE,

  TRADER,
  PORTFOLIO,
  BOOK,
  STRATEGY,
  FIRM_TYPE,

  BROKER,
  BROKER.FEE.AMOUNT,
  BROKER.FEE.PERCENTAGE,
  BROKER.COMMISSION.AMOUNT,
  BROKER.COMMISSION.PERCENTAGE,

  OPTION.IS_OPTION,
  OPTION.TYPE,
  OPTION.STRIKE_PRICE,
  OPTION.OPTION_PREMIUM,
  OPTION.EXPIRATION_DATE,
  OPTION.PRICING_MODEL,
  OPTION.STYLE,
  OPTION.EXERCISE_FREQUENCY,

  //  TEXTS.EXTRAS,
  TEXTS.CUSTOM1,
  TEXTS.CUSTOM2,
  TEXTS.CUSTOM3,
  TEXTS.CUSTOM4,
  TEXTS.NOTES,

  CREATED_BY,
  CREATED_DATE,
  UPDATED_BY,
  UPDATED_DATE,
  RELATIONSHIPS,
];
