import LOOKUP_STORE from '@/store/lookupStore';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import { hasPermission } from '@/utils/authUtils';

const getters = {
    hasWritePermission: (_state, _getters, _rootState, rootGetters) =>
    // eslint-disable-next-line
    hasPermission(rootGetters['auth/getPermissions'], 'spp:schd:resource_offer_set', 'write'),
    getFeatureFlags: () => LOOKUP_STORE.state.userFeatureFlagList,
};

const actions = {
    // async initialize({ dispatch }) {
    //     // eslint-disable-next-line
    //     const payload = { 
    //         referenceItemList: ['fetchLocationList', 'fetchLocationGroupList'], 
    //         market: 'SPP', 
    //         commodity: 'POWER' 
    //     };
    //     await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
    //     dispatch('LOOKUP_STORE/fetchUserFeatureFlagList');
    // },
};

export default {
    namespaced: true,
    modules: { REFERENCE_DATA_STORE, LOOKUP_STORE },
    getters,
    actions,
};
