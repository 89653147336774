const caiso: any = {
  menu: /caiso:*/,
  ads: {
    menu: /caiso:ads:*/,
    dispatches: 'caiso:ads:dispatches',
    dynamicEtsr: 'caiso:ads:dynamic_etsr',
  },
  cmri: {
    menu: /caiso:cmri:*/,
    contractUsage: 'caiso:cmri:contract_usage',
    expectedEnergy: 'caiso:cmri:expected_energy',
    convergenceBidAwards: 'caiso:cmri:convergence_bid_awards',
    marketSchedules: 'caiso:cmri:market_schedules',
    marketAwards: 'caiso:cmri:market_awards',
    resourceForecast: 'caiso:cmri:resource_forecast',
    commitmentCost: 'caiso:cmri:commitment_cost',
    nonDispatchableTime: 'caiso:cmri:non_dispatchable_time',
    verForecast: 'caiso:cmri:ver_forecast',
    resourceLevelMovement: 'caiso:cmri:resource_level_movement',
    crrDownload: 'caiso:cmri:crr_download',
    crrReport: 'caiso:cmri:crr_report',
  },
  cira: {
    menu: /caiso:cira:*/,
    ciraReport: 'caiso:cira:cira_report',
    ciraPreCalcReport: 'caiso:cira:cira_precalcreport',
  },
  bsap: {
    menu: /caiso:bsap:*/,
    balancingTests: 'caiso:bsap:balancing_tests',
    baseSchedules: 'caiso:bsap:base_schedules',
  },
  masterFile: {
    menu: /caiso:masterfile:*/,
    resources: 'caiso:masterfile:resources',
  },
  meter: {
    menu: /caiso:meter:*/,
    read: 'caiso:meter:reads',
    preSubmission: 'caiso:meter:presubmission',
    submissions: 'caiso:meter:submissions',
    meterSubmitMappings: 'caiso:meter:metersubmissionmappings',
    internalmeterdownload: 'caiso:meter:internalmeterdownload',
    meterOverride: 'caiso:meter:meteroverride',
  },
  oasis: {
    menu: /caiso:oasis:*/,
    eimDemandForecast: 'caiso:oasis:eim_demand_forecast',
    sufficiencyEvaluationForecast: 'caiso:oasis:sufficiency_evaluation_forecast',
    eimTransferTie: 'caiso:oasis:eim_trans_tie',
    elapPrices: 'caiso:oasis:elap_prices',
    prices: 'caiso:oasis:prices',
    transTieReport: 'caiso:oasis:trans_tie_report',
  },
  oms: {
    menu: /caiso:oms:*/,
    outages: 'caiso:oms:outages',
    availability: 'caiso:oms:availability',
    raDownload: 'caiso:oms:ra_download',
    raReport: 'caiso:oms:ra_report',
  },
  samc: {
    menu: /caiso:samc:*/,
    loadedStatements: 'caiso:samc:loaded_statements',
    monthlyDailyDetail: 'caiso:samc:monthly_daily_detail',
    chargeCodeByBAA: 'caiso:samc:charge_code_by_baa',
    invoices: 'caiso:samc:invoices',
    invoicesSummary: 'caiso:samc:invoices_summary',
    settlementCycleSummary: 'caiso:samc:settlement_cycle_summary',
    meterComponent: 'caiso:samc:meter_component',
    meterStmtCompare: 'caiso:samc:meter_stmt_compare',
    statement: 'caiso:samc:statement',
    calculationComponents: 'caiso:samc:calculation_components',
    chargeCodeDescription: 'caiso:samc:charge_code_descriptions',
    comparePrices: 'caiso:samc:compare_prices',
    configuration: 'caiso:samc:configuration',
    configurationFile: 'caiso:samc:configuration_file',
    disputes: 'caiso:samc:disputes',
    eimSubAllocation: 'caiso:samc:eimsuballocation',
    eimSubAllocationValidation: 'caiso:samc:eimsuballocation:validation',
    chargeCodeDetails: 'caiso:samc:charge_code_details',
    statementDetails: 'caiso:samc:statement_details',
    determinantSummary: 'caiso:samc:determinant_summary',
    determinantDetails: 'caiso:samc:determinant_details',
    viewChargeDetails: 'caiso:samc:view_charge_details',
    preCalc: 'caiso:samc:pre_calc',
    shadowCalculation: 'caiso:samc:shadow_calc',
    transmissionCalculation: 'caiso:samc:transmission_calc',
    eimCalculation: 'caiso:samc:eim_calc',
    whatif: 'caiso:samc:whatif',
    tagSettlements: 'caiso:samc:tag_settlements',
    tagSettlementsReload: 'caiso:samc:tag_settlements_reload',
    tagSettlementsLogging: 'caiso:internal-data:tag-logging',
    tagSettlementsVsCmri: 'caiso:samc:tag_settlements_vs_cmri',
    settlementReview: 'caiso:samc:settlement_approval',
    settlementReviewAssignment: 'caiso:samc:settlement_approval_assignment',
    settlementReviewCalendar: 'caiso:samc:settlement_approval',
    etsrStatements: 'caiso:samc:etsr_statements',
    chargeCodeCategories: 'caiso:samc:charge_code_categories',
    nodalCalculation: 'caiso:samc:nodal_calc',
    shadowRunAdminOptions: {
      skipValidations: 'caiso:samc:shadowrunadminoptions:skipvalidations',
    },
    shadowRunTypeOptions: {
      shadow: 'caiso:samc:shadowruntype:shadow',
      allocation: 'caiso:samc:shadowruntype:allocation',
      whatIf: 'caiso:samc:shadowruntype:whatif',
      estimation: 'caiso:samc:shadowruntype:estimation',
    },
    operationalAdjustments: 'caiso:samc:op-adjustments',
    stateAllocRef: 'caiso:samc:state_alloc_ref',
  },
  sibr: {
    menu: /caiso:sibr:*/,
    bids: 'caiso:sibr:bids',
    trades: 'caiso:sibr:trades',
    cbbids: 'caiso:sibr:cbbids',
  },
  tools: {
    menu: /caiso:tools:*/,
    calculations: 'caiso:tools:calculations',
    createAReport: {
      menu: 'caiso:tools:create_a_report',
      reports: {
        ads: 'caiso:tools:create_a_report:ads',
        bsap: 'caiso:tools:create_a_report:bsap',
        cmri: 'caiso:tools:create_a_report:cmri',
        custom: {},
        eimBenefitCalc: 'caiso:tools:create_a_report:eim_benefit_calc',
        genMargin: 'caiso:tools:create_a_report:gen_margin_report',
        meterAndPrice: 'caiso:tools:create_a_report:meter_and_price',
        masterfile: 'caiso:tools:create_a_report:masterfile',
        meter: 'caiso:tools:create_a_report:meter',
        mris: 'caiso:tools:create_a_report:mris',
        oasis: 'caiso:tools:create_a_report:oasis',
        oms: 'caiso:tools:create_a_report:oms',
        sibr: 'caiso:tools:create_a_report:sibr',
      },
    },
    eventLog: 'caiso:tools:event_log',
    isoDownloader: 'caiso:tools:iso_downloader',
    isoEnvironment: 'caiso:tools:iso_environment',
    issueTracking: 'caiso:tools:issue_tracking',
    reportStatus: 'caiso:tools:report_status',
    resourceViewer: 'caiso:tools:resource_viewer',
    serverQueue: 'caiso:tools:server_queue',
    serverQueueTerminate: 'caiso:tools:server_queue_terminate',
    shadowSources: 'caiso:tools:shadow_sources',
    eimSuballocationMapping: 'caiso:tools:transmission_billing_info',
    eimTcPseMapping: 'caiso:tools:transmission_billing_pse_map',
    removeData: 'caiso:tools:remove_data',
    deleteData: 'caiso:tools:delete_data',
    shadowBatch: 'caiso:samc:shadow_batch',
    resourceCount: 'caiso:tools:resource_count',
  },
  scheduling: {
    menu: /caiso:scheduling:*/,
    bids: 'caiso:scheduling:bid',
    trades: 'caiso:scheduling:trades',
    baseSchedules: 'caiso:scheduling:base_schedules',
    virtual: 'caiso:scheduling:convergence_bid',
    consolidated: 'caiso:scheduling:consolidated',
    bidConfiguration: 'bidding:configuration',
    bidVariables: 'bidding:variables',
    bidExecution: 'bidding:execution',
    bidVariableValues: 'bidding:variables:value',
  },
};

export default caiso;