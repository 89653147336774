import moment from 'moment';
import { createMutations } from '@/utils/vuexHelper';
import {
  BIDDING_API, SCRIPTING_API, IDENTITY_API, STRUCTURES_API,
} from '@/api';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { HERows } from '@/utils/dataUtil';
import REFERENCE_DATA_STORE from '../../referenceDataStore';
import { EMPTY_HOURLY_VALUE } from '../static';

const state = {
  // page params
  commodity: undefined,
  entityType: undefined,
  market: '',
  marketTypes: [],
  moduleName: '',

  scripts: [],
  scriptCategories: [],
  editorCode: '',
  editor: null,

  strategyTableData: undefined,
  selectedLocations: [],
  marketType: '',
  startDate: dateStore.getDefaultDateLocal().toISOString(),

  hourlyType: undefined,
  variableDialogVisibility: false,
  dailyGlobalVariablesData: [],
  hourlyValuesData: [],
  hourlyValuesConfig: undefined,
  strategiesSelected: [],
  auditData: [],

  scs: [],
  selectedSc: [],
  selectedLocationGroupId: undefined,
  selectedLocationGroup: undefined,
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scriptList: (state) => {
    const scriptCategory = state.scriptCategories.find(({ name }) => name === `${
      state.market.toLowerCase() }-${ state.moduleName.toLowerCase() }-strategies`);
    const scripts = (scriptCategory) ? state.scripts.filter(({ categoryId }) => categoryId === scriptCategory.id) : [];
    return [...new Set(scripts.map(({ name }) => name))];
  },

  scriptCategoryName: (state) => `${ state.market.toLowerCase() }-${ state.moduleName.toLowerCase() }-strategies`,

  getEditorCode: (state) => state.editorCode,
  getEditor: (state) => state.editor,
  scList: (state) => _getList(state.scs, 'name'),
};

const actions = {
  async init({ dispatch }) {
    await dispatch('fetchScripts');
    await dispatch('fetchScriptCategories');
    await dispatch('fetchScs');
    await dispatch('fetchReferenceData');
    dispatch('defaultUserSettings');
  },
  locationGroupSelected({ state, commit, dispatch }, value) {
    const name = state.REFERENCE_DATA_STORE.locationGroupList
      .find((locationGroup) => locationGroup.id === value)?.shortName;
    commit('setSelectedLocationGroupId', value);
    commit('setSelectedLocationGroup', name);

    if (value) {
      dispatch('fetchLocationGroupLocations', value);
    }
  },
  defaultUserSettings({ state, commit, dispatch }) {
    const userSc = state.scs.find(({ name }) => name === caisoStore.coordinator);
    const selectedSc = userSc ? userSc.name : state.scs?.[0]?.name;
    commit('setSelectedSc', [selectedSc]);

    const userLocationGroup = state.REFERENCE_DATA_STORE.locationGroupList
      .find(({ shortName }) => shortName === caisoStore.resourceGroupName);
    if (userLocationGroup) {
      dispatch('locationGroupSelected', userLocationGroup.id);
    }
  },

  setPageParams({ commit }, {
    commodity,
    entityType,
    market,
    marketTypes,
    moduleName,
  }) {
    commit('setCommodity', commodity);
    commit('setEntityType', entityType);
    commit('setMarket', market);
    commit('setMarketTypes', marketTypes);
    commit('setMarketType', marketTypes[0].value);
    commit('setModuleName', moduleName);
  },
  async fetchAuditData({ state, commit }, { selectedRecord }) {
    const { id, variableType } = selectedRecord;
    const startTime = state.startDate.split('T')[0];
    const params = { id, startTime, type: variableType };
    let mappedData = [];
    try {
      const { data } = await BIDDING_API.get(`/variables/${id}/revisions`, { params });
      if (data) {
        if (params.type === 'DAILY') {
          mappedData = data.variables.map(({
            revisionId, createdBy, createdDate, updatedDate, value,
          }) => ({
            id: revisionId,
            createdBy,
            createdDate,
            effectiveDate: updatedDate,
            value,
          })) ?? [];
        } else if (params.type === 'HOURLY') {
          const unformattedHourlyData = data.variables.reduce((acc, curr) => {
            if (curr.createdDate in acc) {
              acc[curr.createdDate][`he${ moment(curr.startTime).get('hour') + 1}`] = curr.value;
            } else {
              acc[curr.createdDate] = {
                createdBy: curr.createdBy,
                createdDate: curr.createdDate,
                effectiveDate: curr.updatedDate,
              };
              acc[curr.createdDate][`he${ moment(curr.startTime).get('hour') + 1}`] = curr.value;
            }
            return acc;
          }, {});

          Object.keys(unformattedHourlyData).forEach((item) => (mappedData.push(unformattedHourlyData[item])));
        }
      }
    } catch (ex) {
      mappedData = [];
      this.$notify('Failed to load audit logs for Variables', 'error');
    } finally {
      commit('setAuditData', mappedData);
    }
  },
  async fetchScriptCategories({ commit }) {
    try {
      const { data } = await SCRIPTING_API.get('categories');
      commit('setScriptCategories', data.data);
    } catch (error) {
      console.error('Error fetching script categories', error);
    }
  },
  async fetchScripts({ commit }) {
    try {
      const { data } = await SCRIPTING_API.get();
      commit('setScripts', data.data.map((props) => props));
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async fetchStrategies({ state, commit }) {
    commit('setStrategyTableData', []);

    const {
      commodity, entityType, market, marketType, location, moduleName, startDate, selectedSc, selectedLocationGroup,
    } = state;

    const reqBody = {
      commodity,
      market,
      entityType,
      marketType,
      location,
      startDate: moment(startDate).format('MM/DD/YYYY'),
      endDate: moment(startDate).add(1, 'days').format('MM/DD/YYYY'),
      module: moduleName,
      scs: selectedSc.length ? selectedSc.join() : null,
      locationGroup: selectedLocationGroup,
    };
    try {
      const { data } = await BIDDING_API.get('/strategies', { params: reqBody });
      commit('setStrategyTableData', data);
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async fetchScs({ commit, state }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === state.entityType));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async fetchLocationGroupLocations({ commit, state }, groupId) {
    const { data } = await STRUCTURES_API.get(`/location-groups/${groupId}/locations`);
    const lgl = Array.isArray(data.locationGroupLocations)
      ? data.locationGroupLocations.filter((location) => location.marketName === state.market)
      : [];
    commit('setLocationGroupLocations', lgl);
  },
  async fetchReferenceData({ state, dispatch, commit }) {
    const payload = {
      referenceItemList: ['fetchLocationGroupList'],
      market: state.market,
      commodity: 'POWER',
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
  },
  async runStrategies({ state }) {
    const {
      commodity, market, entityType, moduleName, marketType, selectedLocations, startDate,
    } = state;

    const tz = dateStore.getTimeZone();
    const momentStartTime = dateStore.toDateFromLocal(startDate, tz);

    const reqBody = {
      commodity,
      market,
      entityType,
      module: moduleName,
      marketType,
      startTime: momentStartTime.toISOString(),
      endTime: momentStartTime.clone().add(1, 'days').toISOString(),
      tz,
      locations: selectedLocations,
    };

    try {
      await BIDDING_API.patch('/strategies', reqBody);
      this.$notify('Successfully Executed Scripts');
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async updateStrategies({ state }, updatedStrategies) {
    try {
      await BIDDING_API.put('/strategies', { strategies: updatedStrategies });
      this.$notify('Successfully Updated Scripts');
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async importStrategyScript({ state, commit }, fileName) {
    try {
      await SCRIPTING_API.post('/import', {
        configurationName: 'scripts-upload-configuration',
        fileName,
      });
      this.$notify({ type: 'success', message: 'Strategies Import Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Strategies Import Failed' });
      console.error(error);
    }
  },
};

const mutations = {
  setEditorSession(state) {
    const editor = ace.edit('scriptEditor');
    editor.getSession().setMode('ace/mode/javascript');
    editor.setTheme('ace/theme/monokai');
    editor.setShowPrintMargin(false);
    editor.session.setValue('');
    editor.$blockScrolling = Infinity;
    editor.setFontSize(12);
    editor.setHighlightActiveLine(true);

    state.editor = editor;
  },
  setEditorCode(state, item) {
    let code = item;
    code = JSON.parse(JSON.stringify(code, null));

    state.editorCode = code;
    state.editor.session.setValue(state.editorCode);
  },

  ...createMutations(
    'scripts',
    'scriptCategories',
    'startDate',
    'strategyTableData',
    'selectedLocations',
    'marketType',
    'marketTypes',
    'location',
    'commodity',
    'entityType',
    'market',
    'moduleName',
    'hourlyType',
    'variableDialogVisibility',
    'dailyGlobalVariablesData',
    'hourlyValuesData',
    'hourlyValuesConfig',
    'auditData',
    'scs',
    'selectedSc',
    'selectedLocationGroupId',
    'selectedLocationGroup',
    'locationGroupLocations',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  actions,
  mutations,
};
