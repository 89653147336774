import { accessRight, caiso, lookup } from '@/auth/permission';

export default {
  path: '/scheduling',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'SCHEDULING',
    permission: { name: caiso.scheduling.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'bid-schedules/detail',
    name: 'BidHorizontalDetail',
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'SIBR Self Schedules',
      permission: { name: caiso.scheduling.bids, rights: [accessRight.visible] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/Bid/BidHorizontalDetail/BidHorizontalDetail'),
  }, {
    path: 'bid-schedules/:locationType/:scheduleName/:variant',
    name: 'BidDetail',
    props: true,
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'SIBR Schedule Detail',
      permission: { name: caiso.scheduling.bids, rights: ['read', 'write'] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/Bid/Detail/BidDetail'),
  }, {
    path: 'bid-schedules/:locationType/:scheduleName/:variant/:executionId',
    name: 'ExecutionBidDetail',
    props: true,
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'SIBR Schedule Detail Preview',
      permission: { name: caiso.scheduling.bids, rights: ['read', 'write'] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/Bid/Detail/BidDetail'),
  }, {
    path: 'trade-schedules',
    name: 'InterScTrades',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'Submit ISTs',
      permission: { name: caiso.scheduling.trades, rights: [accessRight.visible] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/Trades/Submission/InterScTrades'),
  }, {
    path: 'ist-schedules/:scheduleName/:variant/:scheduleStatus',
    name: 'IstDetail',
    props: true,
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'IST Schedule Detail',
      permission: { name: caiso.scheduling.bids, rights: ['read', 'write'] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/Trades/Submission/Detail/IstDetail'),
  }, {
    path: 'ist-schedules/:scheduleName/:variant/:scheduleStatus/:executionId',
    name: 'ExecutionIstDetail',
    props: true,
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'IST Schedule Detail Preview',
      permission: { name: caiso.scheduling.bids, rights: ['read', 'write'] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/Trades/Submission/Detail/IstDetail'),
  }, {
    path: 'consolidated-schedules',
    name: 'ConsolidatedScheduling',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'Submit',
      // featureFlag: 'scheduling:consolidated',
      permission: { name: caiso.scheduling.consolidated, rights: [accessRight.visible] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/ConsolidatedSchedule/ConsolidatedSchedules'),
  }, {
    path: 'consolidated-details',
    name: 'ConsolidatedDetails',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'Curve',
      // featureFlag: 'scheduling:consolidated',
      permission: { name: caiso.scheduling.consolidated, rights: [accessRight.visible] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/ConsolidatedDetail/ConsolidatedDetail'),
  }, {
    path: 'virtual-schedules/:locationType/:scheduleName/:scheduleStatus',
    name: 'VirtualDetail',
    props: true,
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'Virtual Bid Detail',
      permission: { name: caiso.scheduling.bids, rights: ['read', 'write'] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/Virtual/Detail/VirtualDetail'),
  }, {
    path: 'base-schedules/:locationType/:scheduleName/:variant/:scheduleStatus',
    name: 'BaseScheduleLocationDetail',
    props: true,
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'Base Schedule Detail',
      permission: { name: caiso.scheduling.baseSchedules, rights: ['read', 'write'] },
      requiredTimeZone: 'PPT',
    },
    component: () => import('@/components/Scheduling/BaseSchedule/Detail/BaseScheduleDetail'),
  }, {
    path: 'blotter',
    name: 'blotter',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'Scheduling Blotter',
      featureFlag: 'pre_scheduling:blotter',
      market: 'caiso',
      timeZone: 'PPT',
      tz: 'America/Los_Angeles',
      permission: { name: 'pre_scheduling:blotter', rights: ['read', 'write'] },
    },
    component: () => import('@/components/Scheduling/Blotter/Blotter'),
  },
  {
    path: 'bidding',
    meta: {
      visible: true,
      requiresAuth: false,
      text: 'BIDDING',
      permission: { name: caiso.scheduling.bidConfiguration, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'strategies/caiso/bid',
      name: 'CAISO-SIBR',
      meta: {
        visible: true,
        requiresAuth: false,
        text: 'Bid Strategies',
        permission: { name: caiso.scheduling.bids, rights: ['write'] },
        requiredTimeZone: 'PPT',
      },
      props: {
        entityType: 'SC',
        commodity: 'POWER',
        market: 'CAISO',
        marketTypes: [{ label: 'DAM', value: 'DAM' }, { label: 'RTM', value: 'RTM' }],
        moduleName: 'sibrSchedules',
      },
      component: () => import('@/components/Scheduling/Strategies/Strategies'),
    }, {
      path: 'strategies/caiso/base',
      name: 'strategy',
      meta: {
        visible: true,
        requiresAuth: false,
        text: 'Base Schedule Strategies',
        permission: { name: caiso.scheduling.baseSchedules, rights: ['write'] },
        requiredTimeZone: 'PPT',
      },
      props: {
        entityType: 'SC',
        commodity: 'POWER',
        market: 'CAISO',
        marketTypes: [{ label: 'DAM', value: 'DAM' }, { label: 'RTM', value: 'RTM' }],
        moduleName: 'baseSchedules',
      },
      component: () => import('@/components/Scheduling/Strategies/Strategies'),
    }, {
      path: 'strategies/caiso/preview',
      name: 'preview',
      meta: {
        visible: true,
        requiresAuth: false,
        text: 'Schedules Preview',
        // permission: { name: caiso.scheduling.bidExecution, rights: ['enabled'] },
        featureFlag: 'bidding:schedule_preview',
      },
      props: {
        entityType: 'SC',
        commodity: 'POWER',
        market: 'CAISO',
        moduleName: 'sibrSchedules',
      },
      component: () => import('@/components/Scheduling/Preview/Schedules'),
    },
    // {
    //   path: 'strategies/caiso/ist',
    //   name: 'strategy',
    //   meta: {
    //     visible: true,
    //     requiresAuth: false,
    //     text: 'Inter-SC Trade Strategies',
    //     permission: { name: caiso.scheduling.trades, rights: ['write'] },
    //   },
    //   props: {
    //     entityType: 'SC',
    //     commodity: 'POWER',
    //     market: 'CAISO',
    //     moduleName: 'istSchedules',
    //   },
    //   component: () => import('@/components/Scheduling/Strategies/Strategies'),
    // }
    ],
  },
  {
    path: 'resource-availability',
    name: 'ResourceAvailability',
    meta: {
      visible: true,
      requiresAuth: false,
      text: 'Resource Availability',
      permission: { name: lookup.resourceAvailability, rights: ['read', 'write'] },
    },
    props: {
      featureFlag: 'scheduling:resourceAvailability',
    },
    component: () => import('@/components/Scheduling/ResourceAvailability/ResourceAvailability'),
  },

  ],
};